import client from "./BaseService";

export default {
    callCreateApi(payload) {
        return client.apiClient.post("pim-campaign/create", payload);
    },
    callAcceptApi(payload) {
        return client.apiClient.post("pim-campaign/accept", payload);
    },
    callDeclineApi(payload) {
        return client.apiClient.post("pim-campaign/decline", payload);
    },
    callReviewApi(payload) {
        return client.apiClient.post("pim-campaign/review", payload);
    },
    callCompleteApi(payload) {
        return client.apiClient.post("pim-campaign/complete", payload);
    },
    callReadSingleCustomerApi(payload) {
        return client.apiClient.post("pim-campaign/read", payload);
    },
    callCreatePackageApi(payload) {
        return client.apiClient.post("customer/create-package", payload);
    },
    callReadPackageApi(payload) {
        return client.apiClient.post("customer/read-package", payload);
    },
    callReadPackageByCustomerIdApi(payload) {
        return client.apiClient.post("pim-campaign/read-customer", payload);
    },
    callReadPackageByBusinessIdApi(payload) {
        return client.apiClient.post("pim-campaign/read-business", payload);
    },
    callReadRecentCampaignApi(payload) {
        return client.apiClient.post("pim-campaign/read-recent", payload);
    },
    callReadSinglePimApi(payload) {
        return client.apiClient.post("pim-campaign/read-single", payload);
    },
}