class AccountPayoutRequest {
  create(){
    return {
      accountName: null,
      accountNumber: null,
      otp: null,
      accountCountry: null,
      accountBankName: null,
      accountBankCode: "",
      accountCustomerId: null
    }
  }
}

export default AccountPayoutRequest;