<template>
  <div style="background-color: white">
    <section id="IntroMessageModal" class="qoo-modal add-business-modal">
      <div class="welcome-modal">
        <h5 class="heading-4">Add company</h5>
        <div class="w-form">
          <form
            id="email-form-2"
            name="email-form-2"
            data-name="Email Form 2"
            method="get"
            @submit.prevent="createCompany"
          >
            <div class="div-block-15">
              <label for="name" class="form__label">Company Name</label
              ><input style="padding-left: 16px;text-transform: capitalize"
                type="text"
                class="form__input w-input"
                maxlength="256"
                name="name"

                data-name="Name"
                placeholder=""
                id="name"
                v-model="organisationModel.organisationName"
              />
            </div>
            <div class="div-block-15">
              <label for="name-3" class="form__label">Company Type</label
              ><select
                id="field-4"
                name="field-4"
                data-name="Field 4"
                style="text-transform: capitalize"
                class="form__input select w-select"
                v-model="organisationModel.organisationType"
              >
                <option value="">Select one...</option>
                <option v-for="category in organisation.organisationTypes.data" v-bind:key="category.value" :value="category.value">{{ category.label }}</option>
              </select>
            </div>
            <label class="upload-images-frame _1" v-if="url === ''">
              <div v-if="!isLoader">
                <input
                    style="display: none"
                    type="file"
                    class="input-file"
                    id="organisation"
                    name="files"
                    accept="image/*"
                    ref="file"
                    @change="uploadFile('organisation')"
                />
                <a class="addfiles_btn">
                  <div class="w-embed">
                    <svg
                        width="16"
                        height="16"
                        viewbox="0 0 16 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          d="M8.49967 3.3335V12.6668M3.83301 8.00016H13.1663"
                          stroke="currentColor"
                          stroke-width="1.33333"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <div>Add image</div>
                </a>
              </div>
              <div class="addfiles_btn" v-else>
                Uploading...
              </div>
            </label>
            <div class="upload-images-frame _1" v-else style="height: 142px; padding: 0">
              <img :src="url" style="height: 100%;">
            </div>

            <input
              type="submit"
              value="Create Business"
              data-wait="Please wait..."
              class="btn is-wide"
              v-if="!isLoader && url !== ''"
            />
            <button class="btn is-wide" disabled v-else-if="url === ''">Create Business</button>
            <div v-else class="btn is-wide">
              <b-spinner
                small
                style="color: #fff"
              />
            </div>
          </form>
        </div>
      </div>
    </section>
    <main class="main-wrapper" v-if="stage === 1" style="height: 100%">
      <div class="campaign__div" style="height: 100vh">
        <div class="col-3">
          <div class="campaign__wrapper">
            <div class="campaign-info-wrapper">
              <img
                src="../../assets/images/apvertise-icon.svg"
                loading="lazy"
                alt=""
                class="campaign__logo-icon"
              />
              <h3 class="camapign__h3">Launch your campaign on Apvertise</h3>
            </div>
            <img
              src="../../assets/images/campaignIMG.png"
              loading="lazy"
              sizes="(max-width: 991px) 100vw, 391.296875px"
              srcset="
                ../../assets/images/campaignIMG-p-500.png 500w,
                ../../assets/images/campaignIMG.png       716w
              "
              alt=""
              class="image-7"
            />
          </div>
        </div>
        <div class="col-7" style="width: 100%; max-width: 90%; margin:0 auto;">
          <div class="campaign__frame">
            <div class="steps_wrapper">
              <div class="div-block-12">
                <div class="w-form">
                  <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    method="get"
                  >
                    <div class="campaign__form__label _1">
                      What is your campaign goal?
                    </div>

                    <div class="ms-radio-group">
                      <a class="ms-radio-card _1 w-form-label" style="border: 1px solid #d0d5dd; text-decoration: none; margin-bottom: 20px; position: relative" :class="{'active' : !acquistion}" @click="acquistion = false">
                        Generate leads, increase brand awareness<br />‍<span
                        class="ms-help-text"
                      >Increase your customers base &amp; drive
                            sustainable growth.<br /></span
                      >
                        <div class="ms-svg radio-card w-embed" v-if="!acquistion" style="display: flex;">
                          <img src="../../assets/images/checkmark.svg" />
                        </div>
                      </a>
                      <a class="ms-radio-card _2 w-form-label" style="border: 1px solid #d0d5dd; text-decoration: none; margin-bottom: 20px; position: relative" :class="{'active' : acquistion}" @click="acquistion = true">
                        Drive downloads/signups on your platform<br />‍‍‍<span
                        class="ms-help-text"
                      >Generate high quality leads &amp; unlock more
                            conversions<br /></span
                      >
                        <div class="ms-svg radio-card w-embed" v-if="acquistion" style="display: flex;">
                          <img src="../../assets/images/checkmark.svg" />
                        </div>
                      </a>
                      <div class="w-embed"></div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="step">
                <div class="step_num">
                  <div class="step_number">1</div>
                </div>
                <div class="item_text">
                  <h6 class="step_heading">Provide details of your campaign</h6>
                  <p class="step_p">
                    You&#x27;ll need to provide details about your campaign,
                    including the target audience, budget, and campaign goals.
                    This information will help Apvertise match you with the
                    right influencers for your campaign.
                  </p>
                </div>
              </div>
              <div class="step">
                <div class="step_num">
                  <div class="step_number">2</div>
                </div>
                <div class="item_text">
                  <h6 class="step_heading">
                    Specify more information about your campaign
                  </h6>
                  <p class="step_p">
                    To launch your campaign on Apvertise, select your desired
                    campaign location, promotion type (awareness or sign-up),
                    and budget. Your campaign will be displayed on the Apvertise
                    platform once these details are set.
                  </p>
                </div>
              </div>
              <div class="step">
                <div class="step_num">
                  <div class="step_number">3</div>
                </div>
                <div class="item_text">
                  <h6 class="step_heading">
                    Upload images for your Campaign banner
                  </h6>
                  <p class="step_p">
                    You&#x27;ll need to create a campaign banner that will be
                    displayed on the Apvertise platform. The banner should be
                    eye-catching and informative, and it should give influencers
                    a clear idea of what your campaign is about.
                  </p>
                </div>
              </div>
            </div>
            <div class="steps__btn-group">
              <a href="#" class="steps__btn alt" @click="goToAllCampaign">
                <div class="w-embed">
                  <svg
                    width="20"
                    height="20"
                    viewbox="0 0 20 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00004 11.6668L3.83337 7.50016M3.83337 7.50016L8.00004 3.3335M3.83337 7.50016H13.8334C14.7174 7.50016 15.5653 7.85135 16.1904 8.47647C16.8155 9.10159 17.1667 9.94944 17.1667 10.8335V16.6668"
                      stroke="currentColor"
                      stroke-width="1.67"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                <div>Cancel</div>
              </a>
              <a class="steps__btn" @click="stage++">
                <div>Proceed</div>
                <div class="w-embed">
                  <svg
                    width="20"
                    height="20"
                    viewbox="0 0 20 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 15L13 10L8 5"
                      stroke="currentColor"
                      stroke-width="1.67"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
    <main class="main-wrapper" v-if="stage > 1"  style="height: 100%">
      <div class="campaign__div" style="height: 100vh">
        <div class="col-3">
          <div class="campaign__wrapper">
            <div class="campaign-info-wrapper">
              <img
                src="../../assets/images/apvertise-icon.svg"
                loading="lazy"
                alt=""
                class="campaign__logo-icon"
              />
              <div class="step__title" v-if="stage <= 3">
                <div class="step__tag">Step 1</div>
                <h3 class="camapign__h3">Provide details of your campaign</h3>
              </div>
              <div class="step__title" v-if="stage > 3 && stage < 6">
                <div class="step__tag">Step 2</div>
                <h3 class="camapign__h3">
                  Provide more information about your campaign
                </h3>
              </div>
              <div class="step__title" v-if="stage >= 6">
                <div class="step__tag">Step 3</div>
                <h3 class="camapign__h3">Upload images for your campaign banner</h3>
              </div>
            </div>
            <img
              src="../../assets/images/provideDetails.png"
              loading="lazy"
              sizes="(max-width: 991px) 100vw, 391.296875px"
              srcset="
                ../../assets/images/provideDetails-p-500.png 500w,
                ../../assets/images/provideDetails.png       658w
              "
              alt=""
              class="image-7"
            />
          </div>
        </div>
        <div class="col-7" style="width: 100%; max-width: 70%; margin: 0 auto">
          <div class="campaign__frame form-block">
            <div class="campaign__steps-div">
              <div class="progress-bar-1">
                <div class="progress-1 active"></div>
                <div class="progress-1" :class="{ active: stage > 3 }"></div>
                <div class="progress-1" :class="{ active: stage >= 6 }"></div>
              </div>
              <div class="form__block w-form">
                <form
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                  method="get"
                  class="form"
                  v-if="stage === 2"
                >
                  <div class="campaign__form__item">
                    <label for="First-Name-2" class="campaign__form__label"
                      >What would you like to call your campaign?</label
                    >
                    <div class="input">
                      <input
                        type="text"
                        class="form__input no-icon w-input"
                        maxlength="256"
                        name="field"
                        data-name=""
                        placeholder="My first campaign"
                        id="field"
                        required=""
                        style="text-transform: capitalize"
                        v-model="model.campaignName"
                      />
                    </div>
                  </div>
                  <div class="campaign__form__item">
                    <div class="div-block-13">
                      <label for="First-Name-2" class="campaign__form__label"
                        >Select business</label
                      >
                      <a
                        @click="uploadProfile('.add-business-modal')"
                        class="link-block-3 w-inline-block"
                      >
                        <div class="text-block-17">+ Add new business</div>
                      </a>
                    </div>
                    <div
                      data-hover="false"
                      data-delay="0"
                      class="form__input business-dropdown w-dropdown"
                      @click="openNavbar"
                    >
                      <div class="dropdown-toggle-4 w-dropdown-toggle">
                        <div class="icon-2 w-icon-dropdown-toggle"></div>
                        <div class="text-block-18" style="text-transform: capitalize">
                          {{ name || "Select an existing business" }}
                        </div>
                      </div>
                      <nav class="dropdown-list-4 w-dropdown-list" v-if="organisations.length > 0">
                        <a href="#" class="w-dropdown-link" style="text-transform: capitalize" v-for="(company) in organisations" v-bind:key="company.organisationId" @click="selectCompany(company)">{{ company.organisationName }}</a>
                      </nav>
                    </div>
                    <p v-if="text" style="color: red; font-size: 12px">You don't have any existing business. Add new business</p>

                  </div>
                  <div class="campaign__form__item">
                    <div class="campaign__label" >
                      <label for="First-Name" class="campaign__form__label"
                        >Give your campaign a description</label
                      ><label for="First-Name" class="campaign__form__label-sub"
                        >The more detailed you are, the more effective our
                        influencers are.</label
                      >
                    </div>
                    <div class="input">
                      <textarea
                        placeholder="Example Text"
                        maxlength="5000"
                        id="field-3"
                        name="field-3"
                        data-name="Field 3"
                        class="textarea w-input"
                        v-model="model.campaignDescription"
                      ></textarea>
                    </div>
                  </div>
                  <div class="campaign__form__item">
                    <div class="campaign__label">
                      <label for="First-Name" class="campaign__form__label"
                        >Set your campaign start and end dates</label
                      ><label for="First-Name" class="campaign__form__label-sub"
                        >This determines the duration of your campaign</label
                      >
                    </div>
                    <div class="input__row">
                      <div class="ms-input-wrap">
                        <input
                          type="text"
                          class="ms-input icon-left w-input"
                          data-input="datepicker"
                          autocomplete="off"
                          maxlength="256"
                          name="Date-2"
                          data-name="Date 2"
                          placeholder="Start date"
                          id="Date-20"
                          data-ms-member="FIELD_ID"
                          required=""
                          onfocus="(this.type='date')"
                          v-model="model.campaignStartDate"
                        />
                      </div>
                      <div class="date-text">to</div>
                      <div class="ms-input-wrap">
                        <input
                          type="text"
                          class="ms-input icon-left w-input"
                          data-input="datepicker"
                          autocomplete="off"
                          maxlength="256"
                          name="Date-2"
                          data-name="Date 2"
                          placeholder="End date"
                          id="Date-20"
                          data-ms-member="FIELD_ID"
                          required=""
                          onfocus="(this.type='date')"
                          v-model="model.campaignEndDate"
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <form
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                  method="get"
                  v-if="stage === 3"
                >
                  <div style="margin-bottom: 18px">
                    <p class="campaign__form__label">
                      What industry does your product/service belong in?
                    </p>
<!--                    <p class="campaign__form__label-sub">-->
<!--                      You can select a maximum of 1 industry.-->
<!--                    </p>-->
                  </div>
                  <div data-ms-member="skills" class="ms-select-button-wrap">
                    <a class="w-checkbox ms-select-button" :class="{'w--redirected-checked': myCategory.includes(category) && myCategory.length < 4 }"  v-for="(category, index) in categoryChoice" :key="index" style="border-style: solid;border-color: #eaecf0;border-radius: 100px;"  @click="checkCategory(category)">
                      <div class="w-checkbox-input w-checkbox-input--inputType-custom ms-button-cb" style="z-index: 3; border: none;width: 100%;color: #344054;font-family: Figtree, sans-serif;font-size: 1rem;font-weight: 500;position: relative;">{{category}}</div>
                    </a>
                  </div>
                </form>
                <form
                  id="email-form-2"
                  name="email-form-2"
                  data-name="Email Form 2"
                  method="get"
                  class="form-2 _1"
                  v-if="stage === 4"
                >
                  <div class="campaign__form__item">
                    <div class="campaign__label">
                      <p class="campaign__form__label new">
                        What’s your budget?
                      </p>
                      <label for="First-Name" class="campaign__form__label-sub"
                        >The higher your budget the wider your campaign
                        reach</label
                      >
                    </div>
                    <div class="input">
                      <input
                        type="text"
                        class="form__input"
                        maxlength="256"
                        name="Password"
                        data-name="Password"
                        placeholder="Enter amount e.g 10000"
                        id="Password"
                        v-model="model.campaignBudget"
                        required=""
                        style="width: 100%;"
                      /><span class="country__code right">USD</span><img
                        src="../../assets/images/lock.svg"
                        loading="lazy"
                        alt=""
                        class="form__input-icon"
                      />
                    </div>
                    <label for="First-Name" class="campaign__form__label-sub"
                      >${{model.campaignBudget ? model.campaignBudget : 0.00}} = {{model.campaignBudget ? model.campaignBudget / auth.userInfo.campaignPrice : 0}} impressions</label
                    >
                  </div>
                  <div>
                    <p class="campaign__form__label new">
                      What’s your target location?
                    </p>
                    <p class="campaign__form__label-sub">
                      You can select more than one target location for your
                      product discovery.
                    </p>
                    <div class="location">
                      <b-row>
<!--                        <b-col md="8"-->
<!--                        ><label for="Location" class="field-label-9"-->
<!--                        >Location-->
<!--                          <b-icon-info-circle-->
<!--                            style="cursor: pointer"-->
<!--                            @click="displayMessage('Location', locationMsg)" /></label-->
<!--                        ></b-col>-->
                        <b-col md="4" style="width: 100%; margin: 8px 0; display: flex; gap: 6px"
                        ><input
                          type="checkbox"
                          @click="value = []"
                          v-model="selectAllCountry"
                        /><span style="color: black; font-size: 14px; font-weight: 300"> Select All Countries</span></b-col
                        >
                      </b-row>
                      <multiselect
                        style="font-size: 14px"
                        id="Location"
                        v-model="value"
                        :disabled="selectAllCountry"
                        tag-placeholder="Add list countries to view campaigns"
                        placeholder="Search or add a country"
                        label="name"
                        track-by="code"
                        :options="countryOption"
                        :multiple="true"
                        :taggable="true"
                        @tag="addTag"
                      ></multiselect>
                    </div>

                  </div>
                  <div>
                  <p class="campaign__form__label new">
                    What’s your target region?
                  </p>
                  <p class="campaign__form__label-sub">
                    You can select more than one target region for your
                    product discovery.
                  </p>
                    <div class="region">
                      <b-row>
<!--                        <b-col md="8"-->
<!--                        ><label for="Location" class="field-label-9"-->
<!--                        >Region-->
<!--                          <b-icon-info-circle-->
<!--                            style="cursor: pointer"-->
<!--                            @click="displayMessage('Region/State', regionMsg)" /></label-->
<!--                        ></b-col>-->
                        <b-col md="4" style="width: 100%; margin: 8px 0; display: flex; gap: 6px"
                        ><input
                          type="checkbox"
                          @click="regionValue = []"
                          v-model="selectAllRegion"
                        /><span style="color: black; font-size: 14px; font-weight: 300"> Select All Regions</span></b-col
                        >
                      </b-row>
                      <multiselect
                        style="font-size: 14px"
                        id="Region"
                        v-model="regionValue"
                        :disabled="selectAllRegion"
                        tag-placeholder="Add list region to view campaigns"
                        placeholder="Search or add a region"
                        label="name"
                        track-by="code"
                        :options="states"
                        :multiple="true"
                        :taggable="true"
                        @tag="addTag"
                      ></multiselect>
                    </div>

                  </div>
                </form>
                <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form" v-if="stage === 5">
                  <div class="campaign__form__item">
                    <div class="div-block-13"><label for="First-Name-2" class="campaign__form__label">Select Channel</label></div>
                    <div data-hover="false" data-delay="0" class="form__input business-dropdown" @click="openNavbar">
                      <div class="dropdown-toggle-4 w-dropdown-toggle">
                        <div class="w-icon-dropdown-toggle"></div>
                        <div>{{exhibitionName ? exhibitionName : "Select a Channel"}}</div>
                      </div>
                      <nav class="dropdown-list-4 w-dropdown-list">
                        <a href="#" class="w-dropdown-link" style="text-transform: capitalize" v-for="(list, index) in getExhisibition.list" :key="index" @click="createExhisibition(list)">{{list.label}}</a>

                      </nav>
                    </div>
                  </div>
                  <div class="campaign__form__item" v-if="model.campaignType !== 'ACQUISITION'">
                    <div class="campaign__label"><label for="First-Name" class="campaign__form__label">Web/Social Media Page Link?</label></div>
                    <div class="ms-input-wrap"><input type="text" class="ms-input https" maxlength="256" name="input14" data-name="input14" placeholder="www.link.com" id="input-15" data-ms-member="FIELD_ID" style="width: 100%;" v-model="model.campaignLink">
                      <div class="ms-input-inline">https://</div>
                    </div>
                  </div>
                  <div class="campaign__form__item" v-else>
                    <div class="campaign__label"><label for="First-Name" class="campaign__form__label">Firebase dynamic link (the long link)?</label></div>
                    <div class="ms-input-wrap"><input type="text" class="ms-input https w-input" maxlength="256" name="input14" data-name="input14" placeholder="www.link.com" id="input-15" data-ms-member="FIELD_ID" v-model="model.campaignLink">
                      <div class="ms-input-inline">https://</div>
                    </div>
                  </div>
                </form>
                <div class="campaign__steps-div" v-if="stage === 6">
                  <div class="steps__text">
                    <p class="campaign__form__label">Lets add some photos of your product</p>
                    <p class="campaign__form__label-sub">Upload at least 3 beautiful images to effectively showcase your product and capture the attention of potential customers.</p>
                  </div>
                  <div class="campaign-product-images">
                    <upload-image-with-cropper-v2/>
                  </div>
                </div>
              </div>
            </div>
            <div class="steps__btn-group">
              <a href="#" class="steps__btn alt" @click="stage--">
                <div class="w-embed">
                  <svg
                    width="20"
                    height="20"
                    viewbox="0 0 20 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00004 11.6668L3.83337 7.50016M3.83337 7.50016L8.00004 3.3335M3.83337 7.50016H13.8334C14.7174 7.50016 15.5653 7.85135 16.1904 8.47647C16.8155 9.10159 17.1667 9.94944 17.1667 10.8335V16.6668"
                      stroke="currentColor"
                      stroke-width="1.67"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                <div>Back</div>
              </a>
              <a class="steps__btn" @click="increaseState">
                <div>Proceed</div>
                <div class="w-embed">
                  <svg
                    width="20"
                    height="20"
                    viewbox="0 0 20 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 15L13 10L8 5"
                      stroke="currentColor"
                      stroke-width="1.67"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import "../../assets/css/apvertise-new.css";
import { mapState } from "vuex";
import CampaignRequest from "@/model/request/CampaignRequest";
import OrganisationRequest from "@/model/request/OrganisationRequest";
import s3Request from "@/model/request/S3Request";
import CountriesStates from "@/utilities/CountriesStatesUtil";
import countriesJson from "@/assets/countries/data/en/countries.json";
import store from "@/store/store";
import UploadImageWithCropperV2 from "@/components/form/input/UploadImageWithCropperV2.vue";
export default {
  name: "RevampCreateCampaignFormOne",
  components: { UploadImageWithCropperV2 },
  data() {
    return {
      categoryChoice: ["Agriculture", "Arts & Entertainment", "Education", "Fashion & Lifestyle", "Finance", "Fitness", "Gadgets", "Health", "Investment", "SME", "Technology", "Telecommunication", "Travel & Tourism", "Others"],
      stage: 1,
      url1: "",
      url2: "",
      url3: "",
      acquistion: false,
      model: CampaignRequest.prototype.campaignCreateRequest(),
      name: '',
      organisationModel: OrganisationRequest.prototype.organisationCreateRequest(),
      uploadmodel: s3Request.prototype.uploadBase64(),
      isLoader: false,
      myCategory: [],
      fileName: "",
      url: "",
      selectAllCountry: false,
      selectAllRegion: false,
      value: [],
      regionValue: [],
      text: false,
      exhibitionList: "",
      filteredLocation: "",
      filteredRegion: "",
      exhibitionName: ""
    };
  },
  computed:{
    ...mapState(["auth", "sbucket", "organisation", "campaign" ]),
    ...mapState({
      organisations:(state) => state.organisation.organisations
    }),
    countries() {
      return countriesJson;
    },
    countriesLocation() {
      let location = []
      this.countries.forEach((country) => {
        if (country.name.toLowerCase().includes(this.filteredLocation.toLowerCase())){
          location.push(country)
        }
      })
      return location
    },
    countryOption() {
      return this.countries.map(function (val) {
        return { code: val.alpha2.toString().toUpperCase(), name: val.name };
      });
    },
    states() {
      let result = CountriesStates.filter(it =>this.value.map((val)=>{return val.code}).join().toLowerCase().includes(it.code2.toLowerCase()));
      let rtn = []
      result.length > 0 ? result.map((country)=>country.states).forEach((c)=>c.forEach((s)=>rtn.push(s))) : [];
      return rtn
    },
    statesRegion() {
      let result = CountriesStates.filter(it =>this.value.map((val)=>{return val}).join().toLowerCase().includes(it.name.toLowerCase()));
      let rtn = []
      result.length > 0 ? result.map((country)=>country.states).forEach((c)=>c.forEach((s)=>rtn.push(s))) : [];
      let location = []
      rtn.forEach((country) => {
        if (country.name.toLowerCase().includes(this.filteredRegion.toLowerCase())){
          location.push(country)
        }
      })
      return location
    },
    getExhisibition(){
      let exhibit = []
      if (this.acquistion){
        this.campaign.exhibitionTypes.data.forEach((exh) => {
          if (exh.group.includes("SIGNUP")){
            exhibit = exh;
          }
        })
      }
      else {
        this.campaign.exhibitionTypes.data.forEach((exh) => {
          if (exh.group.includes("AWARENESS")){
            exhibit = exh;
          }
        })
      }
      return exhibit
    }
  },
  methods: {
    createExhisibition(list){
      this.exhibitionList = list.value
      this.exhibitionName = list.label
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    selectCompany(company){
      this.name = company.organisationName;
      this.model.campaignOrganisationId = company.organisationId;
    },
    checKCountry(id){
      console.log(document.getElementById(id))
      if (this.value.includes(document.getElementById(id).value)){
        this.value.filter((idd) => {idd.name === document.getElementById(id).value})
      }
      else {
        this.value.push(document.getElementById(id).value)
      }
    },
    checKState(id){
      console.log(document.getElementById(id))
      if (this.regionValue.includes(document.getElementById(id).value)){
        this.regionValue.filter((idd) => {idd.name === document.getElementById(id).value})
      }
      else {
        this.regionValue.push(document.getElementById(id).value)
      }
    },
    isDynamicLink(link) {
      return (link ? link : "").includes("?link=");
    },
    async increaseState(){
      if (this.stage < 6){
        this.stage++
      }
      else if (this.stage === 6){
        this.model.campaignCustomerId = this.auth.userInfo.customerId
        this.model. campaignType = this.exhibitionList;
        this.model.campaignChannel = this.exhibitionList;
        if (!this.isDynamicLink(this.model.campaignLink) && this.model.campaignType.includes("APP")){
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: "Invalid firebase link please use the <code>long link</code> generated on your firebase account",
          });
          return
        }
        if (
          (this.model.campaignLink === "" || this.model.campaignLink == null) &&
          (this.model.campaignLink2 === "" || this.model.campaignLink2 == null)
        ) {
          let payload = {
            type: "error",
            title: "Error",
            body: "Website or Social media link must not be empty",
          };
          if (this.model.campaignType.includes("APP"))
            payload.body =
              "Either Playstore or Appstore link must be provided";
          store.commit("updateMessage", payload);
          return;
        }
        if (this.selectAllCountry) {
          this.model.campaignAudience = "ALL";
        } else {
          this.model.campaignAudience = this.value
            .map(function (val) {
              return val.code;
            })
            .join(",");

        }
        if (this.selectAllRegion) {
          this.model.campaignRegion = "ALL";
        } else {
          this.model.campaignRegion = this.regionValue
            .map(function (val) {
              return val.code;
            })
            .join(",");
        }
        this.model.campaignCategory = this.myCategory.join();
        this.model.campaignBanners = this.sbucket.urls.join() ;
        this.$store.commit("campaign/updateCampaignForm", this.model);
        console.log(this.campaign.campaignForm);
        console.warn(JSON.stringify(this.campaign.campaignForm));
        await this.$store.dispatch(
          "campaign/createCampaign",
          this.model
        );


      }
    },
    goToAllCampaign() {
      this.$router.push("/campaign-all");
    },
    goToNextForm() {
      this.$emit("goNext");
    },
    uploadProfile(className) {
      document.querySelector(className).style.display = "flex";
      setTimeout(() => {
        document.querySelector(className).style.opacity = 1;
      }, 300);
    },
    openNavbar(){
      if(this.organisations.length <= 0){
        this.text = true;
        setTimeout(() => {
          this.text = false
        }, 1500)
      }
      else {
        document.querySelector(".dropdown-list-4").classList.toggle('w--open')
      }
    },
    openCountryDropdown(){
      document.querySelector(".ms-multi-select-wrap").classList.toggle('flexx')
    },
    openCountryDropdown2(){
      document.querySelector(".ms-multi-select-wrap2").classList.toggle('flexx')
    },
    ifExist(event){
      for( let i = 0; i < 1; i++){

        if ( this.myCategory[i] === event.target.value) {

          this.myCategory.splice(i, 1);
        }
        else if (this.myCategory.length < 1){
          this.myCategory.push(event.target.value)
        }
      }
    },
    checkCategory(category){
      if (this.myCategory.includes(category)){
        this.myCategory = this.myCategory.filter((item) => item !== category);
      }
      else if (this.myCategory.length < 1) {
        this.myCategory.push(category)
      }
    },
    uploadFile(id) {
      let input = document.getElementById(id)
      console.log(input)
      let files = input.files;
      console.log(files)
      this.fileName = files[0].name;
      let size = files[0].size / 1000000;
      console.log(size);
      const reader = new FileReader();
      try {
        reader.onload = (e) => {
          this.base64 = e.target.result;
          this.uploadOfficerImage();
        };
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);
      } catch (e) {
        console.warn(e.message);
      }
    },
    async uploadOfficerImage() {
      this.isLoader = true;
      this.uploadmodel.username = `${
        this.auth.userInfo.customerFirstName + this.auth.userInfo.customerLastName
      }_${Date.now()}`;
      this.uploadmodel.base64 = this.base64;
      await this.$store.dispatch(
        "sbucket/uploadEncodedFile",
        this.uploadmodel,
        { root: true }
      );
      this.url = this.sbucket.s3bucketResponse.url;
      this.organisationModel.organisationLogo = this.sbucket.s3bucketResponse.url;
      if (this.url1 === ""){
        this.url1 = this.sbucket.s3bucketResponse.url;
      }
      else if (this.url2 === ""){
        this.url2 = this.sbucket.s3bucketResponse.url;
      }
      else {
        this.url3 = this.sbucket.s3bucketResponse.url;
      }
      console.log(this.url)
      this.isLoader = false;
    },
    async createCompany() {
      this.isLoader = true;
      this.organisationModel.organisationLogo = this.url;
      this.organisationModel.organisationPhone = this.auth.userInfo.customerPhoneNumber;
      this.organisationModel.organisationEmail = this.auth.userInfo.customerEmail;
      this.organisationModel.organisationCustomerId = this.auth.userInfo.customerId;
      await this.$store.dispatch(
        "organisation/createOrganisation",
        this.organisationModel
      );
      await this.$store.dispatch("organisation/updateOrganisations", { customerId: this.auth.userInfo.customerId});
      this.close(".add-business-modal")
      this.isLoader = false;
    },
    close(className) {
      document.querySelector(className).style.opacity = 0;
      setTimeout(()=>{
        document.querySelector(className).style.display = "none";

      }, 500)
    },
  },
  mounted() {
    window.onclick = function(event) {
      if (event.target === document.querySelector(".add-business-modal")) {
        document.querySelector(".add-business-modal").style.opacity = 0;
        setTimeout(()=>{
          document.querySelector(".add-business-modal").style.display = "none";
        }, 500)
      }
    }
  }
};
</script>

<style scoped>
@media screen and (max-width: 320px) {
  .date-text{
    display: none!important;
  }
  .ms-input-wrap{
    align-items: center!important;
  }
}


@media screen and (max-width: 991px) {
  .col-3{
    height: auto!important;
    padding: 32px!important;
  }
  .campaign__div{
    height: auto!important;
  }
}
@media screen and (max-width: 370px) {
  .steps__btn-group{
    gap:25px!important;
  }
  .col-7{
    overflow: scroll!important;
  }
}
@media screen and (max-width: 360px) {
  .campaign__label{
    width: 300px!important;
  }

}

.ms-hide:checked ~ .ms-radio-card {
  border-color: #489e85;
  outline: 1px solid #489e85;
}
.ms-hide:checked ~ .ms-svg {
  display: flex;
}

.col-3 {
  height: 100vh;
  background-color: #f2f4f7;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 32px;
  display: flex;
  position: relative;
  width: 100%;
  max-width: 100%;
}

.image-7 {
  max-width: 70%;
  opacity: 0.1;
  position: absolute;
  top: 28%;
  bottom: 0%;
  left: 16%;
  right: 0%;
}

.col-7 {
  width: 100%!important;
  max-width: 100%!important;
  margin: 0px auto!important;
}

.ms-radio-card {
  border: none;
}

.ms-radio-wrap.card {
  margin-bottom: 20px;
}

.ms-radio-card {
  font-weight: 500;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 0;
}

.ms-help-text {
  font-size: 14px;
  margin: -15px 0 0;
}

.div-block-13 {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.progress-bar-1 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  margin-bottom: 10px;
  display: flex;
  position: static;
}

.progress-1.active {
  background-color: #489e85 !important;
}
.progress-1 {
  width: 32%;
  height: 5px;
  background-color: #d0d5dd;
  border-radius: 50rem;
  position: static;
}

.div-block-15 {
  display: block;
}

.btn {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  color: #fff;
  background-color: #489e85;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  font-weight: 600;
  text-decoration: none;
  display: flex;
  background-image: none;
}

.ms-select-button:hover,
.ms-dropdown-cb-label:hover,
.ms-button-cb:hover {
  border-color: #489e85;
}

.w--redirected-checked{
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)) !important;
  background-position: 0 0;
  background-size: auto;
  border-width: 2px !important;
  border-color: #489e85 !important;
  border-radius: 100px !important;
}

.ms-dropdown-cb.w--redirected-checked {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  background-image: url(../../assets/images/noun_Close_3610311.svg), linear-gradient(#eaecf0, #eaecf0) !important;
  background-position: 90%, 0 0;
  background-repeat: no-repeat, repeat;
  background-size: 14px, auto;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0) !important;
  border-radius: 6px !important;
}

.search-result:has(.w--redirected-checked) {
  display: inline-block !important;
  width: auto;
  padding-right: 1.8rem;
  margin-right: 0.5rem;
  order: -1;
}
.search-result.results:has(.w--redirected-checked) {
  display: inline-block !important;
}

.dropdown-list-4.w--open {
  height: auto;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  top: 120%;
  bottom: auto;
  left: 0%;
  right: 0%;
  box-shadow: 0 0 20px #f2f4f7;
  padding: 0;
  z-index: 99;
}

.flexx {
  display: flex;
}

.ms-radio-card.active{
  border-color: #489e85 !important;
  outline: 1px solid #489e85 !important;
  color: black !important;
}

.ms-input.icon-left.w-input{
  padding:16px!important;
}

</style>
