<template>
  <div style="background-color: white">
    <section id="IntroMessageModal" class="qoo-modal add-business-modal">
      <div class="welcome-modal">
        <h5 class="heading-4">Add company</h5>
        <div class="w-form">
          <form
            id="email-form-2"
            name="email-form-2"
            data-name="Email Form 2"
            method="get"
            @submit.prevent="createCompany"
          >
            <div class="div-block-15" style="flex-direction: column;align-items: flex-start">
              <label for="name" class="form__label">Company Name</label
              ><input style="padding-left: 16px;text-transform: capitalize"
              type="text"
              class="form__input w-input"
              maxlength="256"
              name="name"
              data-name="Name"
              placeholder=""
              id="name"
              v-model="organisationModel.organisationName"
            />
            </div>
            <div class="div-block-15" style="flex-direction: column;align-items: flex-start">
              <label for="name-3" class="form__label">Company Type</label
              ><select
              id="field-4"
              name="field-4"
              style="text-transform: capitalize"
              data-name="Field 4"
              class="form__input select w-select"
              v-model="organisationModel.organisationType"
            >
              <option value="">Select one...</option>
              <option v-for="category in organisation.organisationTypes.data" v-bind:key="category.value" :value="category.value">{{ category.label }}</option>
            </select>
            </div>
            <label class="upload-images-frame _1" v-if="url === ''">
              <input
                style="display: none"
                type="file"
                class="input-file"
                id="organisation"
                name="files"
                accept="image/*"
                ref="file"
                @change="uploadFile('organisation')"
              />
              <a class="addfiles_btn">
                <div class="w-embed">
                  <svg
                    width="16"
                    height="16"
                    viewbox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.49967 3.3335V12.6668M3.83301 8.00016H13.1663"
                      stroke="currentColor"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                <div>Add image</div>
              </a>
<!--              <p class="paragraph-2">Click to upload images.</p>-->
            </label>
            <div class="upload-images-frame _1" v-else style="height: 142px; padding: 0">
              <img :src="url" style="height: 100%;">
            </div>

            <input
              type="submit"
              value="Create Business"
              data-wait="Please wait..."
              class="btn is-wide"
              v-if="!isLoader && url !== ''"
            />
            <button class="btn is-wide" v-else-if="url === ''">Create Business</button>
            <div v-else class="btn is-wide">
              <b-spinner
                small
                style="color: #fff"
              />
            </div>
          </form>
        </div>
      </div>
    </section>

<!--    <div data-w-id="d84ad800-4a62-6d77-00aa-5dbc2506cb90" class="qoo-modal success__modal">-->
<!--      <div class="pop-up-back" ></div>-->
<!--      <div data-w-id="d84ad800-4a62-6d77-00aa-5dbc2506cb92" class="modal_content">-->
<!--        <div class="modal_c"><img src="../../../public/images/success.svg" loading="lazy" alt="" class="modal__image">-->
<!--          <div class="modal_text">-->
<!--            <h1 class="modal_h">Success</h1>-->
<!--            <p class="modal_p">Your Online mall has been created successfully</p>-->
<!--          </div>-->
<!--          <a href="#" class="btn__submit" @click="goToNewMall">Go to Mall</a>-->

<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <main class="main-wrapper" v-if="stage === 1">
      <div class="campaign__div">
        <div class="col-3">
          <div class="campaign__wrapper">
            <div class="campaign-info-wrapper"><img src="../../assets/images/apvertise-icon.svg" loading="lazy" alt="" class="campaign__logo-icon">
              <h3 class="camapign__h3">Create your online mall on Apvertise</h3>
            </div><img src="../../assets/images/campaignIMG.png" loading="lazy" sizes="(max-width: 991px) 100vw, 391.296875px" srcset="../../assets/images/campaignIMG-p-500.png 500w, ../../assets/images/campaignIMG.png 716w" alt="" class="image-7">
          </div>
        </div>
        <div class="col-7" style="width: 100%; max-width: 90%; margin: 0 auto">
          <div class="campaign__frame" style="width: auto">
            <div class="steps_wrapper">
              <div class="step _1">
                <div class="step_num">
                  <div class="step_number">1</div>
                </div>
                <div class="item_text">
                  <h6 class="step_heading">Provide details of your Mall</h6>
                  <p class="step_p">You&#x27;ll need to provide details about your mall, including the mall name, currency type, brief description of your mall, and landing platform link.</p>
                </div>
              </div>
              <div class="step _1">
                <div class="step_num">
                  <div class="step_number">2</div>
                </div>
                <div class="item_text">
                  <h6 class="step_heading">Upload images for your Mall banner</h6>
                  <p class="step_p">You&#x27;ll need to add images to your mall that will be displayed on the Apvertise platform. The images should be eye-catching and informative.</p>
                </div>
              </div>
            </div>
            <div class="steps__btn-group">
              <a href="#" class="steps__btn alt" @click="goToMall">
                <div class="w-embed"><svg width="20" height="20" viewbox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.00004 11.6668L3.83337 7.50016M3.83337 7.50016L8.00004 3.3335M3.83337 7.50016H13.8334C14.7174 7.50016 15.5653 7.85135 16.1904 8.47647C16.8155 9.10159 17.1667 9.94944 17.1667 10.8335V16.6668" stroke="currentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg></div>
                <div>Cancel</div>
              </a>
              <a href="#" class="steps__btn" @click="stage++">
                <div>Proceed</div>
                <div class="w-embed"><svg width="20" height="20" viewbox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 15L13 10L8 5" stroke="currentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
    <main class="main-wrapper" v-else>
      <div class="campaign__div">
        <div class="col-3">
          <div class="campaign__wrapper" v-if="stage < 4">
            <div class="campaign-info-wrapper"><img src="../../assets/images/apvertise-icon.svg" loading="lazy" alt="" class="campaign__logo-icon">
              <div class="step__title">
                <div class="step__tag">Step 1</div>
                <h3 class="camapign__h3">Provide details of your mall</h3>
              </div>
            </div><img src="../../assets/images/provideDetails.png" loading="lazy"  alt="" class="image-7">
          </div>
          <div class="campaign__wrapper" v-else>
            <div class="campaign-info-wrapper"><img src="../../assets/images/apvertise-icon.svg" loading="lazy" alt="" class="campaign__logo-icon">
              <div class="step__title">
                <div class="step__tag">Step 2</div>
                <h3 class="camapign__h3">Upload images for your Mall banner</h3>
              </div>
            </div><img src="../../assets/images/uploadImages.png" loading="lazy" sizes="(max-width: 991px) 100vw, 391.296875px" srcset="../../assets/images/uploadImages-p-500.png 500w, ../../assets/images/uploadImages.png 526w" alt="" class="image-7">
          </div>
        </div>
        <div class="col-7"  style=" width: 100%; max-width: 90%; margin: 0 auto">
          <div class="campaign__frame form-block" style="width:100%">
            <div class="campaign__steps-div" >
              <div class="progress-bar-1">
                <div class="progress-1 active"></div>
                <div class="progress-1" :class="{ active: stage >= 3 }"></div>
                <div class="progress-1" :class="{ active: stage === 4 }"></div>
              </div>
              <div class="form__block w-form">
                <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form" v-if="stage === 2">
                  <div class="campaign__form__item"><label for="First-Name-2" class="campaign__form__label">What would you like to call your Mall?</label>
                    <div class="input"><input style="text-transform: capitalize" type="text" class="form__input no-icon w-input" maxlength="256" name="field" data-name="" placeholder="someone@example.com" id="field" required="" v-model="model.organisationName"></div>
                  </div>
                  <div class="campaign__form__item">
                    <div class="div-block-13" style="flex-direction: row; width: 100%">
                      <label for="First-Name-2" class="campaign__form__label"
                      >Select business</label
                      >
                      <a
                        @click="uploadProfile('.add-business-modal')"
                        class="link-block-3 w-inline-block"
                      >
                        <div class="text-block-17">+ Add new business</div>
                      </a>
                    </div>
                    <div
                      data-hover="false"
                      data-delay="0"
                      class="form__input business-dropdown w-dropdown"
                      @click="openNavbar"
                    >
                      <div class="dropdown-toggle-4 w-dropdown-toggle">
                        <div class="icon-2 w-icon-dropdown-toggle"></div>
                        <div class="text-block-18">
                          {{ name || "Select an existing business" }}
                        </div>
                      </div>
                      <nav class="dropdown-list-4 w-dropdown-list" v-if="organisations.length > 0">
                        <a href="#" class="w-dropdown-link" style="text-transform: capitalize" v-for="(company) in organisations" v-bind:key="company.organisationId" @click="selectCompany(company)">{{ company.organisationName }}</a>
                      </nav>

                    </div>

                  </div>
                  <p v-if="text1" style="color: red; font-size: 12px">You don't have any existing business. Add new business</p>

                  <div class="campaign__form__item"><label for="First-Name-2" class="campaign__form__label">Select your currency type</label>
                    <div class="input"><select id="field" name="field" data-name="Field" required="" class="form__input no-icon w-select" v-model="model.organisationCurrency">
                      <option value="USD">USD</option>
                      <option value="NGN">NGN</option>
                      <option value="EUR">EUR</option>
                    </select></div>
                  </div>
                  <div class="campaign__form__item">
                    <div class="campaign__label"><label for="First-Name" class="campaign__form__label">Give your mall a description</label><label for="First-Name" class="campaign__form__label-sub">Provide a clear and concise description of your mall to help influencers understand what you&#x27;re promoting and create effective content.</label></div>
                    <div class="input"><textarea placeholder="Description" style="text-transform: none" maxlength="5000" id="field-3" name="field-3" data-name="Field 3" class="textarea w-input" v-model="model.organisationDescription"></textarea></div>
                  </div>
                </form>
                <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form _1" v-if="stage === 3">
                  <div class="campaign__form__item">
                    <div class="campaign__label"><label for="First-Name" class="campaign__form__label">What platform would you prefer Customers land on?</label><label for="First-Name" class="campaign__form__label-sub">You’ll need to provide link to the page that customers would first land on when they clink on your website or social media.</label></div>
                    <div class="ms-radio-row"><a class="ms-radio-wrap img-copy w-radio" @click="model.organisationRedirectPlatform = 'Instagram'">
                      <div class="w-form-formradioinput w-form-formradioinput--inputType-custom ms-radio-image instagram w-radio-input" :class="{'w--redirected-checked' : model.organisationRedirectPlatform === 'Instagram'}"></div><input type="radio" id="Instagram" name="channel" value="Instagram" data-name="channel" data-ms-member="FIELD_ID" style="opacity:0;position:absolute;z-index:-1">
                      <div class="text-block-320">Instagram</div><span class="hide w-form-label" for="Instagram">Yellow</span>
                    </a><a class="ms-radio-wrap img-copy w-radio" @click="model.organisationRedirectPlatform = 'Whatsapp'">
                      <div class="w-form-formradioinput w-form-formradioinput--inputType-custom ms-radio-image whatsapp w-radio-input" :class="{'w--redirected-checked' : model.organisationRedirectPlatform === 'Whatsapp'}"></div><input type="radio" id="Whatsapp" name="channel" value="Whatsapp" data-name="channel" data-ms-member="FIELD_ID" style="opacity:0;position:absolute;z-index:-1">
                      <div class="text-block-320">Whatsapp</div><span class="hide w-form-label" for="Whatsapp">Yellow</span>
                    </a><a class="ms-radio-wrap img-copy w-radio" @click="model.organisationRedirectPlatform = 'Custom'">
                      <div class="w-form-formradioinput w-form-formradioinput--inputType-custom ms-radio-image custom w-radio-input" :class="{'w--redirected-checked' : model.organisationRedirectPlatform === 'Custom'}"></div><input type="radio" id="Custom" name="channel" value="Custom" data-name="channel" data-ms-member="FIELD_ID" style="opacity:0;position:absolute;z-index:-1">
                      <div class="text-block-320">Custom</div><span class="hide w-form-label" for="Custom">Yellow</span>
                    </a></div>
                  </div>
                  <div class="campaign__form__item"><label for="First-Name-2" class="campaign__form__label">Enter link to your {{model.organisationRedirectPlatform}} page</label>
                    <div class="input">
                      <input type="text" class="form__input no-icon w-input" maxlength="256" name="field-2" data-name="Field 2" placeholder="Enter profile link" id="field-2" required="" v-model="model.organisationRedirectParameter" v-if="model.organisationRedirectPlatform === 'Instagram'">
                      <input type="text" class="form__input no-icon w-input" maxlength="256" name="field-2" data-name="Field 2" placeholder="Enter profile link" id="field-2" required="" v-model="model.organisationRedirectParameter" v-else-if="model.organisationRedirectPlatform === 'Custom'">
                      <div class="input" v-else-if="model.organisationRedirectPlatform === 'Whatsapp'" style="flex-direction: row; width: 100%">
                        <vue-country-code
                          class="form__input w-input"
                          style="width: 15%; padding: 0 16px; margin: 0; border-radius: 50rem 0 0 54rem; border: 1px solid #cccccc;"
                          v-model="model.organisationCountryCode"
                          @onSelect="onSelect"
                          :preferredCountries="['ng', 'us', 'gb']"
                        >
                        </vue-country-code>
                        <input
                          type="number"
                          v-model="model.organisationRedirectParameter"
                          required
                          style="border-radius: 0 50rem 50rem 0; padding-left: 20px; border-left: none"
                          class="form__input w-input"
                          maxlength="256"
                          name="name-3"
                          data-name="Name 3"
                          placeholder="Enter Whatsapp Phone number"
                          id="name-3"
                        />
                      </div>


                    </div>
                  </div>
                </form>
                <div class="campaign__frame form-block" style="padding-bottom: 0; width: 100%" v-if="stage === 4">
                  <div class="campaign__steps-div">
                    <div class="steps__text">
                      <p class="campaign__form__label">Lets add some photos of your product</p>
                      <p class="campaign__form__label-sub">Upload maximum of 3 beautiful images to effectively showcase your product and capture the attention of potential customers.</p>
                    </div>
                    <div class="campaign-product-images">
                      <div class="campaign-product-images">
                        <upload-image-with-cropper-v2/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="steps__btn-group">
              <a href="#" class="steps__btn alt" @click="stage--">
                <div class="w-embed"><svg width="20" height="20" viewbox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.00004 11.6668L3.83337 7.50016M3.83337 7.50016L8.00004 3.3335M3.83337 7.50016H13.8334C14.7174 7.50016 15.5653 7.85135 16.1904 8.47647C16.8155 9.10159 17.1667 9.94944 17.1667 10.8335V16.6668" stroke="currentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg></div>
                <div>Back</div>
              </a>
              <a href="#" class="steps__btn" @click="createMall">
                <div>Proceed</div>
                <div class="w-embed"><svg width="20" height="20" viewbox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 15L13 10L8 5" stroke="currentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import OrganisationRequest from "@/model/request/OrganisationRequest";
import { mapState } from "vuex";
import s3Request from "@/model/request/S3Request";
import UploadImageWithCropperV2 from "@/components/form/input/UploadImageWithCropperV2.vue";

export default {
  name: "RevampCreateMallForm",
  components: { UploadImageWithCropperV2 },
  data(){
    return{
      stage: 1,
      text: 'Instagram',
      name: "",
      text1: false,
      model: OrganisationRequest.prototype.organisationUpdateRequest(),
      organisationModel: OrganisationRequest.prototype.organisationCreateRequest,
      uploadmodel: s3Request.prototype.uploadBase64(),
      url1: "",
      url: "",
      url2: "",
      url3: "",
      showModal: false,
      isLoader: false,
      readmodel: OrganisationRequest.mallReadByCustomerIdRequest,

    }
  },
  computed:{
    ...mapState(["auth", "sbucket", "organisation", "campaign" ]),
    ...mapState({
      organisations:(state) => state.organisation.organisations
    }),
  },
  methods:{
    selectCompany(company){
      this.name = company.organisationName;
      this.model.organisationId = company.organisationId;
    },
    goToNewMall(){
      this.close(".success__modal");
      this.$router.push("/mall-registered")
    },
    goToMall(){
      this.$router.push("/mall-all")
    },
    async createCompany() {
      this.isLoader = true;
      this.organisationModel.organisationLogo = this.sbucket.s3bucketResponse.url;
      this.organisationModel.organisationPhone = this.auth.userInfo.customerPhoneNumber;
      this.organisationModel.organisationEmail = this.auth.userInfo.customerEmail;
      this.organisationModel.organisationCustomerId = this.auth.userInfo.customerId;
      await this.$store.dispatch("organisation/createOrganisation", this.organisationModel);
      await this.$store.dispatch("organisation/updateOrganisations", { customerId: this.auth.userInfo.customerId});
      this.close(".add-business-modal")
      this.isLoader = false;
    },
    async createMall() {
      if (this.stage === 1){
        this.stage++
      }
      else if (this.stage === 2){
        this.stage++
      }
      else if (this.stage === 3){
        this.stage++
      }
      else {
        this.model.organisationCustomerId = this.auth.userInfo.customerId;
        this.model.organisationLogo = this.sbucket.urls.join()
        this.model.organisationPhone = this.auth.userInfo.customerPhoneNumber;
        this.model.organisationEmail = this.auth.userInfo.customerEmail;
        this.model.organisationCustomerId = this.auth.userInfo.customerId;
        this.model.organisationCountryCode = "234";

        let res = await this.$store.dispatch("organisation/editMall", this.model).then();
        if (res.responseCode === "00"){
          this.uploadProfile(".success__modal")
        }
        await this.$store.dispatch("organisation/updateOrganisations", { customerId: this.auth.userInfo.customerId });
        localStorage.organisationId = this.model.organisationId
        this.orgModel.organisationId = localStorage.organisationId
        console.log(this.model);
        // await this.$router.push("/mall-registered")
        this.hide();
      }

    },
    onSelect({ name, iso2, dialCode }) {
      this.model.organisationCountryCode = dialCode;
      console.log(name, iso2, dialCode);
    },
    uploadFile(id) {
      let input = document.getElementById(id)
      console.log(input)
      let files = input.files;
      console.log(files)
      this.fileName = files[0].name;
      let size = files[0].size / 1000000;
      console.log(size);
      const reader = new FileReader();
      try {
        reader.onload = (e) => {
          this.base64 = e.target.result;
          this.uploadOfficerImage();
        };
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);
      } catch (e) {
        console.warn(e.message);
      }
    },
    close(className) {
      document.querySelector(className).style.opacity = 0;
      setTimeout(()=>{
        document.querySelector(className).style.display = "none";

      }, 500)
    },
    uploadProfile(className) {
      document.querySelector(className).style.display = "flex";
      setTimeout(() => {
        document.querySelector(className).style.opacity = 1;
      }, 300);
    },
    async uploadOfficerImage() {
      this.isLoader = true;
      this.uploadmodel.username = `${
        this.auth.userInfo.customerFirstName + this.auth.userInfo.customerLastName
      }_${Date.now()}`;
      this.uploadmodel.base64 = this.base64;
      await this.$store.dispatch(
        "sbucket/uploadEncodedFile",
        this.uploadmodel,
        { root: true }
      );
      this.url = this.sbucket.s3bucketResponse.url;
      this.organisationModel.organisationLogo = this.sbucket.s3bucketResponse.url;
      if (this.url1 === ""){
        this.url1 = this.sbucket.s3bucketResponse.url;
      }
      else if (this.url2 === ""){
        this.url2 = this.sbucket.s3bucketResponse.url;
      }
      else {
        this.url3 = this.sbucket.s3bucketResponse.url;
      }
      console.log(this.url)
      this.isLoader = false;
    },
    openNavbar(){
      if(this.organisations.length <= 0){
        this.text1 = true;
      }
      else {
        document.querySelector(".dropdown-list-4").classList.toggle('w--open')
      }
    },
    openCountryDropdown(){
      document.querySelector(".ms-multi-select-wrap").classList.toggle('flexx')
    },
  },
  mounted() {
    if (this.organisations.length === 0){
      this.readmodel.customerId = this.auth.userInfo.customerId;
      this.$store.dispatch("organisation/updateMalls", this.readmodel)
    }
    this.model.organisationRedirectPlatform = "Instagram";
    window.onclick = function(event) {
      if (event.target === document.querySelector('.pop-up-back')){
        document.querySelector('.qoo-modal').style.opacity = 0;
        setTimeout(()=>{
          document.querySelector('.qoo-modal').style.display = "none";
        }, 500)
      }
    }

  }
};
</script>

<style scoped>
@media screen and (max-width: 391px) {
  .steps__btn-group{
    gap: 15px;
  }
}
.col-3 {
  background-color: #f2f4f7;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 32px;
  display: flex;
  position: relative;
  max-width: 100%;
  width: 100%;
}
.campaign__div{
  height: auto;
}

.image-7 {
  max-width: 70%;
  opacity: 0.1;
  position: absolute;
  top: 28%;
  bottom: 0%;
  left: 16%;
  right: 0%;
}

.col-7 {
  width: auto;
}

.progress-bar-1 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  margin-bottom: 10px;
  display: flex;
  position: static;
}

.progress-1.active {
  background-color: #489e85 !important;
}
.progress-1 {
  width: 32%;
  height: 5px;
  background-color: #d0d5dd;
  border-radius: 50rem;
  position: static;
}

.text-block-320 {
  color: #344054;
  margin-top: 8px;
  font-family: Figtree, sans-serif;
  font-size: .875rem;
  font-weight: 400;
}

.hide {
  display: none !important;
}

.ms-radio-image{
  margin-bottom: 8px;
}

.ms-radio-wrap{
  text-decoration: none;
}

.btn {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  color: #fff;
  background-color: #489e85;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  font-weight: 600;
  text-decoration: none;
  display: flex;
  background-image: none;
}

.vue-country-select .dropdown{
  width: auto;
  margin: 0;
}

.form__input .dropdown{
  width: auto;
  margin: 0;
}

.col-7::-webkit-scrollbar, .campaign__frame::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.col-7, .campaign__frame{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>