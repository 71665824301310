<template>
  <div>
    <dashboard-new/>
    <div class="section" style="display: block; font-family: Figtree, sans-serif; background: #fff">
      <div class="dashboard-wrapper">
        <div class="module-title-wrapper">
          <div class="module-title"><img src="../../../public/images/malls.svg" loading="lazy" alt="">
            <h5 class="heading">Malls</h5>
          </div>
            <div class="right-block">
              <button href="#" class="btn__submit"  @click="goToCreateMall">Create your mall</button>
            </div>
        </div>
        <div class="search-filter-wrapper">
          <div class="left-block"><input type="text" class="form__input search w-input" maxlength="256" name="field" data-name="Field" placeholder="Search malls" id="field" required="" @input="search" v-model="searchItem"></div>

        </div>
        <div class="mall-wrapper" v-if="filteredArray.length === 0">
            <a id="w-node-_9cd05be7-eb98-d724-ebd4-966f895aa8aa-180fcae0" href="#" class="mall__card"  v-for="mall in allMall.slice(0, this.count)" :key="mall.organisationId" @click="viewMall(mall.organisationId)">
              <div class="mall-image-wrapper">
                <img :src="mall.organisationBanner.split(',')[0]" loading="lazy" sizes="(max-width: 479px) 93vw, (max-width: 767px) 91vw, (max-width: 991px) 46vw, 358px" alt="" class="mall-img" v-if="mall.organisationBanner">
                <img src="../../../public/images/empty-mall.png" loading="lazy" sizes="(max-width: 479px) 93vw, (max-width: 767px) 91vw, (max-width: 991px) 46vw, 358px" alt="" class="mall-img" v-else>
              </div>
              <div class="mall-overlay"></div>
              <div class="mall-info">
                <div v-if="mall.organisationLogo">
                  <img :src="mall.organisationLogo" loading="lazy" alt="" class="mall__logo" v-if="mall.organisationLogo.includes('apvertise')">
                  <img src="../../../public/images/Strike-STRK.svg" loading="lazy" alt="" class="mall__logo" v-else>
                </div>
                <img src="../../../public/images/Strike-STRK.svg" loading="lazy" alt="" class="mall__logo" v-else>
                <div style="text-transform: capitalize">{{ mall.organisationName }}</div>
              </div>
            </a>
        </div>
          <div class="mall-wrapper" v-if="filteredArray.length > 0 && allMall.length > 0 ">
            <a id="w-node-_9cd05be7-eb98-d724-ebd4-966f895aa8aa-180fcae0" href="#" class="mall__card"  v-for="mall in filteredArray.slice(0, this.count)" :key="mall.organisationId" @click="viewMall(mall.organisationId)">
              <div class="mall-image-wrapper">
                <img :src="mall.organisationBanner.split(',')[0]" loading="lazy" sizes="(max-width: 479px) 93vw, (max-width: 767px) 91vw, (max-width: 991px) 46vw, 358px" alt="" class="mall-img" v-if="mall.organisationBanner">
                <img src="../../../public/images/empty-mall.png" loading="lazy" sizes="(max-width: 479px) 93vw, (max-width: 767px) 91vw, (max-width: 991px) 46vw, 358px" alt="" class="mall-img" v-else>
              </div>
              <div class="mall-overlay"></div>
              <div class="mall-info">
                <div v-if="mall.organisationLogo">
                  <img :src="mall.organisationLogo" loading="lazy" alt="" class="mall__logo" v-if="mall.organisationLogo.includes('apvertise')">
                  <img src="../../../public/images/Strike-STRK.svg" loading="lazy" alt="" class="mall__logo" v-else>
                </div>
                <img src="../../../public/images/Strike-STRK.svg" loading="lazy" alt="" class="mall__logo" v-else>
                <div style="text-transform: capitalize">{{ mall.organisationName }}</div>
              </div>
            </a>
          </div>

        <div class="view-more-btn-wrapper" style="padding: 0" v-if="allMall.length !== 0">
          <a @click="increaseCount" class="button is-secondary">
            <div >View more</div>
            <div class="w-embed"><svg width="8" height="12" viewbox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 11L6.5 6L1.5 1" stroke="currentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg></div>
          </a>
        </div>
        <div class="empty-state" v-if="allMall.length === 0" style="height: 100vh; padding: 0; margin-top: -100px"><img src="../../../public/images/emptymall.png" loading="lazy" width="150" alt="">
          <div class="empty-state-content">
            <div class="empty-state__text">
              <h5 class="empty-mall__h">No Mall available</h5>
              <p class="empty-state__p">We’re so glad you’re ready to join Apvertise.<br>Let’s start by creating your account.</p>
            </div>
<!--            <a href="#" class="button is-secondary small">-->
<!--              <div class="w-embed"><svg width="15" height="14" viewbox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M7.49999 1.16699V12.8337M1.66666 7.00033H13.3333" stroke="currentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>-->
<!--              </svg></div>-->
<!--              <div>Create mall</div>-->
<!--            </a>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import StarRating from "vue-star-rating";
import { mapState } from "vuex";
import OrganisationRequest from "@/model/request/OrganisationRequest";
import OrganisationResponse from "@/model/response/OrganisationResponse";
import ProductRequest from "@/model/request/ProductRequest";
import DashboardNew from "@/components/menu/DashboardNew.vue";

export default {
  name: "MallAll",
  data() {
    return {
      model: OrganisationRequest.mallsReadRequest,
      orgModel: OrganisationRequest.prototype.organisationReadSingleRequest(),
      allProducts: ProductRequest.prototype.productReadByOrgRequest(),
      category: "All",
      searchItem: "",
      product: true,
      count: 6,
      filteredArray: []
    };
  },
  components: {
    DashboardNew,
    //StarRating
  },
  methods: {
    myFunction() {
      document.querySelector(".dropdown-list-2-copy").classList.toggle("w--open");
    },
    increaseCount(){
      this.count = this.count + 3;
    },
    viewMall(mall) {
      //localStorage.productID = prop
      //   this.$store.dispatch("property/updateProduct", prop)
      //   this.proId = localStorage.propertyId
      //   console.log(this.proId)
      //   if(this.auth.customerId = organisation.customerOrganisationCustomerId ){
      //     this.$router.push("/product-merchantView")
      //
      //   } else{
      //     this.$router.push("/product-customer-view")
      console.log(this.malls.customerOrganisationCustomerId);
      localStorage.organisationId = mall;
      this.orgModel.organisationId = localStorage.organisationId;
      if (
        this.malls.customerOrganisationCustomerId === localStorage.customerId
      ) {
        this.$router.push("/mall-registered");
      } else {
        this.$router.push("/mall-customer-view");
      }
      ///////////
      // this.$store.dispatch("organisation/updateOrganisation", this.model)
      // this.$router.push("/mall-registered");
    },
    selectCategory(_category) {
      this.$store.commit("organisation/updateAllMalls", []);
      this.$store.commit("organisation/updateFilteredMalls", []);
      let model = OrganisationRequest.mallsReadRequest;
      this.category = _category;
      if (_category === "ALL") model.category = null;
      else model.category = _category;
      this.$store.dispatch("organisation/allMalls", model);
      const menu = document.querySelector(".w-dropdown-list");
      menu.style.cssText = "display: none;";
      console.log("clickeddddd");

      console.log(_category);
    },
    search() {
      //console.log(this.campaign.campaigns);
      let filter = this.organisation.allMalls.data.filter(
        (it) =>
          it.organisationName
            .toLowerCase()
            .includes(this.searchItem.toLowerCase()) ||
          it.organisationType
            .toLowerCase()
            .includes(this.searchItem.toLowerCase())
      );
      console.log(filter)
      this.filteredArray = filter;
    },
    goToCreateMall(){
      this.$router.push("/add-mall")
    }
  },
  computed: {
    ...mapState({
      campaign: (state) => state.campaign,
      products: (state) => state.product.product.data,
      auth: (state) => state.auth,
      organisation: (state) => state.organisation,
      allMall: (state) => state.organisation.allMalls.data,
      filteredMalls: (state) => state.organisation.filteredMalls,
      malls: (state) => state.organisation.malls,
    }),

    computedMall() {
      let mall = []
      if (this.filteredMalls.length > 1) {
        return this.filteredMalls;
      }
      else {
        mall = this.allMall.slice(0, this.count);
        return mall
      }
    },
    categories: function () {
      return OrganisationResponse.prototype.readOrganisationCategoryResponse()
        .data;
    },
  },
  mounted() {
    window.onclick = function(event) {
      if (event.target !== document.querySelector(".dropdown-toggle-2")) {
        console.log("we are happy")
        let dropdowns = document.getElementsByClassName("dropdown-list-2-copy");
        let i;
        for (i = 0; i < dropdowns.length; i++) {
          let openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('w--open')) {
            openDropdown.classList.remove('w--open');
          }
        }
      }
    }
    console.log(this.organisation.customerOrganisationCustomerId);
    console.log(this.auth.userInfo.customerId);
    this.$store.dispatch("organisation/allMalls", this.model);
  },
  created() {
    const script = document.createElement("script");
    script.src = "https://play-apvertise.com/js/webflow.js";
    this.$store.dispatch("organisation/allMalls", this.model);
  },
};
</script>

<style scoped>

@media screen and (max-width:479px) {
  .module-title-wrapper{
    align-items: flex-start !important;
    grid-column-gap: 16px !important;
    grid-row-gap: 16px !important;
    flex-direction: column !important;
    display: flex !important;
    margin-top: 20px !important;
  }
  .btn__submit{
    width: 100% !important;
  }
  .right-block{
    width: 100% !important;
  }
  .left-block{
    width: 100% !important;
  }
}
@media screen and (max-width:991px) {
  .mall-wrapper{
    grid-template-columns: 1fr 1fr !important;

  }
}
@media screen and (max-width:767px) {
  .mall-wrapper{
    grid-template-columns:  1fr !important;
  }
  .dashboard-wrapper{
    margin-top: 100px;
  }
}



h1,h2,h3,h4,h5,h6,p{
  margin: 0;
}
.btn__submit{
  border-radius: 8px !important;
  font-size: .875rem;
  padding: 0.75rem 1.5rem;

}
.section,
.dashboard-wrapper {
  height: 100%;
}
.mall__logo{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.module-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.module-title-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.button.is-secondary {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #d0d5dd;
  background-color: transparent;
  color: #344054;
  text-decoration: none;
}

.button.is-secondary:hover{
  background: #489E85;
  color: white !important;
}

.form__input {
  position: relative;
  height: 44px;
  margin-bottom: auto;
  padding: 10px 14px 10px 48px;
  border-style: solid;
  border-width: 1px;
  border-color: #d0d5dd;
  border-radius: 50rem;
  background-color: #fff;
  font-family: Figtree, sans-serif;
  font-size: 0.875rem;
  line-height: 1.7142857142857142;
  font-weight: 500;
}

.dropdown-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  padding-right: 1rem;
  padding-left: 1rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #eaecf0;
  border-radius: 100px;
  width: auto;
}

.dropdown-toggle-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  margin: 0;
}

.mall-wrapper {
  display: -ms-grid;
  display: grid;
  margin-top: 1.5rem;
  margin-bottom: 0rem;
  padding-bottom: 2rem;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

#w-node-_9cd05be7-eb98-d724-ebd4-966f895aa8aa-180fcae0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

.mall__card {
  position: relative;
  overflow: hidden;
  height: 349px;
  border-radius: 16px;
}

.mall-image-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #101828;
  -o-object-fit: fill;
  object-fit: fill;
  border: none;
}

.mall-overlay {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(16, 24, 40, 0.43);
}

.mall-img{
  width: 100%;
  height: 100%;
}
.mall-info {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 23;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 22px;
  padding-bottom: 33px;
  padding-left: 22px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.1533333333333333;
  font-weight: 600;
}

.text-block-3 {
  font-family: Figtree, sans-serif;
  color: #475467;
  font-size: 0.875rem;
  font-weight: 500;
}

.dropdown-list-2-copy.w--open {
  top: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 220px;
  padding: 8px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #eaecf0;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 2px 20px 0 rgb(234 236 240 / 7%);
  left: 0;
}

.dropdown-link {
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
  border-radius: 16px;
  font-size: 0.875rem;
  font-weight: 500;
}

.w-dropdown-link {
  padding: 10px 20px;
  display: block;
  color: #222222;
  margin: 0;
  font-family: Figtree, sans-serif;
}

</style>
