<template>
  <home-layout style="background-color: #FFFFFF">
    <div class="influencer__nav">
      <div class="container-large">
        <div class="nav__container">
          <div class="title__nav">
            <img
              src="../../../public/images/apvertise-icon.svg"
              loading="lazy"
              alt=""
              class="logo__icon"
            />
            <div class="nav__title-divider"></div>
            <!--            <div class="nav__title">Setup your Influencer Profile</div>-->
          </div>
          <div class="nav__menu">
            <a
              href="#"
              aria-current="page"
              class="menu__item"
              :class="{ 'w--current': stage === 1 }"
              @click="stage = 1"
            >
              <div class="id" :class="{ 'is-active': stage === 1 }">
                <div>1</div>
              </div>
              <div
                class="nav__item-title"
                :class="{ 'is-active': stage === 1 }"
              >
                Gender Information
              </div>
            </a>
            <a
              href="#"
              class="menu__item"
              :class="{ 'w--current': stage === 2 }"
              @click="stage = 2"
            >
              <div class="id" :class="{ 'is-active': stage === 2 }">
                <div>2</div>
              </div>
              <div
                class="nav__item-title"
                :class="{ 'is-active': stage === 2 }"
              >
                Niche &amp; Interest
              </div>
            </a>
            <a
              href="#"
              class="menu__item"
              :class="{ 'w--current': stage === 3 }"
              @click="stage = 3"
            >
              <div class="id" :class="{ 'is-active': stage === 3 }">
                <div>3</div>
              </div>
              <div
                class="nav__item-title"
                :class="{ 'is-active': stage === 3 }"
              >
                Social Presence
              </div>
            </a>
          </div>
          <a href="#" class="close__btn" @click="goToDashboard"><img src="../../assets/images/close-modal.svg" width="20" height="20"/></a>
        </div>
      </div>
    </div>
    <main class="main-wrapper" style="margin-top: 70px; padding: 0; height: 100vh">
      <div
        class="section"
        :class="{ visible: stage === 1 }"
      >
        <div class="main">
          <div class="inf__onboarding_content">
            <div class="inf__onboarding_text">
              <h3 class="inf__onboarding-h">Select Gender</h3>
              <p class="inf__onboarding-p" style="font-weight: 300">
                We’re so glad you’re ready to join Apvertise. Let’s start by
                creating your account.
              </p>
            </div>
            <div class="gender__grid" style="flex-direction: row; display: flex">
              <a href="#" class="gender__card" @click="gender = 'Male'">
                <div class="gender__radio">
                  <img
                    src="../../../public/images/male-symbol.png"
                    loading="lazy"
                    width="74"
                    alt=""
                  />
                  <div
                    class="radio__btn"
                    :class="{ active: gender === 'Male' }"
                  ></div>
                </div>
                <div>Male</div>
              </a>
              <a href="#" class="gender__card" @click="gender = 'Female'">
                <div class="gender__radio">
                  <img
                    src="../../../public/images/female-symbol.png"
                    loading="lazy"
                    width="74"
                    alt=""
                  />
                  <div
                    class="radio__btn"
                    :class="{ active: gender === 'Female' }"
                  ></div>
                </div>
                <div>Female</div>
              </a>
            </div>
            <a href="#" class="btn" @click="moveToStage2">
              <div>Proceed</div>
            </a>
          </div>
        </div>
      </div>
      <div
        class="section"
        :class="{ visible: stage === 2 }"
      >
        <div class="main" style="height: 100vh;">
          <div class="inf__onboarding_content" style="margin-top: 20px">
            <div class="inf__onboarding_text">
              <h3 class="inf__onboarding-h">Select Niche &amp; Interest</h3>
              <p class="inf__onboarding-p">
                 You can select a maximum of 3 interests.
              </p>
            </div>

            <div data-ms-member="skills" class="ms-select-button-wrap">
              <a class="w-checkbox ms-select-button" :class="{'w--redirected-checked': myCategory.includes(category) && myCategory.length < 4 }"  v-for="(category, index) in categoryChoice" :key="index" style="border-style: solid;border-color: #eaecf0;border-radius: 100px;"  @click="checkCategory(category)">
                <div class="w-checkbox-input w-checkbox-input--inputType-custom ms-button-cb" style="z-index: 3; border: none;width: 100%;color: #344054;font-family: Figtree, sans-serif;font-size: 1rem;font-weight: 500;position: relative;">{{category}}</div>
              </a>
            </div>

            <a href="#" class="btn" @click="moveToStage3">
              <div>Proceed</div>
            </a>
          </div>
        </div>
      </div>
      <div
        class="section"
        :class="{ visible: stage === 3 }"
      >
        <div class="main">
          <div class="inf__onboarding_content">
            <div class="inf__onboarding_text">
              <h3 class="inf__onboarding-h">Connect your Social profiles</h3>
              <p class="inf__onboarding-p">
                Select your preferred social media platforms
              </p>
            </div>
            <div class="social__group">
              <div class="social__link">
                <div class="social">
                  <img
                    src="../../../public/images/instagram.svg"
                    loading="lazy"
                    alt=""
                    class="social__icon"
                  />
                  <div>Instagram</div>
                </div>
                <a @click="goToInstagram" class="connect__btn" v-if="!connectedInstagram">Connect</a>
                <a class="connect__btn" style="background-color: #489e85; color: #FFFFFF" v-else>Connected</a>
              </div>
              <div class="social__link">
                <div class="social">
                  <img
                    src="../../../public/images/twitter1.svg"
                    loading="lazy"
                    alt=""
                    class="social__icon"
                  />
                  <div>Twitter</div>
                </div>
                <a @click="goToTwitter" class="connect__btn" v-if="!connectedTwitter">Connect</a>
                <a class="connect__btn" style="background-color: #489e85; color: #FFFFFF" v-else>Connected</a>

              </div>
              <div class="social__link">
                <div class="social">
                  <img
                    src="../../../public/images/tiktok.svg"
                    loading="lazy"
                    alt=""
                    class="social__icon"
                  />
                  <div>TikTok</div>
                </div>
                <a @click="goToTikTok"  class="connect__btn" v-if="!connectedTiktok">Connect</a>
                <a class="connect__btn" style="background-color: #489e85; color: #FFFFFF" v-else>Connected</a>
              </div>
            </div>
            <p v-if="showAddSocial" style="color:red; margin-top:0;">Connect at least one of the above social media accounts</p>
            <a href="#" class="btn" @click="goToComplete">
              <div>Proceed</div>
            </a>
          </div>
        </div>
      </div>
    </main>
  </home-layout>
</template>

<script>
import HomeLayout from "@/views/layout/HomeLayout.vue";
import SocialMediaRequest from "@/model/request/SocialMediaRequest";
import { mapState } from "vuex";

export default {
  name: "DashboardSelectGender",
  components: { HomeLayout },
  data() {
    return {
      categoryChoice: ["Agriculture", "Arts & Entertainment", "Education", "Fashion & Lifestyle", "Finance", "Fitness", "Gadgets", "Health", "Investment", "SME", "Technology", "Telecommunication", "Travel & Tourism", "Others"],
      gender: null,
      selected: false,
      activeIndex: null,
      lastStateGenerated: null,
      lastStateCodeChallengeGenerated: null,
      stage: 1,
      categories: [
        "Fashion",
        "Beauty",
        "Lifestyle",
        "Travel & Tourism",
        "Health",
        "Food",
        "Fitness",
        "Game",
        "Tech",
        "Crypto",
        "Others",
      ],
      myCategory: [],
      selectedIndex: [],
      enrollModel: SocialMediaRequest.enroll,
      twitterModel: SocialMediaRequest.addTwitter,
      tiktokModel: SocialMediaRequest.addTiktok,
      redirectURL: "https://app.apvertise.com/onboarding/complete",
      instagramURL: "https://app.apvertise.com/onboarding/complete",
      tiktokRedirectURL: "https://app.apvertise.com/onboarding/complete",
      twitterSelected: false,
      tiktokSelected: false,
      twitterActive: false,
      tiktokActive: false,
      instagramActive: false,
      instagramSelected: false,

      showAddSocial: false,
    };
  },
  computed: {
    connectedTiktok(){
      let connect = false;
      this.socialMediaDetails.forEach((social) => {
        if (social.socialMediaDetailsPlatform === "Tiktok"){
          connect = true
        }
      })
      return connect
    },
    connectedTwitter(){
      let connect = false;
      this.socialMediaDetails.forEach((social) => {
        if (social.socialMediaDetailsPlatform === "Twitter"){
          connect = true
        }
      })
      return connect
    },
    connectedInstagram(){
      let connect = false;
      this.socialMediaDetails.forEach((social) => {
        if (social.socialMediaDetailsPlatform === "Instagram"){
          connect = true
        }
      })
      return connect
    },
    generateStateParam() {
      let result = "";
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < 4; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      this.setLastState(result);
      return result;
    },
    generateChallengeCodeParam() {
      let result = "";
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < 6; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      this.setLastCodeChallengeCode(result);
      return result;
    },
    twitterLink() {
      return `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=Zl9vZ050MUl5dDB0R1NkWjlMQVQ6MTpjaQ&redirect_uri=${this.redirectURL}&scope=users.read%20tweet.read%20offline.access&state=ST02&code_challenge=ST03s5&code_challenge_method=plain`;
    },
    tiktokLink() {
      return `https://www.tiktok.com/auth/authorize?client_key=awuxk8676d36nim9&response_type=code&scope=user.info.basic&redirect_uri=${this.tiktokRedirectURL}&state=ST02`;
      // return `https://www.tiktok.com/auth/authorize/?client_key=awuxk8676d36nim9&scope=user.info.basic&response_type=code&redirect_uri=https://apvertise.com/&state=generate-a-random-string`
    },
    instagramLink() {
      return `https://www.facebook.com/v16.0/dialog/oauth?client_id=246578654416945&redirect_uri=${this.redirectURL}&state=${this.generateStateParam}&response_type=code&scope=instagram_basic,pages_show_list,instagram_manage_comments,instagram_manage_comments&auth_type=rerequest`;
      // return `https://www.tiktok.com/auth/authorize/?client_key=awuxk8676d36nim9&scope=user.info.basic&response_type=code&redirect_uri=https://apvertise.com/&state=generate-a-random-string`
    },
    ...mapState({
      authorizeState: (state) => state.socialMedia.authorizeState,
      challengeCode: (state) => state.socialMedia.challengeCode,
      socialMediaDetails: (state) => state.socialMedia.socialMediaDetails
    }),
  },
  methods: {
    checkCategory(category){
      if (this.myCategory.includes(category)){
        this.myCategory = this.myCategory.filter((item) => item !== category);
      }
      else if (this.myCategory.length < 3) {
        this.myCategory.push(category)
      }
    },
    goToDashboard() {
      this.$router.push("/onboarding/new-user");
    },
    goToComplete() {
      if(this.socialMediaDetails.length > 0){
        this.$router.push("/onboarding/complete");
      }
      else{
        this.showAddSocial = true;
      }

    },
    ifExist(event){
      for( let i = 0; i < this.myCategory.length; i++){

        if ( this.myCategory[i] === event.target.value) {

          this.myCategory.splice(i, 1);
        }
        else if (this.myCategory.length < 4){
          this.myCategory.push(event.target.value)
        }
      }
    },
    addCategory(category, index) {
      if (!this.myCategory.includes(category)) {
        this.myCategory.push(category);
        if (!this.selectedIndex.includes(index)) {
          this.selectedIndex.push(index);
        }
      } else {
        this.myCategory = this.myCategory.filter((item) => item !== category);
        if (this.selectedIndex.includes(index)) {
          this.selectedIndex = this.selectedIndex.filter(
            (item) => item !== index
          );
        }
      }
    },
    enroll() {
      this.enrollModel.customerGender = this.gender;
      this.enrollModel.customerInterests = this.myCategory.join(',');
      this.$store.dispatch("socialMedia/enrollUser", this.enrollModel);
    },
    moveToStage2() {
      if (this.gender) {
        this.stage = 2;
      }
    },
    moveToStage3() {
      if (this.myCategory.length > 0) {
        this.stage = 3;
        this.enroll();
      }
    },
    setLastState(value) {
      this.$store.commit("socialMedia/updateState", value);
      this.lastStateGenerated = value;
    },
    setLastCodeChallengeCode(value) {
      this.$store.commit("socialMedia/updateChallengeCode", value);
      this.lastStateCodeChallengeGenerated = value;
    },
    goToTwitter(){
      localStorage.setItem("social", "twitter")
      this.twitterActive = true;
      window.location.href = this.twitterLink;
    },
    goToTikTok(){
      localStorage.setItem("social", "tiktok")
      this.tiktokActive = true;
      window.location.href = this.tiktokLink;
    },
    goToInstagram(){
      localStorage.setItem("social", "instagram")
      this.instagramActive = true;
      window.location.href = this.instagramLink;
    }
  },
  beforeMount() {
    if(this.twitterActive){
      let url = window.location.search;
      const urlParams = new URLSearchParams(url);
      let code = urlParams.get("code");
      this.twitterModel.code = code;
      this.twitterModel.code_challenge = 'ST03s5';
      this.$store.dispatch("socialMedia/authorizeTwitter", this.twitterModel);
    }
    if(this.tiktokActive){
      let url = window.location.search;
      const urlParams = new URLSearchParams(url);
      let code = urlParams.get("code");
      this.tiktokModel.code = code;
      this.$store.dispatch("socialMedia/authorizeTiktok", this.tiktokModel);
    }
    if(this.instagramActive){
      let url = window.location.search;
      const urlParams = new URLSearchParams(url);
      let code = urlParams.get("code");
      this.tiktokModel.code = code;
      this.$store.dispatch("socialMedia/authorizeInstagram", this.tiktokModel);
    }

  },
  mounted(){

  }
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  font-size: 20px;
}

p {
  color: #475467;
  line-height: 1.375;
  font-size: 16px;
}

.btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 18px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border-radius: 50rem;
  background-color: #489e85;
  color: #fff !important;
  font-weight: 600;
  text-decoration: none;
  background-image: none !important;
  width: 100%;
}

.btn:hover {
  color: #489e85 !important;
  background-color: rgba(0, 0, 0, 0);
  border-color: #489e85;
}

.section {
  display: none;
}

.visible {
  display: block;
}

.interest__item:hover{
  color: #489e85 !important;
}

.connect__btn:hover {
  color: white !important;
}

.influencer__nav{
  position: fixed;
  top: 0;
  width: 100%;
}
.ms-hide:checked ~ .ms-radio-card {
  border-color: #489e85;
  outline: 1px solid #489e85;
}
.ms-hide:checked ~ .ms-svg {
  display: flex;
}

.col-3 {
  height: 100vh;
  background-color: #f2f4f7;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 32px;
  display: flex;
  position: relative;
  max-width: 100%;
  width: 100%;
}

.image-7 {
  max-width: 70%;
  opacity: 0.1;
  position: absolute;
  top: 28%;
  bottom: 0%;
  left: 16%;
  right: 0%;
}

.col-7 {
  width: auto;
}

.ms-radio-card {
  border: none;
}

.ms-radio-wrap.card {
  margin-bottom: 20px;
}

.ms-radio-card {
  font-weight: 500;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 0;
}

.ms-help-text {
  font-size: 14px;
  margin: -15px 0 0;
}

.w--redirected-checked{
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)) !important;
  background-position: 0 0;
  background-size: auto;
  border-width: 2px !important;
  border-color: #489e85 !important;
  border-radius: 100px !important;
}
.gender__card {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  color: #475467;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  font-family: Figtree, sans-serif;
  font-weight: 600;
  text-decoration: none;
  display: flex;
  position: relative;
}

.gender__card:hover {
  color: #489e85;
  background-color: rgba(72, 158, 133, .05);
  border-color: #eaf5f2;
}

.gender__grid {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.radio__btn {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #98a2b3;
  border-radius: 50rem;
  position: absolute;
  top: 8px;
  bottom: auto;
  left: auto;
  right: 8px;
}

.radio__btn.active {
  border-width: 4px;
  border-color: #489e85;
}

.interest__item {
  color: #1d2939;
  border: 1px solid #eaecf0;
  border-radius: 50rem;
  justify-content: space-between;
  padding: 12px 16px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}

.gender__radio {
  padding: 24px 20px;
}

.gender__grid {
  grid-column-gap: 8px;
}

</style>
