<script>
import DashboardNew from "@/components/menu/DashboardNew.vue";
import {mapState} from "vuex";
import NotificationRequest from "@/model/request/NotificationRequest";
export default {
  name: "NotificationsPim",
  components: {DashboardNew},
  methods:{
    goToPimRequest(id, notificationId){
      this.model.notificationId = notificationId;
      this.$store.dispatch("notification/updateStatus", this.model)
      this.$store.dispatch("pimCampaign/readPimCampaign", {pimCampaignId: id})
      let myid = id
      this.$router.push(`/pim-requests/${myid}`)
    },
    goToNotificationsPim(){
      this.$router.push("/notifications-pim");
    },
    goToVibes(){
      this.$router.push("/influencers");
    }
  },
  data(){
    return {
      model: NotificationRequest.prototype.updateStatus()
    }
  },
  gotToPimChat(){
    this.$router.push("/influencer-pim-chat");
  },
  computed:{
    ...mapState({
      notifications:(state)=>state.notification.notifications
    })
  }
}
</script>

<template>
  <div>
    <dashboard-new/>
    
    <div class="section">
      <div class="dashboard-wrapper">
        <div class="breadcrumb_component">
          <a @click="goToVibes" class="breadcrumb-link w-inline-block">
            <div>Home</div>
          </a>
          <div class="breadcrumb-divider w-embed"><svg width="16" height="16" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 3L11 8L6 13" stroke="CurrentColor" stroke-width="1.5"></path>
          </svg></div>
          <a @click="goToNotificationsPim" class="breadcrumb-link is-active w-inline-block">
            <div>All Notifications</div>
          </a>
        </div>
        <div class="all-notifications-wrapper">
          <div class="module-title-wrapper">
            <div class="module-title"><img src="../../assets/images/campaign-image.svg" loading="lazy" alt="">
              <h5 class="heading">All Notifications</h5>
            </div>
          </div>
          <div class="notification-list"  v-if="notifications.data.length > 0">
            <a @click="goToPimRequest(notification.notificationTypeId, notification.notificationId)"  class="notification-item w-inline-block" v-for="(notification, index) in notifications.data" :key="index">
              <div class="left"><img src="../../assets/images/userAvatar.svg" loading="lazy" alt="" class="notification-icon">
                <div v-if="notification.notificationStatus !== 'READ'" class="new-dot"></div>
              </div>
              <div class="right">
                <p class="notification-description"><span class="notification-type">{{notification.notificationType}}:</span> You’ve got a request from <span class="notification-contact">{{notification.notificationActor}}</span></p>
                <div class="notification-date">{{new Date(notification.notificationCreatedAt).toLocaleString('en-us',{month:'short'})}} {{new Date(notification.notificationCreatedAt).getDate()}}</div>
              </div>
            </a>
<!--            <a @click="goToPimRequest" class="notification-item w-inline-block">-->
<!--              <div class="left"><img src="../../assets/images/earningsAvatar.svg" loading="lazy" alt="" class="notification-icon">-->
<!--                <div class="new-dot"></div>-->
<!--              </div>-->
<!--              <div class="right">-->
<!--                <p class="notification-description"><span class="notification-type">Your weekly earnings:</span> It’s been an amazing week and we wanted to take a moment to recognize your impressive performance.</p>-->
<!--                <div class="notification-date">Jan 20</div>-->
<!--              </div>-->
<!--            </a>-->
<!--            <a href="#" class="notification-item w-inline-block">-->
<!--              <div class="left"><img src="../../assets/images/userAvatar.svg" loading="lazy" alt="" class="notification-icon">-->
<!--                <div class="new-dot hide"></div>-->
<!--              </div>-->
<!--              <div class="right">-->
<!--                <p class="notification-description"><span class="notification-type">Wallet top:</span> You have successfully topped-up your wallet on Apvertise. <span class="notification-contact">View Wallet Balance</span></p>-->
<!--                <div class="notification-date">Jan 20</div>-->
<!--              </div>-->
<!--            </a>-->
<!--            <a href="#" class="notification-item w-inline-block">-->
<!--              <div class="left"><img src="../../assets/images/userAvatar.svg" loading="lazy" alt="" class="notification-icon">-->
<!--                <div class="new-dot hide"></div>-->
<!--              </div>-->
<!--              <div class="right">-->
<!--                <p class="notification-description"><span class="notification-type">Wallet top:</span> You have successfully topped-up your wallet on Apvertise. <span class="notification-contact">View Wallet Balance</span></p>-->
<!--                <div class="notification-date">Jan 20</div>-->
<!--              </div>-->
<!--            </a>-->
<!--            <a href="#" class="notification-item w-inline-block">-->
<!--              <div class="left"><img src="../../assets/images/userAvatar.svg" loading="lazy" alt="" class="notification-icon">-->
<!--                <div class="new-dot hide"></div>-->
<!--              </div>-->
<!--              <div class="right">-->
<!--                <p class="notification-description"><span class="notification-type">Wallet top:</span> You have successfully topped-up your wallet on Apvertise. <span class="notification-contact">View Wallet Balance</span></p>-->
<!--                <div class="notification-date">Jan 20</div>-->
<!--              </div>-->
<!--            </a>-->
          </div>
          <div  v-else class="empty-state"  style="display: flex; opacity: 1; height: 100vh; padding: 0; margin: -200px 0 0;"><img src="../../../public/images/search-null-1.png" loading="lazy" width="120" alt="">
            <div class="empty-state-content">
              <div class="text-block-11">No notification to show</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>