class MessagingRequest{
    create(){
        return{
            messageGroupId : null,
            messageBody : null
        }

    }
    readByCustomerId(){
        return{}
    }
    readByGroupId(){
        return{
            messageGroupId : null,
            page : null
        }

    }
}

export default MessagingRequest