import client from "./BaseService";

export default {
    callEnrollApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("social/enrollment", payload);
    },

    callAddTwitterApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("social/create-twitter", payload);
    },

    callAddTikTokApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("social/create-tiktok", payload);
    },

    callInstagramApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("social/create-instagram", payload);
    },

    callReadByCustomerIdApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("social/read-by-customer-id", payload);
    },

    callReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("social/read", payload);
    },



};
