<template>
  <b-modal
    ref="system-message-modal"
    style="z-index: 10"
    centered
    hide-header
    hide-footer
  >
    <div class="div-block-155">
      <a data-w-id="89b8a742-7170-306e-20da-6054dea3dd98" @click="hide()" href="#" class="close-topup-modal w-inline-block"><img src="@/assets/images/close-modal.svg" loading="lazy" alt=""></a>
    </div>
    <p style="text-align: center;">
      <img v-if="message.type === 'success'" style="width: 120px; height: 120px;" src="@/assets/images/success_check.gif">
      <img v-else-if="message.type === 'error'" style="width: 120px; height: 120px;" src="@/assets/images/error.gif">
      <span v-else-if="message.type === 'n/a'"></span>
      <img v-else style="width: 120px; height: 120px;" src="@/assets/images/error.gif">
    </p>
    <br><br>
    <p style="text-align: center;">
    <strong style="font-size: 20px; line-height: 1;">{{ message.title }}</strong>
    </p>
    <p style="text-align: center;"><span v-html="message.body.replace('$timerCount', timerCount)"></span></p>
    <br><br>
    <input v-if="message.action !== 'NO_BUTTON'" type="submit" @click="hide()" value="Continue" style="z-index: 10; display: block;"  class="button-18 w-button">
    <br>
    <!--    </div>-->
<!--    <a data-w-id="89b8a742-7170-306e-20da-6054dea3dd98"  href="#" @click="hide()" class="close-topup-modal w-inline-block">-->
<!--      <b-icon-x style="width: 35px; height: 35px; color: #63b8a0;"/>-->
<!--    </a>-->
  </b-modal>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MessageModal",
  data() {
    return {
      timerCount: 0,
    }
  },
  methods: {
    hide() {
      this.timerCount = 0
      this.$refs["system-message-modal"].hide();
      if (this.message.action === "reload") window.location.reload();
    },
    logout() {
      this.$store.dispatch("auth/logout", {}, {root: true});
      localStorage.clear();
      window.location = "/"
    }
  },
  computed: {
    ...mapState(["showMessage","system","message"]),
    show: function () {
      return this.$store.system.showMessage;
    },

  },
  mounted() {
    console.log(">>>>>>>>>>>Message Mounted!!!!!");
    if (this.message.type === "timeout") {
      this.timerCount = 15;
    }
  },
  watch: {
    // whenever question changes, this function will run
    showMessage: function (newValue, oldValue) {
      console.log(`${oldValue} to ${newValue}`);
      if (this.message.type === "timeout") {
        this.timerCount = 15;
      }
      this.$refs["system-message-modal"].show();
    },

    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
          if(this.timerCount < 2){
            this.logout()
          }
        }
      },
      //immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  created() {},
};
</script>

<style>
/*.close-topup-modal {*/
/*  position: absolute;*/
/*  left: auto;*/
/*  top: 0%;*/
/*  right: 0%;*/
/*  bottom: auto;*/
/*  display: block;* 5399 8344 3712 7558 - 0364/
/*}*/
</style>


