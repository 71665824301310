<template>
  <div style="background: #fff; font-family: Figtree, sans-serif !important;">
    <dashboard-new/>
    <section id="IntroMessageModal" class="share-campaign-popup" >
      <div class="share-campaign-modal" style="display: block">
        <div class="modal-image-container"></div>
        <div class="share-campaign-header">
          <h5 class="share-campaign-header_text" style="font-size: 18px; font-weight: 500; margin-bottom: 10px">Share Campaign</h5>
          <div class="share-campaign-subheading_text" style="font-size: 16px">You are about to share this campaign, would you like to proceed?</div>
        </div>
        <div class="social-links-wrapper">
          <a :href="
            `https://twitter.com/intent/tweet?text=${encodeURIComponent(
              title
            )}%0A${encodeURIComponent(description)}%0A%0A` + link
          " class="socials "><img src="../../../public/images/twitter_1.svg" loading="lazy" alt="">
            <div class="text-block-7">Twitter</div>
          </a>
          <a :href="`fb-messenger://share/?link= ${encodeURIComponent(
            title
          )}%0A${encodeURIComponent(
            description
          )}%0A%0A${link}&app_id=123456789`" class="socials "><img src="../../../public/images/fb.svg" loading="lazy" alt="">
            <div class="text-block-7">Facebook</div>
          </a>
          <a :href="
            `whatsapp://send?text=*${title.trim()}*%0A${encodeURIComponent(
              description
            )}%0A%0A` + link
          " class="socials "><img src="../../../public/images/whatsapp.svg" loading="lazy" alt="">
            <div class="text-block-7">Whatsapp</div>
          </a>
          <a :href="`https://telegram.me/share/url?url=${link}&text=${encodeURIComponent(
            title
          )}%0A${encodeURIComponent(description)}%0A%0A`" class="socials "><img src="../../../public/images/telegram.svg" loading="lazy" alt="">
            <div class="text-block-7">Telegram</div>
          </a>
        </div>
        <div class="social-divider">
          <div class="divider1"></div>
          <div class="text-block-new">OR</div>
          <div class="divider1"></div>
        </div>
        <div class="campaign-link-wrapper">
          <div class="text-block-new-2">Campaign Link</div>
          <div class="campaign-link-block" style="padding: 0">
            <div class="" style="display: flex; width: 100%; justify-content: space-between; position: relative">
              <div class="text-block-10">{{ link }}</div>
              <button
                id="copy-link"
                @click="copy"
                style="background: none"
              >
                <img
                  style="width: 30px; height: 30px"
                  src="../../../public/images/Copy.svg"
                  alt=""
                />
              </button>
              <div class="copy-card" v-if="show" style="position: absolute; right: -10px; top: -45px; text-align: center; width: 80px; border-radius: 8px; color: black">Copied!</div>
            </div>
          </div>
        </div>
        <a data-w-id="5dd05fa0-7341-aeba-f177-f7142c7cbff4" href="#" class="link-block-2 " @click="close('.share-campaign-popup')"><img src="../../../public/images/x_1.svg" loading="lazy" width="24" alt=""></a>
      </div>
    </section>
    <div class="section">
      <div class="dashboard-wrapper">
        <div class="breadcrumb-wrapper">
          <a href="#" class="back-btn " @click="goToDashboard"><img src="../../assets/images/chevron-right_1.svg" loading="lazy" alt="" class="image-5">
            <div class="text-block-6" style="font-weight: 400">Back to campaigns</div>
          </a>
        </div>
        <div class="campaign-details-wrapper">
          <div class="campaign-image-wrapper">
            <div data-delay="4000" data-animation="slide" style="height: 400px;" class="campaign-image-slider w-slider" data-autoplay="false" data-easing="ease" data-hide-arrows="false" data-disable-swipe="false" data-autoplay-limit="0" data-nav-spacing="3" data-duration="500" data-infinite="true">
              <div class="w-slider-mask">
                <b-carousel
                  id="carousel-1"
                  v-model="slide"
                  :interval="4000"
                  controls
                  indicators
                  label-next=""
                  label-prev=""
                  background="#ffffff"
                  style="text-shadow: 1px 1px 2px #333; width: 100%"
                  @sliding-start="onSlideStart"
                  @sliding-end="onSlideEnd"
                >
                  <b-carousel-slide style="border-radius: 20px 20px 20px 20px;" v-for="banner in banners" v-bind:key="banner">
                    <template #img>
                      <img
                        style="border-radius: 20px 20px 20px 20px; width: 100%; height: 400px"
                        class="d-block img-fluid"
                        width="606"
                        height="387"
                        :src="banner"
                        alt="image slot"
                      >
                    </template>
                  </b-carousel-slide>
                </b-carousel>
              </div>
            </div>
          </div>
          <div class="campaign-detail-wrapper">
            <h4 class="campaign-title">{{campaign.campaign.campaignName}}</h4>
            <div class="campaign-company"><img :src="campaign.campaign.organisationLogo" loading="lazy" alt="" width="40" height="40" style="border-radius: 50%">
              <div class="company-name">{{campaign.campaign.organisationName}}</div>
            </div>
            <div class="tags-wrapper">
              <div class="div-block-2">
                <div class="tag big">
                  <img src="../../assets/images/advertise.svg" loading="lazy" alt="">
                  <div class="text-block-4">Awareness Campaign</div>
                </div>
                <div class="tag big">
                  <div class="tag-dot"></div>
                  <div class="text-block-4">{{campaign.campaign.organisationType}}</div>
                </div>
<!--                <div class="tag big">-->
<!--                  <div class="tag-dot"></div>-->
<!--                  <div class="text-block-4">{{countryCode(campaign.campaign.campaignAudience) || countryName}}</div>-->
<!--                </div>-->
              </div>
            </div>
            <div class="campaign-description-wrapper">
              <div class="campaign-description-text">{{campaign.campaign.campaignDescription}}</div>
            </div>
            <div class="div-block-3">
              <a data-w-id="2f177755-12d5-e970-acc6-b643ea05851b" href="#" class="button" @click="uploadProfile('.share-campaign-popup', campaign.campaign.campaignName, campaign.campaign.campaignDescription, campaign.campaign.campaignId, campaign.campaign.shareLink)">Share campaign</a>
            </div>
          </div>
        </div>
        <div class="related-campaign-header" style="margin-top: 70px; margin-bottom: 20px">
          <h5 style="font-size: 20px">Related campaigns</h5>
        </div>
        <div class="related-campaign-wrapper">
          <div id="w-node-add92689-11c1-f3b9-0816-3f5f23c87914-7d1ae02a" class="campaign-card" v-for="campaignObj in campaign.campaign.relatedCampaigns" v-bind:key="campaignObj.campaignId">
            <div class="campaign-logo-tag">
              <div class="left-div"><img :src="campaignObj.organisationLogo" loading="lazy" alt="" width="40" height="40" style="border-radius: 50%"></div>
              <div class="right-div">
                <div class="tag">
                  <div class="tag-dot"></div>
                  <div class="text-block-4">{{campaignObj.organisationType}}</div>
                </div>
              </div>
            </div>
            <div class="campaign-details_wrapper">
              <div class="campaign-card-title">{{campaignObj.campaignName}}</div>
              <div class="text-block-5" style="height: 40px; overflow: hidden">{{campaignObj.campaignDescription}}</div>
            </div>
            <div class="campaign-card-price">
              <div class="price-per-click-text">Price Per Click</div>
              <div class="price-per-click">${{campaignObj.campaignBudget}}</div>
            </div>
            <div class="campaign-action-wrapper">
              <a href="#" class="view-campaign-btn" @click="selectCampaign(campaignObj.campaignId, campaignObj.campaignBudgetExhausted)">
                <div class="text-block-6" style="font-weight: 400;">View campaign</div>
              </a>
              <a data-w-id="2f177755-12d5-e970-acc6-b643ea05851b" href="#" class="share-campaign-btn" @click="uploadProfile('.share-campaign-popup', campaignObj.campaignName, campaignObj.campaignDescription, campaignObj.campaignId, campaignObj.shareLink)"><img src="../../../public/images/share-2.svg" loading="lazy" alt=""></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p style="text-align: center; margin-top: 25%;"><b-spinner v-if="campaign.campaignLoading || campaign.campaign.responseCode !== '00'" style="color: #34a853;"/></p>
  </div>

</template>

<script>
import { mapState } from "vuex";
import CampaignRequest from "@/model/request/CampaignRequest";
import SecurityUtil from "@/utilities/SecurityUtil";
import DashboardNew from "@/components/menu/DashboardNew.vue";
import countriesJson from "@/assets/countries/data/en/countries.json";
export default {
  name: "CampaignDetail",
  components: { DashboardNew },
  data() {
    return {
      slide: 0,
      sliding: null,
      customerId: "",
      title: "hgh bghghj ghkj",
      description: "hvjh bhkj ",
      searchItem: "",
      category: "All",
      disabled: true,
      model: CampaignRequest.prototype.campaignShareRequest(),
      body: "The text inside this text input will be copied to the clipboard",
      text: "Click to copy",
      variant: "dark",
      id: "",
      show: false,
      shareLink: "",
      countryName: ""
    }
  },
  computed:{
    ...mapState(["campaign","auth"]),
    banners: function(){
      if (this.campaign.campaign.campaignBanners == null) return []
      return this.campaign.campaign.campaignBanners.split(",")
    },
    campaignId: function () {
      //this.$store.dispatch("campaign/updateCampaign", {campaignId: localStorage.campaignId})
      if (this.$route.params.campaignId)
        return this.$route.params.campaignId.hexDecode();
      else return localStorage.campaignId;
    },
    baseUrl: function () {
      return window.__env.api.baseUrl;
    },
    shareUrl: function () {
      return window.__env.api.shareUrl;
    },
    appUrl: function () {
      return window.__env.app.appUrl;
    },
    token: function () {
      return SecurityUtil.prototype.encrypt(JSON.stringify(this.model));
    },
    link: function () {
      if (this.auth.userInfo.customerId === this.customerId)
        return `${this.appUrl}/campaign-details/${this.id.hexEncode()}`;
      else return `${this.shareUrl}share/${this.campaign.shareLink.shareLink}`;
    },
    countries() {
      return countriesJson;
    },
  },

  methods: {
    async copy(){
      this.show = true;
      await navigator.clipboard.writeText(this.link);
      setTimeout(() => {
        this.show = false
      }, 1500)
      // Select the text field
      this.link.select();
      this.link.setSelectionRange(0, 99999); // For mobile devices
      // Copy the text inside the text field;

    },

    // eslint-disable-next-line no-unused-vars
    onCopy: function (e) {
      //this.$refs.tooltip.$emit('enable')
      this.variant = "success";
      this.text = "Copied!";
      this.$refs.tooltip.$emit("show");
      let self = this;
      window.setTimeout(function () {
        self.variant = "dark";
        self.text = "Click to copy";
        self.$refs.tooltip.$emit("show");
      }, 3000);
      // this.$bvToast.toast("You just copied the link", {
      //   title: `Copy - Success`,
      //   variant: "success",
      //   autoHideDelay: 100000,
      //   solid: true
      // })
    },
    // eslint-disable-next-line no-unused-vars
    onError: function (e) {
      this.$bvToast.toast("Failed to copy the text to the clipboard", {
        title: `Copy - Error`,
        variant: "error",
        autoHideDelay: 100000,
        solid: true,
      });
    },
    goToDashboard(){
      this.$router.push("/campaign-all")
    },
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    navigateToEdit(_campaign) {
      this.$store.commit("campaign/updateCampaignEditForm", _campaign);
      this.$router.push("/edit-campaign");
    },
    selectCampaign(_id) {
      localStorage.campaignId = _id;
      console.log(this.$route.path);
      if (!this.$route.path.includes("campaign-details"))
        this.$router.push(`/campaign-details/${_id.hexEncode()}`);
      this.$store.dispatch("campaign/updateCampaign", {
        campaignId: localStorage.campaignId,
      });
    },
    async uploadProfile(className, title, description, id, link, customerId) {
      this.customerId = customerId;
      this.shareLink = link;
      this.id = id;
      this.title = title;
      this.description = description;
      this.model.customerId = customerId;
      this.model.campaignId = id;
      await this.$store.dispatch("campaign/getShareLink", {
        shareCampaignId: this.id,
        shareCustomerId: this.auth.userInfo.customerId,
      }).then()
      document.querySelector(className).style.display = "flex";
      setTimeout(()=>{
        document.querySelector(className).style.opacity = 1;
      }, 500)
    },
    close(className) {
      document.querySelector(className).style.opacity = 0;
      setTimeout(()=>{
        document.querySelector(className).style.display = "none";

      }, 500)
    },
    countryCode(code){
      this.countries.forEach((country) => {
        let coded = code.toLowerCase()
        console.log(code.toLowerCase())
        if (coded === country.alpha2){
          console.log(country.name);
          this.countryName = country.name;
          return country.name;
        }
      })
    }
  },
  mounted() {
    if (this.$route.params.campaignId != null) {
      this.$store.dispatch("campaign/updateCampaign", {campaignId: this.campaignId})
    }
    //this.$store.dispatch("campaign/updateCampaign", {campaignId: localStorage.campaignId})
  },
  watch:{
    campaignId: function (){
      //this.$store.dispatch("campaign/updateCampaign", {campaignId: localStorage.campaignId})
    }
  }
};
</script>

<style scoped>
@media screen and (max-width: 520px){
  .share-campaign-modal {
    width: 280px !important;
    height: 350px !important;
    overflow: scroll!important;
  }
  .link-block-2 {
    position: absolute;
    top: 3%;
    bottom: auto;
    left: auto;
    right: 6%;
  }
  .social-divider{
    margin-top: 5px!important;
  }
  .campaign-link-wrapper{
    margin-top: 5px!important;
  }
  .social-links-wrapper {
    flex-wrap: wrap;
    margin-top: 10px!important;

  }
  .text-block-10{
    overflow: hidden;
    padding-left: 8px;
  }
  .section{
    margin-top: 107px;
  }
}

.text-block-10{
  padding-left: 9px;
}
body{
  font-family: Figtree, sans-serif !important;
}
.div-block-2 {
  width: auto;
  height: auto;
}

.campaign-description-text {
  color: #475467;
  font-size: 16px;
  line-height: 20px;
}

.button {
  padding: 0.75rem 1.5rem;
  border-radius: 100px;
  background-color: #489e85;
  color: #fff !important;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  width: 100%;
}

.text-block-4 {
  font-family: Figtree, sans-serif;
  color: #489e85;
  font-size: 14px;
  font-weight: 500;
}

.section{
  height: 100%;

}
h1,h2,h3,h4,h5,h6,p{
  margin: 0;
}

.dashboard-wrapper{
  height: 100%;
}

.text-block-5 {
  color: #475467;
  font-size: 0.9375rem;
}

.view-campaign-btn:hover{
  background-color: #489e85;
  color: #fff !important;
}

.campaign-details_wrapper {
  padding-bottom: 16px;
  margin-top: 10px;
  border: none;
}

.view-campaign-btn:hover .text-block-6{
  color: #fff ;
}

.button.is-secondary {
  border-style: solid;
  border-width: 1px;
  border-color: #d0d5dd;
  background-color: transparent;
  color: #000;
}

.dropdown-list-2.w--open {
  top: 50px ;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 220px !important;
  padding: 8px !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #eaecf0 !important;
  border-radius: 16px !important;
  background-color: #fff;
  box-shadow: 0 2px 20px 0 #eaecf0 !important;
  left: auto !important;
}

.w-dropdown-link {
  padding: 10px 20px;
  display: block;
  color: #222222;
}
.w-dropdown-btn, .w-dropdown-toggle, .w-dropdown-link {
  position: relative;
  vertical-align: top;
  text-decoration: none;
  color: #222222;
  padding: 20px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
}

.dropdown-link {
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
  border-radius: 16px;
  font-size: 0.875rem;
  font-weight: 500;
}

.share-campaign-modal {
  position: relative;
  display: block;
  overflow: hidden;
  width: auto;
  height: auto;
  padding: 24px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(3, 28, 67, 0.1);
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 0 34px 0 rgb(3 28 67 / 13%);
}

.text-block-new{
  color: #475467;
  font-size: 16px;
}

.text-block-new-2{
  color: #344054;
  font-weight: 500;
}

.share-campaign-popup{
  opacity: 0;
}

</style>