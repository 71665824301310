<template>
  <div class="social-media-sign-up-wrapper-copy">
    <a href="#" @click="login()" class="btn__google-signup">
      <div v-if="type === 'register'"  class="text-block-123">Continue with Google</div>
      <div v-else  class="text-block-123">Sign in with Google</div>
    </a>
    <a href="#" class="facebook-sign-up w-inline-block" style="display: none;"><img src="@/assets/images/Facebook_2.svg" loading="lazy" alt="" class="image-58">
      <div v-if="type === 'register'" class="text-block-123">Continue with Facebook</div>
      <div v-else class="text-block-123">Sign in with Facebook</div>
    </a>
    <b-modal
      style="background: white"
      ref="socialite-modal"
      centered
      hide-header
      hide-footer
    >
      <div class="div-block-156" style="background-color: white">
        <a data-w-id="0e756eac-b69c-ca0d-7ba7-f2b54c7eb6e2" @click="hide()" href="#" class="close-topup-modal w-inline-block"><img src="@/assets/images/close-modal.svg" loading="lazy" alt=""></a>
      </div>
      <br />
<!--      <div class="topup-modal-1">-->
<!--        <h1 class="heading-28" style="text-align: center;">Continue</h1>-->
<!--      </div>-->
      <div class="w-form">
        <form
          id="email-form"
          @submit.prevent="register"
          name="email-form"
          data-name="Email Form"
          class="form-4"
        >
          <div class="phone-number-block" style="margin-bottom: 20px">
            <label for="name-3" class="embolden phone-number-text"
            >Phone Number</label
            >
            <div class="div-block-143">
              <input
                type="number"
                v-model="model.customerPhoneNumber"
                required
                class="phone-number-textfield w-input"
                maxlength="256"
                name="name-3"
                data-name="Name 3"
                style="padding-left: 10px"
                placeholder="Enter your phone number"
                id="name-3"
              />
              <!--          <div>-->
              <vue-country-code
                class="dial-code"
                style="padding-left: 0; width: 120px"
                v-model="model.countryCode"
                @onSelect="onSelect"
                :preferredCountries="['ng', 'us', 'gb']"
              />
              <!--          </div>-->
            </div>
          </div>
          <br />
          <br />
          <input
            type="submit"
            value="Continue"
            style="z-index: 10; display: block"
            class="button-18 w-button"
          />
        </form>
      </div>
      <!--    </div>-->
    </b-modal>
  </div>
</template>

<script>
import AuthRequest from "@/model/request/AuthRequest";

export default {
  name: "SocialiteAuthButton",
  props: ["type"],
  data() {
    return {
      isLogin: false,
      model: {
        idToken: null,
        type: "google",
        customerPhoneNumber: "",
        countryCode: "",
        hostId: null,
      },
      loginModel: new AuthRequest().logon(),
    };
  },
  methods: {
    onSelect({ name, iso2, dialCode }) {
      this.model.countryCode = dialCode;
      console.log(name, iso2, dialCode);
    },
    isNumber(event) {
      if (!/\d/.test(event.key) && event.key !== ".")
        return event.preventDefault();
    },
    hide() {
      this.$refs["socialite-modal"].hide();
    },
    show() {
      this.$refs["socialite-modal"].show();
    },
    async logOut() {
      const result = await this.$gAuth.signOut();
      this.isLogin = false;
      console.log(`result`, result);
    },
    async login() {
      const googleUser = await this.$gAuth.signIn();
      this.model.idToken = googleUser.getAuthResponse().id_token;
      console.log("Get Started");
      //this.$refs["flutterwave-modal"].hide();
      this.loginModel.username = googleUser.getBasicProfile().getEmail();
      this.loginModel.password = "000000";
      this.loginModel.idToken = this.model.idToken;
      this.$store.commit("system/updateOverlayLoading", true);
      this.$store.dispatch("auth/login", this.loginModel).then(response => {
        this.$store.commit("system/updateOverlayLoading", false)
        console.log(response + "HI");
        if (response != null)
          if (response.data.responseCode === "09"){
            this.show();
          }
      });

      // console.log("googleUser", googleUser);
      // console.log("getId", googleUser.getId());
      // console.log("getBaseProfile", googleUser.getBasicProfile());
      // console.log("getAuthResponse", googleUser.getAuthResponse());
      // console.log(
      //   "getAuthResponse$G",
      //   this.$gAuth.GoogleAuth.currentUser.get().getAuthResponse()
      // );
      // this.isLogin = this.$gAuth.isAuthorized;
    },
    async register(){
      const googleUser = await this.$gAuth.signIn();
      this.loginModel.idToken = googleUser.getAuthResponse().id_token;
      this.loginModel.passwordConfirmation = "000000";
      this.loginModel.password = "000000";
      this.loginModel.hostId = localStorage.inviteId;
      this.loginModel.username = googleUser.getBasicProfile().getEmail();
      this.loginModel.type = "google";
      this.loginModel.idToken = this.model.idToken;
      this.loginModel.customerPhoneNumber = this.model.customerPhoneNumber;
      this.loginModel.countryCode = this.model.countryCode;
      this.loginModel.otp = "0000";
      this.loginModel.referralCode = localStorage.referralCode;
      this.loginModel.shareId = localStorage.shareId;
      this.$store.dispatch("auth/socialiteEnrollment", this.loginModel);
    }
  },
};
</script>

<style scoped>

</style>