import BorderlessService from "@/service/BorderlessService";
import swal from "sweetalert";


export const state = {
    loading: false,
    token: null,
    handle: null
}

export const getters = {

}

export const mutations = {
    updateLoading: (state, payload) => {
        state.loading = payload;
    },
    updateToken: (state, payload) => {
        state.token = payload
    },
    updateHandle: (state, payload) => {
        state.handle = payload;
    },
}

export const actions = {
    createToken({commit},payload){
        commit("updateLoading", true)
        return BorderlessService.callCreateApi(payload)
            .then(response => {
                commit("updateLoading", false)
                let responseData = response.data
                if(responseData.responseCode === "00"){
                    swal("Success", responseData.responseMessage, "success");
                    commit('updateHandle', responseData)
                }else{
                    swal("Error", responseData.responseMessage, "error");
                }
            })
            .catch(err=>{
                commit("updateLoading", false)
                console.error(err)
            })
    },
    updateToken: ({ commit }, payload) => {
        commit('updateLoading', true)
        return BorderlessService.callUpdateApi(payload).then(response => {
            let responseData = response.data
            if (responseData.responseCode === "00") {
                commit("updateLoading", false)
                commit("updateToken", responseData)
                // BaseNotification.fireToast("success", responseData.responseMessage).then()

            } else {
                commit("updateLoading", false)
                swal("Error", responseData.responseMessage, "error");
            }
        }).catch((error) => {
            commit("updateLoading", false)
            swal("Error", error, "error");


        })

    },
    getToken: ({ commit }, payload) => {
        //TODO USER LOADING
        commit("updateMoneyLoading", true);
        return BorderlessService.getTokenApi(payload).then((response) => {
            console.log(response)
            console.log(response.data)
            commit("updateToken", response)

            let responseData = response.data;
              if (responseData.responseCode === "00") {
                  swal("Success", responseData.responseMessage, "success");
              }else{
                  swal("Error", responseData.responseMessage, "error");
              }
          })
          .catch((err) => {
              commit("updateMoneyLoading", false);
              console.log(err);
          });
    },
    deleteToken(){

    }
}
