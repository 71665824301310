var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    'flex',
    'items-center',
    'w-full',
    'font-medium',
    'text-base',
    'cursor-pointer',
    _vm.type,
  ],on:{"click":function($event){return _vm.$emit('click', true)}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }