<template >
  <div class="dashboard-nav wf-section" style="background: #FFFFFF; padding-top: 60px">
  <div class="main-dashboard-section wf-section">
    <div class="main-dashboard-body mall">
      <div class="content">
          <div class="create-campaign-form-container">
            <div class="back-button-container" style="margin: 0; position: absolute; left: 260px">
              <a @click="$router.go(-1)" class="link-block-11 w-inline-block">
                <div class="text-block-69">Back to mall </div>
              </a>
            </div>
          <div class="create-campaign-header">
            <div>
              <h1 class="heading-23">Edit your Online Mall</h1>
            </div>
            <div class="div-block-90">
              <div class="text-block-41">Connect with brands, advertisers, and publishers for sponsorship opportunities to create and share content.</div>
            </div>
          </div>
          <div class="new-campaign-block2">
            <div class="w-form">
              <form id="email-form" name="email-form" data-name="Email Form" @submit.prevent="editMall" class="form-4">
<!--                <div class="industry">-->
<!--                  <div class="div-block-145">-->
<!--                    <label for="Industry" class="field-label-8">Company <b-spinner style="color: #63b8a0;" v-if="organisation.organisationLoading" small/></label>-->
<!--                    <a data-w-id="69d342ba-eadf-ddbe-72b4-6fc45182b84c" href="#" class="link-block-16 w-inline-block">-->
<!--                      <div @click="showModal = !showModal">+ Add new company</div>-->
<!--                    </a>-->
<!--                  </div>-->
<!--                  <select v-model="model.organisationId" id="Industry" name="Industry" required="" data-name="Industry" class="select-field w-select">-->
<!--                    <option value="">Select Company </option>-->
<!--                    <option v-for="company in organisations" :value="company.organisationId" v-bind:key="company.organisationId">{{ company.organisationName }}</option>-->
<!--                  </select>-->
<!--                </div>-->
                <div class="campaign-name mall">
                  <div class="div-block-264">
                    <label  class="field-label-8">Mall Currency</label>
<!--                    <img src="images/Question.svg" loading="lazy" data-w-id="501aac60-12aa-a274-c8ce-e0fc20447ec3" alt="">-->
                  </div>
                  <select  v-model="model.organisationCurrency" id="Industry-2" name="Industry-2" required="" data-name="Industry 2" class="select-field w-select">
                  <option value="USD">USD</option>
                  <option value="NGN">NGN</option>
                </select>
                </div>

                <div class="campaign-name mall">
                  <div class="div-block-264">
                    <label  class="field-label-8">Redirection Platform</label>
                    <!--                    <img src="images/Question.svg" loading="lazy" data-w-id="501aac60-12aa-a274-c8ce-e0fc20447ec3" alt="">-->
                  </div>
                  <select  v-model="model.organisationRedirectPlatform" id="Industry-29" name="Industry-2" required="" data-name="Industry 2" class="select-field w-select">
                    <option value="Whatsapp">WhatsApp</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Custom">Custom</option>
                  </select>
                </div>
                <div v-if="model.organisationRedirectPlatform === 'Whatsapp'" class="field-label-8" style="margin-bottom: 20px">
                  <label for="name-3" class="field-label-8"
                  >Whatsapp Phone Number</label>
                  <div class="div-block-143" style="border: none">
                    <input
                      type="number"
                      v-model="model.organisationRedirectParameter"
                      required
                      class="text-field-17 w-input"
                      maxlength="256"
                      name="name-3"
                      data-name="Name 3"
                      style="padding-left: 10px; height: 40px; border-left: 0;  border-radius: 0px 6px 6px 0px; border-color: rgba(31, 36, 49, 0.36);"
                      placeholder="Enter Whatsapp Phone number"
                      id="name-3"
                    />
                    <!--          <div>-->
                    <vue-country-code
                      class="dial-code"
                      style="padding-left: 0; width: 90px; height: 40px; border-radius: 6px 0 0 6px"
                      v-model="model.organisationCountryCode"
                      @onSelect="onSelect"
                      :preferredCountries="['ng', 'us', 'gb']"
                    />
                    <!--          </div>-->
                  </div>
                </div>

                <div v-else-if="model.organisationRedirectPlatform === 'Instagram'" class="div-block-146">
                  <label class="field-label-8">Company Instagram Username </label>
                  <input type="text" v-model="model.organisationRedirectParameter"   required class="text-field-17 w-input" maxlength="256" name="name" data-name="Name" placeholder="Instagram Username" id="namef6392">
                </div>
                <div v-else class="div-block-146">
                  <label class="field-label-8">Custom Redirection link </label>
                  <input type="text" v-model="model.organisationRedirectCustomUrl"   required class="text-field-17 w-input" maxlength="256" name="name" data-name="Name" placeholder="Redirection link" id="namef632">
                </div>

                <div class="div-block-146">
                    <label class="field-label-8">Company Description </label>
                    <input type="text" v-model="model.organisationDescription"   required class="text-field-17 w-input" maxlength="256" name="name" data-name="Name" placeholder="Enter Mall Description" id="namef32">
                </div>

                <base-button title="Submit" :loading="organisation.organisationLoading"/>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <company-create-modal :show="showModal"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrganisationRequest from "@/model/request/OrganisationRequest";
import OrganisationResponse from "@/model/response/OrganisationResponse";
import CompanyCreateModal from "@/components/modal/CompanyCreateModal";
import BaseButton from "@/components/button/BaseButton";

export default {
  name: "EditMall",
  components: { BaseButton, CompanyCreateModal },
  data() {
    return {
      showModal: false,
      model: OrganisationRequest.prototype.organisationUpdateRequest(),
      orgModel: OrganisationRequest.prototype.organisationReadSingleRequest(),
      readModel: OrganisationRequest.prototype.organisationReadSingleRequest()

    };
  },
  computed: {
    ...mapState({
      sbucket: state => state.sbucket,
      organisations:state => state.organisation.organisations,
      auth: state => state.auth,
      organisation: state => state.organisation,
      singleMall: state => state.organisation.organisation

    }),
    categories: function () {
      return new OrganisationResponse().readOrganisationCategoryResponse().data;
    },
  },
  methods:{
    async editMall() {


      // this.model.organisationLogo = this.sbucket.s3bucketResponse.url;
      // this.model.organisationPhone = this.auth.userInfo.customerPhoneNumber;
      // this.model.organisationEmail = this.auth.userInfo.customerEmail;
      // this.model.organisationCustomerId = this.auth.userInfo.customerId;
      // await this.$store.dispatch("organisation/editMall", this.model);
      // await this.$store.dispatch("organisation/updateOrganisations", { customerId: this.auth.userInfo.customerId });
       this.model.organisationId = localStorage.organisationId
      await this.$store.dispatch("organisation/editMall", this.model);
      this.readModel.organisationId = localStorage.organisationId
      await this.$router.push("/mall-registered")
      this.hide();
    },
    onSelect({ name, iso2, dialCode }) {
      this.model.organisationCountryCode = dialCode;
      console.log(name, iso2, dialCode);
    },
  },
  mounted() {
    this.readModel.organisationId = localStorage.organisationId;
    this.$store.dispatch("organisation/updateOrganisation", this.readModel);
    this.model = this.singleMall

    const plugin = document.createElement("script");
    plugin.setAttribute("src", "https://play-apvertise.com/js/webflow.js");
    plugin.async = true;
    document.head.appendChild(plugin);

  },
  created() {
    const script = document.createElement("script");
    script.src = "https://play-apvertise.com/js/webflow.js";

  }

};
</script>

<style scoped>
.dropdown{
  width: auto;
  margin: 0 10px;
}
</style>