import DashboardNewUser from "@/components/onboarding/DashboardNewUser.vue";
import DashboardSelectGender from "@/components/onboarding/DashboardSelectGender.vue";
import DashboardComplete from "@/components/onboarding/DashboardComplete.vue";
import SwitchUser from "@/components/onboarding/SwitchUser.vue";
import InfluencePage from "@/components/influencers/InfluencePage.vue";
import InfluencerProfile from "@/components/influencers/InfluencerProfile.vue";
import InfluencerPimChat from "@/components/influencers/InfluencerPimChat.vue";
import NotificationsPim from "@/components/influencers/NotificationsPim.vue";
import PimRequests from "@/components/influencers/PimRequests.vue";

export default [
  {
    path: "/onboarding/new-user",
    name: "DashboardNewUser",
    meta: { layout: "", authRequired: false },
    component: DashboardNewUser,
  },
  {
    path: "/onboarding/profiling",
    name: "DashboardSelectGender",
    meta: { layout: "", authRequired: false },
    component: DashboardSelectGender,
  },
  {
    path: "/onboarding/complete",
    name: "DashboardComplete",
    meta: { layout: "", authRequired: false },
    component: DashboardComplete,
  },
  {
    path: "/switch-user",
    name: "SwitchUser",
    meta: { layout: "", authRequired: false },
    component: SwitchUser,
  },
  {
    path: "/influencers",
    name: "InfluencePage",
    meta: { layout: "", authRequired: false },
    component: InfluencePage,
  },
  {
    path: "/influencer-pim-chat",
    name: "InfluencePimChat",
    meta: { layout: "", authRequired: false },
    component: InfluencerPimChat,
  },
  {
    path: "/influencer/view/:id",
    name: "InfluencerProfile",
    meta: { layout: "", authRequired: false },
    component: InfluencerProfile,
  },
  {
    path: "/notifications-pim",
    name: "NotificationsPim",
    meta: { layout: "", authRequired: false },
    component: NotificationsPim,
  },
  {
    path: "/pim-requests/:id",
    name: "PimRequests",
    meta: { layout: "", authRequired: false },
    component: PimRequests,
  }
];
