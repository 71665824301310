// import pimCampaignService from "@/service/PimCampaignService";
// import NotificationService from "@/service/NotificationService";
import swal from "sweetalert";
import PimCampaignService from "@/service/PimCampaignService";
import BaseNotification from "@/components/baseNotification/BaseNotification";


export const state={
    pimCampaign: {
        pimCampaignStatus: ""
    },
    pimCampaigns: {
        data: []
    },
    recentCampaigns: {
        data: []
    },
    pimPackage: {},
    pimPackages: {
        data: []
    },
    selectedPackage: {},
    selectedInfluencer: {},
    loading: false,
}

export const getters={
    getLoading:(state)=>{
        return state.loading;
    }
}

export const mutations={
    updateLoading(state, payload){
        state.loading = payload
    },
    updatePimCampaign(state, payload){
        state.pimCampaign = payload
    },
    updatePimCampaigns(state, payload){
        state.pimCampaigns = payload
    },
    updateSelectedPackage(state, payload){
        state.selectedPackage = payload
    },
    updateSelectedInfluencer(state, payload){
        state.selectedInfluencer = payload
    },
    updateRecentCampaign(state, payload){
        state.recentCampaigns = payload
    },
    updatePimPackage(state, payload){
        state.pimPackage = payload
    },
    updatePimPackages(state, payload){
        state.pimPackages = payload
    }
}

export const actions={
    create:({ commit },payload) => {
        commit("updateLoading", true);
        return PimCampaignService.callCreateApi(payload)
            .then((response)=> {
                commit("updateLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updateLoading", false)
                    BaseNotification.fireToast("success", responseData.responseMessage).then()
                    // BaseNotification.fireToast("success", responseData.responseMessage).then()

                } else {
                    commit("updateLoading", false)
                    BaseNotification.fireToast("error", responseData.responseMessage).then()
                }
            }).catch((error) => {
                commit("updateLoading", false)
                swal("Error", error, "error");
            });
    },
    createPackage:({ commit },payload)=>{
        commit("updateLoading", true);
        return PimCampaignService.callCreatePackageApi(payload)
            .then((response)=> {
                commit("updateLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updateLoading", false)
                    BaseNotification.fireToast("success", responseData.responseMessage).then()
                    // BaseNotification.fireToast("success", responseData.responseMessage).then()

                } else {
                    commit("updateLoading", false)
                    BaseNotification.fireToast("error", responseData.responseMessage).then()
                }
            }).catch((error) => {
                commit("updateLoading", false)
                swal("Error", error, "error");
            });


    },
    readPackage:({ commit },payload)=>{
        commit("updateLoading", true);
        return PimCampaignService.callReadPackageApi(payload)
            .then((response)=> {
                commit("updateLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updatePimPackages", responseData)
                    // BaseNotification.fireToast("success", responseData.responseMessage).then()

                } else {
                    commit("updateLoading", false)
                    // swal("Error", responseData.responseMessage, "error");
                }
            }).catch((error) => {
                commit("updateLoading", false)
                console.log(error)
                // swal("Error", error, "error");
            });
    },
    readPimCampaign:({ commit },payload)=>{
        commit("updateLoading", true);
        return PimCampaignService.callReadSinglePimApi(payload)
            .then((response)=> {
                commit("updateLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updatePimCampaign", responseData)
                    // BaseNotification.fireToast("success", responseData.responseMessage).then()

                } else {
                    commit("updateLoading", false)
                    // swal("Error", responseData.responseMessage, "error");
                }
            }).catch((error) => {
                commit("updateLoading", false)
                console.log(error)
                // swal("Error", error, "error");
            });
    },
    readSingleCustomer:({commit}, payload) =>{
            commit("updateLoading", true);
            return PimCampaignService.callReadSingleCustomerApi(payload)
                .then((response)=> {
                    commit("updateLoading", false);
                    console.log(response)
                    let responseData = response.data;
                    console.log(responseData)
                    if (responseData.responseCode === "00") {
                        commit("updatePimCampaigns", responseData)
                        // BaseNotification.fireToast("success", responseData.responseMessage).then()

                    } else {
                        commit("updateLoading", false)
                        // swal("Error", responseData.responseMessage, "error");
                    }
                }).catch((error) => {
                    console.log(error)
                    commit("updateLoading", false)
                    // swal("Error", error, "error");
                });

    },
    readRecentCampaign:({commit}, payload) =>{
        commit("updateLoading", true);
        return PimCampaignService.callReadRecentCampaignApi(payload)
            .then((response)=> {
                commit("updateLoading", false);
                console.log(response)
                let responseData = response.data;
                console.log(responseData)
                if (responseData.responseCode === "00") {
                    commit("updateRecentCampaign", responseData)
                    // BaseNotification.fireToast("success", responseData.responseMessage).then()

                } else {
                    commit("updateLoading", false)
                    // swal("Error", responseData.responseMessage, "error");
                }
            }).catch((error) => {
                console.log(error)
                commit("updateLoading", false)
                // swal("Error", error, "error");
            });

    },
    accept:({commit}, payload)=>{
        commit("updateLoading", true);
        return PimCampaignService.callAcceptApi(payload)
            .then((response)=> {
                commit("updateLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updateLoading", false)
                    BaseNotification.fireToast("success", responseData.responseMessage).then()
                } else {
                    commit("updateLoading", false)
                    BaseNotification.fireToast("error", responseData.responseMessage).then()
                }
            }).catch((error) => {
                commit("updateLoading", false)
                swal("Error", error, "error");
            });
    },
    review:({commit}, payload)=>{
        commit("updateLoading", true);
        return PimCampaignService.callReviewApi(payload)
            .then((response)=> {
                commit("updateLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updateLoading", false)
                    BaseNotification.fireToast("success", responseData.responseMessage).then()
                    return responseData

                } else {
                    commit("updateLoading", false)
                    BaseNotification.fireToast("error", responseData.responseMessage).then()
                    return responseData
                }
            }).catch((error) => {
                commit("updateLoading", false)
                swal("Error", error, "error");
            });
    },
    decline:({commit}, payload)=>{
        commit("updateLoading", true);
        return PimCampaignService.callDeclineApi(payload)
            .then((response)=> {
                commit("updateLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updateLoading", false)
                    BaseNotification.fireToast("success", responseData.responseMessage).then()
                    // BaseNotification.fireToast("success", responseData.responseMessage).then()

                } else {
                    commit("updateLoading", false)
                    BaseNotification.fireToast("error", responseData.responseMessage).then()
                }
            }).catch((error) => {
                commit("updateLoading", false)
                swal("Error", error, "error");
            });
    },
    complete:({commit}, payload)=>{
        commit("updateLoading", true);
        return PimCampaignService.callCompleteApi(payload)
            .then((response)=> {
                commit("updateLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updateLoading", false)
                    BaseNotification.fireToast("success", responseData.responseMessage).then()
                    return responseData
                    // BaseNotification.fireToast("success", responseData.responseMessage).then()

                } else {
                    commit("updateLoading", false)
                    BaseNotification.fireToast("error", responseData.responseMessage).then()
                    return responseData
                }
            }).catch((error) => {
                commit("updateLoading", false)
                swal("Error", error, "error");
            });
    },
    readPackageByCustomerId:({commit}, payload)=>{
        commit("updateLoading", true);
        return PimCampaignService.callReadPackageByCustomerIdApi(payload)
            .then((response)=> {
                commit("updateLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updatePimPackages", responseData)
                    // BaseNotification.fireToast("success", responseData.responseMessage).then()

                } else {
                    commit("updateLoading", false)
                }
            }).catch((error) => {
                commit("updateLoading", false)
                swal("Error", error, "error");
            });
    }

}
