import client from "./BaseService";

export default {
    callCreateApi(payload) {
        return client.apiClient.post("borderless/account/create", payload);
    },
    getTokenApi(payload) {
        return client.apiClient.post("borderless/token", payload);
    }

}