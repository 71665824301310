import SocialMediaService from "@/service/SocialMediaService";
import SocialMediaRequest from "@/model/request/SocialMediaRequest";
import store from "@/store/store";

export const state = {
  loading: false,
  authorizeState: "",
  challengeCode: "",
  socialMediaDetails:[],
    socialMedia: {}
};
export const getters = {};
export const mutations = {
  updateLoading: (state, payload) => {
    state.loading = payload;
  },
    updateState: (state, payload) => {
      state.authorizeState = payload;
    },
    updateSocialMedia: (state, payload) => {
        state.socialMedia = payload;
    },
    updateChallengeCode: (state, payload) => {
        state.challengeCode = payload;
    },
    updateSocialMediaDetails: (state, payload) => {
        state.socialMediaDetails = payload;
    },
};
export const actions = {
  enrollUser: ({ commit }, payload = SocialMediaRequest.enroll) => {
    commit("updateLoading", true);
    return SocialMediaService.callEnrollApi(payload)
      .then((response) => {
        commit("updateLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          console.log("success");
        } else {
          console.log("bad");
        }
      })
      .catch((err) => {
        commit("updateLoading", false);
        console.log(err);
      });
  },
  authorizeTwitter({ commit }, payload = SocialMediaRequest.addTwitter) {
    commit("updateLoading", true);
    return SocialMediaService.callAddTwitterApi(payload)
      .then((response) => {
        commit("updateLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          console.log("success");
          store.commit("socialMedia/updateSocialMedia", responseData)
            return this.$store.dispatch("socialMedia/readCustomer")
        } else {
            store.commit("socialMedia/updateSocialMedia", responseData)
            console.log("bad");
        }
      })
      .catch((err) => {
        commit("updateLoading", false);
        console.log(err);
      });
  },
  authorizeInstagram({ commit }, payload = SocialMediaRequest.addTiktok) {
    commit("updateLoading", true);
    return SocialMediaService.callInstagramApi(payload)
      .then((response) => {
        commit("updateLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          console.log("success");
            store.commit("socialMedia/updateSocialMedia", responseData)
            return this.$store.dispatch("socialMedia/readCustomer")
        } else {
          console.log("bad");
            store.commit("socialMedia/updateSocialMedia", responseData)

        }
      })
      .catch((err) => {
        commit("updateLoading", false);
        console.log(err);
      });
  },
  authorizeTiktok({commit}, payload =  SocialMediaRequest.addTiktok){
      commit("updateLoading", true);
      return SocialMediaService.callAddTikTokApi(payload)
          .then((response) => {
              commit("updateLoading", false);
              let responseData = response.data;
              if (responseData.responseCode === "00") {
                  console.log("success");
                  store.commit("socialMedia/updateSocialMedia", responseData)
                  return this.$store.dispatch("socialMedia/readCustomer")
              } else {
                  store.commit("socialMedia/updateSocialMedia", responseData)
                  console.log("bad");
              }
          })
          .catch((err) => {
              commit("updateLoading", false);
              console.log(err);
          });
  },
  readCustomer({commit}, payload = SocialMediaRequest.read){
      commit("updateLoading", true);
      return SocialMediaService.callReadByCustomerIdApi(payload)
          .then((response) => {
              commit("updateLoading", false);
              let responseData = response.data;
              if (responseData.responseCode === "00") {
                  console.log("success");
                  commit("updateSocialMediaDetails", responseData.data);
              } else {
                  console.log("bad");
              }
          })
          .catch((err) => {
              commit("updateLoading", false);
              console.log(err);
          });

  }
};
