var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"share-campaign-modal",staticClass:"my-modal",staticStyle:{"background":"white"},attrs:{"centered":"","hide-header":"","hide-footer":""}},[_c('div',{staticClass:"div-block-156"},[_c('a',{staticClass:"close-topup-modal w-inline-block",attrs:{"data-w-id":"0e756eac-b69c-ca0d-7ba7-f2b54c7eb6e2","href":"#"},on:{"click":function($event){return _vm.hide()}}},[_c('img',{attrs:{"src":require("@/assets/images/close-modal.svg"),"loading":"lazy","alt":""}})])]),_c('div',[_c('h1',{staticClass:"heading-25"},[_vm._v("Share Campaign")])]),_c('div',{staticClass:"div-block-103"},[_c('div',{staticClass:"w-layout-grid grid-4"},[_c('a',{staticClass:"link-block-13 w-inline-block",attrs:{"href":`https://twitter.com/intent/tweet?text=${encodeURIComponent(
            _vm.title
          )}%0A${encodeURIComponent(_vm.description)}%0A%0A` + _vm.link}},[_c('img',{staticClass:"image-35",attrs:{"src":require("@/assets/images/twitter.svg"),"loading":"lazy","alt":""}}),_c('div',{staticClass:"text-block-48"},[_vm._v("Twitter")])]),_c('a',{staticClass:"link-block-13 w-inline-block",attrs:{"href":`fb-messenger://share/?link= ${encodeURIComponent(
          _vm.title
        )}%0A${encodeURIComponent(
          _vm.description
        )}%0A%0A${_vm.link}&app_id=123456789`}},[_c('img',{staticClass:"image-36",attrs:{"src":require("@/assets/images/Facebook.svg"),"loading":"lazy","alt":""}}),_c('div',{staticClass:"text-block-49"},[_vm._v("Facebook")])]),_c('a',{staticClass:"link-block-13 w-inline-block",attrs:{"href":"#"}},[_c('img',{staticClass:"image-37",attrs:{"src":require("@/assets/images/Discord.svg"),"loading":"lazy","alt":""}}),_c('div',{staticClass:"text-block-50"},[_vm._v("Discord")])]),_c('a',{staticClass:"link-block-13 w-inline-block",attrs:{"href":`whatsapp://send?text=*${_vm.title.trim()}*%0A${encodeURIComponent(
            _vm.description
          )}%0A%0A` + _vm.link}},[_c('img',{staticClass:"image-38",attrs:{"src":require("../../assets/images/whatsapp.svg"),"loading":"lazy","alt":""}}),_c('div',{staticClass:"text-block-51"},[_vm._v("Whatsapp")])]),_c('a',{staticClass:"link-block-13 w-inline-block",attrs:{"href":`https://telegram.me/share/url?url=${_vm.link}&text=${encodeURIComponent(
          _vm.title
        )}%0A${encodeURIComponent(_vm.description)}%0A%0A`}},[_c('img',{staticClass:"image-39",attrs:{"src":require("@/assets/images/telegram.svg"),"loading":"lazy","alt":""}}),_c('div',{staticClass:"text-block-52"},[_vm._v("Telegram")])]),_c('a',{staticClass:"link-block-13 w-inline-block",attrs:{"href":`fb-messenger://share/?link=${encodeURIComponent(
          _vm.title
        )}\n\n${encodeURIComponent(
          _vm.description
        )}\n\n ${_vm.link}&app_id=123456789`}},[_c('img',{staticClass:"image-40",attrs:{"src":require("@/assets/images/Messenger.svg"),"loading":"lazy","alt":""}}),_c('div',{staticClass:"text-block-53"},[_vm._v("Messenger")])])])]),_c('div',[_c('div',{staticClass:"text-block-54"},[_vm._v("Campaign Link")]),_c('div',{staticClass:"div-block-104"},[_c('div',{staticClass:"text-block-55 overflow-auto text-nowrap"},[_vm._v(_vm._s(_vm.link))]),_c('button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.link),expression:"link",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.onError),expression:"onError",arg:"error"}],attrs:{"id":"tooltip-button-show-event","type":"button"}},[_c('img',{staticStyle:{"width":"30px","height":"30px"},attrs:{"src":require("@/assets/images/Copy.svg"),"loading":"lazy","alt":""}})])]),_c('b-tooltip',{ref:"tooltip",attrs:{"variant":_vm.variant,"target":"tooltip-button-show-event"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }