<template>
  <div style="background-color: white; padding-top: 50px">
    <section id="IntroMessageModal" class="share-campaign-popup">
      <div class="share-campaign-modal" style="display: block; width: auto;">
        <div class="modal-image-container"></div>
        <div class="share-campaign-header">
          <h5 class="share-campaign-header_text" style="font-size: 18px; font-weight: 500; margin-bottom: 10px">Share Campaign</h5>
          <div class="share-campaign-subheading_text" style="font-size: 16px">You are about to share this campaign, would you like to proceed?</div>
        </div>
        <div class="social-links-wrapper">
          <a :href="
            `https://twitter.com/intent/tweet?text=${encodeURIComponent(
              title
            )}%0A${encodeURIComponent(description)}%0A%0A` + link
          " class="socials "><img src="../../../public/images/twitter_1.svg" loading="lazy" alt="">
            <div class="text-block-7">Twitter</div>
          </a>
          <a :href="`fb-messenger://share/?link= ${encodeURIComponent(
            title
          )}%0A${encodeURIComponent(
            description
          )}%0A%0A${link}&app_id=123456789`" class="socials "><img src="../../../public/images/fb.svg" loading="lazy" alt="">
            <div class="text-block-7">Facebook</div>
          </a>
          <a :href="
            `whatsapp://send?text=*${title.trim()}*%0A${encodeURIComponent(
              description
            )}%0A%0A` + link
          " class="socials "><img src="../../../public/images/whatsapp.svg" loading="lazy" alt="">
            <div class="text-block-7">Whatsapp</div>
          </a>
          <a :href="`https://telegram.me/share/url?url=${link}&text=${encodeURIComponent(
            title
          )}%0A${encodeURIComponent(description)}%0A%0A`" class="socials "><img src="../../../public/images/telegram.svg" loading="lazy" alt="">
            <div class="text-block-7">Telegram</div>
          </a>
        </div>
        <div class="social-divider">
          <div class="divider1"></div>
          <div class="text-block-new">OR</div>
          <div class="divider1"></div>
        </div>
        <div class="campaign-link-wrapper">
          <div class="text-block-new-2">Campaign Link</div>
          <div class="campaign-link-block" style="position: relative">
            <div class="" style="display: contents;">
              <div class="text-block-10">{{ link }}</div>
              <button
                @click="showText"
                id="copy-link"
                type="button"
                v-clipboard:copy="link"
                style="background: none"
              >
                <img
                  @click="showText"
                  style="width: 30px; height: 30px"
                  src="../../../public/images/Copy.svg"
                  loading="lazy"
                  alt=""
                />
              </button>
              <p style="color: black; font-size: 14px; position: absolute; top: -20px; right: 10px" v-if="text">Copied!</p>
            </div>
          </div>
        </div>
        <a data-w-id="5dd05fa0-7341-aeba-f177-f7142c7cbff4" href="#" class="link-block-2 " @click="close('.share-campaign-popup')"><img src="../../../public/images/x_1.svg" loading="lazy" width="24" alt=""></a>
      </div>
    </section>
    <div class="section">
      <div class="dashboard-wrapper" style="height: 100%">
        <div class="breadcrumb-wrapper">
          <a class="back-btn w-inline-block" @click="goBack"><img src="../../assets/images/chevron-right_1.svg" loading="lazy" alt="" class="image-5">
            <div class="text-block-6">Back to campaigns</div>
          </a>
        </div>
        <div class="mall-details-content">
          <div class="campaign-details-wrapper">
            <div class="campaign-image-wrapper">
              <div data-delay="4000" data-animation="slide" style="height: 400px;" class="campaign-image-slider w-slider" data-autoplay="false" data-easing="ease" data-hide-arrows="false" data-disable-swipe="false" data-autoplay-limit="0" data-nav-spacing="3" data-duration="500" data-infinite="true">
                <div class="w-slider-mask">
                  <b-carousel
                    id="carousel-1"
                    v-model="slide"
                    :interval="4000"
                    controls
                    indicators
                    label-next=""
                    label-prev=""
                    background="#ffffff"
                    style="text-shadow: 1px 1px 2px #333; width: 100%"
                    @sliding-start="onSlideStart"
                    @sliding-end="onSlideEnd"
                  >
                    <b-carousel-slide style="border-radius: 20px 20px 20px 20px;" v-for="banner in banners" v-bind:key="banner">
                      <template #img>
                        <img
                          style="border-radius: 20px 20px 20px 20px; width: 100%; height: 400px"
                          class="d-block img-fluid"
                          width="606"
                          height="387"
                          :src="banner"
                          alt="image slot"
                        >
                      </template>
                    </b-carousel-slide>
                  </b-carousel>
                </div>
              </div>
            </div>
            <div class="campaign-detail-wrapper">
              <h4 class="campaign-title" style="text-transform: capitalize">{{ campaign.campaign.campaignName }}</h4>
              <div class="campaign-company"><img :src="campaign.campaign.organisationLogo" loading="lazy" alt="" class="campaign_logo">
                <div class="company-name">{{ campaign.campaign.organisationName }}</div>
              </div>
              <div class="tags-wrapper">
                <div class="div-block-2">
                  <div class="tag-copy big">
                    <img src="../../assets/images/advertising-1.svg" alt="">
                    <div class="text-block-4" v-if="campaign.campaign.campaignType.includes('AWARENESS')">Awareness</div>
                    <div class="text-block-4" v-else>Acquistion</div>

                  </div>
                  <div class="category big">
                    <div class="tag-dot"></div>
                    <div class="text-block-4">{{ campaign.campaign.organisationType }}</div>
                  </div>
                </div>
              </div>
              <div class="campaign-description-wrapper">
                <div class="campaign-description-text">{{ campaign.campaign.campaignDescription }}</div>
              </div>
              <div class="div-block-3">
                <a data-w-id="66491ffc-800c-a6d8-8a7f-70392d09e7a5" @click="uploadProfile('.share-campaign-popup', campaign.campaignName, campaign.campaignDescription, campaign.campaignId, campaign.shareLink, campaign.customerOrganisationCustomerId)" href="#" class="button w-button">Share campaign</a>
                <a data-w-id="74db2e5d-3cc0-5eab-3c34-114d28850d4e" class="button is-secondary w-button" @click="goToEdit">Edit Campaign</a>
              </div>
            </div>
          </div>
          <div class="campaign-data-wrapper">
            <div data-current="Tab 1" data-easing="ease" data-duration-in="300" data-duration-out="100" class="w-tabs">
              <div class="tabs-menu-2 w-tab-menu">
                <a data-w-tab="Tab 1" class="tabs w-inline-block w-tab-link w--current">
                  <div>General Metrics</div>
                </a>
                <a data-w-tab="Tab 2" class="tabs w-inline-block w-tab-link">
                  <div>My Metrics</div>
                </a>
              </div>
              <div class="tabs-content-2 w-tab-content">
                <div data-w-tab="Tab 1" class="w-tab-pane w--tab-active">
                  <div class="div-block-18">
                    <div class="campaign__info__block">
                      <div class="campaign__info">
                        <h5 class="campaign-detail-heading">Campaign Engagements</h5>
                        <div class="campaign_detail_card">
                          <div class="campaign-details-list">
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-detail-title">Shares</div>
                                <div :class="'campaign-stat-progressbar _' + analytic.sharesPerCampaign.data.length"></div>
                              </div>
                              <div class="campaign-stat-main">{{ analytic.sharesPerCampaign.data.length }}</div>
                            </div>
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-detail-title">Impressions</div>
                                <div :class="'campaign-stat-progressbar _' + analytic.impressionsPerCampaign.data.length"></div>
                              </div>
                              <div class="campaign-stat-main">{{ analytic.impressionsPerCampaign.data.length }}</div>
                            </div>
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-detail-title">Clicks</div>
                                <div :class="'campaign-stat-progressbar _' + analytic.clicksPerCampaign.data.length"></div>
                              </div>
                              <div class="campaign-stat-main">{{analytic.clicksPerCampaign.data.length}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="campaign__info">
                        <h5 class="campaign-detail-heading">Audience Location</h5>
                        <div class="campaign_detail_card">
                          <div class="campaign-details-list">
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-detail-title">Lagos</div>
                                <div class="campaign-stat-progressbar _100"></div>
                              </div>
                              <div class="campaign-stat-main">40</div>
                            </div>
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-detail-title">Abuja</div>
                                <div class="campaign-stat-progressbar _70"></div>
                              </div>
                              <div class="campaign-stat-main">10</div>
                            </div>
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-detail-title">Oyo</div>
                                <div class="campaign-stat-progressbar _30"></div>
                              </div>
                              <div class="campaign-stat-main">8</div>
                            </div>
                          </div>
                          <div class="empty-campaign-overlay"><img src="../../assets/images/bar-chart.svg" loading="lazy" alt="" class="empty-campaign-overlay-icon">
                            <div class="empty-campaign-overlay-h">No data available</div>
                            <div class="empty-campaign-overlay-p">Share a campaign to view data insights</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="campaign__info__block">
                      <div class="campaign__info">
                        <h5 class="campaign-detail-heading">Campaign Engagements</h5>
                        <div class="campaign_detail_card">
                          <div class="campaign-details-list">
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-detail-title">Shares</div>
                                <div class="campaign-stat-progressbar _100"></div>
                              </div>
                              <div class="campaign-stat-main">1140</div>
                            </div>
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-detail-title">Impressions</div>
                                <div class="campaign-stat-progressbar _70"></div>
                              </div>
                              <div class="campaign-stat-main">860</div>
                            </div>
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-detail-title">Clicks</div>
                                <div class="campaign-stat-progressbar _30"></div>
                              </div>
                              <div class="campaign-stat-main">458</div>
                            </div>
                          </div>
                          <div class="empty-campaign-overlay">
                            <img src="../../assets/images/bar-chart.svg" loading="lazy" alt="" class="empty-campaign-overlay-icon">
                            <div class="empty-campaign-overlay-h">No data available</div>
                            <div class="empty-campaign-overlay-p">Share a campaign to view data insights</div>
                          </div>
                        </div>
                      </div>
                      <div class="campaign__info">
                        <h5 class="campaign-detail-heading">Followers (Socials)</h5>
                        <div class="campaign_detail_card">
                          <div class="campaign-details-list">
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-social-title"><img src="../../assets/images/instagram_1.svg" loading="lazy" alt="" class="campaign-social-title-icon">
                                  <div class="campaign-detail-title">Instagram</div>
                                </div>
                                <div class="campaign-stat-progressbar "></div>
                              </div>
                              <div class="campaign-stat-main">0</div>
                            </div>
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-social-title"><img src="../../assets/images/Facebook_5.svg" loading="lazy" alt="" class="campaign-social-title-icon">
                                  <div class="campaign-detail-title">Facebook</div>
                                </div>
                                <div class="campaign-stat-progressbar _0"></div>
                              </div>
                              <div class="campaign-stat-main">0</div>
                            </div>
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-social-title"><img src="../../assets/images/twitter_2.svg" loading="lazy" alt="" class="campaign-social-title-icon">
                                  <div class="campaign-detail-title">Twitter</div>
                                </div>
                                <div class="campaign-stat-progressbar" :style="{width: (connectedTwitter * 0.01) + '%'}"></div>
                              </div>
                              <div class="campaign-stat-main">{{connectedTwitter}}</div>
                            </div>
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-social-title"><img src="../../assets/images/telegram.svg" loading="lazy" alt="" class="campaign-social-title-icon">
                                  <div class="campaign-detail-title">Tiktok</div>
                                </div>
                                <div class="campaign-stat-progressbar" :style="{width: (connectedTiktok * 0.01) + '%'}"></div>
                              </div>
                              <div class="campaign-stat-main">{{ connectedTiktok }}</div>
                            </div>
                          </div>
                          <div class="empty-campaign-overlay inactive"><img src="../../assets/images/bar-chart.svg" loading="lazy" alt="" class="empty-campaign-overlay-icon">
                            <div class="empty-campaign-overlay-h">No data available</div>
                            <div class="empty-campaign-overlay-p">Share a campaign to view data insights</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-w-tab="Tab 2" class="w-tab-pane">
                  <div class="div-block-18">
                    <div class="campaign__info__block">
                      <div class="campaign__info">
                        <h5 class="campaign-detail-heading">Campaign Engagements</h5>
                        <div class="campaign_detail_card">
                          <div class="campaign-details-list">
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-detail-title">Shares</div>
                                <div class="campaign-stat-progressbar _100"></div>
                              </div>
                              <div class="campaign-stat-main">1140</div>
                            </div>
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-detail-title">Impressions</div>
                                <div class="campaign-stat-progressbar _70"></div>
                              </div>
                              <div class="campaign-stat-main">860</div>
                            </div>
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-detail-title">Clicks</div>
                                <div class="campaign-stat-progressbar _30"></div>
                              </div>
                              <div class="campaign-stat-main">458</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="campaign__info">
                        <h5 class="campaign-detail-heading">Audience Location</h5>
                        <div class="campaign_detail_card">
                          <div class="campaign-details-list">
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-detail-title">Lagos</div>
                                <div class="campaign-stat-progressbar _100"></div>
                              </div>
                              <div class="campaign-stat-main">40</div>
                            </div>
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-detail-title">Abuja</div>
                                <div class="campaign-stat-progressbar _70"></div>
                              </div>
                              <div class="campaign-stat-main">10</div>
                            </div>
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-detail-title">Oyo</div>
                                <div class="campaign-stat-progressbar _30"></div>
                              </div>
                              <div class="campaign-stat-main">8</div>
                            </div>
                          </div>
                          <div class="empty-campaign-overlay"><img src="../../assets/images/bar-chart.svg" loading="lazy" alt="" class="empty-campaign-overlay-icon">
                            <div class="empty-campaign-overlay-h">No data available</div>
                            <div class="empty-campaign-overlay-p">Share a campaign to view data insights</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="campaign__info__block">
                      <div class="campaign__info">
                        <h5 class="campaign-detail-heading">Campaign Engagements</h5>
                        <div class="campaign_detail_card">
                          <div class="campaign-details-list">
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-detail-title">Shares</div>
                                <div class="campaign-stat-progressbar _100"></div>
                              </div>
                              <div class="campaign-stat-main">1140</div>
                            </div>
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-detail-title">Impressions</div>
                                <div class="campaign-stat-progressbar _70"></div>
                              </div>
                              <div class="campaign-stat-main">860</div>
                            </div>
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-detail-title">Clicks</div>
                                <div class="campaign-stat-progressbar _30"></div>
                              </div>
                              <div class="campaign-stat-main">458</div>
                            </div>
                          </div>
                          <div class="empty-campaign-overlay"><img src="../../assets/images/bar-chart.svg" loading="lazy" alt="" class="empty-campaign-overlay-icon">
                            <div class="empty-campaign-overlay-h">No data available</div>
                            <div class="empty-campaign-overlay-p">Share a campaign to view data insights</div>
                          </div>
                        </div>
                      </div>
                      <div class="campaign__info">
                        <h5 class="campaign-detail-heading">Followers (Socials)</h5>
                        <div class="campaign_detail_card">
                          <div class="campaign-details-list">
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-social-title"><img src="../../assets/images/instagram_1.svg" loading="lazy" alt="" class="campaign-social-title-icon">
                                  <div class="campaign-detail-title">Instagram</div>
                                </div>
                                <div class="campaign-stat-progressbar _100"></div>
                              </div>
                              <div class="campaign-stat-main">1140</div>
                            </div>
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-social-title"><img src="../../assets/images/Facebook_5.svg" loading="lazy" alt="" class="campaign-social-title-icon">
                                  <div class="campaign-detail-title">Facebook</div>
                                </div>
                                <div class="campaign-stat-progressbar _70"></div>
                              </div>
                              <div class="campaign-stat-main">860</div>
                            </div>
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-social-title"><img src="../../assets/images/twitter_2.svg" loading="lazy" alt="" class="campaign-social-title-icon">
                                  <div class="campaign-detail-title">Twitter</div>
                                </div>
                                <div class="campaign-stat-progressbar" :style="{width: (connectedTwitter * 0.01) + '%'}"></div>
                              </div>
                              <div class="campaign-stat-main">{{connectedTwitter}}</div>
                            </div>
                            <div class="campaign_detail">
                              <div class="campaign-stat-detail-info">
                                <div class="campaign-social-title"><img src="../../assets/images/telegram.svg" loading="lazy" alt="" class="campaign-social-title-icon">
                                  <div class="campaign-detail-title">Tiktok</div>
                                </div>
                                <div class="campaign-stat-progressbar" :style="{width: (connectedTiktok * 0.01) + '%'}"></div>
                              </div>
                              <div class="campaign-stat-main">{{ connectedTiktok }}</div>
                            </div>
                          </div>
                          <div class="empty-campaign-overlay inactive"><img src="../../assets/images/bar-chart.svg" loading="lazy" alt="" class="empty-campaign-overlay-icon">
                            <div class="empty-campaign-overlay-h">No data available</div>
                            <div class="empty-campaign-overlay-p">Share a campaign to view data insights</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="related__products">
            <div class="related-campaign-header">
              <h5>Related campaigns</h5>
            </div>
            <div class="related-campaign-wrapper">
              <div id="w-node-add92689-11c1-f3b9-0816-3f5f23c87914-7d1ae02a" class="campaign-card" v-for="(related, index) in campaign.campaign.relatedCampaigns" :key="index">
                <div class="campaign-logo-tag">
                  <div class="left-div"><img :src="related.organisationLogo" loading="lazy" alt="" class="campaign_logo"></div>
                  <div class="right-div">
                    <div class="tag">
                      <div class="tag-dot"></div>
                      <div class="text-block-4">{{related.organisationType}}</div>
                    </div>
                  </div>
                </div>
                <div class="campaign-details_wrapper">
                  <div class="campaign-card-title">{{related.campaignName}}</div>
                  <div class="campaign-text-p">{{
                      related.campaignDescription
                        | formatCampaignDescriptionWithEllipsis
                    }}</div>
                </div>
                <div class="campaign-card-price">
                  <div class="price-per-click-text">Price Per Click</div>
                  <div class="price-per-click">${{related.campaignPrice}}</div>
                </div>
                <div class="campaign-action-wrapper">
                  <a href="#" class="view-campaign-btn w-inline-block" @click="selectCampaign(related.campaignId)">
                    <div class="text-block-6">View campaign</div><img src="../../assets/images/chevron-right_1.svg" loading="lazy" alt="">
                  </a>
                  <a data-w-id="b44daff9-de44-7a18-5224-989beb99dab9" href="#" class="share-campaign-btn w-inline-block"><img src="../../assets/images/share-2.svg" loading="lazy" alt=""></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import DoughnutChart from "@/components/chart/base/DoughnutChart.vue";
import { mapState } from "vuex";
import format from "date-fns/format";
import SecurityUtil from "@/utilities/SecurityUtil";
import countriesJson from "@/assets/countries/data/en/countries.json";

export default {
  name: "CampaignMetrics",
  components: {},
  data() {
    return {
      text: false,
      slide: 0,
      sliding: null,
      model: {
        customerId: "",
        campaignId: "",
        startDate: "",
        type: "last30",
        endDate: "",
      },
      dateFormat: "D MMM",
      config: {
        type: "bar",
        data: this.data,
        options: {
          indexAxis: "y",
          plugins: {
            tooltip: {
              callbacks: {
                afterBody: (tooltipItems) => {
                  let sum = 0;
                  tooltipItems.forEach(function(tooltipItem) {
                    sum += tooltipItem.parsed.y;
                  });
                  if (sum === 0) {
                    return "\n Total Number of Influencers that \n shared your campaign";
                  }
                  if (sum === 1) {
                    return "\n Total Number of people (potential \n customers) that clicked your link.";
                  }
                  if (sum === 2) {
                    return "\n No of customers/valid clicks on \n your campaign";
                  }
                },
              },
            },
            legend: {
              display: false,
              labels: {
                color: "rgb(255, 99, 132)",
              },
            },
          },
          scales: {
            x: {
              grid: { display: true, drawBorder: false },
              ticks: { color: "rgba(31, 36, 49, 0.4)" },
            },
            y: {
              grid: { display: false, drawBorder: false },
              min: 0,
              max: 45,
              ticks: {
                color: "rgba(31, 36, 49, 0.4)",
              },
            },
          },
        },
      },
      chartOption: {
        indexAxis: 'y',
        legend: {
          display: false,
          position: 'bottom',
          fullWidth: true,
          labels: {
            boxWidth: 10,
            padding: 50
          }
        },
        scales: {
          yAxes: [{
            stacked: true,
            barPercentage: 0.75,
            gridLines: {
              display: true,
              drawTicks: true,
              drawOnChartArea: false
            },
            ticks: {
              fontColor: '#555759',
              fontFamily: 'Lato',
              fontSize: 11
            }

          }],
          xAxes: [{
            barThickness: 20,
            gridLines: {
              display: true,
              drawTicks: false,
              tickMarkLength: 5,
              drawBorder: false
            },
            ticks: {
              padding: 5,
              beginAtZero: true,
              fontColor: '#555759',
              fontFamily: 'Lato',
              fontSize: 11,
              callback: function(label, index, labels) {
                console.log(index, labels)
                return label / 1000;
              }

            },
            scaleLabel: {
              display: true,
              padding: 10,
              fontFamily: 'Lato',
              fontColor: '#555759',
              fontSize: 16,
              fontStyle: 700,
              labelString: 'Scale Label'
            },

          }]
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      doughnutOptions: {
        tooltips: {
          callbacks: {
            label: function () {
              return "Amount left"| "Amount Spent";
            },
          },
        },
        percentageInnerCutout: 85,
        cutoutPercentage: 85,
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: "down",
          },
          title: {
            display: false,
            text: "Chart.js Doughnut Chart",
          },
        },
      },
      options: {
        maintainAspectRatio: false,
        height: "200px",
        animation: {
          easing: "easeOutQuart"
        },
        legend: {
          position: "bottom",
          display: false
        },
        scales: {
          yAxes: [{
            categoryPercentage: 0.6,
            borderRadius: 1,
            barPercentage: 0.6,
            ticks: {
              fontColor: "rgba(31, 36, 49, 0.4)",
              fontStyle: "bold",
              beginAtZero: true,
              padding: 10,
              //display: false - remove this and commenting to display: false
            },
            gridLines: {
              display: true,
            }
          }],
          xAxes: [{
            categoryPercentage: 0.6,
            borderRadius: 1,
            barPercentage: 0.6,
            gridLines: {
              display: false,
              color: "rgba(31, 36, 49, 0.4)",
              zeroLineColor: "transparent"
            },
            ticks: {
              padding: 10,
              beginAtZero: true,
              fontColor: "rgba(31, 36, 49, 0.4)",
              fontStyle: "bold",
              maxTicksLimit: 20,
              //display: false - remove this and commenting to display: false
            }
          }]
        }
      },
      data: {
        labels: ['Clicks','Shares','Impression'],
        datasets: [{
          type: 'horizontalBar',
          label: 'Clicks',
          borderRadius: 1,
          backgroundColor: ["#FAD96C", "rgba(62, 154, 247, 1)", "rgb(255,87,87)"],
          hoverBackgroundColor: ["rgb(239,172,42)", "rgb(0,119,245)", "rgb(255,21,21)"],
          borderColor: "rgba(99, 184, 160, 1)",
          fillColor: ["#FAD96C", "rgba(62, 154, 247, 1)", "rgba(99, 184, 160, 1)"],
          borderWidth: 0.4,
          data: [100, 50, 60]
        }, ],
        backgroundColor: ["#FAD96C", "rgba(62, 154, 247, 1)", "rgba(99, 184, 160, 1)"],
        fill: true,
        borderRadius: 1,
      },
      title: "hgh bghghj ghkj",
      description: "hvjh bhkj ",
      customerId: "",
    }
  },
  methods:{
    goToEdit(){
      this.$router.push("/edit-campaign")
    },
    showText(){
      this.text = true;
      setTimeout(() => {
        this.text = false;
      },3000)
    },
    async uploadProfile(className, title, description, id, link, customerId) {
      this.customerId = customerId;
      this.shareLink = link;
      this.id = id;
      this.title = title;
      this.description = description;
      this.model.customerId = customerId;
      this.model.campaignId = id;
      await this.$store.dispatch("campaign/getShareLink", {
        shareCampaignId: this.campaign.campaign.campaignId,
        shareCustomerId: this.auth.userInfo.customerId,
      }).then()
      document.querySelector(className).style.display = "flex";
      setTimeout(()=>{
        document.querySelector(className).style.opacity = 1;
      }, 500)
    },
    close(className) {
      document.querySelector(className).style.opacity = 0;
      setTimeout(()=>{
        document.querySelector(className).style.display = "none";

      }, 500)
    },
    selectCampaign(_id) {
      localStorage.campaignId = _id;
      this.$router.push(`/campaign-details/${_id.hexEncode()}`);
    },
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    goBack(){
      this.$router.go(-1)
    },
    getMonday(d) {
      d = new Date(d);
      let day = d.getDay(),
        diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    formatDates(dateOne, dateTwo) {
      let formattedDates = "";
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat);
      }
      if (dateTwo) {
        //this.query(true);
        formattedDates += " - " + format(dateTwo, this.dateFormat);
      }
      return formattedDates;
    },
    getDuration(_startDate, _endDate) {
      let years =
        new Date(new Date(_endDate) - new Date(_startDate)).getFullYear() -
        1970;
      //console.log(years);
      let months = new Date(
        new Date(_endDate) - new Date(_startDate)
      ).getMonth();
      //console.log(months);
      let days =
        (new Date(_endDate).getTime() - new Date(_startDate).getTime()) /
        (1000 * 3600 * 24);
      //console.log(days);
      let hours =
        (new Date(_endDate).getTime() - new Date(_startDate).getTime()) /
        (1000 * 3600);
      //console.log(hours);
      let minutes =
        (new Date(_endDate).getTime() - new Date(_startDate).getTime()) /
        (1000 * 60);
      //console.log(minutes);
      let seconds =
        (new Date(_endDate).getTime() - new Date(_startDate).getTime()) / 1000;
      // //console.log(seconds);
      //return years;
      if (years > 0) return `${years} Years`;
      else if (months > 0) return `${months} Months`;
      else if (days > 0) return `${days} Days`;
      else if (hours > 0) return `${hours} Hours`;
      else if (minutes > 0) return `${minutes} Minutes`;
      else `${seconds} Seconds`;
    },
    getDaysArray(start, end) {
      let arr = [];
      let dt = new Date(start);
      for (; dt <= end; dt.setDate(dt.getDate() + 1)) {
        arr.push(format(new Date(dt), "YYYY-MM-DD"));
      }
      return arr;
    },
    fetchAnalytics(_startDate, _endDate, refresh = false) {
      let data = {
        responseCode: "",
        responseMessage: "",
        data: [],
      };
      if (refresh) {
        this.$store.commit("analytic/updateClicksPerCampaign", data);
        this.$store.commit("analytic/updateImpressionsPerCampaign", data);
        this.$store.commit("analytic/updateSharesPerCampaign", data);
      }
      let model = this.model;
      model.startDate = _startDate;
      model.endDate = _endDate;
      model.customerId = localStorage.customerId;
      model.campaignId = this.campaignId;
      this.$store.dispatch("analytic/updateClicksPerCampaign", this.model);
      this.$store.dispatch("analytic/updateImpressionsPerCampaign", this.model);
      this.$store.dispatch("analytic/updateSharesPerCampaign", this.model);
    },
    getStartEndDate() {
      let today = new Date();
      let model = {
        customerId: "",
        campaignId: "",
        startDate: "",
        endDate: "",
      };
      if (this.model.type === "today") {
        model.startDate = today.toJSON().slice(0, 10);
        model.endDate = new Date(new Date().setDate(today.getDate() + 1))
          .toJSON()
          .slice(0, 10);
        //console.log("today::", model.startDate, model.endDate);
      } else if (this.model.type === "thisweek") {
        let priorDate = this.getMonday(new Date());
        model.startDate = priorDate.toJSON().slice(0, 10);
        model.endDate = new Date(new Date().setDate(today.getDate() + 1))
          .toJSON()
          .slice(0, 10);
        //console.log("thisweek::", model.startDate, model.endDate);
      } else if (this.model.type === "thismonth") {
        let priorDate = new Date(today.getFullYear(), today.getMonth(), 2);
        model.startDate = priorDate.toJSON().slice(0, 10);
        model.endDate = new Date(new Date().setDate(today.getDate() + 1))
          .toJSON()
          .slice(0, 10);
      } else if (this.model.type === "last30") {
        let priorDate = new Date(new Date().setDate(today.getDate() - 30));
        model.startDate = priorDate; //.toJSON().slice(0,10);
        model.endDate = new Date(new Date().setDate(today.getDate() + 1))
          .toJSON()
          .slice(0, 10);
      } else if (this.model.type === "last90") {
        let priorDate = new Date(new Date().setDate(today.getDate() - 90));
        model.startDate = priorDate.toJSON().slice(0, 10);
        model.endDate = new Date(new Date().setDate(today.getDate() + 1))
          .toJSON()
          .slice(0, 10);
      } else if (this.model.type === "thisyear") {
        let priorDate = new Date(new Date().getFullYear(), 0, 1);
        model.startDate = priorDate.toJSON().slice(0, 10);
        model.endDate = new Date(new Date().setDate(today.getDate() + 1))
          .toJSON()
          .slice(0, 10);
      } else if (this.model.type === "alltime") {
        model.type = "alltime";
        model.startDate = null;
        model.endDate = null;
      }
      return model;
    },
    query(_ignore) {
      //console.log(this.model.type);
      //console.log(_ignore);
      if (this.model.type === "custom" && !_ignore) return;
      this.$store.commit("analytic/reset");
      let startEndDate = this.getStartEndDate();
      //console.log("startEndDate>>>", startEndDate);
      if (this.model.type !== "custom") {
        this.model.endDate = startEndDate.endDate;
        this.model.startDate = startEndDate.startDate;
      }
      this.$store.dispatch("analytic/updateClicksPerCampaign", this.model);
      this.$store.dispatch("analytic/updateImpressionsPerCampaign", this.model);
      this.$store.dispatch("analytic/updateSharesPerCampaign", this.model);
    },
    progressBar(){
      const meters = document.querySelectorAll('svg[data-value] .meter');

      meters.forEach((path) => {
        // Get the length of the path
        let length = path.getTotalLength();

        // console.log(length);

        // Just need to set this once manually on the .meter element and then can be commented out
        // path.style.strokeDashoffset = length;
        // path.style.strokeDasharray = length;

        // Get the value of the meter
        // let value = parseInt(path.parentNode.getAttribute('data-value'));
        // Calculate the percentage of the total length
        let to = length * ((parseFloat(this.campaign.campaign.campaignBudget) - parseFloat(this.campaign.campaign.campaignRunningBudget)));
        // Trigger Layout in Safari hack https://jakearchibald.com/2013/animated-line-drawing-svg/
        path.getBoundingClientRect();
        // Set the Offset
        path.style.strokeDashoffset = Math.max(0, to);
        // path.nextElementSibling.innerHTML = `${value}%`;
      });
    },

  },
  computed:{
    ...mapState(["analytic", "auth"]),
    ...mapState({
      authorizeState: (state) => state.socialMedia.authorizeState,
      challengeCode: (state) => state.socialMedia.challengeCode,
      socialMediaDetails: (state) => state.socialMedia.socialMediaDetails
    }),
    connectedTiktok(){
      let connect = 0;
      this.socialMediaDetails.forEach((social) => {
        if (social.socialMediaDetailsPlatform === "Tiktok"){
          connect = social.socialMediaDetailsFollowers;
        }
      })
      return connect
    },
    connectedTwitter(){
      let connect = 0;
      this.socialMediaDetails.forEach((social) => {
        if (social.socialMediaDetailsPlatform === "Twitter"){
          connect = social.socialMediaDetailsFollowers;
        }
      })
      return connect
    },
    size(){
        return !!(parseFloat(this.campaign.campaign.campaignBudget) < 10000);
    },
    doughnutData() {
      return {
        datasets: [
          {
            label: "Budget",
            weight: 0.1,
            data: [
              parseFloat(this.campaign.campaign.campaignBudget) - parseFloat(this.campaign.campaign.campaignRunningBudget),
              parseFloat(this.campaign.campaign.campaignRunningBudget),
            ],
            backgroundColor: ["#63b8a0", "#cae3dc"],
            hoverOffset: 4,
          },
        ],
      };
    },

    budget(){
      return this.campaign.campaign.campaignBudget ? this.campaign.campaign.campaignBudget : ""
    },
    consumption(){
      return this.campaign.campaign.campaignRunningBudget ? this.campaign.campaign.campaignRunningBudget : ""
    },
    goal(){
      return this.campaign.campaign.campaignType ? this.campaign.campaign.campaignType : ""
    },
    startDate(){
      return this.campaign.campaign.campaignStartDate ? this.campaign.campaign.campaignStartDate : ""
    },
    endDate(){
      return this.campaign.campaign.campaignEndDate ? this.campaign.campaign.campaignEndDate : ""
    },
    price(){
      return this.campaign.campaign.campaignPrice ? this.campaign.campaign.campaignPrice : ""
    },
    lineGraphData() {
      return  {
        labels: ['Clicks','Shares','Impression'],
          datasets: [{
          type: 'horizontalBar',
          label: 'Clicks',
          borderRadius: 1,
          backgroundColor: ["#FAD96C", "rgba(62, 154, 247, 1)", "rgb(255,87,87)"],
          hoverBackgroundColor: ["rgb(239,172,42)", "rgb(0,119,245)", "rgb(255,21,21)"],
          borderColor: "rgba(99, 184, 160, 1)",
          fillColor: ["#FAD96C", "rgba(62, 154, 247, 1)", "rgba(99, 184, 160, 1)"],
          borderWidth: 0.4,
          data: [this.analytic.clicksPerCampaign.data.length, this.analytic.sharesPerCampaign.data.length, this.analytic.impressionsPerCampaign.data.length]
        }
        ],
          backgroundColor: ["#FAD96C", "rgba(62, 154, 247, 1)", "rgba(99, 184, 160, 1)"],
          fill: true,
          borderRadius: 1,
      }
    },
    banners: function(){
      if (this.campaign.campaign.campaignBanners == null) return []
      return this.campaign.campaign.campaignBanners.split(",")
    },
    campaignId: function () {
      //this.$store.dispatch("campaign/updateCampaign", {campaignId: localStorage.campaignId})
      if (this.$route.params.campaignId)
        return this.$route.params.campaignId.hexDecode();
      else return localStorage.campaignId;
    },
    ...mapState(["campaign","auth","analytic"]),
    shareUrl: function () {
      return window.__env.api.shareUrl;
    },
    appUrl: function () {
      return window.__env.app.appUrl;
    },
    token: function () {
      return SecurityUtil.prototype.encrypt(JSON.stringify(this.model));
    },
    link: function () {
      if (this.auth.userInfo.customerId === this.customerId)
        return `${this.appUrl}/campaign-details/${this.campaignId.hexEncode()}`;
      else return `${this.appUrl}/campaign-details/${this.campaignId.hexEncode()}`;
    },
    countries() {
      return countriesJson;
    },
  },
  mounted() {
    this.$store.dispatch("socialMedia/readCustomer")
    this.progressBar();
    if (this.$route.params.campaignId != null) {
      this.$store.dispatch("campaign/updateCampaign", {campaignId: this.campaignId})
    }
    this.model.customerId = localStorage.customerId;
    this.model.campaignId = this.campaignId;
    let startEndDate = this.getStartEndDate();
    if (
      this.analytic.clicksPerCampaign.responseCode !== "00" ||
      this.analytic.impressionsPerCampaign.responseCode !== "00" ||
      this.analytic.sharesPerCampaign.responseCode !== "00"
    ){
      this.fetchAnalytics(startEndDate.startDate, startEndDate.endDate);
    }
  },
  watch: {
    customerId: function () {
      let startEndDate = this.getStartEndDate();
      this.fetchAnalytics(startEndDate.startDate, startEndDate.endDate);
    },
  },
};
</script>

<style scoped>
.section{
  height: 100%;
}
h1,h2,h3,h4,h5,h6,p,.section{
  margin: 0;
}

.image-67{
  margin-left: 10px;
}

.heading-38-copy-2:hover .div-block-269{
  opacity: 1 !important;
  display: flex !important;
}
.div-block-23,
.div-block-107{
  width: 100%;
}

.div-block-269 {
  position: absolute;
  left: 43%;
  top: 6%;
  display: none;
  width: 242px;
  padding: 12px;
  flex-direction: column;
  grid-row-gap: 10px;
  border-radius: 4px;
  background-color: #373d3f;
  color: #fff;
  z-index: 9999;
}

.graph2-2{
  width: 100%;
}

.svg-container{
//margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.svg-container svg {
  display: inline-flex;
  vertical-align: bottom;
  width: 300px;
  height: 300px;
  margin-bottom: 10px;
}

.svg-container circle {
  stroke-width: 8px;
  stroke: #F3F6F8;

  stroke-dasharray: 0;
  fill: none;
}

.svg text{
  margin-bottom: 4px;
  color: #00325c;
  font-size: 20px;
  line-height: 100%;
  font-weight: 600;
}
.svg-container .meter {
  stroke-width: 8px;

  stroke: #5DC9A0;
  fill: none;
  transition: stroke-dashoffset 1s cubic-bezier(0.43, 0.41, 0.22, 0.91);
  transform-origin: center center;
  transform: rotate(-90deg) scaleX(-1);
}

.svg-container .small-text{
  font-family: 'Space Grotesk', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 18px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Gray/500 */

  color: #667085;

}

.div-block-24{
  justify-content: space-between;
  width: 100%;
}

.w-button{
  color: #63b8a0 !important;
}

.doughnut .chartjs-render-monitor{
  width: 200px !important;
  height: 200px !important;
}


.pie-chart-total-2 .smaller{
  font-size: 26px;
}

.image-67:hover .div-block-269{
  display: flex !important;
}

.heading-38-copy-2:hover .div-block-269{
  display: flex !important;
}

.heading-38-copy-2:hover{
  color: #0a0a0a;
}

@media screen and (max-width: 991px){
  .impression-and-budget-2{
    flex-direction: column;
  }
  .budget-wrapper-2{
    width: 100%;
  }
  .impressions-2{
    width: 100%;
  }
}
@media screen and (min-width: 1280px) {
  .impressions-2 {
    position: relative;
  }
  .div-block-266 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    grid-column-gap: 10px;
  }
  .div-block-269 {
    position: absolute;
    left: 38%;
    top: 8%;
    right: auto;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
  //display: flex;
    width: 242px;
    padding: 12px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-row-gap: 10px;
    border-radius: 4px;
    background-color: #373d3f;
    color: #fff;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
  }
  .image-67:hover .div-block-269{
    display: flex !important;
  }

  .heading-38-copy-2:hover .div-block-269{
    display: flex !important;
  }
}

.back-btn {
  width: auto;
  height: 40px;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  background-color: #f2f4f7;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  text-decoration: none;
  display: flex;
}

.div-block-2 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  display: flex;
}

.tag-copy {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  background-color: rgba(72, 158, 133, .1);
  border-radius: 100px;
  align-items: center;
  padding: 2px 6px;
  display: flex;
}

.tag-copy.big {
  padding: 3px 8px;
}

.category {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  background-color: rgba(72, 158, 133, .1);
  border-radius: 100px;
  align-items: center;
  padding: 2px 6px;
  display: flex;
}

.category.big {
  padding: 3px 8px;
}

.div-block-2 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  display: flex;
  width: auto;
  height: auto;
}

.campaign-description-text {
  color: #475467;
  font-size: 16px;
}

.text-block-4 {
  color: #489e85;
  font-family: Figtree, sans-serif;
  font-size: .75rem;
  font-weight: 500;
}

.div-block-3 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: row;
  margin-top: 2rem;
  display: flex;
}

.button {
  width: auto;
  color: #fff !important;
  text-align: center;
  background-color: #489e85;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
  font-size: .875rem;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}

.button.is-secondary {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  color: #344054 !important;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #d0d5dd;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.mall-details-content {
  grid-column-gap: 56px;
  grid-row-gap: 56px;
  flex-direction: column;
  display: flex;
}

.w-tab-menu {
  position: relative;
}

.tabs-menu-2 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  display: flex;
  border: none;
}

.w-tab-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  padding: 9px 30px;
  text-align: left;
  cursor: pointer;
  color: #222222;
  background-color: #dddddd;
}

.tabs {
  color: #475467;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 16px;
  font-size: .875rem;
  font-weight: 500;
  display: inline;
}

.tabs.w--current {
  color: #387b68;
  background-color: #eaf5f2;
  border-radius: 8px;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.tabs-content-2 {
  margin-top: 32px;
  padding-left: 0;
  padding-right: 0;
}
.w-tab-content {
  position: relative;
  display: block;
  overflow: hidden;
}

.div-block-18 {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-direction: column;
  display: flex;
}

.campaign__info__block {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.campaign__info {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  display: flex;
}

.campaign-detail-heading {
  color: #1d2939;
  font-family: Faktum, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
}

.campaign_detail_card {
  border: 1px solid #eaecf0;
  border-radius: 8px;
  flex: 1;
  padding: 44px 24px;
  position: relative;
}

.campaign-details-list {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  display: flex;
}

.campaign_detail {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.campaign-stat-detail-info {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  display: flex;
}

.campaign-detail-title {
  color: #475467;
  font-family: Figtree, sans-serif;
  font-size: 15px;
  line-height: 1.42857;
}

.campaign-stat-progressbar._100 {
  width: 100%;
}
.campaign-stat-progressbar {
  width: 0;
  height: 10px;
  background-color: #489e85;
  border-radius: 24px;
}

.campaign-stat-main {
  color: #1d2939;
  font-family: Figtree, sans-serif;
  font-weight: 400;
  line-height: 1.42857;
}

.empty-campaign-overlay {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, .8);
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.campaign-stat-progressbar._70 {
  width: 70%;
}

.campaign-stat-progressbar._30 {
  width: 30%;
}

.empty-campaign-overlay-h {
  color: #101828;
  font-family: Figtree, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
}

.empty-campaign-overlay-p {
  color: #475467;
  font-family: Figtree, sans-serif;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 300;
}

.empty-campaign-overlay.inactive {
  display: none;
}

.campaign-social-title {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  align-items: center;
  display: flex;
}

.campaign-stat-progressbar._56 {
  width: 56%;
}

.view-campaign-btn {
  width: 80%;
  height: 40px;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  background-color: #f2f4f7;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.share-campaign-btn {
  width: 40px;
  height: 40px;
  background-color: #f2f4f7;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.campaign_logo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

share-campaign-modal {
  position: relative;
  display: block;
  overflow: hidden;
  width: 470px;
  height: auto;
  padding: 24px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(3, 28, 67, 0.1);
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 0 34px 0 rgb(3 28 67 / 13%);
}

.text-block-new{
  color: #475467;
  font-size: 16px;
}

.text-block-new-2{
  color: #344054;
  font-weight: 500;
}

.share-campaign-popup{
  opacity: 0;
}

</style>
