<template>
  <home-layout style="background: #fff; font-family: Figtree, sans-serif !important;">
    <dashboard-new/>
    <section id="IntroMessageModal" data-w-id="0681c445-f08c-7f49-b416-9cf21e083d08" class="intro-message-modal" style="display: flex;">
      <div class="welcome-modal">
        <div class="modal-image-container"></div>
        <div class="intro-slider">
          <div data-delay="4000" data-animation="outin" class="slider w-slider" data-autoplay="true" data-easing="ease" data-hide-arrows="false" data-disable-swipe="false" data-autoplay-limit="0" data-nav-spacing="4" data-duration="500" data-infinite="true">
            <div class="w-slider-mask">
              <div class="intro-slider-image w-slide" v-if="stage === 1"><img src="../../../public/images/welcome-to-apvertise.png" loading="lazy" sizes="100vw" srcset="../../../public/images/welcome-to-apvertise-p-500.png 500w, ../../../public/images/welcome-to-apvertise-p-800.png 800w, ../../../public/images/welcome-to-apvertise-p-1080.png 1080w, ../../../public/images/welcome-to-apvertise.png 1408w" alt="" class="image-4">
                <div class="margin-top margin-small">
                  <div class="text-size-medium text-weight-medium">Welcome to Apvertise 🥳</div>
                  <div class="margin-top margin-xsmall">
                    <div class="text-size-small text-color-grey text-align-center">We’re glad to have you onboard. Here are some quick tips to get you up and running.</div>
                  </div>
                </div>
              </div>
              <div class="intro-slider-image w-slide" v-if="stage === 2"><img src="../../../public/images/brands.png" loading="lazy" sizes="100vw" srcset="../../../public/images/brands-p-500.png 500w, ../../../public/images/brands-p-800.png 800w, ../../../public/images/brands-p-1080.png 1080w, ../../../public/images/brands.png 1408w" alt="" class="image-4">
                <div class="margin-top margin-small">
                  <div class="text-size-medium text-weight-medium">Meet the best brands 🥳</div>
                  <div class="margin-top margin-xsmall">
                    <div class="text-size-small text-color-grey text-align-center">Apvertise affords you the opportunity to meet and collaborate with goal-driven and verified brands.</div>
                  </div>
                </div>
              </div>
              <div class="intro-slider-image w-slide" v-if="stage === 3"><img src="../../../public/images/best-pricing.png" loading="lazy" sizes="100vw" srcset="../../../public/images/best-pricing-p-500.png 500w, ../../../public/images/best-pricing-p-800.png 800w, ../../../public/images/best-pricing-p-1080.png 1080w, ../../../public/images/best-pricing.png 1408w" alt="" class="image-4">
                <div class="margin-top margin-small">
                  <div class="text-size-medium text-weight-medium">Add money to your account</div>
                  <div class="margin-top margin-xsmall">
                    <div class="text-size-small text-color-grey text-align-center">Apvertise saves you stress of price haggling with brands through transparent pricing</div>
                  </div>
                </div>
              </div>
              <div class="intro-slider-image w-slide" v-if="stage === 4"><img src="../../../public/images/growth.png" loading="lazy" sizes="100vw" srcset="../../../public/images/growth-p-500.png 500w, ../../../public/images/growth-p-800.png 800w, ../../../public/images/growth-p-1080.png 1080w, ../../../public/images/growth.png 1408w" alt="" class="image-4">
                <div class="margin-top margin-small">
                  <div class="text-size-medium text-weight-medium">Your growth is our priority 🥳</div>
                  <div class="margin-top margin-xsmall">
                    <div class="text-size-small text-color-grey text-align-center">We care about your growth as an influencer so we provide tips to improve on your self-branding.</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="left-arrow w-slider-arrow-left">
              <div class="w-icon-slider-left"></div>
            </div>
            <div class="right-arrow w-slider-arrow-right">
              <div class="w-icon-slider-right"></div>
            </div>
            <div class="slide-nav w-slider-nav w-slider-nav-invert w-round" style="position: relative; margin-top: 10px">
              <div class="w-slider-dot" :class="{'w-active' : stage === 1}" data-wf-ignore="" aria-label="Show slide 1 of 4" aria-pressed="true" role="button" tabindex="0" style="margin-left: 4px; margin-right: 4px;" @click="stage = 1"></div>
              <div class="w-slider-dot" :class="{'w-active' : stage === 2}" data-wf-ignore="" aria-label="Show slide 2 of 4" aria-pressed="false" role="button" tabindex="-1" style="margin-left: 4px; margin-right: 4px;" @click="stage = 2"></div>
              <div class="w-slider-dot" :class="{'w-active' : stage === 3}" data-wf-ignore="" aria-label="Show slide 3 of 4" aria-pressed="false" role="button" tabindex="-1" style="margin-left: 4px; margin-right: 4px;" @click="stage = 3"></div>
              <div class="w-slider-dot" :class="{'w-active' : stage === 4}" data-wf-ignore="" aria-label="Show slide 4 of 4" aria-pressed="false" role="button" tabindex="-1" style="margin-left: 4px; margin-right: 4px;" @click="stage = 4"></div>
            </div>
          </div>
        </div>
        <div class="button-wrapper">
          <div class="button-horizontal" style="justify-content: center">
            <a data-w-id="0681c445-f08c-7f49-b416-9cf21e083d35" href="#" class="button max-width-full w-button" @click="close('.intro-message-modal')">Continue to dashboard</a>
          </div>
        </div>
      </div>
    </section>
    <div class="section" style="margin-top: 73px; height: 100vh">
      <div class="main"><img src="../../../public/images/userImage.png" loading="lazy" width="479" sizes="(max-width: 479px) 93vw, 479px" srcset="../../../public/images/userImage-p-500.png 500w, ../../../public/images/userImage-p-800.png 800w, ../../../public/images/userImage.png 958w" alt="">
        <div class="db__onboarding-content">
          <div class="text__content">
            <h1 class="dashboard_home-h">Verify your social media presence</h1>
            <p class="dashboard_onboarding-p">We’re so glad you’re ready to join Apvertise. Let’s start by creating your account.</p>
          </div>
          <a href="#" class="btn" @click="goToProfile">
            <div>Get started</div><img src="../../../public/images/chevron-right.svg" loading="lazy" alt="">
          </a>
        </div>
      </div>
    </div>
  </home-layout>
</template>

<script>
import HomeLayout from "@/views/layout/HomeLayout.vue";
import DashboardNew from "@/components/menu/DashboardNew.vue";

export default {
  name: "DashboardNewUser",
  data(){
    return{
      stage: 1
    }
  },
  components: { HomeLayout, DashboardNew },
  methods:{
    goToProfile(){
      this.$router.push("/onboarding/profiling")
    },
    close(className) {
      document.querySelector(className).style.opacity = 0;
      setTimeout(()=>{
        document.querySelector(className).style.display = "none";

      }, 500)
    },
  },
  mounted(){
    setInterval(() => {
      this.stage++;
    }, 7000)
  },
  watch:{
    stage(){
      if (this.stage === 5){
        this.stage = 1
      }
    }
  }
};
</script>

<style scoped>


h1,h2,h3,h4,h5,h6,p{
  margin: 0;
}

h1{
  font-size: 20px;
}

p{
  color: #475467;
  line-height: 1.375;
  font-size: 16px;
}

.btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 18px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border-radius: 8px;
  background-color: #489e85;
  color: #fff;
  font-weight: 600;
  text-decoration: none;
  background-image: none !important;
}

.intro-message-modal {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 999999;
  display: none;
  width: 100vw;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(16, 24, 40, 0.6);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.welcome-modal {
  position: relative;
  display: block;
  overflow: hidden;
  width: 400px;
  height: 460.796875px;
  padding: 24px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(3, 28, 67, 0.1);
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 0 34px 0 rgb(3 28 67 / 13%);
}

.modal-image-container {
  display: none;
  height: 200px;
  background-position: 50% 50%;
  background-size: 349px;
  background-repeat: no-repeat;
}

.w-slider {
  position: relative;
  height: 300px !important;
  text-align: center;
  background: #fff;
  clear: both;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

.slide-nav {
  position: static;
  top: 0px;
  color: transparent;
  font-size: 0.6rem;
  line-height: 0.7rem;
  margin-top: -85px;

  display: block;
}

.button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.75rem 1.5rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #489e85;
  color: #fff !important;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}

.w-slider-nav-invert>div.w-active {
  background-color: #222;
}
.w-slider-nav.w-round>div {
  border-radius: 100%;
}
.w-slider-dot.w-active {
  background-color: #fff;
}
.w-slider-nav-invert>div {
  background-color: rgba(34,34,34,0.4);
}
.w-slider-dot {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: rgba(255,255,255,0.4);
  cursor: pointer;
  margin: 0 3px 0.5em;
  transition: background-color 100ms,color 100ms;
}

.main{
  padding: 0;
  margin-top: -10px;
}

</style>