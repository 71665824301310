import client from "./BaseService";

export default {
    callReadInfluencerApi(payload) {
        return client.apiClient.post("read-influencer", payload);
    },
    callCreatePackageApi(payload) {
        return client.apiClient.post("customer/create-package", payload);
    }
}
