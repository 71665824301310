<template>
  <div>
    <dashboard-new />
    <div class="section" style="display: block; font-family: Figtree, sans-serif; background: #fff">
      <div class="dashboard-wrapper">
        <div class="breadcrumb-wrapper">
          <a href="#" class="back-btn" @click="goBack"><img src="../../../public/images/chevron-right_1.svg" loading="lazy" alt="" class="image-5">
            <div class="text-block-6">Back to Store</div>
          </a>
        </div>
        <div class="mall-details-content">
          <div class="mall-details-header">
            <b-carousel
              id="carousel-1"
              v-model="slide"
              :interval="4000"
              controls
              indicators
              label-next=""
              label-prev=""
              background="#ffffff"
              style="text-shadow: 1px 1px 2px #333; height: 400px; "
              @sliding-start="onSlideStart"
              @sliding-end="onSlideEnd"
            >
              <b-carousel-slide
                style="border-radius: 3px 3px 3px 3px "
                v-for="image in product.productImage.split(',')"
                v-bind:key="image"
              >
                <template #img>
                  <img
                    style=" border-radius: 5px 5px 5px 5px; width: 100%;"
                    class="d-block img-fluid"
                    width="606"
                    height="387"
                    :src="image"
                    alt="image slot"
                  />
                </template>
              </b-carousel-slide>
            </b-carousel>
            <div class="mall__details"><img :src="organisation.organisationLogo" loading="lazy" alt="" class="mall__company">
              <h4 class="mall__name">{{ product.productName }}</h4>
              <div class="mall__description">{{product.productDescription}}</div>
              <div class="mall-product__price"><span class="naira__price">₦ {{product.productLocalCurrencyPrice | formatAmount}}  </span>/<span class="dollar__price">${{ product.productUsdPrice }}</span></div>
              <a :href="buy.redirectURL" class="button small">
                <div>Buy Product</div>
                <div class="w-embed"><svg width="18" height="20" viewbox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.15833 11.2587L11.85 14.5753M11.8417 5.42533L6.15833 8.74199M16.5 4.16699C16.5 5.5477 15.3807 6.66699 14 6.66699C12.6193 6.66699 11.5 5.5477 11.5 4.16699C11.5 2.78628 12.6193 1.66699 14 1.66699C15.3807 1.66699 16.5 2.78628 16.5 4.16699ZM6.5 10.0003C6.5 11.381 5.38071 12.5003 4 12.5003C2.61929 12.5003 1.5 11.381 1.5 10.0003C1.5 8.61961 2.61929 7.50033 4 7.50033C5.38071 7.50033 6.5 8.61961 6.5 10.0003ZM16.5 15.8337C16.5 17.2144 15.3807 18.3337 14 18.3337C12.6193 18.3337 11.5 17.2144 11.5 15.8337C11.5 14.4529 12.6193 13.3337 14 13.3337C15.3807 13.3337 16.5 14.4529 16.5 15.8337Z" stroke="currentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg></div>
              </a>
            </div>
          </div>
          <div class="related__products">
            <div class="related-campaign-header">
              <h5>More Items from this Merchant</h5>
            </div>
            <div class="related-malls-grid" v-if="products.length > 0">
              <div class="mall-card" v-for="productObj in products" :key="productObj.productId">
                <a id="w-node-cf26d2fd-1317-f2bb-9957-49c8016092ec-70d5e2e7" href="#" class="mall__image-card grid" @click="viewProduct(productObj.productId)">
                  <div class="mall-image-wrapper" style="height: 254px">
                    <img src="../../../public/images/view-product.png" class="opacity"/><img :src="productObj.productImage.split(',')[0]" loading="lazy"  alt="" class="mall-img" style="height: 100%;"></div>
                </a>
                <div class="mall-card-text">
                  <div class="card-product-name">{{ productObj.productName }}</div>
                  <div class="card-product-price">₦ {{productObj.productLocalCurrencyPrice | formatAmount}} </div>
                </div>
              </div>
            </div>
          </div>
<!--          <div class="related__products">-->
<!--            <div class="related-campaign-header">-->
<!--              <h5>Related products</h5>-->
<!--            </div>-->
<!--            <div class="related-malls-grid">-->
<!--              <div class="mall-card">-->
<!--                <a id="w-node-_06cf4f49-ee04-e7be-2aca-9f2c8c03b7e1-f0f853b7" href="#" class="mall__image-card grid">-->
<!--                  <div class="mall-image-wrapper"><img src="../../../public/images/mall__img.png" loading="lazy" sizes="(max-width: 479px) 93vw, (max-width: 767px) 91vw, (max-width: 991px) 46vw, 263px" srcset="../../../public/images/mall__img-p-500.png 500w, ../../../public/images/mall__img.png 662w" alt="" class="mall-img"></div>-->
<!--                  <div class="mall-overlay"></div>-->
<!--                </a>-->
<!--                <div class="mall-card-text">-->
<!--                  <div class="card-product-name">iPhone 13 Pro</div>-->
<!--                  <div class="card-product-price">$700</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="mall-card">-->
<!--                <a id="w-node-_06cf4f49-ee04-e7be-2aca-9f2c8c03b7eb-f0f853b7" href="#" class="mall__image-card grid">-->
<!--                  <div class="mall-image-wrapper"><img src="../../../public/images/mall__img.png" loading="lazy" sizes="(max-width: 479px) 93vw, (max-width: 767px) 91vw, (max-width: 991px) 46vw, 263px" srcset="../../../public/images/mall__img-p-500.png 500w, ../../../public/images/mall__img.png 662w" alt="" class="mall-img"></div>-->
<!--                  <div class="mall-overlay"></div>-->
<!--                </a>-->
<!--                <div class="mall-card-text">-->
<!--                  <div class="card-product-name">iPhone 13 Pro</div>-->
<!--                  <div class="card-product-price">$700</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="mall-card">-->
<!--                <a id="w-node-_06cf4f49-ee04-e7be-2aca-9f2c8c03b7f5-f0f853b7" href="#" class="mall__image-card grid">-->
<!--                  <div class="mall-image-wrapper"><img src="../../../public/images/mall__img.png" loading="lazy" sizes="(max-width: 479px) 93vw, (max-width: 767px) 91vw, (max-width: 991px) 46vw, 263px" srcset="../../../public/images/mall__img-p-500.png 500w, ../../../public/images/mall__img.png 662w" alt="" class="mall-img"></div>-->
<!--                  <div class="mall-overlay"></div>-->
<!--                </a>-->
<!--                <div class="mall-card-text">-->
<!--                  <div class="card-product-name">iPhone 13 Pro</div>-->
<!--                  <div class="card-product-price">$700</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="mall-card">-->
<!--                <a id="w-node-_06cf4f49-ee04-e7be-2aca-9f2c8c03b7ff-f0f853b7" href="#" class="mall__image-card grid">-->
<!--                  <div class="mall-image-wrapper"><img src="../../../public/images/mall__img.png" loading="lazy" sizes="(max-width: 479px) 93vw, (max-width: 767px) 91vw, (max-width: 991px) 46vw, 263px" srcset="../../../public/images/mall__img-p-500.png 500w, ../../../public/images/mall__img.png 662w" alt="" class="mall-img"></div>-->
<!--                  <div class="mall-overlay"></div>-->
<!--                </a>-->
<!--                <div class="mall-card-text">-->
<!--                  <div class="card-product-name">iPhone 13 Pro</div>-->
<!--                  <div class="card-product-price">$700</div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import StarRating from "vue-star-rating";
import OrganisationRequest from "@/model/request/OrganisationRequest";
import { mapState } from "vuex";
import ProductRequest from "@/model/request/ProductRequest";
import OrderRequest from "@/model/request/OrderRequest";
import DashboardNew from "@/components/menu/DashboardNew.vue";
// import CustomerDetailsModal from "@/components/modal/CustomerDetailsModal";
export default {
  name: "ProductCustomerView",
  data() {
    return {
      model: OrganisationRequest.prototype.organisationReadSingleRequest(),
      allProducts: ProductRequest.prototype.productReadByOrgRequest(),
      currentProduct: ProductRequest.productReadByProductIdRequest,
      obtainProduct: ProductRequest.buyProductRequest,
      showModal: true,
      orderModel: OrderRequest.prototype.orderCreateRequest(),
      slide: 0,
      count: 0,
      sliding: null,
      image:
        "https://i.insider.com/61fc2855b0ec040018d0baa4?width=1136&format=jpeg",
    };
  },
  computed: {
    ...mapState(["product", "organisation", "auth"]),
    ...mapState({
      organisation: (state) => state.organisation.organisation,
      organisations: (state) =>
        state.organisation.organisations[0].organisationId,
      products: (state) => state.product.product.data,
      productObj: (state) => state.product.productDetails,
      product: (state) => state.product.singleProduct,
      buy: (state) => state.product.purchaseProduct,
      // productImages: function(){
      //   if (this.product.productDetails.productImage == null) return []
      //   return this.product.productDetails.productImage.split(",")
      // },
    }),
    checkQty() {
      return this.count === 0;
    },
  },
  components: {
    DashboardNew
    // StarRating
  },
  methods: {
    goBack(){
      this.$router.go(-1)
    },
    addQty() {
      this.count++;
    },
    reduceQty() {
      this.count--;
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    viewProduct(i) {
      localStorage.productId = i;
      location.reload();
      this.currentProduct.productId = localStorage.productId;
      this.$store.dispatch("updateSingleProduct", this.singleProduct);
      this.$router.push("/product-merchant-view");
      // this.$store.commit("product/updateProductDetails", product);
      // // this.$router.push("/product-merchant-view")
    },

    // custOrMerch(){
    //
    //   localStorage.productID = prop
    //   this.$store.dispatch("property/updateProduct", prop)
    //   this.proId = localStorage.propertyId
    //   console.log(this.proId)
    //   if(this.auth.customerId = organisation.customerOrganisationCustomerId ){
    //     this.$router.push("/product-merchantView")
    //
    //   } else{
    //     this.$router.push("/product-customer-view")
    //
    //   }
    //
    // z
  },
  mounted() {
    this.currentProduct.productId = localStorage.productId;
    this.$store.dispatch("product/updateSingleProduct", this.currentProduct);
    // this.allProducts.productOrganisationId = localStorage.organisationId;
    (this.allProducts.productOrganisationId = localStorage.organisationId),
      this.$store.dispatch("product/updateProduct", this.allProducts);
    this.obtainProduct.productId = localStorage.productId;
    this.$store.dispatch("product/updatePurchaseProduct", this.obtainProduct);
    console.log(localStorage.productId);
    this.model.organisationId = localStorage.getItem('organisationId');
    this.$store.dispatch("organisation/updateOrganisation", this.model);
  },

  created() {
    console.log("use");
    const script = document.createElement("script");
    script.src = "https://play-apvertise.com/js/webflow.js";
  },
};
</script>

<style scoped>
.section{
  height: 100%;
}
h1,h2,h3,h4,h5,h6,p{
  margin: 0;
}

.dashboard-wrapper{
  height: 100%;
}

.mall-details-header {
  display: -ms-grid;
  display: grid;
  margin-top: 2rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 53px;
  grid-row-gap: 25px;
  -ms-grid-columns: 55% 40%;
  grid-template-columns: 55% 40%;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.mall__details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.mall__company {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 43px;
  height: 43px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border-radius: 50rem;
  -o-object-fit: cover;
  object-fit: cover;
}

.mall__name {
  color: #1d2939;
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 600;
}

.mall__description {
  color: #667085;
  line-height: 1.75;
}

.mall-product__price {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  font-family: Faktum, sans-serif;
  color: #1d2939;
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 600;
}

.button.small {
  padding: 0.625rem 1.125rem;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}
.button:hover {
  background-color: #387b68;
}
a:active, a:hover {
  outline: 0;
}
.button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.75rem 1.5rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #489e85;
  color: #fff !important;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}


.related__products {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.related-campaign-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

h5 {
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-family: Faktum, sans-serif;
  color: #101828;
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 700;
}

.related-malls-grid {
  display: -ms-grid;
  display: grid;
  margin-bottom: 0rem;
  padding-bottom: 2rem;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.mall-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.mall__image-card.grid {
  height: 254px;
}
.mall__image-card {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 349px;
  border-radius: 16px;
  margin: 0;
}

.mall-card-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.card-product-name {
  font-family: Faktum, sans-serif;
  color: #1d2939;
  line-height: 1.09375;
  font-weight: 600;
}

.card-product-price {
  color: #1d2939;
  line-height: 1.09375;
  font-weight: 600;
}

.mall-image-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #101828;
  -o-object-fit: fill;
  object-fit: fill;
}

.opacity{
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in;
  position: absolute;
  width: 100%;
}

.mall__image-card:hover .opacity {
  opacity: 1;
  visibility: visible;
}


</style>
