<script>
import DashboardNew from "@/components/menu/DashboardNew.vue";
import { mapState } from "vuex";

export default {
  name: "InfluencePage",
  components: { DashboardNew },
  data() {
    return {
      loading: false,
      count: 6,
      show: 0,
      empty: false,
      filter: false,
      merchant: "",
      search: "",
      selectedArray: [],
      niche: [
        "Agriculture",
        "Art & Design",
        "Education",
        "Entertainment",
        "Fashion & Lifestyle",
        "Finance",
        "Fitness",
        "Gadgets",
        "Health",
        "Investment",
        "SME",
        "Technology",
        "Telecommunication",
        "Travel & Tourism",
        "Others",
      ],
      selectedNiche: [],
      addNiche: "",
      channel: ["Instagram", "Twitter", "Facebook", "Tiktok"],
      addSocial: "",
      selectedSocial: [],
      followers: ["500 and 1k", "1k and 5k", "5k and 10k", "10k and above"],
      addFollowers: "",
      selectedFollowers: []
    };
  },
  methods: {
    resetForm(){
      this.selectedNiche = [];
      this.selectedSocial = [];
      this.selectedFollowers = [];
      this.$store.dispatch("influencer/readInfluencer", {})
    },
    removeSelected(selected){
      if (this.selectedNiche.includes(selected)){
        const index =this.selectedNiche.indexOf(selected);
        this.selectedNiche.splice(index, 1)
      }
    },
    async filterInfluence(){
      this.loading = true
      await this.$store.dispatch("influencer/readInfluencer", {
        niche: this.selectedNiche.join(),
        followers: this.selectedFollowers.join(),
        channel: this.selectedSocial.join()
      })
      this.loading = false;
    },
    getNiche(event){
      console.log(event)
      if (this.selectedNiche.includes(event.target.value.toLowerCase())){
        const index =this.selectedNiche.indexOf(event.target.value.toLowerCase());
        this.selectedNiche.splice(index, 1)
      }
      else {
        this.selectedNiche.push(event.target.value.toLowerCase())
      }
      console.log(this.selectedNiche)
    },
    getChannel(event){
      console.log(event)
      if (this.selectedSocial.includes(event.target.value.toLowerCase())){
        const index =this.selectedSocial.indexOf(event.target.value.toLowerCase());
        this.selectedNiche.splice(index, 1)
      }
      else {
        this.selectedSocial.push(event.target.value.toLowerCase())
      }
      console.log(this.selectedNiche)
    },
    getFollowers(event){
      console.log(event)
      if (this.selectedFollowers.includes(event.target.value.toLowerCase())){
        const index =this.selectedFollowers.indexOf(event.target.value.toLowerCase());
        this.selectedFollowers.splice(index, 1)
      }
      else {
        this.selectedFollowers.push(event.target.value.toLowerCase())
      }
      console.log(this.selectedNiche)
    },
    viewInfluencer(id) {
      this.$router.push(`/influencer/view/${id.hexEncode()}`);
    },
    toggleFilter() {
      this.filter = !this.filter;
    },
    changeMerchantToNiche() {
      if (this.merchant === "niche") {
        this.merchant = "";
      } else {
        this.merchant = "niche";
      }
    },
    changeMerchantToChannel() {
      if (this.merchant === "channel") {
        this.merchant = "";
      } else {
        this.merchant = "channel";
      }
    },
    changeMerchantToFollowers() {
      if (this.merchant === "followers") {
        this.merchant = "";
      } else {
        this.merchant = "followers";
      }
    },
    increaseCount() {
      this.count = this.count + 6;
    },
  },
  computed: {
    ...mapState({
      influencers: (state) => state.influencer.influencers,
      influencerLoading: (state) => state.influencer.loading,
    }),
    filteredList() {
      let list = [];
      if (this.search !== "") {
        this.influencers.data.forEach((influencer) => {
          let name =
              influencer.customerFirstName.toLowerCase() +
              " " +
              influencer.customerLastName.toLowerCase();
          if (name.includes(this.search.toLowerCase())) {
            list.push(influencer);
          }
        });
      }
      return list;
    },
  },
};
</script>

<template>
  <div>
    <dashboard-new />
    <div class="section">
      <div class="dashboard-wrapper">
        <div class="module-title-wrapper">
          <div class="module-title">
            <img
                src="../../assets/images/campaign-image.svg"
                loading="lazy"
                alt=""
            />
            <h5 class="heading">Influencers</h5>
          </div>
        </div>
        <div>
          <div class="search-filter-wrapper">
            <div class="left-block">
              <input
                  type="text"
                  v-model="search"
                  onChange=""
                  class="form__input search w-input"
                  maxlength="256"
                  name="field"
                  data-name="Field"
                  placeholder="Search influencers, countries, number of followers"
                  id="field"
                  required=""
              />
            </div>
            <div
                data-hover="false"
                data-delay="200"
                data-w-id="9282d2d1-7072-0ffe-3f80-03bd0fe7473d"
                class="dropdown2_component w-dropdown"
            >
              <div
                  @click="toggleFilter"
                  class="dropdown2_toggle w-dropdown-toggle"
              >
                <img
                    src="../../assets/images/Filters-lines.svg"
                    loading="lazy"
                    alt=""
                />
                <div class="text-block-45">Filters</div>
              </div>
              <nav
                  style="
                  -webkit-transform: translate3d(0, 3rem, 0) scale3d(1, 1, 1)
                    rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                  -moz-transform: translate3d(0, 3rem, 0) scale3d(1, 1, 1)
                    rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                  -ms-transform: translate3d(0, 3rem, 0) scale3d(1, 1, 1)
                    rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                  transform: translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0)
                    rotateY(0) rotateZ(0) skew(0, 0);
                  opacity: 0;
                "
                  class="dropdown2_dropdown-list w-dropdown-list"
                  :class="{ 'w--open drop': filter === true }"
              >
                <div class="filter-form-block w-form">
                  <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      data-wf-page-id="64c1fb0872c965807a53ad7e"
                      data-wf-element-id="365a8250-e344-b837-dc47-489d5b8b8428"
                      @submit.prevent="filterInfluence"
                  >
                    <div class="filter-dropdown-form-item">
                      <div class="filter-title">
                        <h6 class="heading-30">Filters</h6>
                        <p class="paragraph-4">
                          Filters will be added to the table
                        </p>
                      </div>
                      <div class="filter-tag-div">
                        <div
                            class="filter-tag"
                            v-for="(selected, index) in selectedNiche"
                            :key="index"
                        >
                          <div class="filter-dot"></div>
                          <div>{{ selected }}</div>
                          <a @click="removeSelected(selected)" class="delete-filter w-inline-block"
                          ><img
                              src="../../assets/images/x_2.svg"
                              loading="lazy"
                              alt=""
                          /></a>
                        </div>
                      </div>
                      <div class="filter-tag-div">
                        <div
                            class="filter-tag"
                            v-for="(selected, index) in selectedSocial"
                            :key="index"
                        >
                          <div class="filter-dot"></div>
                          <div>{{ selected }}</div>
                          <a @click="removeSelected(selected)" class="delete-filter w-inline-block"
                          ><img
                              src="../../assets/images/x_2.svg"
                              loading="lazy"
                              alt=""
                          /></a>
                        </div>
                      </div>
                      <div class="filter-tag-div">
                        <div
                            class="filter-tag"
                            v-for="(selected, index) in selectedFollowers"
                            :key="index"
                        >
                          <div class="filter-dot"></div>
                          <div>{{ selected }}</div>
                          <a @click="removeSelected(selected)" class="delete-filter w-inline-block"
                          ><img
                              src="../../assets/images/x_2.svg"
                              loading="lazy"
                              alt=""
                          /></a>
                        </div>
                      </div>
                    </div>
                    <div class="filter-dropdown-form-item" style="z-index: 99999">
                      <label class="filter-form-label">Niche</label>
                      <div style="z-index: 999999999999"
                           data-hover="false"
                           data-delay="200"
                           data-w-id="b40bb2eb-12ba-c5f4-25b7-5152783a0105"
                           class="dropdown2_component w-dropdown"
                           :class="{ 'new-filter': merchant === 'niche' }"
                      >
                        <a @click="changeMerchantToNiche"
                           class="dropdown2_toggle-2 w-dropdown-toggle"
                           :class="{ 'w--open': merchant === 'niche' }"
                        >
                          <div class="dropdown2_dropdown-icon w-embed">
                            <svg
                                width=" 100%"
                                height=" 100%"
                                viewbox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.55806 6.29544C2.46043 6.19781 2.46043 6.03952 2.55806 5.94189L3.44195 5.058C3.53958 4.96037 3.69787 4.96037 3.7955 5.058L8.00001 9.26251L12.2045 5.058C12.3021 4.96037 12.4604 4.96037 12.5581 5.058L13.4419 5.94189C13.5396 6.03952 13.5396 6.19781 13.4419 6.29544L8.17678 11.5606C8.07915 11.6582 7.92086 11.6582 7.82323 11.5606L2.55806 6.29544Z"
                                  fill="currentColor"
                              ></path>
                            </svg>
                          </div>
                          <div>Select Niche</div>
                        </a>
                        <nav
                            style="
                            -webkit-transform: translate3d(0, 3rem, 0)
                              scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                              skew(0, 0);
                            -moz-transform: translate3d(0, 3rem, 0)
                              scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                              skew(0, 0);
                            -ms-transform: translate3d(0, 3rem, 0)
                              scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                              skew(0, 0);
                            transform: translate3d(0, 3rem, 0) scale3d(1, 1, 1)
                              rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                            opacity: 0;
                          "
                            class="dropdown2_dropdown-list-2 w-dropdown-list"
                            :class="{ 'w--open drop': merchant === 'niche' }"
                        >
                          <div class="filter-checkbox-field">
                            <input
                                type="text"
                                class="form__input search w-input"
                                maxlength="256"
                                name="field-2"
                                data-name="Field 2"
                                placeholder="Search Channel.."
                                id="field-22"
                                required=""
                            />
                          </div>
                          <label
                              class="w-checkbox filter-checkbox-field"
                              v-for="(niches, index) in niche"
                              :key="index"
                          ><input
                              type="checkbox"
                              id="checkbox"
                              name="Niches"
                              data-name="Niches"
                              :value="niches"
                              class="w-checkbox-input filter-checkbox"
                              @change="getNiche"
                          />
                            <span class="w-form-label">{{ niches }}</span>
                          </label>
                        </nav>
                      </div>
                    </div>
                    <div class="filter-dropdown-form-item" style="z-index: 99999">
                      <label class="filter-form-label">Channel</label>
                      <a
                          data-hover="false"
                          data-delay="200"
                          data-w-id="ecc56bd7-8390-c0f6-8552-4722bff80907"
                          class="dropdown2_component w-dropdown"
                          @click="changeMerchantToChannel"
                          style="z-index: 99999"
                      >
                        <div class="dropdown2_toggle-2 w-dropdown-toggle" :class="{ 'w--open': merchant === 'channel' }">
                          <div class="dropdown2_dropdown-icon w-embed">
                            <svg
                                width=" 100%"
                                height=" 100%"
                                viewbox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.55806 6.29544C2.46043 6.19781 2.46043 6.03952 2.55806 5.94189L3.44195 5.058C3.53958 4.96037 3.69787 4.96037 3.7955 5.058L8.00001 9.26251L12.2045 5.058C12.3021 4.96037 12.4604 4.96037 12.5581 5.058L13.4419 5.94189C13.5396 6.03952 13.5396 6.19781 13.4419 6.29544L8.17678 11.5606C8.07915 11.6582 7.92086 11.6582 7.82323 11.5606L2.55806 6.29544Z"
                                  fill="currentColor"
                              ></path>
                            </svg>
                          </div>
                          <div>Select category</div>
                        </div>
                        <nav
                            style="
                            -webkit-transform: translate3d(0, 3rem, 0)
                              scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                              skew(0, 0);
                            -moz-transform: translate3d(0, 3rem, 0)
                              scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                              skew(0, 0);
                            -ms-transform: translate3d(0, 3rem, 0)
                              scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                              skew(0, 0);
                            transform: translate3d(0, 3rem, 0) scale3d(1, 1, 1)
                              rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                            opacity: 0;
                          "
                            class="dropdown2_dropdown-list-2 w-dropdown-list"
                            :class="{ 'w--open drop': merchant === 'channel' }"
                        >
                          <div class="filter-checkbox-field">
                            <input
                                type="text"
                                class="form__input search w-input"
                                maxlength="256"
                                name="field-2"
                                data-name="Field 2"
                                placeholder="Search Channel.."
                                id="field-2"
                                required=""
                            />
                          </div>
                          <label class="w-checkbox filter-checkbox-field" v-for="(social, index) in channel" :key="index"
                          ><input
                              type="checkbox"
                              id="checkbox-6"
                              name="Socials"
                              data-name="Socials"
                              class="w-checkbox-input filter-checkbox"
                              :value="social"
                              @change="getChannel"
                          /><span class="w-form-label" for="checkbox-6"
                          >{{social}}</span
                          ></label
                          >
                        </nav>
                      </a>
                    </div>
                    <div class="filter-dropdown-form-item">
                      <label class="filter-form-label">Followers</label>
                      <a
                          data-hover="false"
                          data-delay="200"
                          data-w-id="9afaa1a7-dfbc-3683-16c5-36344d8c74e1"
                          class="dropdown2_component w-dropdown"
                          @click="changeMerchantToFollowers"
                      >
                        <div class="dropdown2_toggle-2 w-dropdown-toggle" :class="{ 'w--open drop': merchant === 'followers' }">
                          <div class="dropdown2_dropdown-icon w-embed">
                            <svg
                                width=" 100%"
                                height=" 100%"
                                viewbox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.55806 6.29544C2.46043 6.19781 2.46043 6.03952 2.55806 5.94189L3.44195 5.058C3.53958 4.96037 3.69787 4.96037 3.7955 5.058L8.00001 9.26251L12.2045 5.058C12.3021 4.96037 12.4604 4.96037 12.5581 5.058L13.4419 5.94189C13.5396 6.03952 13.5396 6.19781 13.4419 6.29544L8.17678 11.5606C8.07915 11.6582 7.92086 11.6582 7.82323 11.5606L2.55806 6.29544Z"
                                  fill="currentColor"
                              ></path>
                            </svg>
                          </div>
                          <div>Select type</div>
                        </div>
                        <nav
                            style="
                            -webkit-transform: translate3d(0, 3rem, 0)
                              scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                              skew(0, 0);
                            -moz-transform: translate3d(0, 3rem, 0)
                              scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                              skew(0, 0);
                            -ms-transform: translate3d(0, 3rem, 0)
                              scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                              skew(0, 0);
                            transform: translate3d(0, 3rem, 0) scale3d(1, 1, 1)
                              rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                            opacity: 0;
                          "
                            class="dropdown2_dropdown-list-2 w-dropdown-list"
                            :class="{ 'w--open drop': merchant === 'followers' }"
                        >
                          <label class="w-checkbox filter-checkbox-field" v-for="(follow, index) in followers" :key="index"
                          ><input
                              type="checkbox"
                              id="checkbox-5"
                              name="Followers"
                              data-name="Followers"
                              class="w-checkbox-input filter-checkbox"
                              :value="follow"
                              @change="getFollowers"
                          /><span class="w-form-label" for="checkbox-5"
                          >{{follow}}</span
                          ></label
                          >
                        </nav>
                      </a>
                    </div>
                    <div class="filter-dropdown-form-item is-cta">
                      <div
                          class="filter-form-button-group div-block-244 div-block-245 div-block-246"
                      >
                        <a href="#" class="text-button" @click="resetForm">Reset Filter</a
                        >
                        <button class="filter-cta-btn w-button" v-if="!loading" @click="filterInfluence">Apply Filter</button>
                        <p class="filter-cta-btn w-button" v-else>Loading...</p>
                      </div>
                    </div>
                  </form>
                </div>
              </nav>
            </div>
          </div>
          <div
              class="influencer-db-wrapper"
              v-if="
              influencers.data.length > 0 &&
              filteredList.length <= 0 &&
              search === '' &&
              !influencerLoading
            "
          >
            <div
                id="w-node-fd0c1bad-194d-a63f-0eaf-9a8a4fe912f9-7a53ad7e"
                class="influencer-image-item"
                v-for="(influencer, index) in influencers.data.slice(0, count)"
                :key="index"
            >
              <img
                  :src="influencer.customerPicture"
                  loading="lazy"
                  alt=""
                  class="influencer-image"
                  v-if="influencer.customerPicture"
              />
              <img
                  src="../../assets/images/DBS_Amira_web_0037.jpg"
                  loading="lazy"
                  alt=""
                  class="influencer-image"
                  v-else
              />

              <div class="image-gradient-overlay"></div>
              <div class="influencer-name-tag">
                {{ influencer.customerName }}
              </div>
              <div class="influencer-cta-overlay">
                <a
                    @click="viewInfluencer(influencer.customerId)"
                    class="influencer-cta w-inline-block"
                >
                  <div>Book Influencer</div>
                  <img
                      src="../../assets/images/arrow-up-right.svg"
                      loading="lazy"
                      alt=""
                  />
                </a>
                <div class="div-block-252">
                  <a href="#" class="w-inline-block">
                    <div class="socials-icon w-embed">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="iconify iconify--bx"
                          width="100%"
                          height="100%"
                          preserveaspectratio="xMidYMid meet"
                          viewbox="0 0 24 24"
                      >
                        <path
                            d="M12.001 2.002c-5.522 0-9.999 4.477-9.999 9.999c0 4.99 3.656 9.126 8.437 9.879v-6.988h-2.54v-2.891h2.54V9.798c0-2.508 1.493-3.891 3.776-3.891c1.094 0 2.24.195 2.24.195v2.459h-1.264c-1.24 0-1.628.772-1.628 1.563v1.875h2.771l-.443 2.891h-2.328v6.988C18.344 21.129 22 16.992 22 12.001c0-5.522-4.477-9.999-9.999-9.999z"
                            fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </a>
                  <a href="#" class="w-inline-block">
                    <div class="socials-icon w-embed">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="iconify iconify--bx"
                          width="100%"
                          height="100%"
                          preserveaspectratio="xMidYMid meet"
                          viewbox="0 0 24 24"
                      >
                        <path
                            d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248a4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008a3.004 3.004 0 0 1 0 6.008z"
                            fill="currentColor"
                        ></path>
                        <circle
                            cx="16.806"
                            cy="7.207"
                            r="1.078"
                            fill="currentColor"
                        ></circle>
                        <path
                            d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42a4.6 4.6 0 0 0-2.633 2.632a6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71c0 2.442 0 2.753.056 3.71c.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632a6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419a4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186c.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688a2.987 2.987 0 0 1-1.712 1.711a4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055c-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311a2.985 2.985 0 0 1-1.719-1.711a5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654c0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311a2.991 2.991 0 0 1 1.712 1.712a5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655c0 2.436 0 2.698-.043 3.654h-.011z"
                            fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </a>
                  <a href="#" class="w-inline-block">
                    <div class="socials-icon w-embed">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="iconify iconify--fe"
                          width="100%"
                          height="100%"
                          preserveaspectratio="xMidYMid meet"
                          viewbox="0 0 24 24"
                      >
                        <g
                            id="IconifyId18a480bf9122de72c15"
                            fill="none"
                            fill-rule="evenodd"
                            stroke="none"
                            stroke-width="1"
                        >
                          <g
                              id="IconifyId18a480bf9122de72c16"
                              fill="currentColor"
                              fill-rule="nonzero"
                          >
                            <path
                                id="IconifyId18a480bf9122de72c17"
                                d="M8.283 20.263c7.547 0 11.676-6.259 11.676-11.677c0-.176 0-.352-.008-.528A8.36 8.36 0 0 0 22 5.928a8.317 8.317 0 0 1-2.36.649a4.129 4.129 0 0 0 1.808-2.273a8.163 8.163 0 0 1-2.61.993A4.096 4.096 0 0 0 15.847 4a4.109 4.109 0 0 0-4.106 4.106c0 .32.04.632.104.936a11.654 11.654 0 0 1-8.46-4.29a4.115 4.115 0 0 0 1.273 5.482A4.151 4.151 0 0 1 2.8 9.722v.056a4.113 4.113 0 0 0 3.29 4.026a4.001 4.001 0 0 1-1.08.144c-.265 0-.521-.024-.77-.072a4.104 4.104 0 0 0 3.834 2.85a8.231 8.231 0 0 1-5.098 1.76c-.328 0-.656-.016-.976-.056a11.674 11.674 0 0 0 6.283 1.833"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
              class="influencer-db-wrapper"
              v-else-if="
              influencers.data.length > 0 &&
              filteredList.length > 0 &&
              search !== ''
            "
          >
            <div
                id="w-node-fd0c1bad-194d-a63f-0eaf-9a8a4fe912f9-7a53ad7e"
                class="influencer-image-item"
                v-for="(influencer, index) in filteredList.slice(0, count)"
                :key="index"
            >
              <img
                  :src="influencer.customerPicture"
                  loading="lazy"
                  alt=""
                  class="influencer-image"
                  v-if="influencer.customerPicture"
              />
              <img
                  src="../../assets/images/DBS_Amira_web_0037.jpg"
                  loading="lazy"
                  alt=""
                  class="influencer-image"
                  v-else
              />

              <div class="image-gradient-overlay"></div>
              <div class="influencer-name-tag">
                {{ influencer.customerName }}
              </div>
              <div class="influencer-cta-overlay">
                <a
                    @click="viewInfluencer(influencer.customerId)"
                    class="influencer-cta w-inline-block"
                >
                  <div>Book Influencer</div>
                  <img
                      src="../../assets/images/arrow-up-right.svg"
                      loading="lazy"
                      alt=""
                  />
                </a>
                <div class="div-block-252">
                  <a href="#" class="w-inline-block">
                    <div class="socials-icon w-embed">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="iconify iconify--bx"
                          width="100%"
                          height="100%"
                          preserveaspectratio="xMidYMid meet"
                          viewbox="0 0 24 24"
                      >
                        <path
                            d="M12.001 2.002c-5.522 0-9.999 4.477-9.999 9.999c0 4.99 3.656 9.126 8.437 9.879v-6.988h-2.54v-2.891h2.54V9.798c0-2.508 1.493-3.891 3.776-3.891c1.094 0 2.24.195 2.24.195v2.459h-1.264c-1.24 0-1.628.772-1.628 1.563v1.875h2.771l-.443 2.891h-2.328v6.988C18.344 21.129 22 16.992 22 12.001c0-5.522-4.477-9.999-9.999-9.999z"
                            fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </a>
                  <a href="#" class="w-inline-block">
                    <div class="socials-icon w-embed">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="iconify iconify--bx"
                          width="100%"
                          height="100%"
                          preserveaspectratio="xMidYMid meet"
                          viewbox="0 0 24 24"
                      >
                        <path
                            d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248a4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008a3.004 3.004 0 0 1 0 6.008z"
                            fill="currentColor"
                        ></path>
                        <circle
                            cx="16.806"
                            cy="7.207"
                            r="1.078"
                            fill="currentColor"
                        ></circle>
                        <path
                            d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42a4.6 4.6 0 0 0-2.633 2.632a6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71c0 2.442 0 2.753.056 3.71c.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632a6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419a4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186c.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688a2.987 2.987 0 0 1-1.712 1.711a4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055c-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311a2.985 2.985 0 0 1-1.719-1.711a5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654c0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311a2.991 2.991 0 0 1 1.712 1.712a5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655c0 2.436 0 2.698-.043 3.654h-.011z"
                            fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </a>
                  <a href="#" class="w-inline-block">
                    <div class="socials-icon w-embed">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="iconify iconify--fe"
                          width="100%"
                          height="100%"
                          preserveaspectratio="xMidYMid meet"
                          viewbox="0 0 24 24"
                      >
                        <g
                            id="IconifyId18a480bf9122de72c15"
                            fill="none"
                            fill-rule="evenodd"
                            stroke="none"
                            stroke-width="1"
                        >
                          <g
                              id="IconifyId18a480bf9122de72c16"
                              fill="currentColor"
                              fill-rule="nonzero"
                          >
                            <path
                                id="IconifyId18a480bf9122de72c17"
                                d="M8.283 20.263c7.547 0 11.676-6.259 11.676-11.677c0-.176 0-.352-.008-.528A8.36 8.36 0 0 0 22 5.928a8.317 8.317 0 0 1-2.36.649a4.129 4.129 0 0 0 1.808-2.273a8.163 8.163 0 0 1-2.61.993A4.096 4.096 0 0 0 15.847 4a4.109 4.109 0 0 0-4.106 4.106c0 .32.04.632.104.936a11.654 11.654 0 0 1-8.46-4.29a4.115 4.115 0 0 0 1.273 5.482A4.151 4.151 0 0 1 2.8 9.722v.056a4.113 4.113 0 0 0 3.29 4.026a4.001 4.001 0 0 1-1.08.144c-.265 0-.521-.024-.77-.072a4.104 4.104 0 0 0 3.834 2.85a8.231 8.231 0 0 1-5.098 1.76c-.328 0-.656-.016-.976-.056a11.674 11.674 0 0 0 6.283 1.833"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
              class="empty-state home"
              v-else
              style="height: 100vh; margin-top: -200px"
          >
            <img
                width="150"
                alt=""
                src="../../assets/images/promotion-1_1.svg"
                loading="lazy"
            />
            <div class="empty-state-content">
              <div class="empty-state__text">
                <h5 class="empty-mall__h" v-if="!influencerLoading">No influencer available</h5>
                <h5 class="empty-mall__h" v-else>Loading...</h5>
              </div>
            </div>
          </div>
          <div class="influencer-db-wrapper mobile-view"
               v-if="
                influencers.data.length > 0 &&
                filteredList.length <= 0 &&
                search === '' &&
                !influencerLoading
              ">
            <a
                href="#"
                class="influencer-image-item-mobile w-inline-block"

                v-for="(influencer, index) in influencers.data.slice(0, count)"
                :key="index"
            >
              <img
                  :src="influencer.customerPicture"
                  loading="lazy"
                  alt=""
                  class="influencer-image"
                  v-if="influencer.customerPicture"
              />
              <div class="image-gradient-overlay"></div>

              <div class="influencer-name-tag">
                {{ influencer.customerName }}
              </div>
              <div class="influencer-cta-overlay">
                <a
                    @click="viewInfluencer(influencer.customerId)"
                    class="influencer-cta w-inline-block"
                >
                  <div>Book Influencer</div>
                  <img
                      src="../../assets/images/arrow-up-right.svg"
                      loading="lazy"
                      alt=""
                  />
                </a>
                <div class="div-block-252">
                  <a href="#" class="w-inline-block">
                    <div class="socials-icon w-embed">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="iconify iconify--bx"
                          width="100%"
                          height="100%"
                          preserveaspectratio="xMidYMid meet"
                          viewbox="0 0 24 24"
                      >
                        <path
                            d="M12.001 2.002c-5.522 0-9.999 4.477-9.999 9.999c0 4.99 3.656 9.126 8.437 9.879v-6.988h-2.54v-2.891h2.54V9.798c0-2.508 1.493-3.891 3.776-3.891c1.094 0 2.24.195 2.24.195v2.459h-1.264c-1.24 0-1.628.772-1.628 1.563v1.875h2.771l-.443 2.891h-2.328v6.988C18.344 21.129 22 16.992 22 12.001c0-5.522-4.477-9.999-9.999-9.999z"
                            fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </a>
                  <a href="#" class="w-inline-block">
                    <div class="socials-icon w-embed">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="iconify iconify--bx"
                          width="100%"
                          height="100%"
                          preserveaspectratio="xMidYMid meet"
                          viewbox="0 0 24 24"
                      >
                        <path
                            d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248a4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008a3.004 3.004 0 0 1 0 6.008z"
                            fill="currentColor"
                        ></path>
                        <circle
                            cx="16.806"
                            cy="7.207"
                            r="1.078"
                            fill="currentColor"
                        ></circle>
                        <path
                            d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42a4.6 4.6 0 0 0-2.633 2.632a6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71c0 2.442 0 2.753.056 3.71c.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632a6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419a4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186c.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688a2.987 2.987 0 0 1-1.712 1.711a4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055c-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311a2.985 2.985 0 0 1-1.719-1.711a5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654c0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311a2.991 2.991 0 0 1 1.712 1.712a5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655c0 2.436 0 2.698-.043 3.654h-.011z"
                            fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </a>
                  <a href="#" class="w-inline-block">
                    <div class="socials-icon w-embed">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="iconify iconify--fe"
                          width="100%"
                          height="100%"
                          preserveaspectratio="xMidYMid meet"
                          viewbox="0 0 24 24"
                      >
                        <g
                            id="IconifyId18a480bf9122de72c15"
                            fill="none"
                            fill-rule="evenodd"
                            stroke="none"
                            stroke-width="1"
                        >
                          <g
                              id="IconifyId18a480bf9122de72c16"
                              fill="currentColor"
                              fill-rule="nonzero"
                          >
                            <path
                                id="IconifyId18a480bf9122de72c17"
                                d="M8.283 20.263c7.547 0 11.676-6.259 11.676-11.677c0-.176 0-.352-.008-.528A8.36 8.36 0 0 0 22 5.928a8.317 8.317 0 0 1-2.36.649a4.129 4.129 0 0 0 1.808-2.273a8.163 8.163 0 0 1-2.61.993A4.096 4.096 0 0 0 15.847 4a4.109 4.109 0 0 0-4.106 4.106c0 .32.04.632.104.936a11.654 11.654 0 0 1-8.46-4.29a4.115 4.115 0 0 0 1.273 5.482A4.151 4.151 0 0 1 2.8 9.722v.056a4.113 4.113 0 0 0 3.29 4.026a4.001 4.001 0 0 1-1.08.144c-.265 0-.521-.024-.77-.072a4.104 4.104 0 0 0 3.834 2.85a8.231 8.231 0 0 1-5.098 1.76c-.328 0-.656-.016-.976-.056a11.674 11.674 0 0 0 6.283 1.833"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </div>
                  </a>
                </div>
              </div>

            </a>
          </div>
          <div
              class="influencer-db-wrapper mobile-view"
              v-else-if="
              influencers.data.length > 0 &&
              filteredList.length > 0 &&
              search !== ''
            "
          >
            <div
                id="w-node-fd0c1bad-194d-a63f-0eaf-9a8a4fe912f9-7a53ad7e"
                class="influencer-image-item-mobile w-inline-block"
                v-for="(influencer, index) in filteredList.slice(0, count)"
                :key="index"
            >
              <img
                  :src="influencer.customerPicture"
                  loading="lazy"
                  alt=""
                  class="influencer-image"
                  v-if="influencer.customerPicture"
              />
              <img
                  src="../../assets/images/DBS_Amira_web_0037.jpg"
                  loading="lazy"
                  alt=""
                  class="influencer-image"
                  v-else
              />

              <div class="image-gradient-overlay"></div>
              <div class="influencer-name-tag">
                {{ influencer.customerName }}
              </div>
              <div class="influencer-cta-overlay">
                <a
                    @click="viewInfluencer(influencer.customerId)"
                    class="influencer-cta w-inline-block"
                >
                  <div>Book Influencer</div>
                  <img
                      src="../../assets/images/arrow-up-right.svg"
                      loading="lazy"
                      alt=""
                  />
                </a>
                <div class="div-block-252">
                  <a href="#" class="w-inline-block">
                    <div class="socials-icon w-embed">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="iconify iconify--bx"
                          width="100%"
                          height="100%"
                          preserveaspectratio="xMidYMid meet"
                          viewbox="0 0 24 24"
                      >
                        <path
                            d="M12.001 2.002c-5.522 0-9.999 4.477-9.999 9.999c0 4.99 3.656 9.126 8.437 9.879v-6.988h-2.54v-2.891h2.54V9.798c0-2.508 1.493-3.891 3.776-3.891c1.094 0 2.24.195 2.24.195v2.459h-1.264c-1.24 0-1.628.772-1.628 1.563v1.875h2.771l-.443 2.891h-2.328v6.988C18.344 21.129 22 16.992 22 12.001c0-5.522-4.477-9.999-9.999-9.999z"
                            fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </a>
                  <a href="#" class="w-inline-block">
                    <div class="socials-icon w-embed">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="iconify iconify--bx"
                          width="100%"
                          height="100%"
                          preserveaspectratio="xMidYMid meet"
                          viewbox="0 0 24 24"
                      >
                        <path
                            d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248a4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008a3.004 3.004 0 0 1 0 6.008z"
                            fill="currentColor"
                        ></path>
                        <circle
                            cx="16.806"
                            cy="7.207"
                            r="1.078"
                            fill="currentColor"
                        ></circle>
                        <path
                            d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42a4.6 4.6 0 0 0-2.633 2.632a6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71c0 2.442 0 2.753.056 3.71c.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632a6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419a4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186c.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688a2.987 2.987 0 0 1-1.712 1.711a4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055c-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311a2.985 2.985 0 0 1-1.719-1.711a5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654c0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311a2.991 2.991 0 0 1 1.712 1.712a5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655c0 2.436 0 2.698-.043 3.654h-.011z"
                            fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </a>
                  <a href="#" class="w-inline-block">
                    <div class="socials-icon w-embed">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          aria-hidden="true"
                          role="img"
                          class="iconify iconify--fe"
                          width="100%"
                          height="100%"
                          preserveaspectratio="xMidYMid meet"
                          viewbox="0 0 24 24"
                      >
                        <g
                            id="IconifyId18a480bf9122de72c15"
                            fill="none"
                            fill-rule="evenodd"
                            stroke="none"
                            stroke-width="1"
                        >
                          <g
                              id="IconifyId18a480bf9122de72c16"
                              fill="currentColor"
                              fill-rule="nonzero"
                          >
                            <path
                                id="IconifyId18a480bf9122de72c17"
                                d="M8.283 20.263c7.547 0 11.676-6.259 11.676-11.677c0-.176 0-.352-.008-.528A8.36 8.36 0 0 0 22 5.928a8.317 8.317 0 0 1-2.36.649a4.129 4.129 0 0 0 1.808-2.273a8.163 8.163 0 0 1-2.61.993A4.096 4.096 0 0 0 15.847 4a4.109 4.109 0 0 0-4.106 4.106c0 .32.04.632.104.936a11.654 11.654 0 0 1-8.46-4.29a4.115 4.115 0 0 0 1.273 5.482A4.151 4.151 0 0 1 2.8 9.722v.056a4.113 4.113 0 0 0 3.29 4.026a4.001 4.001 0 0 1-1.08.144c-.265 0-.521-.024-.77-.072a4.104 4.104 0 0 0 3.834 2.85a8.231 8.231 0 0 1-5.098 1.76c-.328 0-.656-.016-.976-.056a11.674 11.674 0 0 0 6.283 1.833"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
              class="empty-state home"
              v-else
              style="height: 100vh; margin-top: -200px"
          >
            <img
                width="150"
                alt=""
                src="../../assets/images/promotion-1_1.svg"
                loading="lazy"
            />
            <div class="empty-state-content">
              <div class="empty-state__text">
                <h5 class="empty-mall__h" v-if="!influencerLoading">No influencer available</h5>
                <h5 class="empty-mall__h" v-else>Loading...</h5>
              </div>
            </div>
          </div>
          <div class="view-more-btn-wrapper" v-if="influencers.data.length > 0">
            <a @click="increaseCount" class="button is-secondary w-button"
            >View more</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.heading {
  font-weight: 700;
  color: #101828;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Faktum, sans-serif;
  font-size: 1.25rem;
  line-height: 1.5;
}
.influencer-image-item:hover .influencer-cta-overlay {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
  rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 1;
  visibility: visible;
  color: white;
}
.influencer-image-item:hover .influencer-name-tag {
  transform: translate3d(0px, 84px, 0px) scale3d(1, 1, 1) rotateX(0deg)
  rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 0;
}
.influencer-image-item-mobile:hover .influencer-cta-overlay {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
  rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 1;
  visibility: visible;
  color: white;
}
.influencer-image-item-mobile:hover .influencer-name-tag {
  transform: translate3d(0px, 84px, 0px) scale3d(1, 1, 1) rotateX(0deg)
  rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 0;
}

.div-block-252 {
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 1rem;
  line-height: 1.5;
}
.w-inline-block {
  max-width: 100%;
  display: inline-block;
}
.socials-icon.w-embed {
  width: 2rem;
  height: 2rem;
  color: rgba(255, 255, 255, 0.27);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  display: flex;
}
.socials-icon.w-embed:hover {
  color: white;
}

.influencer-cta.w-inline-block {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
}
.drop {
  transform: translate3d(0px, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg)
  rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !important;
  opacity: 1 !important;
  transform-style: preserve-3d !important;
}
.new-filter {
  z-index: 901;
}

nav{
  z-index: 99;
}
</style>
