<template>
<!--  <div class="right-section">-->
<!--    <div class="login-form-container">-->
<!--      <div class="logo-container-copy"><img src="@/assets/images/Logo.svg" loading="lazy" alt="" class="logo"></div>-->
<!--      <div class="login-header">-->
<!--        <h1 class="heading-20">Login to your account</h1>-->
<!--        <socialite-auth-button/>-->
<!--        <div class="divide">-->
<!--          <div class="div-block-216"></div>-->
<!--          <div class="div-block-216-copy">-->
<!--            <div class="text-block-122">OR</div>-->
<!--          </div>-->
<!--          <div class="div-block-216"></div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <login-form/>-->
<!--      <div class="sign-up-block">-->
<!--        <router-link to="/register" class="link-block-6 w-inline-block">-->
<!--          <div class="text-block-22">Not a member? <span class="text-span-3">Sign Up</span></div>-->
<!--        </router-link>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  <home-layout>
    <main class="main-wrapper" style="background-color: #FFFFFF">
      <div class="section">
        <div class="wrapper" style="display: grid">
          <div id="w-node-_87bd402b-7670-11cd-2caa-9c5a6de2e5dd-334ad1ac" class="col is-image" style="height: 100%">

            <!--          <img src="../../../public/images/auth-bgd.png" alt="Auth" class="image-52" height="765"/>-->
            <div class="image_wrapper"></div>
          </div>
          <div id="w-node-ccead28d-c7cd-0dbc-2ebe-872781b23751-334ad1ac" class="col right">
            <div class="block">
              <a href="#" class="logo__block"><img src="../../../public/images/Apvertise-logo.svg" loading="lazy" alt=""></a>
              <div class="message__block">
                <h3 class="form__heading">Hello again!</h3>
                <p class="form__p" style="font-size: 15px; font-weight: 300">We’re happy to have you back. Log into the account.</p>
              </div>
              <socialite-auth-button style="margin-bottom: -5px"/>
              <div class="form__block w-form">
                <div class="or">
                  <div class="divider"></div>
                  <div style="font-size: 15px">OR</div>
                  <div class="divider"></div>
                </div>
                <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form" autocomplete="off" @submit.prevent="login">
                  <div class="form__item"><label for="First-Name-2" class="form__label">Email</label>
                    <div class="input"><input type="email" class="form__input w-input" maxlength="256" name="Email" data-name="Email" placeholder="name@someone.com" id="Email" required autocomplete="false" v-model="model.username"><img src="../../../public/images/mail.svg" loading="lazy" alt="" class="form__input-icon"></div>
                  </div>
                  <div class="form__item"><label for="First-Name-2" class="form__label">Password</label>
                    <div class="input"><input type="password" class="form__input w-input" maxlength="6" minlength="6" name="Password" data-name="Password" placeholder="password" id="Password" required autocomplete="false" v-model="model.password" @keypress="isNumber" ><img src="../../../public/images/lock.svg" loading="lazy" alt="" class="form__input-icon" ></div>
                  </div>
                  <a class="login__link" style="margin-left: auto" @click="goToForgot">Forgot Password</a>
                  <div v-if="auth.authLoading" class="btn__submit w-button">
                    <b-spinner
                      small
                      style="color: #fff"
                    />
                  </div>
                  <input type="submit" value="Log in" data-wait="Please wait..." class="btn__submit w-button" v-else>
                  <div class="login__block">Are you a new user?<a href="#" class="login__link" @click="goToRegister">Create an account</a>
                  </div>
                </form>
                <div class="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </home-layout>

</template>

<script>
// import LoginForm from "@/components/form/LoginForm";
// import SocialiteAuthButton from "@/components/form/input/SocialiteAuthButton";
import HomeLayout from "@/views/layout/HomeLayout.vue";
import AuthRequest from "@/model/request/AuthRequest";
import { mapState } from "vuex";
import SocialiteAuthButton from "@/components/form/input/SocialiteAuthButton.vue";

export default {
  name: "Logon",
  components:{
    SocialiteAuthButton,
    HomeLayout
  },
  data(){
    return{
      model: AuthRequest.prototype.logon()//new AuthRequest().logon(),
    }
  },
  methods:{
    goToForgot(){
      this.$router.push("/forget-password");
    },
    goToRegister(){
      this.$router.push("/register");
    },
    login(){
      this.$store.dispatch("auth/login", this.model)
    },
    isNumber(event) {
      if (!/\d/.test(event.key) && event.key !== ".")
        return event.preventDefault();
    },
  },
  computed:{
    ...mapState(["auth"])
  }
};
</script>

<style scoped>
.section{
  margin: 0;
}

.wrapper{
  background: #FFFFFF !important;
}
h1, h2, h3, h4, h5, h6, p{
  margin: 0;
}

.w-input{
  padding: 10px 14px 10px 48px;
  margin: 0;
  height: 44px;
  border-style: solid;
  border-width: 1px;
  border-color: #d0d5dd;
  border-radius: 50rem;
  background-color: #fff;
  font-family: Figtree, sans-serif;
  font-size: 0.875rem;
  line-height: 1.7142857142857142;
  font-weight: 500;
}

.w-input::placeholder{
  color: rgba(52, 64, 84, 0.6);
}

.btn__submit {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50rem;
  background-color: #489e85;
  font-family: Figtree, sans-serif;
  font-weight: 600;
  cursor: pointer;
}

.country__code {
  position: absolute;
  top: 50%;
  display: block;
  width: 66px;
  margin-bottom: 0px;
  padding-right: 0px;
  border: 1px none #000;
  background-color: transparent;
  outline-color: #333;
  outline-offset: 0px;
  outline-style: none;
  outline-width: 3px;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.login__link{
  font-weight: 500;
}

.divider{
  margin: 0;
}
/* Focus state style for keyboard navigation for the focusable elements */
*[tabindex]:focus-visible,
input[type="file"]:focus-visible {
  outline: 0.125rem solid #4d65ff;
  outline-offset: 0.125rem;
}
/* Get rid of top margin on first element in any rich text element */
.w-richtext > :not(div):first-child, .w-richtext > div:first-child > :first-child {
  margin-top: 0 !important;
}
/* Get rid of bottom margin on last element in any rich text element */
.w-richtext>:last-child, .w-richtext ol li:last-child, .w-richtext ul li:last-child {
  margin-bottom: 0 !important;
}

</style>