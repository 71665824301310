class NotificationRequest{
    updateStatus(){
        return{
        notificationId: null
        }
    }
}


export default NotificationRequest


















