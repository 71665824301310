<template>
  <div class="div-block-8" style="background-color: #FFFFFF; margin: 0; padding-top: 115px">
    <div class="dashboard__wrapper">
      <div class="performance__section">
        <h5 class="heading-3">Performance Overview</h5>
        <div class="stats">
          <div class="stat_card">
            <div class="stats_frame">
              <div class="stat_icon-frame"><img src="../../assets/images/earning-1.svg" loading="lazy" alt="" class="stat_icon"></div>
              <div class="stat_details">
                <div class="stat_detail-title">Total Earned</div>
                <div class="div-block-9">
                  <div class="stat-detail-info">{{getCurrency(balance)}}</div>
                  <div class="stat_tag"><img src="../../assets/images/arrow-up.svg" loading="lazy" alt="" class="image-8">
                    <div>0%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="stat_card">
            <div class="stats_frame">
              <div class="stat_icon-frame"><img src="../../assets/images/announcement-1.svg" loading="lazy" alt="" class="stat_icon"></div>
              <div class="stat_details">
                <div class="stat_detail-title">Campaigns Shared</div>
                <div class="div-block-9">
                  <div class="stat-detail-info">{{campaign.myCampaigns.data.length}}</div>
                  <div class="stat_tag"><img src="../../assets/images/arrow-up.svg" loading="lazy" alt="" class="image-8">
                    <div>0%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="stat_card">
            <div class="stats_frame">
              <div class="stat_icon-frame"><img src="../../assets/images/share-1.svg" loading="lazy" alt="" class="stat_icon"></div>
              <div class="stat_details">
                <div class="stat_detail-title">Total Shares</div>
                <div class="div-block-9">
                  <div class="stat-detail-info">{{shares}}</div>
                  <div class="stat_tag"><img src="../../assets/images/arrow-up.svg" loading="lazy" alt="" class="image-8">
                    <div>0%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="stat_card">
            <div class="stats_frame">
              <div class="stat_icon-frame"><img src="../../assets/images/report-1.svg" loading="lazy" alt="" class="stat_icon"></div>
              <div class="stat_details">
                <div class="stat_detail-title">Total Impressions</div>
                <div class="div-block-9">
                  <div class="stat-detail-info">{{impression}}</div>
                  <div class="stat_tag"><img src="../../assets/images/arrow-up.svg" loading="lazy" alt="" class="image-8">
                    <div>0%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="campaign__info">
        <h5 class="campaign-detail-heading">Top Campaigns</h5>
        <div class="table-wrapper">
          <div class="table-header">
            <div class="campaign-name-wrapper-head">
              <div class="table-header-text">Campaign Name</div>
            </div>
            <div class="start-date-wrapper-head">
              <div class="table-header-text">Start Date</div>
            </div>
            <div class="end-date-wrapper-head">
              <div class="table-header-text">End Date</div>
            </div>
            <div class="campaign-type-wrapper-head">
              <div class="table-header-text">Campaign Type</div>
            </div>
            <div class="status-wrapper-head">
              <div class="table-header-text">Status</div>
            </div>
            <div class="amount-earned-wrapper-head">
              <div class="table-header-text">Amount Earned</div>
            </div>
            <div class="link-wrapper-head"></div>
          </div>
          <div class="table-body">
            <div class="table-content" v-for="(campaign, index) in campaign.myCampaigns.data.slice(0,5)" :key="index">
              <div class="campaign-name-wrapper-body">
                <div class="table-body-text is-campaign-name" style="text-transform: capitalize">{{campaign.campaignName}}</div>
              </div>
              <div class="start-date-wrapper-body">
                <div class="table-body-text">{{new Date(campaign.campaignStartDate).toDateString()}}</div>
              </div>
              <div class="end-date-wrapper-body">
                <div class="table-body-text">{{new Date(campaign.campaignEndDate).toDateString()}}</div>
              </div>
              <div class="campaign-type-wrapper-body">
                <div class="table-body-text" v-if="campaign.campaignType.includes('AWARENESS')">Awareness</div>
                <div class="table-body-text" v-else>Acquisition</div>

              </div>
              <div class="status-wrapper-body">
                <div class="indicator" v-if="campaign.campaignStatus === 'ACTIVE'"></div>
                <div class="indicator inactive" v-else></div>
                <div class="table-body-text" v-if="campaign.campaignStatus === 'ACTIVE'">Active</div>
                <div class="table-body-text" v-else>Inactive</div>

              </div>
              <div class="amount-earned-wrapper-body">
                <div class="table-body-text">${{campaign.campaignBudget}}.00</div>
              </div>
              <div class="link-wrapper-body">
                <a href="#" class="table-body-text is-link" @click="selectCampaign(campaign.campaignId)">View</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="campaign__info__block">
        <div class="campaign__info">
          <h5 class="campaign-detail-heading">Campaign Engagements</h5>
          <div class="campaign_detail_card">
            <div class="campaign-details-list">
              <div class="campaign_detail">
                <div class="campaign-stat-detail-info">
                  <div class="campaign-detail-title">Shares</div>
                  <div :class="'campaign-stat-progressbar _' + shares"></div>
                </div>
                <div class="campaign-stat-main">{{shares}}</div>
              </div>
              <div class="campaign_detail">
                <div class="campaign-stat-detail-info">
                  <div class="campaign-detail-title">Impressions</div>
                  <div :class="'campaign-stat-progressbar _' + impression"></div>
                </div>
                <div class="campaign-stat-main">{{impression}}</div>
              </div>
              <div class="campaign_detail">
                <div class="campaign-stat-detail-info">
                  <div class="campaign-detail-title">Clicks</div>
                  <div :class="'campaign-stat-progressbar _' + clicks"></div>
                </div>
                <div class="campaign-stat-main">{{clicks}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="campaign__info">
          <h5 class="campaign-detail-heading">Audience Location</h5>
          <div class="campaign_detail_card">
            <div class="campaign-details-list">
              <div class="campaign_detail">
                <div class="campaign-stat-detail-info">
                  <div class="campaign-detail-title">Lagos</div>
                  <div class="campaign-stat-progressbar _100"></div>
                </div>
                <div class="campaign-stat-main">40</div>
              </div>
              <div class="campaign_detail">
                <div class="campaign-stat-detail-info">
                  <div class="campaign-detail-title">Abuja</div>
                  <div class="campaign-stat-progressbar _70"></div>
                </div>
                <div class="campaign-stat-main">10</div>
              </div>
              <div class="campaign_detail">
                <div class="campaign-stat-detail-info">
                  <div class="campaign-detail-title">Oyo</div>
                  <div class="campaign-stat-progressbar _30"></div>
                </div>
                <div class="campaign-stat-main">8</div>
              </div>
            </div>
            <div class="empty-campaign-overlay"><img src="../../assets/images/bar-chart.svg" loading="lazy" alt="" class="empty-campaign-overlay-icon">
              <div class="empty-campaign-overlay-h">No data available</div>
              <div class="empty-campaign-overlay-p">Share a campaign to view data insights</div>
            </div>
          </div>
        </div>
      </div>
      <div class="campaign__info__block">
        <div class="campaign__info">
          <h5 class="campaign-detail-heading">Campaign Engagements</h5>
          <div class="campaign_detail_card">
            <div class="campaign-details-list">
              <div class="campaign_detail">
                <div class="campaign-stat-detail-info">
                  <div class="campaign-detail-title">Shares</div>
                  <div class="campaign-stat-progressbar _100"></div>
                </div>
                <div class="campaign-stat-main">1140</div>
              </div>
              <div class="campaign_detail">
                <div class="campaign-stat-detail-info">
                  <div class="campaign-detail-title">Impressions</div>
                  <div class="campaign-stat-progressbar _70"></div>
                </div>
                <div class="campaign-stat-main">860</div>
              </div>
              <div class="campaign_detail">
                <div class="campaign-stat-detail-info">
                  <div class="campaign-detail-title">Clicks</div>
                  <div class="campaign-stat-progressbar _30"></div>
                </div>
                <div class="campaign-stat-main">458</div>
              </div>
            </div>
            <div class="empty-campaign-overlay"><img src="../../assets/images/bar-chart.svg" loading="lazy" alt="" class="empty-campaign-overlay-icon">
              <div class="empty-campaign-overlay-h">No data available</div>
              <div class="empty-campaign-overlay-p">Share a campaign to view data insights</div>
            </div>
          </div>
        </div>
        <div class="campaign__info">
          <h5 class="campaign-detail-heading">Followers (Socials)</h5>
          <div class="campaign_detail_card">
            <div class="campaign-details-list">
              <div class="campaign_detail">
                <div class="campaign-stat-detail-info">
                  <div class="campaign-social-title"><img src="../../assets/images/instagram_1.svg" loading="lazy" alt="" class="campaign-social-title-icon">
                    <div class="campaign-detail-title">Instagram</div>
                  </div>
                  <div class="campaign-stat-progressbar _100"></div>
                </div>
                <div class="campaign-stat-main">1140</div>
              </div>
              <div class="campaign_detail">
                <div class="campaign-stat-detail-info">
                  <div class="campaign-social-title"><img src="../../assets/images/Facebook.svg" loading="lazy" alt="" class="campaign-social-title-icon">
                    <div class="campaign-detail-title">Facebook</div>
                  </div>
                  <div class="campaign-stat-progressbar _70"></div>
                </div>
                <div class="campaign-stat-main">860</div>
              </div>
              <div class="campaign_detail">
                <div class="campaign-stat-detail-info">
                  <div class="campaign-social-title"><img src="../../assets/images/twitter_2.svg" loading="lazy" alt="" class="campaign-social-title-icon">
                    <div class="campaign-detail-title">Twitter</div>
                  </div>
                  <div class="campaign-stat-progressbar _56"></div>
                </div>
                <div class="campaign-stat-main">458</div>
              </div>
              <div class="campaign_detail">
                <div class="campaign-stat-detail-info">
                  <div class="campaign-social-title"><img src="../../assets/images/telegram.svg" loading="lazy" alt="" class="campaign-social-title-icon">
                    <div class="campaign-detail-title">Telegram</div>
                  </div>
                  <div class="campaign-stat-progressbar _30"></div>
                </div>
                <div class="campaign-stat-main">312</div>
              </div>
            </div>
            <div class="empty-campaign-overlay inactive"><img src="../../assets/images/bar-chart.svg" loading="lazy" alt="" class="empty-campaign-overlay-icon">
              <div class="empty-campaign-overlay-h">No data available</div>
              <div class="empty-campaign-overlay-p">Share a campaign to view data insights</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import format from "date-fns/format";

export default {
  name: "NewDashboard",
  data(){
    return{
      dateFormat: "D MMM",
      model: {
        customerId: "",
        campaignId: "",
        startDate: "",
        type: "last30",
        endDate: "",
      },
      clicks: 0,
      shares: 0,
      impression: 0
    }
  },
  computed: {
    ...mapState(["auth", "transaction", "analytic"]),
    ...mapState({
      campaign: (state) => state.campaign,
      auth: (state) => state.auth,
      organisation: (state) => state.organisation,
      malls: (state) => state.organisation.malls.data,
      customerId: (state) => state.auth.userInfo.customerId,
      clicksPerCampaign: (state) => state.analytic.clicksPerCampaign,
      impressionsPerCampaign: (state) => state.analytic.impressionsPerCampaign,
      sharesPerCampaign: (state) => state.analytic.sharesPerCampaign,
    }),
    currency: function () {
      if (this.transaction.transactions.accounts.length < 1) return "---";
      else return this.transaction.transactions.accounts[1].accountCurrency;
    },
    balance: function () {
      if (this.transaction.transactions.accounts.length < 1) return "0.00";
      else return this.transaction.transactions.accounts[1].accountBalance;
    },
    userInfo(){
      return this.auth.userInfo
    },
    walletTransactions(){
      let newList = [];
      newList = this.transaction.transactions.transactions.filter(
        (item) => item.accountNumber === this.auth.userInfo.accounts[1].accountNumber)
      return newList;
    }
  },
  methods:{
    selectCampaign(_id) {
      localStorage.campaignId = _id;
      this.$router.push(`/campaign-metrics/${_id.hexEncode()}`);
    },
    getCurrency(currency){
      let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return USDollar.format(currency)
    },
    getMonday(d) {
      d = new Date(d);
      let day = d.getDay(),
        diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    formatDates(dateOne, dateTwo) {
      let formattedDates = "";
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat);
      }
      if (dateTwo) {
        //this.query(true);
        formattedDates += " - " + format(dateTwo, this.dateFormat);
      }
      return formattedDates;
    },
    getDuration(_startDate, _endDate) {
      let years =
        new Date(new Date(_endDate) - new Date(_startDate)).getFullYear() -
        1970;
      //console.log(years);
      let months = new Date(
        new Date(_endDate) - new Date(_startDate)
      ).getMonth();
      //console.log(months);
      let days =
        (new Date(_endDate).getTime() - new Date(_startDate).getTime()) /
        (1000 * 3600 * 24);
      //console.log(days);
      let hours =
        (new Date(_endDate).getTime() - new Date(_startDate).getTime()) /
        (1000 * 3600);
      //console.log(hours);
      let minutes =
        (new Date(_endDate).getTime() - new Date(_startDate).getTime()) /
        (1000 * 60);
      //console.log(minutes);
      let seconds =
        (new Date(_endDate).getTime() - new Date(_startDate).getTime()) / 1000;
      // //console.log(seconds);
      //return years;
      if (years > 0) return `${years} Years`;
      else if (months > 0) return `${months} Months`;
      else if (days > 0) return `${days} Days`;
      else if (hours > 0) return `${hours} Hours`;
      else if (minutes > 0) return `${minutes} Minutes`;
      else `${seconds} Seconds`;
    },
    getDaysArray(start, end) {
      let arr = [];
      let dt = new Date(start);
      for (; dt <= end; dt.setDate(dt.getDate() + 1)) {
        arr.push(format(new Date(dt), "YYYY-MM-DD"));
      }
      return arr;
    },
    async fetchAnalytics(_startDate, _endDate, refresh = false) {
      let data = {
        responseCode: "",
        responseMessage: "",
        data: [],
      };
      if (refresh) {
        this.$store.commit("analytic/updateClicksPerCampaign", data);
        this.$store.commit("analytic/updateImpressionsPerCampaign", data);
        this.$store.commit("analytic/updateSharesPerCampaign", data);
      }
      let model = this.model;
      model.startDate = _startDate;
      model.endDate = _endDate;
      model.customerId = this.auth.userInfo.customerId;
      await this.campaign.myCampaigns.data.forEach((campaign) => {
        model.campaignId = campaign.campaignId;
        this.$store.dispatch("analytic/updateClicksPerCampaign", this.model).then();
        this.$store.dispatch("analytic/updateImpressionsPerCampaign", this.model).then();
        this.$store.dispatch("analytic/updateSharesPerCampaign", this.model).then();
      })

    },
    getStartEndDate() {
      let today = new Date();
      let model = {
        customerId: "",
        campaignId: "",
        startDate: "",
        endDate: "",
      };
      if (this.model.type === "today") {
        model.startDate = today.toJSON().slice(0, 10);
        model.endDate = new Date(new Date().setDate(today.getDate() + 1))
          .toJSON()
          .slice(0, 10);
        //console.log("today::", model.startDate, model.endDate);
      } else if (this.model.type === "thisweek") {
        let priorDate = this.getMonday(new Date());
        model.startDate = priorDate.toJSON().slice(0, 10);
        model.endDate = new Date(new Date().setDate(today.getDate() + 1))
          .toJSON()
          .slice(0, 10);
        //console.log("thisweek::", model.startDate, model.endDate);
      } else if (this.model.type === "thismonth") {
        let priorDate = new Date(today.getFullYear(), today.getMonth(), 2);
        model.startDate = priorDate.toJSON().slice(0, 10);
        model.endDate = new Date(new Date().setDate(today.getDate() + 1))
          .toJSON()
          .slice(0, 10);
      } else if (this.model.type === "last30") {
        let priorDate = new Date(new Date().setDate(today.getDate() - 30));
        model.startDate = priorDate; //.toJSON().slice(0,10);
        model.endDate = new Date(new Date().setDate(today.getDate() + 1))
          .toJSON()
          .slice(0, 10);
      } else if (this.model.type === "last90") {
        let priorDate = new Date(new Date().setDate(today.getDate() - 90));
        model.startDate = priorDate.toJSON().slice(0, 10);
        model.endDate = new Date(new Date().setDate(today.getDate() + 1))
          .toJSON()
          .slice(0, 10);
      } else if (this.model.type === "thisyear") {
        let priorDate = new Date(new Date().getFullYear(), 0, 1);
        model.startDate = priorDate.toJSON().slice(0, 10);
        model.endDate = new Date(new Date().setDate(today.getDate() + 1))
          .toJSON()
          .slice(0, 10);
      } else if (this.model.type === "alltime") {
        model.type = "alltime";
        model.startDate = null;
        model.endDate = null;
      }
      return model;
    },
    query(_ignore) {
      //console.log(this.model.type);
      //console.log(_ignore);
      if (this.model.type === "custom" && !_ignore) return;
      this.$store.commit("analytic/reset");
      let startEndDate = this.getStartEndDate();
      //console.log("startEndDate>>>", startEndDate);
      if (this.model.type !== "custom") {
        this.model.endDate = startEndDate.endDate;
        this.model.startDate = startEndDate.startDate;
      }
      this.$store.dispatch("analytic/updateClicksPerCampaign", this.model);
      this.$store.dispatch("analytic/updateImpressionsPerCampaign", this.model);
      this.$store.dispatch("analytic/updateSharesPerCampaign", this.model);
    },
  },
  watch: {
    async userInfo(){
      await this.$store.dispatch("campaign/updateMyCampaigns", {
        customerId: this.auth.userInfo.customerId,
      });
      let startEndDate = this.getStartEndDate();

      this.fetchAnalytics(startEndDate.startDate, startEndDate.endDate);
      this.$store.dispatch("transaction/queryTransaction", {
        customerId: this.auth.userInfo.customerId,
      });
    },
    customerId: function () {
      let startEndDate = this.getStartEndDate();
      this.fetchAnalytics(startEndDate.startDate, startEndDate.endDate);
    },
    clicksPerCampaign(){
      this.clicks = this.clicks + this.clicksPerCampaign.data.length;
    },
    sharesPerCampaign(){
      this.shares = this.shares + this.sharesPerCampaign.data.length;
    },
    impressionsPerCampaign(){
      this.impression = this.impression + this.impressionsPerCampaign.data.length;
    },
  },
  mounted() {
    this.$store.dispatch("campaign/updateMyCampaigns", {
      customerId: this.auth.userInfo.customerId,
    });
    this.$store.dispatch("transaction/queryTransaction", {
      customerId: this.auth.userInfo.customerId,
    });
    this.model.customerId = this.auth.userInfo.customerId;
    this.campaign.myCampaigns.data.forEach((campaign) => {
      this.model.campaignId = campaign.campaignId;
      let startEndDate = this.getStartEndDate();
      if (
        this.analytic.clicksPerCampaign.responseCode !== "00" ||
        this.analytic.impressionsPerCampaign.responseCode !== "00" ||
        this.analytic.sharesPerCampaign.responseCode !== "00"
      )
        this.fetchAnalytics(startEndDate.startDate, startEndDate.endDate);

    })
  }
};
</script>

<style scoped>
.heading-3 {
  color: #1d2939;
  font-weight: 600;
}
h5 {
  color: #101828;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Faktum, sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
  text-align: left;
}

.campaign-detail-heading {
  color: #1d2939;
  font-family: Faktum, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
}
</style>