import BaseResponse from "@/model/response/BaseResponse";

class OrganisationResponse extends BaseResponse {
  readSingleResponse() {
    return {
      organisationId: "101",
      organisationSerial: null,
      organisationName: null,
      organisationLogo: null,
      organisationRegistrationNo: null,
      organisationRegistrationDate: null,
      organisationStressAddress: null,
      organisationCity: null,
      organisationCurrency: null,
      organisationDescription: null,
      organisationState: null,
      organisationPhone: null,
      organisationCountryCode: null,
      organisationEmail: null,
      organisationRedirectParameter:  null,
      organisationRedirectPlatform: null,
      organisationRedirectCustomUrl: null,
      organisationType: null,
      organisationCreatedAt: null,
      organisationUpdatedAt: null,
      responseCode: null,
      responseMessage: null,
    };
  }
  readResponse() {
    return {
      responseCode: null,
      responseMessage: null,
      data: [],
    };
  }

  static mallReadByCustomerIdResponse =  {
    responseCode: null,
    responseMessage: null,
    data: [
      {
        organisationId: null,
        organisationSerial: null,
        organisationName: null,
        organisationLogo: null,
        organisationRegistrationNo: null,
        organisationRegistrationDate: null,
        organisationStressAddress: null,
        organisationCity: null,
        organisationCurrency: null,
        organisationDescription: null,
        organisationState: null,
        organisationPhone: null,
        organisationCountryCode: null,
        organisationEmail: null,
        organisationRedirectParameter:  null,
        organisationRedirectPlatform: null,
        organisationRedirectCustomUrl: null,
        organisationType: null,
        organisationStatus: null,
        organisationCreatedAt: null,
        organisationUpdatedAt: null
      }
]
  }
  static organisationUpdateResponse =  {
    responseCode: null,
    responseMessage: null,

  }
  static mallsReadResponse = {
  responseCode: null,
  responseMessage: null,
  data: [
    {
      organisationId: null,
        organisationName: null,
        organisationLogo: null,
        organisationRegistrationNo: null,
      organisationRegistrationDate: null,
        organisationStressAddress: null,
      organisationCity: null,
      organisationBanner: null,
      organisationState: null,
      organisationPhone: null,
      organisationEmail: null,
      organisationCurrency: null,
      organisationNewsletterSent: null,
      organisationType: null,
      organisationStatus: null,
      organisationDescription: null,
      organisationCreatedAt: null,
      organisationUpdatedAt: null
    }
  ]
  }
  readOrganisationCategoryResponse() {
    return {
      responseCode: null,
      responseMessage: null,
      data: [
        { label: "Transportation", value: "TRANSPORTATION" },
        { label: "Marketing", value: "MARKETING" },
        { label: "Telecommunication", value: "TELECOMMUNICATION" },
        { label: "Agriculture", value: "AGRICULTURE" },
        { label: "Fashion & Beauty", value: "FASHION & BEAUTY" },
        { label: "Catering", value: "CATERING" },
        { label: "Arts & Entertainment", value: "ARTS & ENTERTAINMENT" },
        { label: "Gadgets", value: "GADGETS" },
        { label: "Electronics", value: "ELECTRONICS" },
        { label: "Banking", value: "BANKING" },
        { label: "Education", value: "EDUCATION" },
        { label: "Technology", value: "TECHNOLOGY" },
        { label: "SME", value: "SME" },
        { label: "Others", value: "OTHERS" },
      ],
    };
  }
}

export default OrganisationResponse;
