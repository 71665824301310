import client from "./BaseService";

export default {
    callUpdateStatusApi(payload){
        return client.apiClient.post("notification/update-status", payload);

    },
    callReadApi(payload){
        return client.apiClient.post("notification/read", payload);

    },
    callReadPendingApi(payload){
        return client.apiClient.post("notification/read-pending", payload);

    }

}