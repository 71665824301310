<script>
import DashboardNew from "@/components/menu/DashboardNew.vue";
import {mapState} from "vuex";
import PimCampaignRequest from "@/model/request/PimCampaignRequest";
import ActionButton from "@/components/shared/ActionButton.vue";
import LoaderComponent from "@/components/shared/LoaderComponent.vue";
export default {
  name: "PimRequests",
  components: {LoaderComponent, ActionButton, DashboardNew},
  data(){
    return{
      accept:false,
      acceptModel:PimCampaignRequest.prototype.accept(),
      declineModel:PimCampaignRequest.prototype.decline(),
      isLoader: false,
      showLoader:false,
      description: ""
    }
  },
  computed: {
    ...mapState({
      influencers: (state) => state.influencer.influencers,
      pimPackages: (state) => state.pimCampaign.pimPackages,
      pimCampaign: (state) => state.pimCampaign.pimCampaign,
      userInfo: (state) => state.auth.userInfo,
      organisation: (state) => state.organisation.organisations,
      loading: (state) => state.pimCampaign.loading,
    }),
  },
  methods:{
    async acceptPackage(){
      this.showLoader = true;
      this.acceptModel.pimCampaignId =this.pimCampaign.pimCampaignId;
      await this.$store.dispatch("pimCampaign/accept", this.acceptModel );
      this.showLoader = false;
    },
    async declinePackage(){
      this.isLoader = true;
      this.declineModel.pimCampaignId =this.pimCampaign.pimCampaignId;
      await this.$store.dispatch("pimCampaign/decline", this.declineModel );
      this.isLoader = false
    },
    toggleAccept(){
      this.accept=!this.accept
    },
    goToPimRequest(id){
      this.$router.push(`/pim-requests/${id.hexEncode()}`)
    },
    goToVibes(){
      this.$router.push("/influencers");
    },
    goToNotificationsPim(){
      this.$router.push("/notifications-pim");
    },
    openModal(className) {
      document.querySelector(className).style.opacity = 1;
      setTimeout(()=>{
        document.querySelector(className).style.display = "flex";
      }, 500)
      setTimeout(() => {
        this.show1 = true;
      }, 650)
    },
    close(className) {
      document.querySelector(className).style.opacity = 0;
      setTimeout(()=>{
        document.querySelector(className).style.display = "none";

      }, 500)
    },
    async sendReview(){
      await this.$store.dispatch("pimCampaign/review", {pimCampaignId: this.pimCampaign.pimCampaignId, pimCampaignComment: this.description}).then((res) => {
        if (res.responseCode === "00"){
          this.close(".review-pop-up");
        }
      });
    }

  },
  watch: {
    userInfo(){
      this.$store.dispatch("pimCampaign/readPimCampaign", {pimCampaignId: this.$route.params.id})
    },


  }


}

</script>

<template>
<div>
  <section class="review-pop-up" >
    <div class="review-modal" style="opacity: 1; display: flex;">
      <div class="body">
        <div class="modal-title">
          Review Request
        </div>
        <div class="modal-body" style="margin-top: 25px">
          <p><label for="modal-text">What's your reason for reviewing?</label></p>

          <textarea id="modal-text" placeholder="I'm looking for..." v-model="description">

        </textarea>
        </div>
        <div class="review-button-block" style=" display:flex; justify-content: flex-end; margin-right: 10px" >
          <button class="review-button" @click="sendReview"><span v-if="!loading">Send</span><b-spinner v-else small style="color: white"/></button>
        </div>
      </div>
      <a data-w-id="5dd05fa0-7341-aeba-f177-f7142c7cbff4" href="#" class="link-block-2" @click="close('.review-pop-up')"><img src="../../../public/images/x_1.svg" loading="lazy" width="24" alt=""></a>
    </div>
  </section>
  <dashboard-new/>
  <div class="section" style="height: 100%">
    <div class="dashboard-wrapper" style="height: 100vh">
      <div class="breadcrumb_component">
        <a @click="goToVibes" class="breadcrumb-link w-inline-block">
          <div>Home</div>
        </a>
        <div class="breadcrumb-divider w-embed"><svg width="16" height="16" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 3L11 8L6 13" stroke="CurrentColor" stroke-width="1.5"></path>
        </svg></div>
        <a @click="goToNotificationsPim"  class="breadcrumb-link w-inline-block">
          <div>All Notifications</div>
        </a>
        <div class="breadcrumb-divider w-embed"><svg width="16" height="16" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 3L11 8L6 13" stroke="CurrentColor" stroke-width="1.5"></path>
        </svg></div>
        <a @click="goToPimRequest" aria-current="page" class="breadcrumb-link is-active w-inline-block w--current">
          <div>PIM Request</div>
        </a>
      </div>
      <div class="div-block-248">
        <div class="module-title-wrapper">
          <div class="module-title"><img src="../../assets/images/campaign-image.svg" loading="lazy" alt="">
            <h5 class="heading" style="text-transform: capitalize">{{pimCampaign.organisationName}}</h5>
          </div>
          <div class="module-cta" v-if="pimCampaign.pimCampaignStatus === 'PENDING'">
            <action-button class="grey-button" v-if="isLoader"><loader-component /></action-button>
            <a v-else @click="declinePackage" class="link-block-27 w-inline-block">
              <div>Decline</div>
            </a>
            <action-button class="grey-button" v-if="showLoader"><loader-component /></action-button>
            <div v-else @click="toggleAccept" data-hover="false" data-delay="200" data-w-id="a1ecb80b-cf2f-607a-843d-765881661db7" class="dropdown2_component w-dropdown" :class="{'accept': accept===false}">
              <div  class="dropdown2_toggle-5 w-dropdown-toggle" :class="{'w--open':accept}">
                <div>Accept</div>
                <div v-if="accept" class="dropdown2_dropdown-icon-3" style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(180deg) skew(0deg, 0deg);transform-style: preserve-3d;"><svg width=" 100%" height=" 100%" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.55806 6.29544C2.46043 6.19781 2.46043 6.03952 2.55806 5.94189L3.44195 5.058C3.53958 4.96037 3.69787 4.96037 3.7955 5.058L8.00001 9.26251L12.2045 5.058C12.3021 4.96037 12.4604 4.96037 12.5581 5.058L13.4419 5.94189C13.5396 6.03952 13.5396 6.19781 13.4419 6.29544L8.17678 11.5606C8.07915 11.6582 7.92086 11.6582 7.82323 11.5606L2.55806 6.29544Z" fill="currentColor"></path>
                </svg>
                </div>
                <div v-else class="dropdown2_dropdown-icon-3 w-embed">
                  <svg width=" 100%" height=" 100%" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.55806 6.29544C2.46043 6.19781 2.46043 6.03952 2.55806 5.94189L3.44195 5.058C3.53958 4.96037 3.69787 4.96037 3.7955 5.058L8.00001 9.26251L12.2045 5.058C12.3021 4.96037 12.4604 4.96037 12.5581 5.058L13.4419 5.94189C13.5396 6.03952 13.5396 6.19781 13.4419 6.29544L8.17678 11.5606C8.07915 11.6582 7.92086 11.6582 7.82323 11.5606L2.55806 6.29544Z" fill="currentColor"></path>
                  </svg>
                </div>
              </div>
              <nav style="-webkit-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0;" class="dropdown2_dropdown-list-5 w-dropdown-list" :class="{'w--open drop': accept===true}">
                <a @click="acceptPackage" class="dropdown2_dropdown-link w-dropdown-link">Accept</a>
                <a @click="openModal('.review-pop-up')" class="dropdown2_dropdown-link w-dropdown-link">Review</a>
              </nav>
            </div>
          </div>
          <div class="module-cta" v-else>
            <button class="dropdown2_toggle-5" style="cursor: not-allowed" v-if="pimCampaign.pimCampaignStatus === 'ACCEPTED'">Accepted</button>
            <button class="link-block-27" style="cursor: not-allowed" v-else-if="pimCampaign.pimCampaignStatus === 'REVIEWING'">In Review</button>
            <button class="link-block-27" style="cursor: not-allowed" v-else>Declined</button>
          </div>
        </div>
        <div class="pim-request-details">
          <div class="pim-request-info is-inline">
            <h6 class="pim-request-info-h">Plan:</h6>
            <p class="pim-request-info-p" v-if="pimCampaign.packageType === 'BASIC'">Basic Plan</p>
            <p class="pim-request-info-p" v-if="pimCampaign.packageType === 'STANDARD'">Standard Plan</p>
            <p class="pim-request-info-p" v-if="pimCampaign.packageType === 'PREMIUM'">Premium Plan</p>

          </div>
          <div class="pim-request-info">
            <h6 class="pim-request-info-h">Campaign Details</h6>
            <p class="pim-request-info-p">{{pimCampaign.pimCampaignDescription}}</p>
          </div>
          <div class="pim-request-info" v-if="pimCampaign.pimCampaignMedia">
            <h6 class="pim-request-info-h">All files</h6>
            <div style="display: flex; gap: 16px; flex-wrap: wrap" v-if="pimCampaign.pimCampaignMedia.includes(',')">
              <div class="div-block-250" v-for="(media, index) in pimCampaign.pimCampaignMedia.split(',')" :key="index">
                <div class="file-details">
                  <div class="image-frame" style="padding: 0">
                    <img src="../../assets/images/pdf-icon.png" v-if="media.includes('pdf')" loading="lazy" alt="">
                    <img src="../../assets/images/excel.svg" v-else-if="media.includes('xlsx') || media.includes('xlsm') || media.includes('csv')" loading="lazy" alt="">
                    <img src="../../assets/images/word.svg" v-else-if="media.includes('docx')" loading="lazy" alt="">
                    <img :src="media" v-else loading="lazy" alt="" style="border-radius: 8px">
                  </div>
                  <div class="file-text">
                    <p class="file-name">{{pimCampaign.organisationName}}</p>
                    <p class="file-size">2MB</p>
                  </div>
                </div>
                <a :href="media" class="button is-secondary is-small w-button">View</a>
              </div>

            </div>
            <div class="div-block-250" v-else>
              <div class="file-details">
                <div class="image-frame" style="padding: 0">
                  <img src="../../assets/images/pdf-icon.png" loading="lazy" alt="">
                </div>
                <div class="file-text">
                  <p class="file-name">{{pimCampaign.organisationName}}</p>
                  <p class="file-size">2MB</p>
                </div>
              </div>
              <a :href="pimCampaign.pimCampaignMedia" class="button is-secondary is-small w-button">Download</a>
            </div>

          </div>
          <div class="empty-state home" v-else style="align-items: center;width: 100%"><img width="150" alt="" src="../../assets/images/promotion-1_1.svg" loading="lazy">
            <div class="empty-state-content">
              <div class="empty-state__text">
                <h5 class="empty-mall__h">No file uploaded</h5>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>
.link-block-2{
  width: 19px!important;
  height: 19px!important;
}

@media screen and (max-width: 700px) {
  .review-modal{
  width: 500px!important;
}
  .body{
    width: 430px!important;
  }
  textarea{
    width: 430px!important;
  }
}
@media screen and (max-width: 540px) {
  .review-modal{
    width: 320px!important;
  }
  .body{
    width: 250px!important;
  }
  textarea{
    width: 250px!important;
  }
  .modal-body{
    width: auto!important;
  }
  .link-block-2 {
    left: auto!important;
  }
}
@media screen and (max-width: 320px) {
  .review-modal{
    width: 250px!important;
  }
  .body{
    width: 200px!important;
  }
  textarea{
    width: 200px!important;
  }
  .modal-body{
    width: auto!important;
    margin-top: 10px!important;
  }
  .link-block-2 {
    left: auto!important;
  }
}


.accept{
  z-index: 901;
}
.drop{
  transform: translate3d(0px, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !important;
  opacity: 1 !important;
  transform-style: preserve-3d !important;
  transition: all 0.5s ease-in !important;
}
.grey-button{
  width: 102px;
  border-radius: 100px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.review-modal{
  /*background-color: white;
  padding: 32px;
  border-radius: 8px;
  border: 1px solid gray ;*/
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 626px;
  height: 384px;
  padding: 24px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(3, 28, 67, 0.1);
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(3, 28, 67, 0.4);
}
textarea{
  border-radius: 8px;
  padding-top:5px;
  padding-left:10px;
  width:562px;
  height:160px;
  border: 1px solid #d0d5dd;
  outline: none;
}
.review-button{
  width: 178px;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  color: #fff;
  background-color: #489e85;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 50rem;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  font-weight: 600;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}
.modal-body{
  width: 562px;
  height: 192px;
}
.modal-title{
  font-size: 20px;
  font-weight: 700;
}
.body{
  width: 562px;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.link-block-2{
  position: absolute;
  top:2%;
  left:95%
}
.review-pop-up{
  opacity: 0;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5px);
  background-color: rgba(16, 24, 40, .6);
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
</style>