import AccountSettings from "@/components/settings/AccountSettings.vue";
import UnsubscribePage from "@/components/unsubscribe/UnsubscribePage.vue";
import NewProfile from "@/views/dashboard/NewProfile";
import NewDashboard from "@/views/dashboard/NewDashboard.vue";

export default [
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { layout: "dashboard", authRequired: false },
    component: NewProfile,
  },
  {
    path: "/profile-settings",
    name: "Profile",
    meta: { layout: "dashboard", authRequired: false },
    component: NewDashboard,
  },
  {
    path: "/account-settings",
    name: "AccountSettings",
    meta: { layout: "dashboard", authRequired: false },
    component: AccountSettings,
  },
  {
    path: "/unsubscribe",
    name: "UnsubscribePage",
    meta: { layout: "", authRequired: false },
    component: UnsubscribePage,
  },
  {
    path: "/my-profile",
    name: "NewProfile",
    meta: { layout: "dashboard", authRequired: false },
    component: NewProfile,
  },

];
