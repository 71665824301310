<template>
  <div class="section-sub">
    <div class="frame-sub">
      <div class="frame-header-sub">
        <div class="frame-image-container-sub">
          <img src="../../assets/images/Frame1.png" height="64" width="64" />
        </div>
        <div class="frame-text-block-sub">
          <h3>Do you really want to unsubscribe from Apvertise emails?</h3>
          <p>Unsubscribing means you will no longer receive emails about campaign activities.</p>
        </div>
      </div>
      <div class="frame-button-block">
        <a class="link-sub" :class="{'current-sub' : subsribe}" @click="unsubscribe" v-if="!showLoader">Yes, unsubscribe me</a>
        <a class="current-sub" v-else><b-spinner
          small
          style="color: #fff"
        /></a>
        <a class="link-sub" :class="{'current-sub' : !subsribe}">No, keep me subscribed</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "UnsubscribePage",
  data(){
    return{
      subsribe: true,
      showLoader: false
    }
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth
    })
  },
  methods:{
    async unsubscribe(){
      this.showLoader = true;
      let res = await this.$store.dispatch("settings/updateUnsubcribe", {type: "login", email: this.auth.userInfo.customerEmail}).then()
      if (res.responseCode === "00"){
        this.showLoader = false;
      }
    }
  }
};
</script>

<style scoped>
.section-sub{
  position: relative;
  width: 100%;
  height: 100vh;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.frame-sub{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 72px;
}

.frame-header-sub{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 48px;
}

.frame-text-block-sub{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;
}

.frame-text-block-sub h3{
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  /* identical to box height, or 83% */
  color: #101828;
}

.frame-text-block-sub p {
  font-family: 'Figtree', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */


  color: #101828;
}

.frame-button-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 32px;
}

.frame-button-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 32px;
}

.link-sub{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  color: #489E85;
  text-decoration: none;
}

.current-sub{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 203px;
  height: 40px;

  /* Primary/600 */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */


  /* White */

  color: #FFFFFF !important;
  background: #489E85;
  /* Primary/600 */

  border: 1px solid #489E85;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 100px;
}
</style>