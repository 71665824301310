<template>
  <revamp-create-mall-form/>
</template>

<script>
import RevampCreateMallForm from "@/components/form/RevampCreateMallForm.vue";

export default {
  name: "RevampMallCreate",
  components: { RevampCreateMallForm }
};
</script>

<style scoped>

</style>