<template>
  <div style="display: block; font-family: Figtree, sans-serif; background: #fff">
    <AddProductFormOne v-if="product.screen === 'one'" />
    <AddProductFormTwo v-else-if="product.screen === 'two'" />
    <AddProductsSuccess v-else />
  </div>
</template>

<script>
import ProductRequest from "@/model/request/ProductRequest";
import AddProductFormOne from "@/components/form/AddProductFormOne";
import { mapState } from "vuex";
import AddProductFormTwo from "@/components/form/AddProductFormTwo";
import AddProductsSuccess from "@/views/mall/AddProductsSuccess";

export default {
  name: "AddProducts",
  components: {AddProductsSuccess, AddProductFormTwo, AddProductFormOne },
  data() {
    return {
      model: ProductRequest.productCreateRequest,
    };
  },
  computed: {
    ...mapState(["product"]),
  },
};
</script>

<style scoped></style>
