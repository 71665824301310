<template>
  <button
    :class="[
      'flex',
      'items-center',
      'w-full',
      'font-medium',
      'text-base',
      'cursor-pointer',
      type,
    ]"
    @click="$emit('click', true)"
  >
    <slot></slot>
  </button>
</template>
  
  <script>
export default {
  name: "ActionButton",
  computed: {},
  props: {
    type: {
      type: String,
      required: false,
      default: "primary",
    },
  },
};
</script>
  
  <style scoped>
button {
  border-radius: 8px;
  padding: 0 !important;
  height: 44px;
}

.primary {
  @apply bg-life-primary-500 text-white justify-center;
}

.secondary {
  @apply border border-gray-300 border-solid;
}
/* .danger {
  @apply bg-kanban-red text-kanban-white;
} */
</style>
  