class SocialMediaRequest {
  static enroll = {
    customerGender: null,
    customerInterests: null,
  };
  static addTwitter = {
    code_challenge: null,
    code: null
  }
  static addTiktok = {
    code: null
  }

  static addInstagram = {
    code: null
  }
  static readByCustomerId = {

  }
  static read = {

  }
}
export default SocialMediaRequest;
