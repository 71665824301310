<template>
  <div>
    <apvertise-earn-banner/>
    <dashboard-new/>
  </div>

</template>

<script>
import { mapState } from "vuex";
import ApvertiseEarnBanner from "@/components/banner/ApvertiseEarnBanner.vue";
import DashboardNew from "@/components/menu/DashboardNew.vue";

export default {
  name: "DashboardHeader",
  components: { DashboardNew, ApvertiseEarnBanner },
  data(){
    return {
      open: false,
      money: true
    }
  },
  methods:{
    openNavbar(){
     if (document.querySelector(".w-dropdown-list").classList.contains("visible")) {
       this.open = false;
     }
     else {
       this.open = true
     }
    },
    makeTrue(){
      this.open = !this.open;
      this.money = true;
    },
    logout(){
      localStorage.clear();
      document.location = "/";
    },
    goToProfileSettings(){
      localStorage.setItem("wallet", "no")
      this.$router.push("/profile-settings")
    },
    checkOrg(){
      if(this.organisation.length > 0){
        this.$router.replace("/mall-registered");
      }else{
        this.$router.replace("/mall-get-started");
      }
      this.$router.push("/mall-all");
    },
    navigate(_to, _active) {
      // const menu = document.querySelector('.w-nav-overlay');
      const menu = document.querySelector(".w-nav-overlay");
      let active = document.querySelector(_active);
      let inactive = document.querySelectorAll(".w--current");
      [].forEach.call(inactive, function(el) {
        el.classList.remove("w--current");
      });
      this.$router.push(_to)
      active.classList.add("w--current");
      menu.style.cssText = "display: none;"
      console.log("clickeddddd");
    }
  },
  computed:{
    // ...mapState(["auth"]),
    ...mapState({
      auth:state => state.auth,
      organisation:state => state.organisation.organisations
    })
  },
  mounted() {
    console.log(this.money)
    this.$store.dispatch("organisation/updateOrganisations", {
      customerId: this.auth.userInfo.customerId,
    });

  },
  watch: {
    money(){
      console.log("I am true")
      if (!document.querySelector(".w-dropdown-list").classList.contains("w--open")){
        console.log("I am here")
        this.open = false
      }
      if (!document.querySelector(".w-dropdown-list").className.includes("w--open")){
        console.log("I am here")
        this.open = false
      }
    }
  },
  created() {
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "https://play-apvertise.com/js/webflow.js");
    plugin.async = true;
    document.head.appendChild(plugin);
  }
};
</script>

<style scoped>
.w-dropdown-link:hover{
  color: #5DC9A0 !important;
}

.w-dropdown-list{
  display: none !important;
  top: 58px;
}

.w-dropdown:hover .w-dropdown-list {
  display: block !important;
}

.w-dropdown-toggle{
  display: flex !important;
  align-items: flex-end;
  justify-content: right;
}

.dashboard-container {
  display: flex;
  width: 100%;
  max-width: 100%;
  /* margin-right: auto; */
  /* margin-left: auto; */
}

.div-block-43{
  width: 1240px;
}

.dropdown-list-2 {
  left: -144px;
  width: 180px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(31, 36, 49, 0.05);
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 64px 0 rgb(31 36 49 / 5%);
}
</style>