import AuthService from "../../service/AuthService";
import AuthResponse from "@/model/response/AuthResponse";
import router from "../../router";
import swal from "sweetalert";
import store from "@/store/store";
//export const namespaced = true

export const state = {
  q_auth: null,
  isDocumentsUpt: false,
  authLoading: false,
  screen: "login",
  loginScreen: "login",
  regScreen: "reg",
  userInfo: new AuthResponse().logon(),
  userInfoLoading: false,
  isUserInfoUpt: false,
  deviceId: "",
  longitude: "",
  latitude: "",
  token: "",
  forgetPasswordScreen: "email",
  response: {},
  uniqueId: false,
};

export const getters = {
  getTok: (state) => {
    // console.log("getter getTok")
    return state.token;
  },
  getUser: (state) => {
    // console.log("getter getTok")
    return state.userInfo;
  },
};

export const mutations = {
  updateResponse: (state, payload) => {
    state.response = payload;
  },
  updateUniqueId: (state, payload) => {
    state.uniqueId = payload;
  },
  updateAuthLoading: (state, payload) => {
    state.authLoading = payload;
  },

  updateDeviceId: (state, payload) => {
    state.deviceId = payload;
  },

  updateLongitude: (state, payload) => {
    state.longitude = payload;
  },

  updateLatitude: (state, payload) => {
    state.latitude = payload;
  },

  updateForgetPasswordScreen: (state, payload) => {
    state.forgetPasswordScreen = payload;
  },

  updateScreen: (state, payload) => {
    state.screen = payload;
  },

  updateLoginScreen: (state, payload) => {
    state.loginScreen = payload;
  },

  updateRegScreen: (state, payload) => {
    state.regScreen = payload;
  },

  updateUserInfo: (state, payload) => {
    state.userInfo = payload;
  },

  updateUserInfoLoading: (state, payload) => {
    state.userInfoLoading = payload;
  },

  updateIsUserInfoUpt: (state, payload) => {
    state.isUserInfoUpt = payload;
  },

  updateAuthToken: (state, payload) => {
    // console.log("mutation updateAuthToken ==>>", payload)
    state.q_auth = payload;
    state.token = payload;
  },

  // eslint-disable-next-line no-unused-vars
  reset: (state, payload) => {
    state.q_auth = null;
    state.isDocumentsUpt = false;
    state.authLoading = false;
    state.screen = "login";
    state.regScreen = "reg";
    state.response = {};
  },
};

export const actions = {
  login: ({ commit }, payload) => {
    commit("updateAuthLoading", true);
    commit("updateResponse", {});
    return AuthService.callLogonApi(payload)
      .then((response) => {
        commit("updateAuthLoading", false);
        let responseData = response.data;
        commit("updateResponse", responseData);
        if (responseData.responseCode === "00") {
          store.dispatch("organisation/updateOrganisations", {
            customerId: responseData.customerId,
          });
          store.dispatch("influencer/readInfluencer", {})
          store.dispatch("message/readByCustomerId", {})
          store.dispatch("pimCampaign/readPackage", {})
          store.dispatch("pimCampaign/readSingleCustomer", {})
          store.dispatch("notification/read", {})
          store.dispatch("socialMedia/readCustomer");
          store.dispatch("message/readByCustomerId", {customerId: responseData.customerId}).then((res) => {
            store.dispatch("message/readByGroupId", {messageGroupId: res.data[0].messageGroupId})
          })

          localStorage.token = responseData.token;
          localStorage.customerId = responseData.customerId;
          commit("updateUserInfo", responseData);
          if (responseData.customerInviteId) {
            commit("updateUniqueId", true);
          }

          store.dispatch("accountPayout/updateAccountPayout", {
            customerId: responseData.customerId,
          })
          commit("updateAuthToken", responseData.token);
          //window.location = "/campaign-all";
          // router.push("/preloader");
          if (responseData.firstLogin){
            router.push("/switch-user");
          }
          else {
            router.push("/dashboard");
          }
          store.commit("system/updateOverlayLoading", true);
          store.dispatch("campaign/updateCampaigns", {customerId: responseData.customerId, readAll: "YES"});

        } else if (responseData.responseCode === "09") {
          commit("updateAuthLoading", false);
          return response;
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((error) => {
        commit("updateAuthLoading", false);
        alert(error);
      });
  },

  initiateEnrollment: ({ commit }, payload) => {
    commit("updateAuthLoading", true);
    commit("updateResponse", {});
    return AuthService.callInitiateEnrollmentApi(payload)
      .then((response) => {
        commit("updateAuthLoading", false);
        let responseData = response.data;
        commit("updateResponse", responseData);
        if (responseData.responseCode === "00") {
          // store.commit("updateMessage", {
          //   type: "success",
          //   title: "Info",
          //   body: "Otp has been sent to your email, please supply it to proceed",
          // });
          commit("updateRegScreen", "password");
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((error) => {
        commit("updateAuthLoading", false);
        alert(error);
      });
  },

  completeEnrollment: ({ commit }, payload) => {
    commit("updateAuthLoading", true);
    commit("updateResponse", {});
    return AuthService.callCompleteEnrollmentApi(payload)
      .then((response) => {
        commit("updateAuthLoading", false);
        let responseData = response.data;
        commit("updateResponse", responseData);
        if (responseData.responseCode === "00") {
          commit("updateResponse", responseData);
          commit("updateRegScreen", "success");
          //window.location = "/successful-register";
        } else {
          swal("Error", responseData.responseMessage, "error").then(() => {});
        }
      })
      .catch((error) => {
        commit("updateAuthLoading", false);
        alert(error);
        // throw error;
        // return Promise.reject(error)
      });
  },

  socialiteEnrollment: ({ commit, dispatch }, payload) => {
    commit("updateAuthLoading", true);
    store.commit("system/updateOverlayLoading", true);
    commit("updateResponse", {});
    return AuthService.callSocialiteEnrollmentApi(payload)
      .then((response) => {
        commit("updateAuthLoading", false);
        store.commit("system/updateOverlayLoading", false);
        let responseData = response.data;
        commit("updateResponse", responseData);
        if (responseData.responseCode === "00") {
          commit("updateResponse", responseData);
          //commit("updateRegScreen", "success");
          dispatch("login", payload);
          //window.location = "/successful-register";
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((error) => {
        commit("updateAuthLoading", false);
        alert(error);
        // throw error;
        // return Promise.reject(error)
      });
  },

  initiateResetPassword: ({ commit }, payload) => {
    commit("updateAuthLoading", true);
    commit("updateResponse", {});
    return AuthService.callInitiateResetPasswordApi(payload)
      .then((response) => {
        let responseData = response.data;
        commit("updateResponse", responseData);
        if (responseData.responseCode === "00") {
          commit("updateAuthLoading", false);
          store.commit("updateMessage", {
            type: "success",
            title: "Info",
            body: "Otp has been sent to your email, please supply it to proceed",
          });
          commit("updateForgetPasswordScreen", "otp");
        } else {
          commit("updateAuthLoading", false);
          swal("Error", responseData.responseMessage, "error").then(() => {
            //commit("updateScreen", "recovery2");
          });
        }
      })
      .catch((error) => {
        commit("updateAuthLoading", false);
        alert(error);
        // throw error;
      });
  },

  completeResetPassword: ({ commit }, payload) => {
    commit("updateAuthLoading", true);
    return AuthService.callCompleteResetPasswordApi(payload)
      .then((response) => {
        let responseData = response.data;
        commit("updateAuthLoading", false);
        if (responseData.responseCode === "00") {
          store.commit("updateMessage", {
            type: "success",
            title: "Success",
            body: "Your password has been changed successfully.",
          });
          router.push("/logon");
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((error) => {
        commit("updateAuthLoading", false);
        alert(error);
        // throw error;
      });
  },

  resendOtp: ({ commit }, payload) => {
    commit("updateAuthLoading", true);
    return AuthService.callResendOtpApi(payload)
      .then((response) => {
        let responseData = response.data;
        commit("updateAuthLoading", false);
        if (responseData.responseCode === "00") {
          store.commit("updateMessage", {
            type: "success",
            title: "Success",
            body: responseData.responseMessage,
          });
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((error) => {
        commit("updateAuthLoading", false);
        alert(error);
        // throw error;
      });
  },

  resendOtpInApp: ({ commit }, payload) => {
    commit("updateAuthLoading", true);
    return (
      AuthService.callResendOtpApi(payload)
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          commit("updateAuthLoading", false);
        })
        .catch((error) => {
          commit("updateAuthLoading", false);
          alert(error);
          // throw error;
        })
    );
  },

  checkPhoneExistence: ({ commit }, payload) => {
    commit("updateAuthLoading", true);
    return AuthService.callCheckPhoneExistenceApi(payload)
      .then((response) => {
        let responseData = response.data;
        commit("updateAuthLoading", false);
        if (responseData.responseCode === "00") {
          store.commit("updateMessage", {
            type: "success",
            title: "Success",
            body: responseData.responseMessage,
          });
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((error) => {
        commit("updateAuthLoading", false);
        alert(error);
        // throw error;
      });
  },

  verifyToken: ({ commit }, payload) => {
    commit("updateAuthLoading", true);
    return AuthService.callVerifyTokenApi(payload)
      .then((response) => {
        let responseData = response.data;
        commit("updateAuthLoading", false);
        if (responseData.responseCode === "00") {
          store.dispatch("socialMedia/readCustomer")
          store.dispatch("accountPayout/updateAccountPayout", {
            customerId: responseData.customerId,
          })
          store.dispatch("organisation/updateMalls", {
            customerId: responseData.customerId,
          });
          store.dispatch("campaign/updateCampaigns", {customerId: responseData.customerId, readAll: "YES"});

          store.commit("updateMessage", {
            type: "success",
            title: "Success",
            body: responseData.responseMessage,
          });
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((error) => {
        commit("updateAuthLoading", false);
        alert(error);
      });
  },

  changePassword: ({ commit }, payload) => {
    commit("updateAuthLoading", true);
    return AuthService.callChangePasswordApi(payload)
      .then((response) => {
        let responseData = response.data;
        commit("updateAuthLoading", false);
        if (responseData.responseCode === "00") {
          store.commit("updateMessage", {
            type: "success",
            title: "Success",
            body: responseData.responseMessage,
          });
          commit("updateScreen", "recovery3");
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((error) => {
        commit("updateAuthLoading", false);
        alert(error);
      });
  },

  reset: ({ commit }, payload) => {
    commit("reset", payload);
  },

  validate({ commit }) {
    //getting token from local storage
    let rtn = false;
    let userToken = localStorage.token;

    //start App Loading
    commit("updateAuthLoading", true);

    if (userToken === "") {
      //stop App Loading
      commit("updateAuthLoading", false);
      commit("updateUserInfo", new AuthResponse().logon());

      //if (router.currentRoute.path !== "/register" || router.currentRoute.path !== "/logon")
      // router.push("/logon");
      // return rtn
    } else {
      let payload = {
        token: "",
      };
      return AuthService.callValidateUserTokenApi(payload)
        .then((response) => {
          commit("updateAuthLoading", false);
          let responseData = response.data;
          console.log(responseData)
          if (responseData.responseCode === "00") {
            store.dispatch("socialMedia/readCustomer")
            store.dispatch("accountPayout/updateAccountPayout", {
              customerId: responseData.customerId,
            })
            store.dispatch("organisation/updateMalls", {
              customerId: responseData.customerId,
            });
            store.dispatch("influencer/readInfluencer", {})
            store.dispatch("message/readByCustomerId", {})
            store.dispatch("pimCampaign/readPackage", {})
            store.dispatch("pimCampaign/readSingleCustomer", {})

            store.dispatch("notification/read", {})
            store.dispatch("campaign/updateCampaigns", {customerId: responseData.customerId, readAll: "YES"});

            commit("updateUserInfo", responseData);
            if (responseData.customerInviteId) {
              commit("updateUniqueId", true);
            }
            rtn = true;
            return rtn;
          } else {
            return rtn;
          }
        })
        .catch((error) => {
          commit("updateAuthLoading", false);
          alert(error);
          return rtn;
        });
    }
  },

  logout: ({ commit }, payload) => {
    commit("updateAuthLoading", true);
    commit("updateResponse", {});
    return (
      AuthService.callLogoutApi(payload)
        .then((response) => {
          commit("updateAuthLoading", false);
          let responseData = response.data;
          commit("updateResponse", responseData);
          localStorage.clear();
          router.push("/");
          //window.location = "/";
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          commit("updateAuthLoading", false);
          localStorage.clear();
          router.push("/");
          //window.location = "/";
        })
    );
  },

  // eslint-disable-next-line no-unused-vars
  updateUserInfo: ({ commit }, payload = { payload: "you" }) => {
    commit("updateUserInfoLoading", true);
    return (
      AuthService.callLogonApi({ payload: "you" })
        .then((response) => {
          if (response.data.responseCode === "00") {
            commit("updateUserInfoLoading", false);
            commit("updateIsUserInfoUpt", true);
            let responseData = response.data;
            store.dispatch("organisation/updateOrganisations", {
              customerId: responseData.customerId,
            });
            commit("updateUserInfo", responseData);
            store.dispatch("accountPayout/updateAccountPayout", {
              customerId: responseData.customerId,
            })
            store.dispatch("influencer/readInfluencer", {})
            store.dispatch("message/readByCustomerId", {customerId: responseData.customerId}).then((res) => {
              store.dispatch("message/readByGroupId", {messageGroupId: res.data[0].messageGroupId})
            })
            store.dispatch("pimCampaign/readPackage", {})
            store.dispatch("pimCampaign/readSingleCustomer", {})
            store.dispatch("notification/read", {})
            store.dispatch("campaign/updateCampaigns", {customerId: responseData.customerId, readAll: "YES"});

          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          commit("updateUserInfoLoading", false);
          // alert(error)
          // throw error;
        })
    );
  },
};
