<template>
  <home-layout>
    <div
        data-animation="default"
        data-collapse="medium"
        data-duration="400"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        class="navbar w-nav"
    >
      <div class="container-large">
        <div class="main-nav_container">
          <div class="logo-wrapper">
            <a href="#" class="w-nav-brand"
            ><img
                src="../../../public/images/Apvertise-logo.svg"
                loading="lazy"
                alt=""
                height="32"
            /></a>
          </div>
          <nav role="navigation" class="nav-menu w-nav-menu">
            <div class="dropdown-topprofile">
              <div class="profile-section mobile">
                <div class="profile-icon">
                  <div class="profile_initials">
                    {{ auth.userInfo.customerFirstName.charAt(0)
                    }}{{ auth.userInfo.customerLastName.charAt(0) }}
                  </div>
                </div>
                <div class="profile-details_wrapper">
                  <div class="profile_name">
                    {{
                      auth.userInfo.customerFirstName +
                      " " +
                      auth.userInfo.customerLastName
                    }}
                  </div>
                  <div class="email-text">
                    {{ auth.userInfo.customerEmail }}
                  </div>
                </div>
              </div>
            </div>
            <div class="left-navlink">
              <a
                  href="#"
                  class="topnav-link home"
                  :class="{
                  'w--current':
                    this.$route.path === '/dashboard' ||
                    this.$route.path === '/onboarding/new-business'||
                    this.$route.path === '/onboarding/new-user'
                }"
                  @click="goToSwitch"
              >Home</a
              >
              <a
                  href="#"
                  aria-current="page"
                  class="topnav-link campaigns"
                  :class="{ 'w--current': this.$route.path === '/campaign-all' }"
                  @click="goToCampaign"
              >Campaigns</a
              >
              <a
                  href="#"
                  class="topnav-link malls"
                  :class="{
                  'w--current': this.$route.path === '/mall-all',
                }"
                  @click="goToMall"
              >Malls</a
              >
              <a
                  href="#"
                  class="topnav-link earnings"
                  :class="{ 'w--current': this.$route.path === '/view-wallet' }"
                  @click="goToWallet"
              >Wallet</a
              >
              <a
                  href="#"
                  class="topnav-link earnings"
                  :class="{ 'w--current': this.$route.path === '/influencers' }"
                  @click="goToVibes"
              >Influencers</a
              >
            </div>
            <div class="right-navlink">
              <a @click="gotToPimChat" class="w-inline-block" style="position: relative"><img src="../../assets/images/message-square.svg" loading="lazy" alt=""><span style="position: absolute; background: red; color: white; width: 20px; height: 20px; display: flex; align-items: center; justify-content: center; border-radius: 50%;right: -10px;top: -10px; font-size: 10px">{{activeMessage.length}}</span></a>
              <a @click="toggleNotification" class="dropdown1_component w-dropdown" :class="{'new-nav':notification}">
                <div class="dropdown1_toggle w-dropdown-toggle" style="position: relative" :class="{'w--open': notification}"><img src="../../assets/images/bell.svg" loading="lazy" alt=""><span style="position: absolute; background: red; color: white; width: 20px; height: 20px; display: flex; align-items: center; justify-content: center; border-radius: 50%;right: -9px;top: -10px; font-size: 10px">{{activeNotification.length}}</span></div>
                <nav class="dropdown1_dropdown-list-2 w-dropdown-list" style="transform: translate3d(0px, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d; opacity: 0;" :class="{'w--open show':notification}">
                  <div class="notification-dropdown-title">
                    <div class="text-block-44">Notifications</div>
                    <a @click="goToNotificationsPim" class="link-5" tabindex="0">View all</a>
                  </div>
                  <div style="width: 100%;" v-if="notifications.data.length > 0">
                    <a @click="goToPimRequest(notification.notificationTypeId,notification.notificationId)"  class="notification-item w-inline-block" v-for="(notification, index) in notifications.data.slice(0,3)" :key="index">
                    <div class="left"><img src="../../assets/images/userAvatar.svg" loading="lazy" alt="" class="notification-icon">
                      <div v-if="notification.notificationStatus !== 'READ'" class="new-dot"></div>
                    </div>
                    <div class="right">
                      <p class="notification-description"><span class="notification-type">{{notification.notificationType}}:</span> You’ve got a request from <span class="notification-contact">{{notification.notificationActor}}</span></p>
                      <div class="notification-date">{{new Date(notification.notificationCreatedAt).toLocaleString('en-us',{month:'short'})}} {{new Date(notification.notificationCreatedAt).getDate()}}</div>
                    </div>
                  </a>
                  </div>
                  <div  v-else class="empty-state" style="padding-top: 1rem; padding-bottom: 2rem; border-top: 1px solid #D9D9D9">
                    <div class="empty-state-content">
                      <div class="text-block-11">No notification to show</div>
                    </div>
                  </div>

                </nav>
              </a>
              <div
                  data-hover="true"
                  data-delay="0"
                  class="dropdown w-dropdown dp2"
                  @click="toggleMyAccount"
              >
                <div class="dropdown-toggle w-dropdown-toggle dm" :class="{'new-drop': myAccount===true}">
                  <div class="text-block">
                    {{ auth.userInfo.customerFirstName.charAt(0)
                    }}{{ auth.userInfo.customerLastName.charAt(0) }}
                  </div>
                </div>
                <nav class="dropdown1_dropdown-list w-dropdown-list" :class="{'w--open drop' :myAccount===true}">
                  <a
                      href="#"
                      class="dropdown1_dropdown-link w-dropdown-link"
                      @click="goToSettings"
                  >Account Settings</a
                  >
                  <a href="#" class="dropdown1_dropdown-link w-dropdown-link" @click="logout"
                  >Log Out</a
                  >
                </nav>
              </div>
              <a href="#" class="link-block" @click="goToSwitch"
              ><img
                  src="../../../public/images/repeat.svg"
                  loading="lazy"
                  alt=""
              />
                <div class="text-block-2">Switch mode</div>
              </a>
              <a
                  href="#"
                  class="link-block"
                  @click="goToSettings"
              ><img
                  src="../../../public/images/settings.svg"
                  loading="lazy"
                  alt=""
              />
                <div class="text-block-2">Settings</div>
              </a>
              <a href="#" class="link-block" @click="logout"
              ><img
                  src="../../../public/images/log-out.svg"
                  loading="lazy"
                  alt=""
              />
                <div class="text-block-2">Log Out</div>
              </a>
            </div>
          </nav>
          <a class="menu-button w-nav-button" @click="reveal = !reveal">
            <div class="icon w-icon-nav-menu"></div>
          </a>
        </div>
      </div>
      <div
          class="w-nav-overlay"
          data-wf-ignore=""
          id="w-nav-overlay-0"
          style="height: 0; display: block; opacity: 1; visibility: visible;transition: all 200ms ease 0s;"
          :class="{ reveal: reveal }"
      >
        <nav role="navigation" class="pim-nav-menu w-nav-menu" style="transform: translateY(0px) translateX(0px); transition: transform 400ms ease 0s;" data-nav-menu-open="">
          <div class="dropdown-topprofile">
            <div class="profile-section mobile">
              <div class="profile-icon">
                <div class="profile_initials">{{ auth.userInfo.customerFirstName.charAt(0)
                  }}{{ auth.userInfo.customerLastName.charAt(0) }}</div>
              </div>
              <div class="profile-details_wrapper">
                <div class="profile_name">{{
                    auth.userInfo.customerFirstName +
                    " " +
                    auth.userInfo.customerLastName
                  }}</div>
                <div class="email-text">{{ auth.userInfo.customerEmail }}</div>
              </div>
            </div>
          </div>
          <div class="mobile-nav-link-group">
            <a @click="goToOnboarding" class="topnav-link home w-nav-link w--nav-link-open">Dashboard</a>
            <a @click="goToCampaign" class="topnav-link campaigns w-nav-link w--nav-link-open">Campaigns</a>
            <a @click="goToMall" class="topnav-link malls w-nav-link w--nav-link-open">Malls</a>
            <a @click="goToVibes" class="topnav-link earnings w-nav-link w--nav-link-open">Influencers</a>
          </div>
          <div class="right-navlink">
            <a @click="goToSettings" class="link-block w-inline-block"><img src="../../assets/images/settings.svg" loading="lazy" alt="">
              <div class="text-block-2">Settings</div>
            </a>
            <a @click="logout" class="link-block w-inline-block"><img src="../../assets/images/log-out.svg" loading="lazy" alt="">
              <div class="text-block-2">Log Out</div>
            </a>
          </div>
        </nav>
      </div>
    </div>
  </home-layout>
</template>

<script>
import HomeLayout from "@/views/layout/HomeLayout.vue";
import { mapState } from "vuex";
import NotificationRequest from "@/model/request/NotificationRequest";

export default {
  name: "DashboardNew",
  components: { HomeLayout },
  data() {
    return {
      userType: "user",
      reveal: false,
      notification:false,
      myAccount:false,
      model: NotificationRequest.prototype.updateStatus
    };
  },
  methods: {
    goToSwitch() {
      if (this.connectedTiktok || this.connectedTwitter) {
        this.$router.push("/dashboard");
      } else if (this.userType === "user") {
        this.$router.push("/onboarding/new-user");
      } else {
        this.$router.push("/onboarding/new-business");
      }
    },
    myFunction() {
      console.log(document.querySelector(".dt"));
      console.log(document.querySelector(".dt").style);
      if (document.querySelector(".dt").style.display === "none") {
        document.querySelector(".dt").style.display = "block";
      } else {
        document.querySelector(".dt").style.display = "none";
      }
    },
    goToMall() {
      this.$router.push("/mall-all");
    },
    goToEarning() {
      this.$router.push("/view-earnings");
    },
    goToWallet() {
      this.$router.push("/view-wallet");
    },
    goToVibes(){
      this.$router.push("/influencers");
    },
    openNavbar() {
      if (
          document.querySelector(".w-dropdown-list").classList.contains("w--open")
      ) {
        document.querySelector(".w-dropdown-list").classList.remove("w--open");
      } else {
        document.querySelector(".w-dropdown-list").classList.add("w--open");
      }
    },
    goToOnboarding() {
      if (this.userType === "user") {
        this.$router.push("/onboarding/new-user");
      } else {
        this.$router.push("/onboarding/new-business");
      }
    },
    goToCampaign() {
      this.$router.push("/campaign-all");
    },
    goToProfile() {
      this.$router.push("/my-profile");
    },
    goToSettings() {
      this.$router.push("/account-settings");
    },
    gotToPimChat(){
      this.$router.push("/influencer-pim-chat");
    },
    toggleNotification(){
      this.notification = !this.notification
    },
    toggleMyAccount(){
      this.myAccount = !this.myAccount
    },
    goToNotificationsPim(){
      this.$router.push("/notifications-pim");
    },
    goToPimRequest(id, notificationId){
      this.model.notificationId = notificationId;
      this.$store.dispatch("notification/updateStatus", this.model)
      this.$store.dispatch("pimCampaign/readPimCampaign", {pimCampaignId: id})
      let myid = id
      this.$router.push(`/pim-requests/${myid}`)
    },
    logout() {
      localStorage.token = ""
      localStorage.setItem("token" , "")
      localStorage.clear();
      this.$store.dispatch("auth/validate", {}, ).then();
      this.$router.push("/");
    },
  },
  computed: {
    ...mapState(["auth", "message"]),
    connectedTiktok() {
      let connect = false;
      this.socialMediaDetails.forEach((social) => {
        if (social.socialMediaDetailsPlatform === "Tiktok") {
          connect = true;
        }
      });
      return connect;
    },
    connectedTwitter() {
      let connect = false;
      this.socialMediaDetails.forEach((social) => {
        if (social.socialMediaDetailsPlatform === "Twitter") {
          connect = true;
        }
      });
      return connect;
    },
    ...mapState({
      authorizeState: (state) => state.socialMedia.authorizeState,
      challengeCode: (state) => state.socialMedia.challengeCode,
      socialMediaDetails: (state) => state.socialMedia.socialMediaDetails,
      notifications:(state)=>state.notification.notifications
    }),
    activeMessage(){
      let activeMessage = [];
      this.message.message.data.forEach((message) => {
          if (message.messageStatus === "ACTIVE" && message.messageInfluencerName.toUpperCase() !== this.auth.userInfo.customerName.toUpperCase()) {
            activeMessage.push(message)
          }
      })
      return activeMessage
    },
    activeNotification(){
      let activeMessage = [];
      this.notifications.data.forEach((message) => {
        if (message.notificationStatus === "PENDING"){
          activeMessage.push(message)
        }
      })
      return activeMessage
    }
  },
  mounted() {
    this.userType = localStorage.getItem("userType");
    window.onclick = function (event) {
      console.log(event.target);
      if (
          event.target !== document.querySelector(".dropdown-list") ||
          event.target !== document.querySelector(".dm") ||
          event.target !== document.querySelector(".text-block")
      ) {
        let dropdownss = document.getElementsByClassName("dt");
        let ii;
        for (ii = 0; ii < dropdownss.length; ii++) {
          let openDropdowns = dropdownss[ii];
          if (openDropdowns.style.display === "block") {
            console.log("we are happyss");
            openDropdowns.style.display = "none";
          }
        }
      }
    };
  },
};
</script>

<style scoped>
.navbar {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  border-bottom: 1px solid #eaecf0;
  background-color: #fff;
  padding: 0;
}

.main-nav_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.logo-wrapper {
  width: 22.6875rem;
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 58.3125rem;
  padding-left: 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.w-nav-menu {
  position: relative;
  float: right;
}

.w-nav-button {
  position: relative;
  float: right;
  padding: 18px;
  font-size: 24px;
  display: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dropdown-topprofile {
  display: none;
  margin-bottom: 8px;
  padding: 12px 16px;
  border-bottom: 1px solid #eaecf0;
}

.left-navlink {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.right-navlink {
  width: auto;
  height: auto;
  background-color: transparent;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  align-items: center;
  display: flex;

}

.topnav-link.home {
  background-position: 16px 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.topnav-link {
  font-family: Figtree, sans-serif;
  margin-right: 0px;
  margin-left: 0px;
  padding: 8px 16px 8px 40px;
  border-radius: 100px;
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}
.w-nav-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: #222222;
  padding: 20px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.dropdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #eaf5f2;
}

.w-dropdown-toggle {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  cursor: pointer;
  padding-right: 40px;
  border: none !important;
}

.dropdown-toggle {
  padding: 0px;
  margin: 0px;
}

.text-block {
  color: #489e85;
  font-size: 14px;
  font-weight: 600;
}

.dropdown-list {
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
}
.w-dropdown-list {
  position: absolute;
  background: #dddddd;
  display: none;
  min-width: 100%;
}

.dropdown-topprofile {
  display: none;
  margin-bottom: 8px;
  padding: 12px 16px;
  border-bottom: 1px solid #eaecf0;
}

.profile-dropdown_link {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 44px;
  background-position: 16px 50%;
  background-size: auto;
  background-repeat: no-repeat;
  color: #101828;
  font-size: 12px;
  font-weight: 500;
}
.dropdown-list.w--open {
  left: auto;
  top: 110%;
  right: 0%;
  bottom: auto;
  width: 240px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #eaecf0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 12px 16px -4px rgb(16 24 40 / 6%);
}
.w-dropdown-list.w--open {
  display: block;
}

.link-block {
  display: none;
}

.dropdown-toggle::after {
  display: none !important;
}
.reveal {
  height: 3375.5px !important;
  opacity: 1 !important;
  visibility: visible !important;
}
.topnav-link.w--current {
  background-color: #489e85;
  color: #fff;
}

.w--current:hover {
  color: white !important;
}

.dropdown-list {
  left: auto;
  top: 110%;
  right: 0%;
  bottom: auto;
  width: 240px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #eaecf0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 12px 16px -4px rgb(16 24 40 / 6%);
}

.visible {
  display: block;
}

.dropdown:hover .dropdown-list {
  display: block;
}
.dropdown.w-dropdown.dp2 {
  position: relative;
  text-align: center;
  z-index: 900;
  width: 40px;
  height: 40px;
  background-color: #eaf5f2;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  display: flex;
}
.dropdown1_component.w-dropdown{
  display: inline-block;
  position: relative;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  z-index: 900;
}
.dropdown1_toggle.w-dropdown-toggle{
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}
.new-nav{
  z-index: 901;
}
.show{
  transform: translate3d(0px, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !important;
  transform-style: preserve-3d !important;
  display:block !important;
  position: absolute !important;
  opacity: 1 !important;
  min-width: 500px !important;
  background-color: #fff !important;
  border: 1px solid #eaecf0 !important;
  border-radius: 16px !important;
  margin-top: 0.5rem !important;
  padding: 0.5rem !important;
  right: 0 !important;
}
/*.dropdown1_dropdown-list-2.w-dropdown-list{*/
/*  position: absolute;*/
/*  background: #dddddd;*/
/*  display: none;*/
/*  min-width: 100%;*/
/*}*/
.link-5:hover{
  color:#489e85
}
.new-drop{
  z-index: 901;
}
.drop{

}
.dropdown1_dropdown-list.w-dropdown-list{
  top: 113%;
  bottom: auto;
  left: auto;
  right: 0%;
  transform: translate3d(0px, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 0;
}
.drop{
  transform: translate3d(0px, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !important;
  transform-style: preserve-3d !important;
  opacity: 1 !important;
}
.notification-dropdown-item:hover{
  color: #667085 ;
}

@media screen and (min-width: 969px){
  .w-nav-overlay nav{
    display: none !important;
  }
}

@media screen and (max-width: 991px){
  .reveal nav{
    display: block !important;
  }
  .dropdown-topprofile {
    padding-left: 0 !important;
    display: block !important;
  }
  .nav-menu{
    padding-left: 92px !important;
    width: 100% !important;
  }
  .pim-nav-menu{
    width: 100% !important;
    padding-top: 0 !important;
  }
  .topnav-link {
    color: #667085 !important;
    border-radius: 100px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 8px 16px 8px 40px !important;
    font-size: .875rem !important;
    font-weight: 500 !important;
  }
  .link-block{
    display: flex !important;
  }
  .right-navlink{
    display: block !important;
  }
}

</style>
