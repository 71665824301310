<template>
  <div class="modal">
    <div class="location-permission-modal">
      <div class="f-modal-header">
        <p id="demo1" style="display: none"> </p>
        <div class="f-sub-heading-regular-3"><strong class="bold-text">Get the Best Experience with Location</strong></div>
        <a href="#" class="f-modal-close-link-2 w-inline-block">
          <div class="f-icon-regular-4 w-embed"><svg width="420" height="420" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0002 10.586L16.9502 5.63599L18.3642 7.04999L13.4142 12L18.3642 16.95L16.9502 18.364L12.0002 13.414L7.05023 18.364L5.63623 16.95L10.5862 12L5.63623 7.04999L7.05023 5.63599L12.0002 10.586Z" fill="currentColor"></path>
          </svg>
          </div>
        </a>
      </div>
      <div class="f-modal-line-2"></div>
      <div class="f-modal-image"><img src="../../assets/images/6407d32f98ca0fe3312db422_awareness_02-01.png" loading="lazy" sizes="100vw" srcset="../../assets/images/6407d32f98ca0fe3312db422_awareness_02-01-p-500.png 500w, ../../assets/images/6407d32f98ca0fe3312db422_awareness_02-01-p-800.png 800w, ../../assets/images/6407d32f98ca0fe3312db422_awareness_02-01-p-1080.png 1080w, ../../assets/images/6407d32f98ca0fe3312db422_awareness_02-01.png 1196w" alt="" class="f-image-cover"></div>
      <p class="f-paragraph-regular">We want to provide you with the best possible experience tailored to your location. To make that happen, we kindly request your permission to access your location information.</p>
      <div class="f-modal-line-2"></div>
      <div class="f-modal-wrapper-right-2">
        <a href="#" class="f-button-secondary-2 w-button" @click="close">Cancel</a>
        <a href="#" class="f-button-action w-button" @click="getLocation">Grant permission</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LocationModal",
  methods:{
    getLocation() {
      let x = document.getElementById("demo");
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition);
      } else {
        document.querySelector(".modal").style.display = "flex";
        x.innerHTML = "Geolocation is not supported by this browser.";
      }
    },
    showPosition(position) {
      document.cookie = `latitude=${position.coords.latitude}`;
      document.cookie = `longitude=${position.coords.longitude}`;
    },
    close(){
      // console.log("noneclass")
      document.querySelector(".modal").style.display = "none"
    }
  },
  mounted() {
    this.getLocation();
  }
};
</script>

<style scoped>

</style>