// import influencerRequest fromm "@/model/request/InfluencerRequest";
import InfluencerService from "@/service/InfluencerService";
// import influencerResponse from "@/model/response/InfluencerResponse";
// import router from "@/router";
// import store from "@/store/store";
import InfluencerResponse from "@/model/response/InfluencerResponse";

export const state={
    influencers:InfluencerResponse.prototype.readInfluencer(),
    loading:false

}
export const getters = {
    getLoading: (state) => {
        return state.loading;
    },
};

export const mutations = {
    updateLoading(state, payload) {
        state.loading = payload;
    },
    updateInfluencer(state, payload){
        state.influencers = payload;
    }
}

export const actions ={
    readInfluencer: ({ commit }, payload) => {
        //TODO USER LOADING
        commit("updateLoading", true);
        return InfluencerService.callReadInfluencerApi(payload)
            .then((response) => {
                commit("updateLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updateInfluencer", responseData);
                } else {
                    // swal("Error", responseData.responseMessage, "error");
                }
            })
            .catch((err) => {
                commit("updateLoading", false);
                console.log(err);
            });
    }
}
