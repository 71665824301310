class BillRequest {

    createAccount() {
        return {
            accountName : null,
            accountCustomerId :null,
            accountCountry: null
        }
    }

}
export default BillRequest;