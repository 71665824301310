import MessagingService from "@/service/MessagingService";
import swal from "sweetalert";
// import store from "@/store/store";
import MessagingResponse from "@/model/response/MessagingResponse";
import BaseNotification from "@/components/baseNotification/BaseNotification";

export const state={
    message:MessagingResponse.prototype.readByCustomerId(),
    messages:MessagingResponse.prototype.readByGroupId(),
    loading:false
}

export const getters={
    getLoading:(state)=>{
        return state.loading;
    }
};

export const mutations={
    updateLoading(state, payload) {
        state.loading = payload;
    },
    updateMessage(state, payload){
        state.message = payload;
    },
    updateMessages(state, payload){
        state.messages = payload;
    }
}

export const actions= {

    create:({commit}, payload)=>{
        commit("updateLoading", true)
        return MessagingService.callCreateApi(payload)
            .then((response)=> {
                commit("updateLoading", false);
                let responseData = response.data;
                // console.log(response)
                if (responseData.responseCode === "00") {
                    commit("updateLoading", false);
                    return responseData
                } else {
                    commit("updateLoading", false)
                    BaseNotification.fireToast("error", responseData.responseMessage);
                    return responseData
                }
            }).catch((error) => {
                commit("updateLoading", false)
                swal("Error", error, "error");
            });
    },
    update:({commit}, payload)=>{
        commit("updateLoading", true)
        return MessagingService.callUpdateApi(payload)
            .then((response)=> {
                commit("updateLoading", false);
                let responseData = response.data;
                // console.log(response)
                if (responseData.responseCode === "00") {
                    commit("updateLoading", false);
                    BaseNotification.fireToast("success", responseData.responseMessage);

                    return responseData
                } else {
                    commit("updateLoading", false)
                    BaseNotification.fireToast("error", responseData.responseMessage);
                    return responseData
                }
            }).catch((error) => {
                commit("updateLoading", false)
                swal("Error", error, "error");
            });
    },
    readByCustomerId:({commit})=>{
        commit("updateLoading", true);
        return MessagingService.callReadByCustomerIdApi()
            .then((response)=> {
                commit("updateLoading", false);
                let responseData = response.data;
                // console.log(response)
                if (responseData.responseCode === "00") {
                    commit("updateMessage", responseData);
                    return responseData
                } else {
                    return responseData
                    // swal("Error", responseData.responseMessage, "error");
                }
            })
            .catch((err) => {
                commit("updateLoading", false);
                console.log(err);
            });
    },
    readByGroupId:({commit},payload)=>{
        commit("updateLoading", true);
        return MessagingService.callReadByGroupIdApi(payload)
            .then((response)=> {
                commit("updateLoading", false);
                let responseData = response.data;
                // console.log(response)
                if (responseData.responseCode === "00") {
                    commit("updateMessages", responseData);
                    return responseData
                } else {
                    return responseData
                    // swal("Error", responseData.responseMessage, "error");
                }
            })
            .catch((err) => {
                commit("updateLoading", false);
                console.log(err);
            });
    }

}