import SettingsService from "@/service/SettingsService";
import store from "@/store/store";
import swal from "sweetalert";

export const state = {
  notificationSettings: {  },
  settingsLoading: false,
  inviteId: {}
};
export const getters = {};
export const mutations = {
  updateNotificationSettings: (state, payload) => {
    state.notificationSettings = payload;
  },
  updateSettingsLoading: (state, payload) => {
    state.settingsLoading = payload;
  },
  updateInviteId: (state, payload) => {
    state.inviteId = payload;
  },
};
export const actions = {
  updateNotificationSettings: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateSettingsLoading", true);
    return SettingsService.callReadNotificationSettingsApi(payload)
      .then((response) => {
        commit("updateSettingsLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateNotificationSettings", responseData);
        } else {
          //swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateSettingsLoading", false);
        console.log(err);
      });
  },
  updateGeneralInvite: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateSettingsLoading", true);
    return SettingsService.callGenerateInvite(payload)
      .then((response) => {
        commit("updateSettingsLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateInviteId", responseData);
          return responseData;
        } else {
          //swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateSettingsLoading", false);
        console.log(err);
      });
  },
  updatePicture: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateSettingsLoading", true);
    return SettingsService.callUpdatePicture(payload)
      .then((response) => {
        commit("updateSettingsLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success")
          store.dispatch("auth/validate", {}, ).then();

          // commit("updateNotificationSettings", responseData);=> );
          return responseData;
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((err) => {
        commit("updateSettingsLoading", false);
        console.log(err);
      });
  },
    updateBio: ({ commit }, payload) => {
        //TODO USER LOADING
        commit("updateSettingsLoading", true);
        return SettingsService.callUpdateBio(payload)
            .then((response) => {
                commit("updateSettingsLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    swal("Success", responseData.responseMessage, "success")
                    store.dispatch("auth/validate", {}, ).then();

                    // commit("updateNotificationSettings", responseData);=> );
                    return responseData;
                } else {
                    store.commit("updateMessage", {
                        type: "error",
                        title: "Error",
                        body: responseData.responseMessage,
                    });
                }
            })
            .catch((err) => {
                commit("updateSettingsLoading", false);
                console.log(err);
            });
    },
  updateUnsubcribe: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateSettingsLoading", true);
    return SettingsService.callUnsubscribe(payload)
      .then((response) => {
        commit("updateSettingsLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          // commit("updateNotificationSettings", responseData);
          return responseData;
        } else {
          //swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateSettingsLoading", false);
        console.log(err);
      });
  },
  editNotificationSettings: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateSettingsLoading", true);
    return SettingsService.callUpdateNotificationSettingsApi(payload)
      .then((response) => {
        commit("updateSettingsLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateNotificationSettings", responseData);
        }
      })
      .catch((err) => {
        commit("updateSettingsLoading", false);
        console.log(err);
      });
  },
};
