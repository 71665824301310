<template>
  <div>
    <dashboard-new/>
    <div class="section" style="display: block; font-family: Figtree, sans-serif; background: #fff">
      <div class="dashboard-wrapper">
        <div class="breadcrumb-wrapper">
          <a href="#" aria-current="page" class="back-btn w--current" @click="goToMall"><img src="../../../public/images/chevron-right_1.svg" loading="lazy" alt="" class="image-5">
            <div class="text-block-6">Back to Malls</div>
          </a>
        </div>
        <div class="mall-details-content">
          <div class="mall__profile-wrapper">
            <div class="mall-profile-content">
              <div class="mall-profile">
                <img :src="organisation.organisationLogo" loading="lazy" alt="" class="mall-profile-image" v-if="organisation.organisationLogo.includes('apvertise')">
                <img src="../../../public/images/Strike-STRK.svg" loading="lazy" alt="" class="mall-profile-image" v-else>
                <div class="mall-profile-name" style="text-transform: capitalize">{{ organisation.organisationName }}</div>
              </div>
              <p class="mall-profile-desc">{{ organisation.organisationDescription }}</p>
              <div class="mall-profile-tag-group">
                <div class="mall-profile-tag"><img src="../../../public/images/email.svg" loading="lazy" alt="" class="mall-tag-icon">
                  <div>{{ organisation.organisationEmail }} </div>
                </div>
                <div class="mall-profile-tag"><img src="../../../public/images/phone.svg" loading="lazy" alt="" class="mall-tag-icon">
                  <div>{{ organisation.organisationPhone }}</div>
                </div>
              </div>
              <div class="div-block-3" style="margin: 0">
                <a data-w-id="66491ffc-800c-a6d8-8a7f-70392d09e7a5" @click="showModal = !showModal" href="#" class="button w-button" style="width: 160px;">Switch company</a>
                <a data-w-id="74db2e5d-3cc0-5eab-3c34-114d28850d4e" class="button is-secondary w-button" style="width: 160px;" @click="goToEditMall">Edit Mall</a>
              </div>
            </div>
          </div>
          <div class="related__products">
            <div class="related-campaign-header">
              <h5>All Products</h5>
              <div class="right-block">
                <a @click="goToProduct" style="color: #489e85;">+ Add new product</a>
              </div>
            </div>
            <div class="related-malls-grid" v-if="products.length > 0">
              <div class="mall-card" v-for="productObj in products" :key="productObj.productId">
                <a id="w-node-cf26d2fd-1317-f2bb-9957-49c8016092ec-70d5e2e7" href="#" class="mall__image-card grid" @click="seeProduct(productObj.productId)">
                  <div class="mall-image-wrapper" style="height: 254px">
                    <img src="../../../public/images/view-product.png" class="opacity"/><img :src="productObj.productImage.split(',')[0]" loading="lazy"  alt="" class="mall-img" style="height: 100%;"></div>
                </a>
                <div class="mall-card-text">
                  <div class="card-product-name">{{ productObj.productName }}</div>
                  <div class="card-product-price">₦ {{productObj.productLocalCurrencyPrice | formatAmount}}</div>
                </div>
              </div>
            </div>
            <div class="empty-state"  v-else><img src="../../../public/images/emptymall.png" loading="lazy" width="150" alt="">
              <div class="empty-state-content">
                <div class="empty-state__text">
                  <h5 class="empty-mall__h">No Product available</h5>
                  <p class="empty-state__p">We’re so glad you’re ready to join Apvertise.<br>Let’s start by creating your account.</p>
                </div>
                <!--                <a href="#" class="button is-secondary small" @click="goToProduct">-->
                <!--                  <div class="w-embed"><svg width="15" height="15" viewbox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                <!--                    <path d="M7.49999 1.16699V12.8337M1.66666 7.00033H13.3333" stroke="currentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>-->
                <!--                  </svg></div>-->
                <!--                  <div>Create Prodcut</div>-->
                <!--                </a>-->
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <switch-company-modal :show="showModal" />
    <order-modal :show="showOrderModal"/>
  </div>

</template>

<script>
import { mapState } from "vuex";
import OrganisationRequest from "@/model/request/OrganisationRequest";
import ProductRequest from "@/model/request/ProductRequest";
import productRequest from "@/model/request/ProductRequest";
import DashboardNew from "@/components/menu/DashboardNew.vue";
import SwitchCompanyModal from "@/components/modal/SwitchCompanyModal.vue";
import OrderModal from "@/components/modal/OrderModal.vue";
export default {
  name: "MallRegistered",
  data() {
    return {
      showOrderModal: false,
      showModal: false,
      model: OrganisationRequest.prototype.organisationReadSingleRequest(),
      allProducts: ProductRequest.prototype.productReadByOrgRequest(),
      singleProduct: productRequest.productReadByProductIdRequest,
      image:'https://i.insider.com/61fc2855b0ec040018d0baa4?width=1136&format=jpeg'

    };
  },
  components: { OrderModal, SwitchCompanyModal, DashboardNew },
  computed:{
    ...mapState(["organisation", "auth", "product"]),
    ...mapState({
      organisation:state => state.organisation.organisation,
      organisations:state => state.organisation.organisations[0].organisationId,
      products:state => state.product.product.data
    }),
    currentOrganisation(){
      return this.$store.getters.getCurrentOrganization
    }
  },
  methods:{
    goToProduct(){
      localStorage.setItem("mall", true)
      this.$router.push("/add-products")
    },
    goToMall(){
      this.$router.push("/mall-all")
    },
    goToEditMall(){
      this.$router.push("/edit-mall")
    },
    seeProduct(productObj) {
      // this.$store.commit("product/updateProductDetails", productObj);
      localStorage.productId = productObj
      this.singleProduct.productId = localStorage.productId
      this.$store.dispatch("updateSingleProduct", this.singleProduct)
      this.$router.push("/product-customer-view");
      console.log(productObj);
    },
  },
  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "https://play-apvertise.com/js/webflow.js");
    plugin.async = true;
    document.head.appendChild(plugin);
    this.model.organisationId = localStorage.getItem('organisationId');
    this.$store.dispatch("organisation/updateOrganisation", this.model);
    this.allProducts.productOrganisationId = localStorage.getItem('organisationId');
    this.$store.dispatch("product/updateProduct", this.allProducts);
    console.log(this.allProducts)
    this.currentOrganisation();
    console.log(localStorage.getItem('organisationId'));
  },

  created() {
    const script = document.createElement("script");
    script.src = "https://play-apvertise.com/js/webflow.js";
  },
};
</script>

<style scoped>
.section,
.dashboard-wrapper{
  height: 100%;
}

h1,h2,h3,h4,h5,h6,p{
  margin: 0;
}

.main{
  padding: 0;
}

.btn{
  color: #5DC9A0 !important;
  border: 1px solid #5DC9A0 !important;
  margin-bottom: 0 !important;
}
.card{
  border-radius: 7px;
  width: 207px;
  height: auto !important;
}
.price{
  margin: 0;
}
.card-body{
  margin: 0;
}

.img{
  border-top-left-radius: 7px;
  border-top-right-radius:7px ;
}
.view-product-btn{
  margin: 0;
}
.bbb{
  background-size: cover;
  background-repeat: no-repeat;
}

.mall__profile-wrapper {
  padding-top: 24px;
  padding-bottom: 24px;
}

.mall-profile-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 506px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.mall-profile {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 216px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.mall-profile-image {
  width: 72px;
  height: 72px;
  border-radius: 50rem;
  -o-object-fit: cover;
  object-fit: cover;
}

.mall-profile-name {
  font-family: Faktum, sans-serif;
  color: #1d2939;
  font-size: 1.125rem;
  line-height: 1.3333333333333333;
  font-weight: 600;
  text-align: center;
}

.mall-profile-desc {
  color: #475467;
  font-size: 0.875rem;
  line-height: 1.7142857142857142;
  text-align: center;
}

.mall-profile-tag-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.mall-profile-tag {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 7px 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border-radius: 50rem;
  background-color: #eaf5f2;
  color: #489e85;
  font-size: 0.875rem;
  line-height: 1.1428571428571428;
}

.related__products {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.related-campaign-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

h5 {
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-family: Faktum, sans-serif;
  color: #101828;
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 700;
}

.dropdown-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  padding-right: 1rem;
  padding-left: 1rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #eaecf0;
  border-radius: 100px;
  margin: 0;
  width: auto;

}

.dropdown-toggle-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  margin: 0;
}


.text-block-3 {
  font-family: Figtree, sans-serif;
  color: #475467;
  font-size: 0.875rem;
  font-weight: 500;
}


.related-malls-grid {
  display: -ms-grid;
  display: grid;
  margin-bottom: 0rem;
  padding-bottom: 2rem;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.mall-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.mall__image-card.grid {
  height: 254px;
}
.mall__image-card {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 349px;
  border-radius: 16px;
  margin: 0;
}

.mall-card-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.card-product-name {
  font-family: Faktum, sans-serif;
  color: #1d2939;
  line-height: 1.09375;
  font-weight: 600;
}

.card-product-price {
  color: #1d2939;
  line-height: 1.09375;
  font-weight: 600;
}

.mall-image-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #101828;
  -o-object-fit: fill;
  object-fit: fill;
  border: none;
}

.opacity{
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in;
  position: absolute;
  width: 100%;
}

.mall__image-card:hover .opacity{
  opacity: 1;
  visibility: visible;
}

.empty-state {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 3rem;
  padding-bottom: 6rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: auto;
}

.empty-state-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
}

.empty-state__text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-column-gap: 8px;
  text-align: center !important;
  grid-row-gap: 8px;
}

.button.is-secondary.small {
  padding: 0.625rem 1.125rem;
}
.button.small {
  padding: 0.625rem 1.125rem;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}
.button.is-secondary {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #d0d5dd;
  background-color: transparent;
  color: #344054;
  text-decoration: none;
}
.button {
  width: auto;
  color: #fff !important;
  text-align: center;
  background-color: #489e85;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
  font-size: .875rem;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}

.button.is-secondary {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  color: #344054 !important;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #d0d5dd;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

</style>
