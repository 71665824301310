import DashboardNewBusiness from "@/components/onboarding/business/DashboardNewBusiness.vue";
import BusinessOnboardingForm from "@/components/onboarding/business/BusinessOnboardingForm.vue";

export default [
  {
    path: "/onboarding/new-business",
    name: "DashboardNewBusiness",
    meta: { layout: "", authRequired: false },
    component: DashboardNewBusiness,
  },
  {
    path: "/onboarding/business-form",
    name: "DashboardNewBusiness",
    meta: { layout: "", authRequired: false },
    component: BusinessOnboardingForm,
  }
  ]