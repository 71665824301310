<template>
  <div style="background: #fff; padding-top: 90px">
    <edit-campaign-form-one v-if="campaign.screen === 'one'"/>
    <edit-campaign-form-two v-else-if="campaign.screen === 'two'"/>
    <edit-campaign-form-three v-else-if="campaign.screen === 'three'"/>
    <campaign-edit-success v-else/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EditCampaignFormOne from "@/components/form/EditCampaignFormOne";
import EditCampaignFormTwo from "@/components/form/EditCampaignFormTwo";
import EditCampaignFormThree from "@/components/form/EditCampaignFormThree";
import CampaignEditSuccess from "@/views/campaign/CampaignEditSuccess";
export default {
  name: "CampaignEdit",
  components: {
    CampaignEditSuccess,
    EditCampaignFormThree,
    EditCampaignFormTwo,
    EditCampaignFormOne
  },
  computed:{
    ...mapState(["campaign"])
  },
  created() {
    this.$store.commit("campaign/updateScreen", "one")
  },
  mounted() {

  }
};
</script>

<style scoped>

</style>