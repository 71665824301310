import axios from "axios";
import router from "@/router";
// import store from "../store/store";
// import router from "@/router";
// import Swal from "sweetalert2";

const apiClient = axios.create({
  baseURL: window.__env.api.baseUrl,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
// const apiClient2 = axios.create({
//   baseURL: window.__env.api.baseUrl2,
//   withCredentials: false,
//   headers: {
//     Accept: "application/json",
//     "Content-Type": "application/json",
//   },
// });

apiClient.interceptors.request.use((config) => {
  // console.log("Axios Interceptor Config ==>", config);
  const token = localStorage.token;
  const tok = localStorage.token;
  // console.log("INTERCEPTOR TOKEN", token)
  // console.log("INTERCEPTOR  TOK ==>", tok)
  config.headers.Authorization = token == null ? tok : token;
  return config;
});

apiClient.interceptors.response.use(response => {
  if (response != null) {
    if (response.data != null) {
      if (response.data.responseCode === '115') {
        router.push("/logon")
      }
    }
  }
  return response
});



// apiClient2.interceptors.request.use((config) => {
//   // console.log("Axios Interceptor Config ==>", config);
//   //const token = store.getters.getToken;
//   //const tok = store.getters["auth/getTok"];
//   // console.log("INTERCEPTOR TOKEN", token)
//   // console.log("INTERCEPTOR  TOK ==>", tok)
//   //config.headers.Authorization = token == null ? tok : token;
//   return config;
// });

const s3ApiClient = axios.create({
  baseURL: window.__env.api.uploadS3BaseUrl,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

s3ApiClient.interceptors.request.use((config) => {
  return config;
});

export default {
  apiClient: apiClient,
  // apiClient2: apiClient2,
  s3ApiClient: s3ApiClient,
};
