// import router from "@/router";
import AccountPayoutService from "@/service/AccountPayoutService";
// import AccountPayoutRequest from "@/model/request/AccountPayoutRequest";
import AccountPayoutResponse from "@/model/response/AccountPayoutResponse";
// import swal from "sweetalert";
// import router from "@/router";
import store from "@/store/store";

export const state = {
  accountPayout: AccountPayoutResponse.prototype.read(),
  accountPayouts: AccountPayoutResponse.prototype.read(),
  accountPayoutLoading: false
};
export const getters = {};
export const mutations = {
  updateAccountPayout: (state, payload) => {
    state.accountPayout = payload;
  },
  updateAccountPayouts: (state, payload) => {
    state.accountPayouts = payload;
  },
  updateAccountPayoutLoading: (state, payload) => {
    state.accountPayoutLoading = payload;
  },
};
export const actions = {
  updateAccountPayout: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateAccountPayoutLoading", true);
    return AccountPayoutService.callReadSingleByUserAccountPayoutApi(payload)
      .then((response) => {
        commit("updateAccountPayoutLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateAccountPayout", responseData);
        } else {
          //swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateAccountPayoutLoading", false);
        console.log(err);
      });
  },

  createAccountPayout: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateAccountPayoutLoading", true);
    return AccountPayoutService.callCreateAccountPayoutApi(payload)
      .then((response) => {
        commit("updateAccountPayoutLoading", false);
        let responseData = response.data;
        console.log(response)
        if (responseData.responseCode === "00") {
          return responseData
        } else {
          store.commit("updateMessage",{
            type: "error",
            title: "Error",
            body: responseData.responseMessage
          });
          return responseData
        }
      })
      .catch((err) => {
        commit("updateAccountPayoutLoading", false);
        console.log(err);
      });
  },
};
