<template>
  <div style="background-color: #FFFFFF">
    <div class="influencer__nav">
      <div class="container-large">
        <div class="nav__container">
          <div class="title__nav"><img src="../../../assets/images/apvertise-icon.svg" loading="lazy" alt="" class="logo__icon"></div>
          <div class="nav__menu">
            <a class="menu__item" :class="{ 'completed': stage > 1, 'w--current': stage === 1 }"
               @click="stage = 1">
              <div class="id" :class="{ 'completed': stage > 1 , 'is-active': stage === 1 }"><img src="../../../assets/images/checkwhite.svg" loading="lazy" alt="" v-if="stage > 1"><span v-if="stage === 1">1</span></div>
              <div class="nav__item-title" :class="{ 'is-active': stage === 1 }">Business Details</div>
            </a>
<!--            <a class="menu__item" :class="{ 'completed': stage > 2, 'w&#45;&#45;current': stage === 2 }">-->
<!--              <div class="id" :class="{ 'completed': stage > 2, 'is-active': stage === 2 }">-->
<!--                <img src="../../../assets/images/checkwhite.svg" loading="lazy" alt="" v-if="stage > 2">-->
<!--                <div v-if="stage === 2 || stage === 1">2</div>-->
<!--              </div>-->
<!--              <div class="nav__item-title" :class="{ 'is-active': stage === 1 }">Industry &amp; Interest</div>-->
<!--            </a>-->
            <a class="menu__item" :class="{ 'completed': stage > 2, 'w--current': stage === 2 }">
              <div class="id" :class="{ 'completed': stage > 2, 'is-active': stage === 2 }">
                <img src="../../../assets/images/checkwhite.svg" loading="lazy" alt="" v-if="stage > 2">
                <div>2</div>
              </div>
              <div class="nav__item-title" :class="{ 'is-active': stage === 1 }">Social Presence</div>
            </a>
          </div>
          <a href="#" class="close__btn" @click="goToDashboard"><img src="../../../assets/images/x.svg" loading="lazy" alt="" class="close__icon"></a>
        </div>
      </div>
    </div>
    <div class="section" style="display: block; margin: 0" v-if="stage === 1">
      <div class="main">
        <div class="inf__onboarding_content">
          <div class="inf__onboarding_text">
            <h3 class="inf__onboarding-h">Tell Us About Your Business</h3>
            <p class="inf__onboarding-p">We’re so glad you’re ready to join Apvertise. Let’s start by creating your account.</p>
          </div>
          <div class="form__block w-form">
            <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form" @submit.prevent="createCompany; stage++">
              <div class="form__item"><label for="First-Name-2" class="form__label large">What is your business name?</label>
                <div class="input"><input style="width: 100%;" type="text" class="form__input" maxlength="256" name="BusinessName" v-model="organisationModel.organisationName" data-name="BusinessName" placeholder="Enter business name" id="BusinessName" required><img src="../../../assets/images/lock.svg" loading="lazy" alt="" class="form__input-icon"></div>
              </div>
              <div class="form__item">
                <label for="name-3" class="form__label large">Company Type</label
                ><select
                id="field-4"
                name="field-4"
                style="text-transform: capitalize"
                required
                data-name="Field 4"
                class="form__input select w-select"
                v-model="organisationModel.organisationType"
              >
                <option value="">Select one...</option>
                <option v-for="category in organisation.organisationTypes.data" v-bind:key="category.value" :value="category.value">{{ category.label }}</option>
              </select>
              </div>
              <div class="form__item"><label for="field" class="form__label large">Describe your business goals</label><textarea required placeholder="Enter a description..." maxlength="5000" id="field" name="field" v-model="organisationModel.organisationDescription" data-name="Field" class="form__input rounded" style="height: 100px;"></textarea></div>
              <div class="form__item"><label for="field" class="form__label large">Company Logo</label>
              <label class="upload-images-frame _1" v-if="url === ''">
                <input
                  style="display: none"
                  type="file"
                  class="input-file"
                  id="organisation"
                  required
                  name="files"
                  accept="image/*"
                  ref="file"
                  @change="uploadFile('organisation')"
                />
                <a class="addfiles_btn">
                  <div class="w-embed">
                    <svg
                      width="16"
                      height="16"
                      viewbox="0 0 16 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.49967 3.3335V12.6668M3.83301 8.00016H13.1663"
                        stroke="currentColor"
                        stroke-width="1.33333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <div>Add image</div>
                </a>
                <!--              <p class="paragraph-2">Click to upload images.</p>-->
              </label>
              <div class="upload-images-frame _1" v-else style="height: 142px; padding: 0">
                <img :src="url" style="height: 100%;">
              </div>
              </div>
              <input class="button" type="submit" value="Proceed" style="color: #FFFFFF !important; outline: none"/>
            </form>
          </div>

        </div>
      </div>
    </div>
    <div class="section" style="display: block; margin: 0" v-if="stage === 4">
      <div class="main">
        <div class="inf__onboarding_content">
          <div class="inf__onboarding_text">
            <h3 class="inf__onboarding-h">Select Industry &amp; Interest</h3>
            <p class="inf__onboarding-p">We’re so glad you’re ready to join Apvertise. Let’s start by creating your account.</p>
          </div>
          <div class="interest__group">
            <a
              data-w-id="9594f00b-96dd-6802-fda6-b710dae4da17"
              href="#"
              v-for="(category, index) in categories"
              :key="index"
              :class="
                  myCategory.includes(category) && selectedIndex.includes(index)
                    ? 'interest__item is-selected'
                    : 'interest__item'
                "
              @click="addCategory(category, index)"
            >
              <div>{{ category }}</div>
              <img
                src="../../../assets/images/check.svg"
                loading="lazy"
                alt=""
                :class="
                    myCategory.includes(category) &&
                    selectedIndex.includes(index)
                      ? 'tick is-selected'
                      : 'tick'
                  "
              />
            </a>
          </div>
          <a href="#" class="btn" @click="stage++">
            <div>Proceed</div>
          </a>
        </div>
      </div>
    </div>
    <div
      class="section"
      style="display: block; margin: 0"
      v-if="stage === 2"
    >
      <div class="main">
        <div class="inf__onboarding_content">
          <div class="inf__onboarding_text">
            <h3 class="inf__onboarding-h">Connect your Social profiles</h3>
            <p class="inf__onboarding-p">
              We’re so glad you’re ready to join Apvertise. Let’s start by
              creating your account.
            </p>
          </div>
          <div class="social__group">
            <div class="social__link">
              <div class="social">
                <img
                  src="../../../assets/images/instagram.svg"
                  loading="lazy"
                  alt=""
                  class="social__icon"
                />
                <div>Instagram</div>
              </div>
              <a @click="goToInstagram" class="connect__btn" v-if="!connectedInstagram">Connect</a>
              <a class="connect__btn" style="background-color: #489e85; color: #FFFFFF" v-else>Connected</a>
            </div>
            <div class="social__link">
              <div class="social">
                <img
                  src="../../../assets/images/twitter1.svg"
                  loading="lazy"
                  alt=""
                  class="social__icon"
                />
                <div>Twitter</div>
              </div>
              <a @click="goToTwitter" class="connect__btn" v-if="!connectedTwitter">Connect</a>
              <a class="connect__btn" style="background-color: #489e85; color: #FFFFFF" v-else>Connected</a>
            </div>
            <div class="social__link">
              <div class="social">
                <img
                  src="../../../assets/images/tiktok.svg"
                  loading="lazy"
                  alt=""
                  class="social__icon"
                />
                <div>TikTok</div>
              </div>
              <a @click="goToTikTok"  class="connect__btn" v-if="!connectedTiktok">Connect</a>
              <a class="connect__btn" style="background-color: #489e85; color: #FFFFFF" v-else>Connected</a>
            </div>
          </div>
          <p v-if="showAddSocial" style="color:red; margin-top:0;">Connect at least one of the above social media accounts</p>
          <a href="#" class="btn" @click="goToComplete">
            <div>Proceed</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocialMediaRequest from "@/model/request/SocialMediaRequest";
import { mapState } from "vuex";
import OrganisationRequest from "@/model/request/OrganisationRequest";

export default {
  name: "BusinessOnboardingForm",
  data(){
    return{
      stage: 1,
      myCategory: [],
      categories: ["Agriculture", "Arts & Entertainment", "Education", "Fashion & Lifestyle", "Finance", "Fitness", "Gadgets", "Health", "Investment", "SME", "Technology", "Telecommunication", "Travel & Tourism", "Others"],
      selectedIndex: [],
      enrollModel: SocialMediaRequest.enroll,
      twitterModel: SocialMediaRequest.addTwitter,
      tiktokModel: SocialMediaRequest.addTiktok,
      redirectURL: "https://app.apvertise.com/onboarding/profiling",
      tiktokRedirectURL: "https://app.apvertise.com/onboarding/profiling",
      twitterSelected: false,
      tiktokSelected: false,
      twitterActive: false,
      tiktokActive: false,
      organisationModel: OrganisationRequest.prototype.organisationCreateRequest,
      showAddSocial: false,
      url: ""
    }
  },
  computed: {
    connectedTiktok(){
      let connect = false;
      this.socialMediaDetails.forEach((social) => {
        if (social.socialMediaDetailsPlatform === "Tiktok"){
          connect = true
        }
      })
      return connect
    },
    connectedTwitter(){
      let connect = false;
      this.socialMediaDetails.forEach((social) => {
        if (social.socialMediaDetailsPlatform === "Twitter"){
          connect = true
        }
      })
      return connect
    },
    connectedInstagram(){
      let connect = false;
      this.socialMediaDetails.forEach((social) => {
        if (social.socialMediaDetailsPlatform === "Instagram"){
          connect = true
        }
      })
      return connect
    },
    generateStateParam() {
      let result = "";
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < 4; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      this.setLastState(result);
      return result;
    },
    generateChallengeCodeParam() {
      let result = "";
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < 6; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      this.setLastCodeChallengeCode(result);
      return result;
    },
    twitterLink() {
      return `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=Zl9vZ050MUl5dDB0R1NkWjlMQVQ6MTpjaQ&redirect_uri=${this.redirectURL}/onboarding/profiling&scope=users.read%20tweet.read%20offline.access&state=ST02&code_challenge=ST03s5&code_challenge_method=plain`;
    },
    tiktokLink() {
      return `https://www.tiktok.com/auth/authorize?client_key=awuxk8676d36nim9&response_type=code&scope=user.info.basic&redirect_uri=${this.tiktokRedirectURL}&state=ST02`;
      // return `https://www.tiktok.com/auth/authorize/?client_key=awuxk8676d36nim9&scope=user.info.basic&response_type=code&redirect_uri=https://apvertise.com/&state=generate-a-random-string`
    },
    instagramLink() {
      return `https://www.facebook.com/v16.0/dialog/oauth?client_id=246578654416945&redirect_uri=${this.redirectURL}&state=${this.generateStateParam}&response_type=code&scope=instagram_basic,pages_show_list,instagram_manage_comments,instagram_manage_comments&auth_type=rerequest`;
      // return `https://www.tiktok.com/auth/authorize/?client_key=awuxk8676d36nim9&scope=user.info.basic&response_type=code&redirect_uri=https://apvertise.com/&state=generate-a-random-string`
    },
    ...mapState(["auth", "sbucket", "organisation", "campaign" ]),
    ...mapState({
      authorizeState: (state) => state.socialMedia.authorizeState,
      challengeCode: (state) => state.socialMedia.challengeCode,
      socialMediaDetails: (state) => state.socialMedia.socialMediaDetails
    }),
  },
  methods:{
    uploadFile(id) {
      let input = document.getElementById(id)
      console.log(input)
      let files = input.files;
      console.log(files)
      this.fileName = files[0].name;
      let size = files[0].size / 1000000;
      console.log(size);
      const reader = new FileReader();
      try {
        reader.onload = (e) => {
          this.base64 = e.target.result;
          this.uploadOfficerImage();
        };
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);
      } catch (e) {
        console.warn(e.message);
      }
    },
    async uploadOfficerImage() {
      this.isLoader = true;
      this.uploadmodel.username = `${
        this.auth.userInfo.customerFirstName + this.auth.userInfo.customerLastName
      }_${Date.now()}`;
      this.uploadmodel.base64 = this.base64;
      await this.$store.dispatch(
        "sbucket/uploadEncodedFile",
        this.uploadmodel,
        { root: true }
      );
      this.url = this.sbucket.s3bucketResponse.url;
      this.organisationModel.organisationLogo = this.sbucket.s3bucketResponse.url;
      if (this.url1 === ""){
        this.url1 = this.sbucket.s3bucketResponse.url;
      }
      else if (this.url2 === ""){
        this.url2 = this.sbucket.s3bucketResponse.url;
      }
      else {
        this.url3 = this.sbucket.s3bucketResponse.url;
      }
      console.log(this.url)
      this.isLoader = false;
    },
    async createCompany() {
      this.isLoader = true;
      this.organisationModel.organisationLogo = this.url;
      this.organisationModel.organisationPhone = this.auth.userInfo.customerPhoneNumber;
      this.organisationModel.organisationEmail = this.auth.userInfo.customerEmail;
      this.organisationModel.organisationCustomerId = this.auth.userInfo.customerId;
      await this.$store.dispatch("organisation/createOrganisation", this.organisationModel);
      await this.$store.dispatch("organisation/updateOrganisations", { customerId: this.auth.userInfo.customerId});
      this.close(".add-business-modal")
      this.isLoader = false;
    },
    addCategory(category, index) {
      if (!this.myCategory.includes(category)) {
        this.myCategory.push(category);
        if (!this.selectedIndex.includes(index)) {
          this.selectedIndex.push(index);
        }
      } else {
        this.myCategory = this.myCategory.filter((item) => item !== category);
        if (this.selectedIndex.includes(index)) {
          this.selectedIndex = this.selectedIndex.filter(
            (item) => item !== index
          );
        }
      }
    },
    goToDashboard() {
      this.$router.push("/onboarding/new-business");
    },
    goToComplete() {
      if(this.socialMediaDetails.length > 0){
        this.$router.push("/onboarding/complete");
      }
      else{
        this.showAddSocial = true;
      }

    },
    enroll() {
      this.enrollModel.customerGender = this.gender;
      this.enrollModel.customerInterests = this.myCategory.join(',');
      this.$store.dispatch("socialMedia/enrollUser", this.enrollModel);
    },
    moveToStage2() {
      if (this.gender) {
        this.stage = 2;
      }
    },
    moveToStage3() {
      if (this.myCategory.length > 0) {
        this.stage = 3;
        this.enroll();
      }
    },
    setLastState(value) {
      this.$store.commit("socialMedia/updateState", value);
      this.lastStateGenerated = value;
    },
    setLastCodeChallengeCode(value) {
      this.$store.commit("socialMedia/updateChallengeCode", value);
      this.lastStateCodeChallengeGenerated = value;
    },
    goToTwitter(){
      this.twitterActive = true;
      window.location.href = this.twitterLink;
    },
    goToTikTok(){
      this.tiktokActive = true;
      window.location.href = this.tiktokLink;
    },
    goToInstagram(){
      this.instagramActive = true;
      window.location.href = this.instagramLink;
    }
  },
  beforeMount() {
    if(this.twitterActive){
      let url = window.location.search;
      const urlParams = new URLSearchParams(url);
      let code = urlParams.get("code");
      this.twitterModel.code = code;
      this.twitterModel.code_challenge = 'ST03s5';
      this.$store.dispatch("socialMedia/authorizeTwitter", this.twitterModel);
    }
    if(this.tiktokActive){
      let url = window.location.search;
      const urlParams = new URLSearchParams(url);
      let code = urlParams.get("code");
      this.tiktokModel.code = code;
      this.$store.dispatch("socialMedia/authorizeTiktok", this.tiktokModel);
    }
    if(this.instagramActive){
      let url = window.location.search;
      const urlParams = new URLSearchParams(url);
      let code = urlParams.get("code");
      this.tiktokModel.code = code;
      this.$store.dispatch("socialMedia/authorizeInstagram", this.tiktokModel);
    }

  },
  mounted(){
    this.$store.dispatch("socialMedia/readCustomer")
  }
};
</script>

<style scoped>


h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  font-size: 20px;
}

p {
  color: #475467;
  line-height: 1.375;
  font-size: 16px;
}

.btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 18px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border-radius: 50rem;
  background-color: #489e85;
  color: #fff !important;
  font-weight: 600;
  text-decoration: none;
  background-image: none !important;
  width: 100%;
}

.btn:hover {
  color: #489e85 !important;
  background-color: rgba(0, 0, 0, 0);
  border-color: #489e85;
}

.section {
  display: none;
}

.visible {
  display: block;
}

.connect__btn:hover {
  color: white !important;
}

.influencer__nav{
  position: fixed;
  top: 0;
  width: 100%;
}

.interest__item{
  color: black !important;
}
.button{
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    border-radius: 50rem;
    background-color: #489e85;
    color: #fff !important;
    font-weight: 600;
    text-decoration: none;
    background-image: none !important;
    width: 100%;

}
</style>