<template>
  <div style="background: #fff; height: 100%">
    <dashboard-new/>
    <home-layout>
      <section id="IntroMessageModal" class="share-campaign-popup" >
        <div class="share-campaign-modal" style="display: block; width: auto">
          <div class="modal-image-container"></div>
          <div class="share-campaign-header">
            <h5 class="share-campaign-header_text" style="font-size: 18px; font-weight: 500; margin-bottom: 10px">Share Campaign</h5>
            <div class="share-campaign-subheading_text" style="font-size: 16px">You are about to share this campaign, would you like to proceed?</div>
          </div>
          <div class="social-links-wrapper">
            <a :href="
            `https://twitter.com/intent/tweet?text=${encodeURIComponent(
              title
            )}%0A${encodeURIComponent(description)}%0A%0A` + link
          " class="socials "><img src="../../../public/images/twitter_1.svg" loading="lazy" alt="">
              <div class="text-block-7">Twitter</div>
            </a>
            <a :href="`fb-messenger://share/?link= ${encodeURIComponent(
            title
          )}%0A${encodeURIComponent(
            description
          )}%0A%0A${link}&app_id=123456789`" class="socials "><img src="../../../public/images/fb.svg" loading="lazy" alt="">
              <div class="text-block-7">Facebook</div>
            </a>
            <a :href="
            `whatsapp://send?text=*${title.trim()}*%0A${encodeURIComponent(
              description
            )}%0A%0A` + link
          " class="socials "><img src="../../../public/images/whatsapp.svg" loading="lazy" alt="">
              <div class="text-block-7">Whatsapp</div>
            </a>
            <a :href="`https://telegram.me/share/url?url=${link}&text=${encodeURIComponent(
            title
          )}%0A${encodeURIComponent(description)}%0A%0A`" class="socials "><img src="../../../public/images/telegram.svg" loading="lazy" alt="">
              <div class="text-block-7">Telegram</div>
            </a>
          </div>
          <div class="social-divider">
            <div class="divider1"></div>
            <div class="text-block-new">OR</div>
            <div class="divider1"></div>
          </div>
          <div class="campaign-link-wrapper">
            <div class="text-block-new-2">Campaign Link</div>
            <div class="campaign-link-block" style="padding-right: 0">
            <div class="" style="display: flex; width: 100%; justify-content: space-between; position: relative">
              <div class="text-block-10">{{ link }}</div>
              <button
                id="copy-link"
                @click="copy"
                style="background: none"
              >
                <img
                  style="width: 30px; height: 30px"
                  src="../../../public/images/Copy.svg"
                  alt=""
                />
              </button>
              <div class="copy-card" v-if="show" style="position: absolute; right: -10px; top: -45px; text-align: center; width: 80px; border-radius: 8px; color: black">Copied!</div>
            </div>
            </div>
          </div>
          <a data-w-id="5dd05fa0-7341-aeba-f177-f7142c7cbff4" href="#" class="link-block-2 " @click="close('.share-campaign-popup')"><img src="../../../public/images/x_1.svg" loading="lazy" width="24" alt=""></a>
        </div>
      </section>
      <div class="section">
        <div class="dashboard-wrapper" style="height: 100%">
          <div class="module-title-wrapper"><img src="../../../public/images/campaign-image.svg" loading="lazy" alt="">
            <h5 class="heading">Campaigns</h5>
          </div>
          <div class="search-filter-wrapper">
            <div class="left-block"><input type="text" class="form__input search w-input" maxlength="256" name="field" data-name="Field" placeholder="Search campaigns" id="field" required="" v-model="searchItem" @input="search"></div>
            <div class="right-block" style="display: flex; flex-direction: row; gap: 16px">
              <div data-hover="false" data-delay="0" class="dropdown-2 w-dropdown ee" @click="myFunction">
                <div class="dropdown-toggle-2 w-dropdown-toggle" >
                  Filter
                </div>
                <nav class="dropdown-list-2-copy w-dropdown-list">
                  <a href="#" class="dropdown-link w-dropdown-link" @click="selectCategory(category.value)"
                     v-for="category in categories"
                     v-bind:key="category.value"
                  >{{ category.label }}</a
                  >
                </nav>
              </div>
              <a class="btn small w-inline-block" @click="goToCreateCampaign">
                <div class="create-campaign-text">Create campaign</div>
              </a>
            </div>
          </div>
          <div style="width: 100%; height: 100%" v-if="campaign.campaigns.length > 0">
            <div class="campaign-wrapper"  v-if="modifiedCampaign.length !== 0 && searchItem === '' && category === 'ALL'" >
              <div id="w-node-add92689-11c1-f3b9-0816-3f5f23c87914-33b22501" class="campaign-card" v-for="campaignObj in modifiedCampaign.slice(0, this.count)" v-bind:key="campaignObj.campaignId" style="height: 303px">
                <div class="campaign-logo-tag">
                  <div class="left-div"><img :src="campaignObj.organisationLogo" loading="lazy" alt="" width="40" height="40" style="border-radius: 50%"></div>
                  <div class="right-div">
                    <div class="tag">
                      <div class="tag-dot"></div>
                      <div class="text-block-4">{{campaignObj.organisationType}}</div>
                    </div>
                  </div>
                </div>
                <div class="campaign-details_wrapper">
                  <div class="campaign-card-title">{{ campaignObj.campaignName }}</div>
                  <div class="text-block-5"  style="height: 45px; overflow: hidden; font-weight: 400; padding-top: 5px">{{campaignObj.campaignDescription}}</div>
                </div>
                <div class="campaign-card-price">
                  <div class="price-per-click-text" style="font-weight: 400;">Price Per Click</div>
                  <div class="price-per-click">${{campaignObj.campaignBudget}}</div>
                </div>
                <div class="campaign-action-wrapper">
                  <a href="#" class="view-campaign-btn" @click="selectCampaign(campaignObj.campaignId, campaignObj.campaignBudgetExhausted)">
                    <div class="text-block-6">View campaign</div>
                  </a>
                  <a data-w-id="2f177755-12d5-e970-acc6-b643ea05851b" href="#" class="share-campaign-btn" @click="uploadProfile('.share-campaign-popup', campaignObj.campaignName, campaignObj.campaignDescription, campaignObj.campaignId, campaignObj.shareLink, campaignObj.customerOrganisationCustomerId)"><img src="../../../public/images/share-2.svg" loading="lazy" alt=""></a>
                </div>
              </div>

            </div>
            <div class="campaign-wrapper" v-else-if="searchItem !== ''  && category === 'ALL'">
              <div id="w-node-add92689-11c1-f3b9-0816-3f5f23c87914-33b22501" class="campaign-card" v-for="campaignObj in searchCampaign.slice(0, this.count)" v-bind:key="campaignObj.campaignId" style="height: 303px">
                <div class="campaign-logo-tag">
                  <div class="left-div"><img :src="campaignObj.organisationLogo" loading="lazy" alt="" width="40" height="40" style="border-radius: 50%"></div>
                  <div class="right-div">
                    <div class="tag">
                      <div class="tag-dot"></div>
                      <div class="text-block-4">{{campaignObj.organisationType}}</div>
                    </div>
                  </div>
                </div>
                <div class="campaign-details_wrapper">
                  <div class="campaign-card-title">{{ campaignObj.campaignName }}</div>
                  <div class="text-block-5"  style="height: 45px; overflow: hidden; font-weight: 400; padding-top: 5px">{{campaignObj.campaignDescription}}</div>
                </div>
                <div class="campaign-card-price">
                  <div class="price-per-click-text" style="font-weight: 400;">Price Per Click</div>
                  <div class="price-per-click">${{campaignObj.campaignBudget}}</div>
                </div>
                <div class="campaign-action-wrapper">
                  <a href="#" class="view-campaign-btn" @click="selectCampaign(campaignObj.campaignId, campaignObj.campaignBudgetExhausted)">
                    <div class="text-block-6">View campaign</div>
                  </a>
                  <a data-w-id="2f177755-12d5-e970-acc6-b643ea05851b" href="#" class="share-campaign-btn" @click="uploadProfile('.share-campaign-popup', campaignObj.campaignName, campaignObj.campaignDescription, campaignObj.campaignId, campaignObj.shareLink, campaignObj.customerOrganisationCustomerId)"><img src="../../../public/images/share-2.svg" loading="lazy" alt=""></a>
                </div>
              </div>
            </div>
            <div class="campaign-wrapper" v-else>
              <div id="w-node-add92689-11c1-f3b9-0816-3f5f23c87914-33b22501" class="campaign-card" v-for="campaignObj in searchCampaign.slice(0, this.count)" v-bind:key="campaignObj.campaignId" style="height: 303px">
                <div class="campaign-logo-tag">
                  <div class="left-div"><img :src="campaignObj.organisationLogo" loading="lazy" alt="" width="40" height="40" style="border-radius: 50%"></div>
                  <div class="right-div">
                    <div class="tag">
                      <div class="tag-dot"></div>
                      <div class="text-block-4">{{campaignObj.organisationType}}</div>
                    </div>
                  </div>
                </div>
                <div class="campaign-details_wrapper">
                  <div class="campaign-card-title">{{ campaignObj.campaignName }}</div>
                  <div class="text-block-5"  style="height: 45px; overflow: hidden; font-weight: 400; padding-top: 5px">{{campaignObj.campaignDescription}}</div>
                </div>
                <div class="campaign-card-price">
                  <div class="price-per-click-text" style="font-weight: 400;">Price Per Click</div>
                  <div class="price-per-click">${{campaignObj.campaignBudget}}</div>
                </div>
                <div class="campaign-action-wrapper">
                  <a href="#" class="view-campaign-btn" @click="selectCampaign(campaignObj.campaignId, campaignObj.campaignBudgetExhausted)">
                    <div class="text-block-6">View campaign</div>
                  </a>
                  <a data-w-id="2f177755-12d5-e970-acc6-b643ea05851b" href="#" class="share-campaign-btn" @click="uploadProfile('.share-campaign-popup', campaignObj.campaignName, campaignObj.campaignDescription, campaignObj.campaignId, campaignObj.shareLink, campaignObj.customerOrganisationCustomerId)"><img src="../../../public/images/share-2.svg" loading="lazy" alt=""></a>
                </div>
              </div>
            </div>
          </div>

          <div class="view-more-btn-wrapper" style="padding: 0" v-if="modifiedCampaign.length !== 0">
            <a class="button is-secondary" @click="increaseCount">View more campaigns</a>
          </div>
<!--          <div class="view-more-btn-wrapper" v-else>-->
<!--            <b-spinner small /><a href="#" class="button is-secondary">View more campaigns</a>-->
<!--          </div>-->
          <div class="empty-state"  v-if="modifiedCampaign.length === 0 || campaign.campaigns.length <= 0" style="display: flex; opacity: 1; height: 100vh; padding: 0; margin: -100px 0 0;"><img src="../../../public/images/search-null-1.png" loading="lazy" width="120" alt="">
            <div class="empty-state-content">
              <div class="text-block-11">No campaign to show</div>
            </div>
          </div>
        </div>
      </div>
    </home-layout>
  </div>
</template>

<script>
import { mapState } from "vuex";
import HomeLayout from "@/views/layout/HomeLayout.vue";
import CampaignRequest from "@/model/request/CampaignRequest";
import OrganisationResponse from "@/model/response/OrganisationResponse";
import SecurityUtil from "@/utilities/SecurityUtil";
import DashboardNew from "@/components/menu/DashboardNew.vue";
export default {
  name: "CampaignAll",
  props:["showMoreIsRemoved"],
  components: {DashboardNew, HomeLayout },
  data() {
    return {
      title: "hgh bghghj ghkj",
      description: "hvjh bhkj ",
      customerId: "",
      searchItem: "",
      category: "All",
      disabled: true,
      model: CampaignRequest.prototype.campaignShareRequest(),
      body: "The text inside this text input will be copied to the clipboard",
      text: "Click to copy",
      variant: "dark",
      id: "",
      shareLink: "" ,
      count: 12,
      show: false,
      userType: "user",
      readmodel: CampaignRequest.prototype.campaignReadRequest()
    };
  },
  methods: {
    goToCreateCampaign(){
      this.$router.push("/create-new-campaign")
    },
    increaseCount(){
      this.count = this.count + 3;
    },
    myFunction() {
      document.querySelector(".dropdown-list-2-copy").classList.toggle("w--open");
    },
    selectCategory(_category) {
      this.$store.commit("campaign/updateCampaigns", []);
      this.$store.commit("campaign/updateFilteredCampaigns", []);
      let model = CampaignRequest.prototype.campaignReadRequest();
      this.category = _category;
      if (_category === "ALL") model.category = null;
      else model.category = _category;
      this.$store.dispatch("campaign/updateCampaigns", model);
      const menu = document.querySelector('.w-dropdown-list');
      menu.style.cssText = "display: none;";
      console.log("clickeddddd");
    },
    search() {
      let array = [];
      this.campaign.campaigns.data.forEach((campaign) => {
        if (campaign.campaignName.toLowerCase().includes(this.searchItem.toLowerCase())) {array.push(campaign)}
      })
      //console.log(this.campaign.campaigns);
      let filter = this.campaign.campaigns.data.filter(
        (it) => {
          it.campaignName.toLowerCase().includes(this.searchItem.toLowerCase())
        }
      );
      console.log(filter)
      this.$store.commit("campaign/updateFilteredCampaigns", array);
    },
    async selectCampaign(_id, _campaignBudgetExhausted) {
      await this.$store.dispatch("campaign/updateCampaign", {
        campaignId: _id,
      });
      console.log(_campaignBudgetExhausted);
      localStorage.campaignId = _id;
      console.log(this.$route.path);
      if (!this.$route.path.includes("campaign-details"))
        this.$router.push(`/campaign-details/${_id.hexEncode()}`);

      // } else {
      //   store.commit("updateMessage", {
      //     type: "error",
      //     title: "Error",
      //     body: "Campaign budget has been exhausted!",
      //   });
      // }
    },
    async uploadProfile(className, title, description, id, link, customerId) {
      this.customerId = customerId;
      this.shareLink = link;
      this.id = id;
      this.title = title;
      this.description = description;
      this.model.customerId = customerId;
      this.model.campaignId = id;
      await this.$store.dispatch("campaign/getShareLink", {
        shareCampaignId: this.id,
        shareCustomerId: this.auth.userInfo.customerId,
      }).then()
      document.querySelector(className).style.display = "flex";
      setTimeout(()=>{
        document.querySelector(className).style.opacity = 1;
      }, 500)
    },
    close(className) {
      document.querySelector(className).style.opacity = 0;
      setTimeout(()=>{
        document.querySelector(className).style.display = "none";

      }, 500)
    },
    async copy(){
      this.show = true;
      await navigator.clipboard.writeText(this.link);
      setTimeout(() => {
        this.show = false
      }, 1500)
      // Select the text field
      this.link.select();
      this.link.setSelectionRange(0, 99999); // For mobile devices
      // Copy the text inside the text field;

    },

    // eslint-disable-next-line no-unused-vars
    onCopy: function (e) {
      //this.$refs.tooltip.$emit('enable')
      this.variant = "success";
      this.text = "Copied!";
      this.$refs.tooltip.$emit("show");
      let self = this;
      window.setTimeout(function () {
        self.variant = "dark";
        self.text = "Click to copy";
        self.$refs.tooltip.$emit("show");
      }, 3000);
      // this.$bvToast.toast("You just copied the link", {
      //   title: `Copy - Success`,
      //   variant: "success",
      //   autoHideDelay: 100000,
      //   solid: true
      // })
    },
    // eslint-disable-next-line no-unused-vars
    onError: function (e) {
      this.$bvToast.toast("Failed to copy the text to the clipboard", {
        title: `Copy - Error`,
        variant: "error",
        autoHideDelay: 100000,
        solid: true,
      });
    },
    loadMore() {
      let model = this.campaign.model;
      model.page += 1;
      this.$store.commit("campaign/updateModel", model);
      this.$store.dispatch("campaign/updateCampaigns", this.campaign.model);
    },
  },
  computed: {
    ...mapState(["campaign", "sbucket", "organisation", "auth"]),
    campaigns: function(){
      if (this.campaign.filteredCampaigns.length < 1)
        return this.campaign.campaigns
      else
        return this.campaign.filteredCampaigns
    },
    modifiedCampaign: function() {
      if (this.campaign.filteredCampaigns.length > 1) {return this.campaign.filteredCampaigns;}
      else return this.campaign.campaigns.slice(0, this.count);
    },
    categories: function () {
      return OrganisationResponse.prototype.readOrganisationCategoryResponse().data;
    },
    baseUrl: function () {
      return window.__env.api.baseUrl;
    },
    shareUrl: function () {
      return window.__env.api.shareUrl;
    },
    appUrl: function () {
      return window.__env.app.appUrl;
    },
    token: function () {
      return SecurityUtil.prototype.encrypt(JSON.stringify(this.model));
    },
    link: function () {
      if (this.auth.userInfo.customerId === this.customerId)
        return `${this.appUrl}/campaign-details/${this.id.hexEncode()}`;
      else return `${this.shareUrl}share/${this.campaign.shareLink.shareLink}`;
    },
    searchCampaign(){
      let array = [];
      this.campaign.campaigns.forEach((campaign) => {
        if (campaign.campaignName.toLowerCase().includes(this.searchItem.toLowerCase())) {array.push(campaign)}
      })
      return array
    },

  },
  mounted() {
    this.userType = localStorage.getItem("userType")
    window.onclick = function(event) {
      if (event.target !== document.querySelector(".dropdown-toggle-2")) {
        console.log("we are happy")
        let dropdowns = document.getElementsByClassName("dropdown-list-2-copy");
        let i;
        for (i = 0; i < dropdowns.length; i++) {
          let openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('w--open')) {
            openDropdown.classList.remove('w--open');
          }
        }
      }
    }
  },
  watch: {
    category(){
      this.searchCategory()
    }
  },
};
</script>

<style scoped>

@media screen and (max-width: 660px){
  .search-filter-wrapper{
    flex-direction: column!important;
    align-items: baseline;
    gap: 8px !important;

  }
  .left-block{
    width: 20rem;
  }
  .right-block{
    justify-content: center;
    align-items: center;
    gap:8px;
    margin-top: 10px;
  }

}
@media screen and (max-width:560px) {
  .search-filter-wrapper{
    flex-direction: column;
  }

  .dropdown-2.w-dropdown.ee {
    width: 45%
  }
  .btn small {
    width: 55%
  }
  .left-block{
    width: 20rem!important;
  }
  .dropdown-2.w-dropdown.ee {
    width: auto
  }

  .btn small {
    width: auto
  }

}

@media screen and (max-width:320px) {
  .right-block {
    width: 100%!important;
  }
  .dropdown-2.w-dropdown.ee{
    width: 45%!important;
  }
  .create-campaign-text{
    font-size: 13px!important;
  }

}
@media screen and (max-width:350px) {
  .left-block{
    width: 18rem!important;
  }

  .right-block{
    display: flex;
    flex-direction: row;
    justify-items: center;
    justify-content: center !important;
    align-items: center!important;
  }
}

@media screen and (max-width:767px) {
  .dashboard-wrapper{
    margin-top: 100px;
  }
}

@media screen and (max-width:660px) {
  .left-block{
  width: 100%!important;
  }
  .right-block{
    width: 100%!important;
  }
  .dropdown-2.w-dropdown.ee{
    width: 48%;
  }
}
@media screen and (min-width:914px) {
  .left-block{
    width: 25rem!important;
  }
  .right-block{
    width: auto!important;
  }
  .dropdown-2.w-dropdown.ee{
    width: auto!important;
  }
}

@media screen and (max-width: 520px) {
  .share-campaign-modal {
    width: 280px !important;
    height: 350px !important;
    overflow: scroll !important;
  }
  .social-links-wrapper {
    flex-wrap: wrap;
    margin-top: 10px!important;
  }
  .text-block-10{
    overflow: hidden;
  }
  .campaign-link-block{
    padding-right: 0;
    padding-left: 8px;
  }
  .social-divider{
    margin-top: 5px!important;
  }
  .campaign-link-wrapper{
    margin-top: 5px!important;
  }
}

.section{
  height: 100%;
}
h1,h2,h3,h4,h5,h6,p{
  margin: 0;
}

.form__input {
  position: relative;
  height: 44px;
  margin-bottom: auto;
  padding: 10px 14px 10px 48px;
  border-style: solid;
  border-width: 1px;
  border-color: #d0d5dd;
  border-radius: 8px;
  background-color: #fff;
  font-family: Figtree, sans-serif;
  font-size: 0.875rem;
  line-height: 1.7142857142857142;
  font-weight: 500;
}

.w-dropdown {
  display: inline-block;
  position: relative;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  z-index: 900;
}

.dropdown-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  padding-right: 1rem;
  padding-left: 1rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #d0d5dd;
  border-radius: 8px;
  width: auto;

}

.text-block-3 {
  font-family: Figtree, sans-serif;
  color: #475467;
  font-size: 0.875rem;
  font-weight: 500;
}
.w-dropdown-toggle {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  cursor: pointer;
  padding-right: 40px;
}
.w-dropdown-btn, .w-dropdown-toggle, .w-dropdown-link {
  position: relative;
  vertical-align: top;
  text-decoration: none;
  color: #222222;
  padding: 20px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
}
.dropdown-toggle-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  margin: 0;
  font-family: Figtree, sans-serif;
  color: #475467;
  font-size: 0.875rem;
  font-weight: 500;
}

.dashboard-wrapper{
  height: 100%;
}

.text-block-5 {
  color: #475467;
  font-size: 0.9375rem;
}

.view-campaign-btn:hover{
  background-color: #489e85;
  color: #fff !important;
}

.view-campaign-btn:hover .text-block-6{
  color: #fff;
}

.button.is-secondary {
  border-style: solid;
  border-width: 1px;
  border-color: #d0d5dd;
  background-color: transparent;
  color: #000;
}

.dropdown-list-2.w--open {
  top: 50px ;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 220px !important;
  padding: 8px !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #eaecf0 !important;
  border-radius: 16px !important;
  background-color: #fff;
  box-shadow: 0 2px 20px 0 #eaecf0 !important;
  left: 0 !important;
}

.w-dropdown-link {
  padding: 10px 20px;
  display: block;
  font-family: Figtree, sans-serif;
  color: #222222;
}
.w-dropdown-btn, .w-dropdown-toggle, .w-dropdown-link {
  position: relative;
  vertical-align: top;
  text-decoration: none;
  color: #222222;
  padding: 20px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
}

.dropdown-link {
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
}

.button.is-secondary {
  border-style: solid;
  border-width: 1px;
  border-color: #d0d5dd;
  background-color: transparent;
  color: #000;
  text-decoration: none;
}

.share-campaign-modal {
  position: relative;
  display: block;
  overflow: hidden;
  width: 470px;
  height: auto;
  padding: 24px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(3, 28, 67, 0.1);
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 0 34px 0 rgb(3 28 67 / 13%);
}

.text-block-new{
  color: #475467;
  font-size: 16px;
}

.text-block-new-2{
  color: #344054;
  font-weight: 500;
}

.share-campaign-popup{
  opacity: 0;
}

.dropdown-list-2-copy.w--open {
  top: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 220px;
  padding: 8px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #eaecf0;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 2px 20px 0 rgb(234 236 240 / 7%);
  left: 0;
}

.dropdown-link {
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
  border-radius: 50rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.w-dropdown-link {
  padding: 10px 20px;
  display: block;
  color: #222222;
  margin: 0;
  font-family: Figtree, sans-serif;
}

.text-block-6 {
  font-weight: 400;
}

.btn-outline {
  color: #489e85;
  text-align: center;
  border: 1px solid #489e85;
  border-radius: 50rem;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.42857;
  text-decoration: none;
  transition: all .2s;
  display: block;
}

.btn-outline:hover {
  color: #fff;
  background-color: #489e85;
}

.btn {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  color: #fff;
  background-color: #489e85;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  background-image: none;
}

.btn:hover {
  color: #489e85;
  background-color: rgba(0, 0, 0, 0);
  border-color: #489e85;
}
.module-title-wrapper{
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btn.small{
  height: 44px!important;
}
</style>
