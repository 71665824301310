<template>
  <div style="background-color: #fff;">
    <dashboard-new/>
    <div class="section" style="height: 100%; padding-bottom: 50px">
      <div class="dashboard-wrapper" style="height: 100%; padding-bottom: 100px">
        <div class="wallet__wrapper">
          <div class="module-title-wrapper">
            <div class="module-title">
              <img
                src="@/assets/images/Group-36529.svg"
                loading="lazy"
                alt=""
              />
              <h5 class="heading">Wallet</h5>

            </div>
          </div>
          <div class="wallet__grid">
            <div class="wallet__side-bar">
              <div class="wallet__balance-frame">
                <div class="wallet__bal">
                  <div>Wallet Balance</div>
                </div>
                <div class="wallet__figure">
                  {{ currency }}{{balance.split(".")[0]}}<span class="wallet__decimal">.00</span>
                </div>
                <div @click="openPay" class="button small w-inline-block" style="cursor:pointer;">
                  <div>Fund Wallet</div>
                  <div class="w-embed">
                    <svg
                      width="20"
                      height="15"
                      viewbox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.66698 13.1666L10.0003 16.4999M10.0003 16.4999L13.3336 13.1666M10.0003 16.4999V8.99994M17.4003 14.0749C18.1248 13.5655 18.6681 12.8384 18.9514 11.9992C19.2347 11.1601 19.2432 10.2525 18.9756 9.40813C18.7081 8.56381 18.1785 7.82669 17.4637 7.30375C16.7489 6.78081 15.886 6.49925 15.0003 6.49994H13.9503C13.6997 5.52317 13.2307 4.61598 12.5787 3.84668C11.9268 3.07737 11.1088 2.46599 10.1863 2.05857C9.26387 1.65115 8.26101 1.4583 7.25325 1.49454C6.24548 1.53078 5.25907 1.79517 4.36827 2.2678C3.47747 2.74043 2.7055 3.40898 2.11047 4.22314C1.51544 5.03729 1.11285 5.97582 0.933028 6.96807C0.753202 7.96032 0.800819 8.98044 1.07229 9.95163C1.34377 10.9228 1.83203 11.8198 2.50031 12.5749"
                        stroke="currentColor"
                        stroke-width="1.67"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="wallet__transactions">
              <div class="transaction__header">
                <div>Recent Transactions</div>
                <div class="right-block">
                  <div
                    data-hover="false"
                    data-delay="0"
                    class="dropdown-2 w-dropdown"
                    style="width:auto;"
                  >
                    <div class="dropdown-toggle-2 w-dropdown-toggle" style="margin-top: 0;">
                      <img
                        src="@/assets/images/Filters-lines.svg"
                        loading="lazy"
                        alt=""
                      />
                      <div class="text-block-3">Filters</div>
                    </div>
                    <nav class="dropdown-list-2-copy w-dropdown-list">
                      <a href="#" class="dropdown-link w-dropdown-link"
                        >Transportation</a
                      >
                      <a href="#" class="dropdown-link w-dropdown-link"
                        >Marketing</a
                      >
                      <a href="#" class="dropdown-link w-dropdown-link"
                        >Telecommunications</a
                      >
                      <a href="#" class="dropdown-link w-dropdown-link"
                        >Agriculture</a
                      >
                      <a href="#" class="dropdown-link w-dropdown-link"
                        >Fashion &amp; Beauty</a
                      >
                      <a href="#" class="dropdown-link w-dropdown-link"
                        >Catering</a
                      >
                      <a href="#" class="dropdown-link w-dropdown-link"
                        >Arts &amp; Entertainment</a
                      >
                      <a href="#" class="dropdown-link w-dropdown-link"
                        >Electronics</a
                      >
                      <a href="#" class="dropdown-link w-dropdown-link"
                        >Gadgets</a
                      >
                      <a href="#" class="dropdown-link w-dropdown-link"
                        >Banking</a
                      >
                      <a href="#" class="dropdown-link w-dropdown-link"
                        >Technology</a
                      >
                    </nav>
                  </div>
                </div>
              </div>
              <div class="transaction-content" v-if="walletTransactions.length > 0">
                <div class="transactions" v-for="trxn in walletTransactions" v-bind:key="trxn.id">
                  <div class="left-transaction">
                    <img src="../../../public/images/success_1.svg" loading="lazy" alt="" v-if="trxn.drCr === 'CR'">
                    <img src="../../../public/images/error_1.svg" loading="lazy" alt="" v-else>
                    <div class="transaction-details">
                      <div class="transaction-title">{{ trxn.narration }}<span class="amount"> &nbsp;${{ trxn.amount | formatAmount }}</span></div>
                      <div class="transaction-date">{{ getDate(trxn.transactionDate)}}</div>
                    </div>
                  </div>
                  <div class="right-transaction">
                    <div class="transaction__type credit" v-if="trxn.drCr === 'CR'">funding</div>
                    <div class="transaction__type debit" v-else>Debit</div>
                  </div>
                </div>
              </div>
              <div class="transactions-is-empty" v-else>
                <img
                  src="@/assets/images/empty-wallet-1.png"
                  loading="lazy"
                  alt=""
                  class="wallet-empty-image"
                />
                <div class="wallet-empty-text">
                  <h5 class="wallet-empty-h">Oops! Your wallet is empty.</h5>
                  <p class="wallet-empty-sub">
                    Click on the “Fund wallet” button to begin
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <PaymentMethodModal :pay="pay" @pay="this.pay = false"/>
    <FundingModal :fund="fund"/>
  </div>
</template>

<script>
import DashboardNew from "@/components/menu/DashboardNew";
import PaymentMethodModal from "@/components/modal/PaymentMethodModal";
import FundingModal from "@/components/modal/FundingModal";
import { mapState } from "vuex";

export default {
  name: "Wallet",
  components: { PaymentMethodModal, DashboardNew, FundingModal },
  data(){
    return{
      pay: false,
      fund: false,
      date: null
    }
  },
  computed: {
    ...mapState(["auth", "transaction"]),
    currency: function () {
      if (this.transaction.transactions.accounts.length < 1) return "---";
      else return this.transaction.transactions.accounts[1].accountCurrency;
    },
    balance: function () {
      if (this.transaction.transactions.accounts.length < 1) return "0.00";
      else return this.transaction.transactions.accounts[1].accountBalance;
    },
    userInfo(){
      return this.auth.userInfo
    },
    walletTransactions(){
      let newList = [];
      newList = this.transaction.transactions.transactions.filter(
        (item) => item.accountNumber === this.auth.userInfo.accounts[1].accountNumber)
      return newList;
    }
  },
  methods:{
    openPay(){
      this.fund = true;
      document.querySelector('.funding_modal').style.opacity = 1;
      setTimeout(()=>{
        document.querySelector('.funding_modal').style.display = "flex";
      }, 100)
    },
    closePay(){
      this.pay = false;
    },
    openFund(){
      this.fund = true;
    },
    closeFund(){
      this.fund = false;
    },
    getCurrency(currency){
      let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return USDollar.format(currency)
    },
    getDate(date){
      let date1 = new Date(date)
      return date1.toDateString();
    },

  },
  mounted() {
    window.onclick = function(event) {
      if (event.target === document.querySelector('.pop-up-back')) {
        document.querySelector('.funding_modal').style.opacity = 0;
        setTimeout(()=>{
          document.querySelector('.funding_modal').style.display = "none";
        }, 500)
      }

    }
    this.$store.dispatch("transaction/queryTransaction", {
      customerId: this.auth.userInfo.customerId,
    })
    setTimeout(this.walletTransactions(), 2000);
  },
  watch:{
    async userInfo(){
      await this.$store.dispatch("transaction/queryTransaction", {
        customerId: this.auth.userInfo.customerId,
      }).then();
    }
  }
};
</script>

<style scoped>
.wallet__wrapper {
  grid-column-gap: 52px;
  grid-row-gap: 52px;
  flex-direction: column;
  display: flex;
}
.module-title-wrapper {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.module-title {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  align-items: center;
  display: flex;
}
.wallet__grid {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-rows: auto;
  grid-template-columns: 310px 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}
.wallet__side-bar {
  grid-column-gap: 8px;
  /* grid-row-gap: 24px; */
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}
.wallet__transactions {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  border: 1px solid #eaecf0;
  border-radius: 0.5rem;
  flex-direction: column;
  align-items: stretch;
  padding: 24px;
  display: flex;
}
.wallet__balance-frame {
  width: 100%;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  border: 1px solid #eaecf0;
  border-radius: 0.5rem;
  flex-direction: column;
  padding: 24px;
  display: flex;
}
.wallet__bal {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  color: #667085;
  align-items: center;
  display: flex;
}
.wallet__bal-icon {
  height: 24px;
}

.wallet__figure {
  color: #344054;
  font-family: Faktum, sans-serif;
  font-size: 2.375rem;
  font-weight: 600;
}
.wallet__decimal {
  font-size: 1.75rem;
}
.button.small {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  padding: 0.625rem 1.125rem;
}
.wallet__transactions {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  border: 1px solid #eaecf0;
  border-radius: 0.5rem;
  flex-direction: column;
  align-items: stretch;
  padding: 24px;
  display: flex;
}
.transaction__header {
  width: 100%;
  color: #1d2939;
  border-bottom: 1px solid #eaecf0;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  font-family: Faktum, sans-serif;
  font-weight: 600;
  line-height: 1.125;
  display: flex;
}
.dropdown-2 {
  z-index: auto;
  height: 44px;
  border: 1px solid #eaecf0;
  border-radius: 100px;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
}
.dropdown-toggle-2 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  align-items: center;
  padding: 0;
  display: flex;
}
.dropdown-list-2-copy {
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
}
.dropdown-link {
  width: 100%;
  border-radius: 16px;
  margin-left: 0;
  margin-right: 0;
  font-size: 0.875rem;
  font-weight: 500;
}
.transaction__list {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  display: flex;
}
.w-inline-block:hover{
  opacity: 1;
  color: white;
}
.transactions-is-empty {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 64px;
  padding-bottom: 64px;
  display: flex;
}
.wallet-empty-image {
  height: 130px;
  object-fit: cover;
}
.wallet-empty-text {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}
.wallet-empty-h {
  color: #1d2939;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.33333;
}
.wallet-empty-sub {
  color: #475467;
  line-height: 1.375;
}
.button.small {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  padding: 0.625rem 1.125rem;
}
.button {
  color: #fff;
  text-align: center;
  background-color: #489e85;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  text-decoration: none;
  display: flex;
}
.wallet__transactions {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  border: 1px solid #eaecf0;
  border-radius: 0.5rem;
  flex-direction: column;
  align-items: stretch;
  padding: 24px;
  display: flex;
  height: 700px;
  overflow: scroll;
}
.transaction__item {
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.transaction__info {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  align-items: center;
  display: flex;
}
.transaction__icon {
  height: 40px;
}
.transac_txt {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}
.transaction__desc {
  color: #1d2939;
  font-weight: 500;
  line-height: 1.125;
}
.transac-date {
  color: #667085;
  font-size: 0.75rem;
}
.transaction__type.credit {
  color: #027a48;
  background-color: #ecfdf3;
}
.transaction__type.debit {
  color: #f04438;
  background-color: #fef3f2;
}
.transaction__icon {
  height: 40px;
}
.transaction__header {
  width: 100%;
  color: #1d2939;
  border-bottom: 1px solid #eaecf0;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  font-family: Faktum, sans-serif;
  font-weight: 600;
  line-height: 1.125;
  display: flex;
}
.dropdown-2 {
  z-index: auto;
  height: 44px;
  border: 1px solid #eaecf0;
  border-radius: 100px;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
}
.dropdown-toggle-2 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  align-items: center;
  padding: 0;
  display: flex;
}
.text-block-3 {
  color: #475467;
  font-family: Figtree, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
}
.dropdown-list-2-copy {
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
}
.dropdown-link {
  width: 100%;
  border-radius: 16px;
  margin-left: 0;
  margin-right: 0;
  font-size: 0.875rem;
  font-weight: 500;
}
.right-block {
  /*width: 100px;*/
  /*height: 44px;*/
}
.transaction__type {
  text-transform: capitalize;
  border-radius: 50rem;
  padding: 4px 13px;
  font-size: 0.875rem;
  line-height: 1.42857;
}
.transaction-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
}

.transaction__type.credit {
  background-color: #ecfdf3;
  color: #027a48;
}
.transaction__type {
  padding: 4px 13px;
  border-radius: 50rem;
  font-size: 0.875rem;
  line-height: 1.4285714285714286;
  text-transform: capitalize;
}

.transaction__type.debit {
  background-color: #fef3f2;
  color: #f04438;
}
.transaction__type {
  padding: 4px 13px;
  border-radius: 50rem;
  font-size: 0.875rem;
  line-height: 1.4285714285714286;
  text-transform: capitalize;
}

.transactions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
}

.left-transaction {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.transaction-details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.transaction-title {
  color: #344054;
  font-weight: 400;
  font-size: 16px;
}

.amount {
  font-weight: 600;
}

.transaction-date {
  color: #667085;
  font-size: 0.75rem;
  font-weight: 300;
}

.dropdown-toggle-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  margin: 0;
}

.text-block-12 {
  font-family: Faktum, sans-serif;
}

.earnings-wrapper {
  display: -ms-grid;
  display: grid;
  margin-top: 1.5rem;
  margin-bottom: 0rem;
  padding-bottom: 2rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 35% 63%;
  grid-template-columns: 35% 63%;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.balance-wrapper {
  width: auto;
  height: 201.8984375px;
  padding: 24px;
  border-style: solid;
  border-width: 1px;
  border-color: #eaecf0;
  border-radius: 8px;
}
</style>
