
import BaseResponse from "@/model/response/BaseResponse";

class MessagingResponse extends BaseResponse{
    create(){
        return{
            responseCode: null,
            responseMessage: null
        }

    }
    readByCustomerId(){
        return{
            data:[],
            responseCode: null,
            responseMessage: null
        }
    }
    readByGroupId(){
        return{
            data:[],
            responseCode: null,
            responseMessage: null
        }
    }

}

export default MessagingResponse