<template>
  <div class="dashboard-nav wf-section">
    <div class="main-dashboard-section wf-section">
      <div class="main-dashboard-body mall-copy">
        <div class="get-started-wrapper"></div>
        <div class="container-v2">
          <div class="create-campaign-form-container">
            <div class="back-button-container">
              <a @click="goBack" class="link-block-11 w-inline-block">
                <div class="text-block-69">Back</div>
              </a>
            </div>
            <div class="create-campaign-header">
              <div>
                <h1 class="heading-23">Create new product</h1>
              </div>
              <div class="div-block-90">
                <div class="text-block-41">
                  Please provide your basic product details.
                </div>
              </div>
              <div class="div-block-87">
                <div class="campaigndetail-copy">
                  <img
                    :src="require('@/assets/images/checkmark.svg')"
                    loading="lazy"
                    alt=""
                    class="image-28"
                  />
                  <div class="text-block-72-copy">Product Details</div>
                </div>
                <div class="mediaupload">
                  <img
                    :src="require('@/assets/images/2.svg')"
                    loading="lazy"
                    alt=""
                    class="image-29"
                  />
                  <div class="text-block-73">Media Uploads</div>
                </div>
              </div>
            </div>
              <div>
                <upload-image-with-cropper-v2 name="images"/>
              </div>
              <br>
              <br>
            <button
              v-if="this.sbucket.urls.length === 3 && !showLoader"
              @click="newProduct"
              title="Proceed"
              class="form-control"
              style="background: #63b8a0; border-color: #63b8a0; color: #fff !important; height: 50px"
            >Proceed</button>
            <action-button class="grey-button" v-if="showLoader"><loader-component /></action-button>
            <button class="grey-button disabled" disabled v-if="this.sbucket.urls.length < 3">
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import UploadSingleImageField from "@/components/form/input/UploadSingleImageField";
import ProductRequest from "@/model/request/ProductRequest";
import { mapState } from "vuex";
//import UploadMultipleImageField from "@/components/form/input/UploadMultipleImageField";
import swal from "sweetalert";
import UploadImageWithCropperV2 from "@/components/form/input/UploadImageWithCropperV2";
import ActionButton from "@/components/shared/ActionButton.vue";
import LoaderComponent from "@/components/shared/LoaderComponent.vue";

export default {
  name: "AddProductFormTwo",
  components: { LoaderComponent, ActionButton, UploadImageWithCropperV2 },
  data() {
    return {
      showLoader: false,
      model: ProductRequest.productCreateRequest,
      urlMax: 3,
    };
  },
  computed: {
    ...mapState(["sbucket", "product", "auth", "organisation"]),
  },
  methods: {
     async newProduct() {
       this.showLoader = true;
      if (this.sbucket.urls.length < this.urlMax){
        swal("Error", `Please select at least  ${this.urlMax} image(s)`);
        this.showLoader = false;
        return;
      }
      // if (this.model) {
      // let productImage = { productImage: this.sbucket.urls.join()}

      this.model.productOrganisationId = localStorage.getItem('organisationId')
      this.model.productImage = this.sbucket.urls.join()
       this.model.productLocalCurrencyPrice = "USD"
       this.model.productUsdPrice = 3
       this.model.productQuantity = 2
       this.model.productMaxDeliveryTime = "29-10-2033"
       this.model.productMinDeliveryTime = "29-10-2033"
      // this.$store.commit("product/updateProductFormObject", productImage);
       await this.$store.dispatch("product/createProduct", this.model).then();
       this.showLoader = false;
       console.log(this.model)
      console.log(this.product.productFormObject);
    },
    goBack() {
      this.$store.commit("product/updateScreen", "one");
    },
  },
};
</script>

<style scoped>
.grey-button{
  background: #D9D9D9;
  border-radius: 6px;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;

  color: #FFFFFF;

}
</style>
