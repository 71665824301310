  import Earnings from "@/views/wallet/Earnings";
  import Wallet from "@/views/wallet/Wallet.vue";

export default [
  {
    path: "/view-wallet",
    name: "Earnings",
    meta: { layout: "", authRequired: false },
    component: Earnings,
  },
  {
    path: "/view-earnings",
    name: "Wallet",
    meta: { layout: "", authRequired: false },
    component: Wallet,
  },
];
