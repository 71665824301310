<template>
  <main class="main-wrapper" style="background-color: #FFFFFF">
    <div class="section ranking">
      <div class="main">
        <div class="inf__onboarding_content is-ranking">
          <div class="inf__onboarding_text">
            <div class="rankings"><img src="../../assets/images/Basic.svg" loading="lazy" alt="" class="ranking__image"><img src="../../assets/images/Bronze.svg" loading="lazy" alt="" class="ranking__image"><img src="../../assets/images/Gold.svg" loading="lazy" alt="" class="ranking__image"><img src="../../assets/images/diigital-campaign-1.svg" loading="lazy" alt="" class="ranking__image active"></div>
            <h3 class="inf__onboarding-h">Congratulations</h3>
            <p class="inf__onboarding-p">Your campaign has been created successfully</p>
          </div>
          <a href="#" class="btn" @click="goToCampaign">
            <div>Proceed</div>
          </a>
        </div>
        <div class="lottie-wrapper">
          <div data-w-id="47eafa05-e47d-8f97-8cc6-22cd7fc688f9" data-animation-type="lottie" data-src="../documents/confetti-2.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-default-duration="1.5833333333333333" data-duration="0"></div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
// import Lottie from './lottie.vue';
import * as animationData from '@/assets/documents/64787-success.json';
import BaseLottie from "@/components/lottie/BaseLottie";

export default {
  name: "CampaignCreateSuccess",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BaseLottie,
    // 'lottie': Lottie
  },
  data() {
    return {
      defaultOptions: {animationData: animationData},
      animationSpeed: 1
    }
  },
  methods: {
    goToCampaign(){
      let id = localStorage.getItem("createCampaignId")
      this.$router.push(`/campaign-metrics/${id}`)
    },
    handleAnimation: function (anim) {
      this.anim = anim;
    },

    stop: function () {
      this.anim.stop();
    },

    play: function () {
      this.anim.play();
    },

    pause: function () {
      this.anim.pause();
    },

    onSpeedChange: function () {
      this.anim.setSpeed(this.animationSpeed);
    }
  }
};
</script>

<style scoped>
.btn {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  color: #fff;
  background-color: #489e85;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 50rem;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  font-weight: 600;
  text-decoration: none;
  display: flex;
  width: 100%;
  background-image: none;
}

.btn:hover {
  color: #489e85;
  background-color: rgba(0, 0, 0, 0);
  border-color: #489e85;
}
</style>