<template>
  <div style="background: #fff; font-family: Figtree, sans-serif">
    <dashboard-new/>
    <div class="section" style="height: 100%">
      <div class="dashboard-wrapper">
        <div class="module-title-wrapper">
          <div class="module-title"><img src="../../../public/images/Group-36529.svg" loading="lazy" alt="">
            <h5 class="heading">Wallet</h5>
          </div>
          <div class="div-block-233" style="margin: 0; width: auto;">
            <a data-w-id="ee31f9ce-7dde-2e48-397d-7ef52de493aa" href="#" class="button is-secondary w-button" @click="uploadProfile('.funding_modal')">Withdraw</a>
            <a data-w-id="61e47ea5-a3ab-ae6e-4b4a-7b7e4418d64f" href="#" class="button w-button" @click="uploadProfile('.payment-method_modal')">Fund Wallet</a>
          </div>
        </div>
        <div class="earnings-wrapper">
          <div class="wallet__side-bar">
            <div class="div-block-231">
              <div class="div-block-232"><img src="../../../public/images/wallet-2.svg" loading="lazy" alt="" class="image-61" >
                <div>
                  <div class="text-block-34">Wallet</div>
                  <div class="text-block-34-copy">{{ getCurrency(balance)}}</div>
                </div>
              </div>
              <div></div>
            </div>
            <div class="div-block-231">
              <div class="div-block-232"><img src="../../../public/images/earnings.svg" loading="lazy" alt="" class="image-61" width="50px" height="50px">
                <div>
                  <div class="text-block-34">Earnings</div>
                  <div class="text-block-34-copy">{{getCurrency(balance1)}}</div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
          <div id="w-node-fb20f10d-cab7-eeeb-fac6-b105faec5960-0cc645c3" class="transactions-wrapper">
            <div class="transactions-header">
              <div class="text-block-12">Recent Transactions</div>
<!--              <div data-hover="false" data-delay="0" class="filter w-dropdown">-->
<!--&lt;!&ndash;                <div class="dropdown-toggle-2 w-dropdown-toggle"><img src="../../../public/images/Filters-lines.svg" loading="lazy" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="text-block-3">Filter</div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--                <nav class="dropdown-list-2 w-dropdown-list">-->
<!--                  <a href="#" class="dropdown-link w-dropdown-link">Transportation</a>-->
<!--                  <a href="#" class="dropdown-link w-dropdown-link">Marketing</a>-->
<!--                  <a href="#" class="dropdown-link w-dropdown-link">Telecommunications</a>-->
<!--                  <a href="#" class="dropdown-link w-dropdown-link">Agriculture</a>-->
<!--                  <a href="#" class="dropdown-link w-dropdown-link">Fashion &amp; Beauty</a>-->
<!--                  <a href="#" class="dropdown-link w-dropdown-link">Catering</a>-->
<!--                  <a href="#" class="dropdown-link w-dropdown-link">Arts &amp; Entertainment</a>-->
<!--                  <a href="#" class="dropdown-link w-dropdown-link">Electronics</a>-->
<!--                  <a href="#" class="dropdown-link w-dropdown-link">Gadgets</a>-->
<!--                  <a href="#" class="dropdown-link w-dropdown-link">Banking</a>-->
<!--                  <a href="#" class="dropdown-link w-dropdown-link">Technology</a>-->
<!--                </nav>-->
<!--              </div>-->
            </div>
            <div class="transaction-content" v-if="transaction.transactions.transactions.length > 0">
              <div class="transactions" v-for="trxn in walletTransactions" v-bind:key="trxn.id">
                <div class="left-transaction">
                  <img src="../../../public/images/success_1.svg" loading="lazy" alt="" v-if="trxn.drCr === 'CR'">
                  <img src="../../../public/images/error_1.svg" loading="lazy" alt="" v-else>
                  <div class="transaction-details">
                    <div class="transaction-title">{{ trxn.narration }}<span class="amount"> &nbsp;${{ trxn.amount | formatAmount }}</span></div>
                    <div class="transaction-date">{{ getDate(trxn.transactionDate)}}</div>
                  </div>
                </div>
                <div class="right-transaction">
                  <div class="transaction__type credit" v-if="trxn.drCr === 'CR'">funding</div>
                  <div class="transaction__type debit" v-else>Debit</div>
                </div>
              </div>
            </div>
            <div class="empty-state" v-else><img src="../../../public/images/empty-wallet-1.png" loading="lazy" width="150" alt="">
              <div class="empty-state-content">
                <div class="text-block-11">No recent activity</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <funding-modal :fund="fund"/>
    <fund-wallet :fund ="fund"/>
    <PaymentMethodModal :pay="fund" />
  </div>
</template>

<script>
// import BalanceCard from "@/components/card/BalanceCard";
// import WalletStatCard from "@/components/card/WalletStatCard";
// import TransactionCard from "@/components/card/TransactionCard";
import DashboardNew from "@/components/menu/DashboardNew.vue";
import { mapState } from "vuex";
import FundingModal from "@/components/modal/FundingModal.vue";
import FundWallet from "@/components/modal/FundwalletModal.vue";
import PaymentMethodModal from "@/components/modal/PaymentMethodModal.vue";

export default {
  name: "Earnings",
  components: {PaymentMethodModal, FundingModal, FundWallet, DashboardNew },
  data(){
    return{
      date: null,
      fund: true,
    }
  },
  computed: {
    ...mapState(["auth", "transaction"]),
    currency: function () {
      if (this.transaction.transactions.accounts.length < 1) return "---";
      else return this.transaction.transactions.accounts[0].accountCurrency;
    },
    balance: function () {
      if (this.transaction.transactions.accounts.length < 1) return "0.00";
      else return this.transaction.transactions.accounts[0].accountBalance;
    },
    currency1: function () {
      if (this.transaction.transactions.accounts.length < 1) return "---";
      else return this.transaction.transactions.accounts[1].accountCurrency;
    },
    balance1: function () {
      if (this.transaction.transactions.accounts.length < 1) return "0.00";
      else return this.transaction.transactions.accounts[1].accountBalance;
    },
    userInfo(){
      return this.auth.userInfo
    },
    walletTransactions(){
      let newList = [];
      newList = this.transaction.transactions.transactions.filter(
        (item) => item.accountNumber === this.auth.userInfo.accounts[0].accountNumber)
      return newList;
    }

  },
  methods:{
    getCurrency(currency){
      let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return USDollar.format(currency)
    },
    getDate(date){
      let date1 = new Date(date)
      return date1.toDateString();
    },
    uploadProfile(className) {
      document.querySelector(className).style.display = "flex";
      setTimeout(() => {
        document.querySelector(className).style.opacity = 1;
      }, 100);
    }

    // makeDate(date){
    //   return date.toLocaleDateString('en-US')
    //
    // }
  },
  mounted() {
    this.$store.dispatch("transaction/queryTransaction", {
      customerId: this.auth.userInfo.customerId,
    });
    let id = this.$route.query.transaction_id;
    console.log(this.$route.query.transaction_id)
    if (this.$route.query.transaction_id){
      this.$store.dispatch("transaction/callFlutterwaveVerify", {id: id})
    }
  },
  watch:{
    async userInfo(){
      await this.$store.dispatch("transaction/queryTransaction", {
        customerId: this.auth.userInfo.customerId,
      }).then();
    },

  }
};
</script>

<style scoped>
@media screen and (max-width:479px){
  .module-title-wrapper{
    align-items: flex-start !important;
    grid-row-gap: 16px !important;
  }
  .transactions-wrapper {
    grid-column-gap: 16px !important;
    grid-row-gap: 16px !important;
    padding: 16px !important;
  }
  .earnings-wrapper {
    grid-template-columns: 1fr !important;
  }
  .div-block-233{
    width: 100%!important;
  }
  .button.w-button{
    width: 50%!important;
  }
  .button.is-secondary{
    width: 50%!important;
  }

}
@media screen and (max-width:767px) {
  .dashboard-wrapper{
    margin-top: 100px;
  }
}
@media screen and (max-width:425px) {
  .button.w-button{
    width: 50%;
  }
}

h1,h2,h3,h4,h5,h6,p{
  margin: 0;
}
.module-title-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.earnings-wrapper {
  display: -ms-grid;
  display: grid;
  margin-top: 1.5rem;
  margin-bottom: 0rem;
  padding-bottom: 2rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 35% 63%;
  grid-template-columns: 35% 63%;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.balance-wrapper {
  width: auto;
  height: 201.8984375px;
  padding: 24px;
  border-style: solid;
  border-width: 1px;
  border-color: #eaecf0;
  border-radius: 8px;
}

.transactions-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border-style: solid;
  border-width: 1px;
  border-color: #eaecf0;
  border-radius: 8px;
  height: 540px;
  overflow: scroll;
}

.module-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

h5 {
  color: #101828;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 700;
}

.div-block-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.text-size-regular {
  color: #475467;
  font-size: 18px;
}

h3 {
  color: #344054;
  font-size: 32px;
  line-height: 1.2;
  font-weight: 700;
}

.text-span {
  color: #344054;
  font-size: 20px;
}

.button.is-not-wide {
  width: 170px;
}
.button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.75rem 1.5rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #489e85;
  color: #fff;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}

.balance-content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.transactions-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #eaecf0;
}

.filter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 1rem;
  padding-left: 1rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #d0d5dd;
  border-radius: 100px;
}

.text-block-3 {
  font-family: Figtree, sans-serif;
  color: #475467;
  font-size: 16px;
  font-weight: 500;
}

.transactions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
}

.left-transaction {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.transaction-details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.transaction-title {
  color: #344054;
  font-weight: 400;
  font-size: 16px;
}

.amount {
  font-weight: 600;
}

.transaction-date {
  color: #667085;
  font-size: 0.75rem;
  font-weight: 300;
}

.dropdown-toggle-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  margin: 0;
}

.text-block-12 {
  font-family: Faktum, sans-serif;
}

.transaction-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
}

.transaction__type.credit {
  background-color: #ecfdf3;
  color: #027a48;
}
.transaction__type {
  padding: 4px 13px;
  border-radius: 50rem;
  font-size: 0.875rem;
  line-height: 1.4285714285714286;
  text-transform: capitalize;
}

.transaction__type.debit {
  background-color: #fef3f2;
  color: #f04438;
}
.transaction__type {
  padding: 4px 13px;
  border-radius: 50rem;
  font-size: 0.875rem;
  line-height: 1.4285714285714286;
  text-transform: capitalize;
}

.text-block-34-copy {
  color: #101828;
  font-family: Faktum, sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
}

.heading{
  color: #101828;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Faktum, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;
}
.div-block-231{
  background: none;
  height: auto;
  margin-bottom: 0;
}
.earnings-wrapper{
margin-top:52px;
}
.button.is-secondary.w-button{
  height: 45px!important;
}
</style>