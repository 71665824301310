class OrganisationRequest {
 organisationCreateRequest()  {
   return {
     organisationSerial: null,
     organisationName: null,
     organisationLogo: null,
     organisationRegistrationNo: null,
     organisationRegistrationDate: null,
     organisationStressAddress: null,
     organisationCity: null,
     organisationDescription: null,
     organisationState: null,
     organisationPhone: null,
     organisationEmail: null,
     organisationCurrency: null,
     organisationCustomerId: null,
     organisationType: null
   }
  }
  organisationReadRequest() {
    return {
      readAll: null,
    };
  }
 static mallsReadRequest= {
      readAll: "YES",
  }
  organisationReadSingleRequest() {
    return {
      organisationId: null,
    };
  }
 static mallReadByCustomerIdRequest =  {
   customerId: null,
  }
  organisationUpdateRequest() {
    return {
      organisationId: null,
      organisationSerial: null,
      organisationName: null,
      organisationRegistrationNo: null,
      organisationRegistrationDate: null,
      organisationStressAddress: null,
      organisationCity: null,
      organisationCurrency: null,
      organisationDescription: null,
      organisationState: null,
      organisationPhone: null,
      organisationCountryCode: null,
      organisationEmail: null,
      organisationRedirectParameter:  null,
      organisationRedirectPlatform: null,
      organisationRedirectCustomUrl: null,
      organisationType: null,
    };
  }
  organisationUpdateStatusRequest() {
    return {
      organisationId: null,
      organisationStatus: null,
    };
  }
}

export default OrganisationRequest;
