<template>
  <div
    style="display: block; font-family: Figtree, sans-serif; background: #fff"
  >
    <div
      data-w-id="d84ad800-4a62-6d77-00aa-5dbc2506cb90"
      class="success__modal"
    >
      <div class="pop-up-back"></div>
      <div
        data-w-id="d84ad800-4a62-6d77-00aa-5dbc2506cb92"
        class="modal_content"
      >
        <div class="modal_c">
          <img
            src="../../../public/images/success.svg"
            loading="lazy"
            alt=""
            class="modal__image"
          />
          <div class="modal_text">
            <h1 class="modal_h">Success message</h1>
            <p class="modal_p">Your product has been added successfully</p>
          </div>
          <a href="#" class="btn__submit" @click="goToNewMall"
            >Go to Product -></a
          >
        </div>
      </div>
    </div>
    <div class="influencer__nav">
      <div class="container-large">
        <div class="nav__container">
          <div class="title__nav">
            <img
              src="../../../public/images/apvertise-icon.svg"
              loading="lazy"
              alt=""
              class="logo__icon"
            />
          </div>
          <div class="nav__menu">
            <a
              href="#"
              class="menu__item"
              :class="{ 'w--current': stage === 1 }"
              style="color: #98a2b3"
              @click="stage = 1"
            >
              <div class="id" :class="{ 'is-active': stage === 1 }">
                <div style="color: white">1</div>
              </div>
              <div
                class="nav__item-title"
                :class="{ 'is-active': stage === 1 }"
              >
                Product Details
              </div>
            </a>
            <a
              href="#"
              class="menu__item"
              :class="{ 'w--current': stage === 2 }"
              style="color: #98a2b3"
              @click="stage = 2"
            >
              <div class="id" :class="{ 'is-active': stage === 2 }">
                <div style="color: white">2</div>
              </div>
              <div
                class="nav__item-title"
                :class="{ 'is-active': stage === 2 }"
              >
                Media Upload
              </div>
            </a>
          </div>

          <a href="#" class="close__btn" @click="goToMall"
            ><img
              src="../../../public/images/x.svg"
              loading="lazy"
              alt=""
              class="close__icon"
          /></a>
        </div>
      </div>
    </div>
    <div class="section" v-if="stage === 1">
      <div class="main">
        <div class="inf__onboarding_content">
          <div class="inf__onboarding_text">
            <h3 class="inf__onboarding-h">Create new product</h3>
            <p class="inf__onboarding-p">
              Please provide your basic product details.
            </p>
          </div>
          <div class="form__block w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
              class="form"
              @submit.prevent="stage = 2"
            >
              <div class="form__item">
                <div class="div-block-6">
                  <label for="First-Name" class="form__label"
                    >Enter name of product</label
                  >
                </div>
                <div class="input">
                  <input
                    id="field-2"
                    name="field-2"
                    data-name="Field 2"
                    @input="filterBadChar"
                    class="form__input w-input"
                    v-model="productModel.productName"
                    required
                    placeholder="Enter name of product"
                  /><img
                    src="../../../public/images/briefcase.svg"
                    loading="lazy"
                    alt=""
                    class="form__input-icon"
                  />
                </div>
              </div>
              <div class="form__item">
                <div class="div-block-6">
                  <label for="First-Name" class="form__label"
                    >Price in Local Currency</label
                  >
                </div>
                <div class="input">
                  <input
                    @input="filterBadChar()"
                    id="field-2"
                    name="field-2"
                    data-name="Field 2"
                    class="form__input w-input"
                    type="number"
                    v-model="productModel.productLocalCurrencyPrice"
                    required
                    placeholder="Enter Price in Local Currency"
                  /><img
                    src="../../../public/images/briefcase.svg"
                    loading="lazy"
                    alt=""
                    class="form__input-icon"
                  />
                </div>
              </div>

              <div class="form__item">
                <b-tooltip target="target-1" triggers="hover">
                  <div class="div-block-6">
                    <label for="First-Name" class="form__label"
                      >Price in USD</label
                    >
                  </div>
                  <p class="text-block-155">
                    This is the dollar equivalent of the price of your product.
                    <br /><br /><strong>NOTE: </strong>When withdrawing,
                    Apvertise will pay you based on the official excahange rate
                    for your local currency
                  </p>
                </b-tooltip>
                <div class="div-block-6">
                  <label for="First-Name" class="form__label"
                    >Price in USD</label
                  >
                </div>
                <div class="input">
                  <input
                    id="field-2"
                    name="field-2"
                    data-name="Field 2"
                    class="form__input w-input"
                    type="number"
                    @input="filterBadChar"
                    v-model="productModel.productUsdPrice"
                    required
                    placeholder="Enter USD Price"
                  /><img
                    src="../../../public/images/briefcase.svg"
                    loading="lazy"
                    alt=""
                    class="form__input-icon"
                  />
                </div>
              </div>
              <div class="form__item">
                <label for="field" class="form__label"
                  >Enter product description</label
                ><textarea
                  @input="filterBadChar"
                  placeholder="A brief description about your product"
                  maxlength="5000"
                  id="field"
                  name="field"
                  data-name="Field"
                  class="textarea w-input"
                  style="height: 100px"
                  v-model="productModel.productDescription"
                ></textarea>
              </div>

              <button
                data-w-id="44cd7187-86d6-5b57-9707-88bb338aef61"
                class="button"
              >
                <div>Proceed</div>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="section" v-if="stage === 2" style="margin-top: 50px">
      <div class="main">
        <div class="inf__onboarding_content">
          <upload-image-with-cropper-v2 name="images" />
          <button
            v-if="this.sbucket.urls.length === 3 && !showLoader"
            @click="newProduct"
            title="Proceed"
            class="form-control"
            style="
              background: #63b8a0;
              border-color: #63b8a0;
              color: #fff !important;
              height: 50px;
            "
          >
            Proceed
          </button>
          <action-button class="grey-button" v-if="showLoader"
            ><loader-component
          /></action-button>
          <button
            class="grey-button disabled"
            disabled
            v-if="this.sbucket.urls.length < 3"
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
    <company-create-modal :show="showModal" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrganisationRequest from "@/model/request/OrganisationRequest";
import OrganisationResponse from "@/model/response/OrganisationResponse";
import CompanyCreateModal from "@/components/modal/CompanyCreateModal.vue";
import ProductRequest from "@/model/request/ProductRequest";
import UploadImageWithCropperV2 from "@/components/form/input/UploadImageWithCropperV2.vue";
import swal from "sweetalert";
import ActionButton from "@/components/shared/ActionButton.vue";
import LoaderComponent from "@/components/shared/LoaderComponent.vue";

export default {
  name: "AddProductsNew",
  components: {
    LoaderComponent,
    ActionButton,
    UploadImageWithCropperV2,
    CompanyCreateModal,
  },
  data() {
    return {
      stage: 1,
      showModal: false,
      showLoader: false,
      model: OrganisationRequest.prototype.organisationUpdateRequest(),
      orgModel: OrganisationRequest.prototype.organisationReadSingleRequest(),
      readmodel: OrganisationRequest.mallReadByCustomerIdRequest,
      productModel: ProductRequest.productCreateRequest,
      urlMax: 3,
      mall: false,
    };
  },
  computed: {
    ...mapState({
      sbucket: (state) => state.sbucket,
      organisations: (state) => state.organisation.organisations,
      auth: (state) => state.auth,
      organisation: (state) => state.organisation,
      product: (state) => state.product,
    }),
    categories: function () {
      return new OrganisationResponse().readOrganisationCategoryResponse().data;
    },
  },
  methods: {
    goToNewMall() {
      this.close(".success__modal");
      this.$router.push("/mall-registered");
    },
    goToMall() {
      this.$router.push("/mall-all");
    },
    async createCompany() {
      this.model.organisationLogo = this.sbucket.s3bucketResponse.url;
      this.model.organisationPhone = this.auth.userInfo.customerPhoneNumber;
      this.model.organisationEmail = this.auth.userInfo.customerEmail;
      this.model.organisationCustomerId = this.auth.userInfo.customerId;
      let res = await this.$store
        .dispatch("organisation/editMall", this.model)
        .then();
      if (res.responseCode === "00") {
        this.uploadProfile(".success__modal");
      }
      await this.$store.dispatch("organisation/updateOrganisations", {
        customerId: this.auth.userInfo.customerId,
      });
      localStorage.organisationId = this.model.organisationId;
      this.orgModel.organisationId = localStorage.organisationId;
      console.log(this.model);
      // await this.$router.push("/mall-registered")
      this.hide();
    },
    onSelect({ name, iso2, dialCode }) {
      this.model.organisationCountryCode = dialCode;
      console.log(name, iso2, dialCode);
    },
    filterBadChar() {
      this.model.productLocalCurrencyPrice =
        this.model.productLocalCurrencyPrice.replace(/[^A-Z0-9\s]/i, "");
      this.model.productUsdPrice = this.model.productUsdPrice.replace(
        /[^A-Z0-9\s]/i,
        ""
      );
      this.model.productQuantity = this.model.productQuantity.replace(
        /[^A-Z0-9\s]/i,
        ""
      );
      this.model.productMaxDeliveryTime =
        this.model.productMaxDeliveryTime.replace(/[^A-Z0-9\s]/i, "");
      this.model.productMinDeliveryTime =
        this.model.productMinDeliveryTime.replace(/[^A-Z0-9\s]/i, "");
    },
    close(className) {
      this.class1 = className;
      this.stage++;
      document.querySelector(className).style.opacity = 0;
      setTimeout(() => {
        document.querySelector(className).style.display = "none";
      }, 500);
    },
    uploadProfile(className) {
      this.class1 = className;
      document.querySelector(className).style.display = "flex";
      setTimeout(() => {
        document.querySelector(className).style.opacity = 1;
      }, 500);
    },
    async newProduct() {
      this.showLoader = true;
      if (this.sbucket.urls.length < this.urlMax) {
        swal("Error", `Please select at least  ${this.urlMax} image(s)`);
        this.showLoader = false;
        return;
      }
      // if (this.model) {
      // let productImage = { productImage: this.sbucket.urls.join()}
      this.productModel.productOrganisationId =
        localStorage.getItem("organisationId");
      this.productModel.productImage = this.sbucket.urls.join();
      this.productModel.productQuantity = "2";
      this.productModel.productMaxDeliveryTime = "3";
      this.productModel.productMinDeliveryTime = "2";

      // this.$store.commit("product/updateProductFormObject", productImage);
      let res = await this.$store
        .dispatch("product/createProduct", this.productModel)
        .then();
      if (res.responseCode === "00") {
        this.uploadProfile(".success__modal");
      }
      this.showLoader = false;
      console.log(this.model);
      console.log(this.product.productFormObject);
    },
  },
  mounted() {
    this.productModel = {};
    this.mall = localStorage.getItem("mall");
    this.model.organisationId = "Select Company";
    this.model.organisationCurrency = "Select company";
    this.model.organisationRedirectPlatform = "Custom";
    if (this.organisations.length === 0) {
      this.readmodel.customerId = this.auth.userInfo.customerId;
      this.$store.dispatch("organisation/updateMalls", this.readmodel);
    }
  },
};
</script>

<style scoped>
.section {
  margin: 0;
  height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.main {
  padding: 0;
}

.div-block-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
}

.form__input {
  position: relative;
  height: 44px;
  margin-bottom: auto;
  padding: 10px 14px 10px 48px;
  border-style: solid;
  border-width: 1px;
  border-color: #d0d5dd;
  border-radius: 50rem;
  background-color: #fff;
  font-family: Figtree, sans-serif;
  font-size: 0.875rem;
  line-height: 1.7142857142857142;
  font-weight: 500;
}

.modal_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 32px;
  gap: 24px;

  position: relative;
  width: 357px;
  height: 334.57px;

  background: #ffffff;
  border-radius: 16px;
}

.modal_content h1 {
  font-family: "Faktum", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.05em;

  /* Gray/900 */

  color: #101828;
}

.modal_content p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  text-align: center;

  /* Gray/500 */
  margin-top: 16px;
  color: #667085;
}

.btn__submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 100%;
  height: 44px;

  background: #489e85;
  /* Primary/600 */

  border: 1px solid #489e85;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 100px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* White */

  color: #ffffff;
  margin-top: 8px;
  /* Inside auto layout */
}

.is-active {
  color: #489e85;
}

.grey-button {
  background: #d9d9d9;
  border-radius: 6px;
  height: 50px;
  width: 455px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}
</style>
