<template>
  <div>
    <slot/>
  </div>
</template>

<script>

import "@/assets/css/normalisenew.css";
import "@/assets/css/webflownew.css";
import "@/assets/css/apvertise-new.css";
export default {
  name: "HomeLayout"
}
</script>


<style scoped>
  .navbar {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    border-bottom: 1px solid #eaecf0;
    background-color: #fff;
}
</style>

<!--<style scoped src="../../assets/css/konetpay-payment-gateway.webflow.css">-->

<!--</style>-->