<template>
  <div class="funding_modal">
    <div class="pop-up-back"></div>
    <div class="wallet_modal_content">
      <div class="wallet-modal-frame">
        <div class="wallet-modal_text">
          <h1 class="wallet-modal_h" v-if="fund === true">How much would you like to withdraw?</h1>
          <h1 class="wallet-modal_h" v-else>Payout</h1>
          <p class="wallet-modal_p" v-if="fund === true"></p>
          <p class="wallet-modal_p" v-else>Enter the amount you would like to payout?</p>
        </div>
        <div class="w-form">
          <form id="email-form" name="email-form" data-name="Email Form" method="get" class="wallet-form" @submit.prevent="makePayout">
            <label for="name-2" class="wallet-input-label">Amount ($)</label><input type="number" class="wallet-input-round w-input" maxlength="256" name="name-2" data-name="Name 2" placeholder="Enter amount" id="name-2" v-model="model.payoutAmount" required>

        <div class="withdraw_modal" style="margin-top: 44px">
          <button type="button" @click="close('.funding_modal')" class="close_btn">Cancel</button>
          <input type="submit" style="cursor: pointer;" class="btn__submit w-button" v-if="!showLoader" value="Continue">
          <div v-else class="btn__submit w-button">
            <b-spinner
                small
                style="color: #fff"
            />
         </div>

        </div>
        </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "FundingModal",
  data(){
    return{
      amount: "",
      showLoader: false,
      userType: "user",
      model: {
        payoutReference: "",
        payoutCustomerId: "",
        payoutAmount: "",
      },
    }
  },
  props:["fund"],
  methods: {
    openPay(){
      this.showLoader = true;
      this.pay = true;
      if (this.model.payoutAmount !== ""){
        this.$store.dispatch("manualFunding/updateAmount", this.model.payoutAmount)
        localStorage.setItem("payoutAmount", this.model.payoutAmount)
        document.querySelector('.funding_modal').style.opacity = 0;
        document.querySelector('.payment-method_modal').style.display = "flex";
        setTimeout(()=>{
          document.querySelector('.funding_modal').style.display = "none";
          document.querySelector('.payment-method_modal').style.opacity = 1;
        }, 100)
      }
      this.showLoader = false;
    },
    callback() {
      console.log("Payment made, verify payment");
    },
    close(className) {
      this.model.payoutAmount = "";
      document.querySelector(className).style.opacity = 0;
      setTimeout(()=>{
        document.querySelector(className).style.display = "none";
      }, 500)
    },
    hide() {
      this.$refs["payout-modal"].hide();
    },
    makePayout() {
      this.model.payoutReference = this.reference();
      this.model.payoutCustomerId = this.auth.userInfo.customerId;
      this.$store.dispatch("transaction/payoutCreate", this.model).then(() => {
        this.$store.dispatch("transaction/payoutReadByCustomerId", {
          customerId: this.auth.userInfo.customerId,
          readAll: "ALL",
        });
        document.querySelector('.funding_modal').style.opacity = 0;
        setTimeout(()=>{
          document.querySelector('.funding_modal').style.display = "none";
        }, 100)
      });
    },
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 30; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
  },
  mounted() {
    this.model.payoutAmount = "";
    this.userType = localStorage.getItem("userType")
  },
  created() {
    const script = document.createElement("script");
    script.src =
      window.__env.app.stage === "dev"
        ? "https://ravemodal-dev.herokuapp.com/v3.js"
        : "https://checkout.payout.com/v3.js";
    document.getElementsByTagName("head")[0].appendChild(script);
  },
  computed: {
    ...mapState(["auth", "transaction"]),
    currency: function () {
      if (this.transaction.transactions.accounts.length < 1) return "---";
      else return this.transaction.transactions.accounts[0].accountCurrency;
    },
    balance: function () {
      if (this.transaction.transactions.accounts.length < 1) return "0.00";
      else
        return this.transaction.transactions.accounts.filter(
          (it) => it.accountClass === "TIER_1_SAVINGS"
        )[0].accountBalance;
    },
    earnings: function () {
      if (this.transaction.transactions.accounts.length < 1) return "0.00";
      else
        return this.transaction.transactions.accounts.filter(
          (it) => it.accountClass !== "TIER_1_SAVINGS"
        )[0].accountBalance;
    },
  },
  watch: {
    // whenever question changes, this function will run
    show: function () {
      this.model = {
        payoutReference: "",
        payoutCustomerId: "",
        payoutAmount: "",
      };
      this.$refs["payout-modal"].show();
    },
  },
}
</script>

<style scoped>
.funding_modal {
  justify-content: center;
  align-items: center;
  display: none;
  opacity: 0;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
.pop-up-back {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(32, 32, 32, 0.6);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
.wallet_modal_content {
  justify-content: center;
  justify-items: center;
  z-index: 99;
  width: 408px;
  background-color: #fff;
  border-radius: 16px;
  align-self: center;
  padding: 24px;
  position: relative;
}
.funding_modal {
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
.wallet-modal-frame {
  padding: 0;
  width: 100%;
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;
  flex-direction: column;
  align-items: stretch;
  display: flex;
}
.wallet__modal-icon {
  height: 40px;
  align-self: flex-start;
  display: block;
}
.wallet-modal_text {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.wallet-modal_h {
  color: #1d2939;
  text-align: center;
  font-family: Figtree, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75;
}
.wallet-modal_p {
  color: #667085;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.42857;
}
.wallet-form {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  display: flex;
  border-radius: 8px;
}
.wallet-input-label {
  color: #344054;
  margin-bottom: 0;
  font-size: .875rem;
  line-height: 1.42857;
}
.btn__submit {
  height: 44px;
  width: 130px;
  color: #fff;
  cursor: pointer;
  background-color: #489e85;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-family: Figtree, sans-serif;
  font-weight: 600;
  text-decoration: none;
  display: flex;
}
a:hover{
  color: #fff;
  opacity: 1;
}
.btn__submit:hover{
  color: #fff;
  opacity: 1;
}
.w-button:hover{
  color: #fff;
  opacity: 1;
}
.w-inline-block:hover{
  opacity: 1;
  color: white;
}
.wallet-input-round {
  background-image: url("../../assets/images/Help-icon.svg");
  background-position: 98%;
  background-repeat: no-repeat;
  background-size: auto;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  margin-bottom: 0;
  height: 50px;
  padding: 10px 38px 10px 14px;
}

.visible{
  opacity: 1;
  display: flex;
}

.close_btn{
  //width: 30%;
  width: 130px;
  height: 44px;
  color: #160042;
  letter-spacing: -.02em;
  background-color: #fff;
  border: 1px solid #e4e6f1;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  transition: border-color .3s, background-color .3s;
  display: flex;
}

.withdraw_modal{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  margin-left: 24px;
}


</style>