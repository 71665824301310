<template>
  <router-view></router-view>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  methods: {},
  created() {
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "https://play-apvertise.com/js/webflow.js");
    plugin.async = true;
    document.head.appendChild(plugin);
  },
  computed: {},
  mounted() {},
};
</script>
