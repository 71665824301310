<template>
  <div>
    <revamp-create-campaign-form-one/>

  </div>
</template>

<script>
import RevampCreateCampaignFormOne from "@/components/form/RevampCreateCampaignFormOne.vue";

export default {
  name: "RevampCreateCampaign",
  data(){
    return{
      stage: 1,
    }
  },
  components: {  RevampCreateCampaignFormOne },
  methods:{
    increaseState(){
      console.log("kkk")
      this.stage++
    },

  }
};
</script>

<style scoped>

</style>