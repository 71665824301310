<template>
  <home-layout>
    <main class="main-wrapper" style="background: #fff">
      <div class="section ranking">
        <div class="main">
          <div v-if="loading" style="display: flex; flex-direction: column; align-items: center; justify-content: center">
            <b-spinner style="color: #489E85"/>
            <h4>Loading...</h4>
          </div>
          <div class="inf__onboarding_content is-ranking" v-else-if="socialMedia.responseCode === '00'">
            <div class="inf__onboarding_text">
              <div class="rankings"><img src="../../../public/images/Basic.svg" loading="lazy" alt="" class="ranking__image" :class="{'active' : rankingUser === 'Basic'}"><img src="../../../public/images/Bronze.svg" loading="lazy" alt="" class="ranking__image"  :class="{'active' : rankingUser === 'Bronze'}"><img src="../../../public/images/Gold.svg" loading="lazy" alt="" class="ranking__image"  :class="{'active' : rankingUser === 'Gold'}"><img src="../../../public/images/Platinum.svg" loading="lazy" alt="" class="ranking__image" :class="{'active' : rankingUser === 'Platinum'}"></div>
              <h3 class="inf__onboarding-h">Congratulations</h3>
              <p class="inf__onboarding-p">You are ranked <span class="rank__name">{{rankingUser}}</span> based on your social media</p>
            </div>
            <a class="btn" @click="goToDashboard">
              <div>Proceed</div>
            </a>
          </div>
          <div class="inf__onboarding_content is-ranking" v-else>
            <div class="inf__onboarding_text" style="display: flex; flex-direction: column; align-items: center; justify-content: center">
              <div class="rankings"><img src="../../../public/images/error.svg" loading="lazy" alt="" class="ranking__image active"></div>
              <h3 class="inf__onboarding-h">Oops...</h3>
              <p class="inf__onboarding-p">{{socialMedia.responseMessage}}</p>
            </div>
            <a class="btn" @click="goToSettings">
              <div>Proceed to Settings</div>
            </a>
          </div>
          <div class="lottie-wrapper">
            <div data-w-id="47eafa05-e47d-8f97-8cc6-22cd7fc688f9" data-animation-type="lottie" data-src="../documents/confetti-2.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-default-duration="1.5833333333333333" data-duration="0"></div>
          </div>
        </div>
      </div>
    </main>
  </home-layout>
</template>

<script>
import HomeLayout from "@/views/layout/HomeLayout.vue";
import { mapState } from "vuex";
import SocialMediaRequest from "@/model/request/SocialMediaRequest";

export default {
  name: "DashboardComplete",
  components: { HomeLayout },
  data(){
    return{
      enrollModel: SocialMediaRequest.enroll,
      twitterModel: SocialMediaRequest.addTwitter,
      tiktokModel: SocialMediaRequest.addTiktok,
    }
  },
  methods:{
    goToDashboard(){
      this.$router.push("/dashboard")
    },
    goToSettings(){
      this.$router.push("/account-settings")
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.auth.userInfo,
      authorizeState: (state) => state.socialMedia.authorizeState,
      challengeCode: (state) => state.socialMedia.challengeCode,
      socialMediaDetails: (state) => state.socialMedia.socialMediaDetails,
      loading: (state) => state.socialMedia.loading,
      socialMedia: (state) => state.socialMedia.socialMedia
    }),
    rankingUser(){
      let rank = 'Basic';
      this.socialMediaDetails.forEach((social) => {
        if (social.socialMediaDetailsFollowers >= 3000 && social.socialMediaDetailsFollowers < 10000){
          rank = "Bronze"
        }
        else if (social.socialMediaDetailsFollowers >= 10000 && social.socialMediaDetailsFollowers < 30000){
          rank = "Gold"
        }
        else if (social.socialMediaDetailsFollowers > 30000){
          rank = "Platinum"
        }
      })
      return rank
    }
  },
  beforeMount() {
    let social = localStorage.getItem("social")
    if(social === "twitter"){
      let url = window.location.search;
      const urlParams = new URLSearchParams(url);
      let code = urlParams.get("code");
      this.twitterModel.code = code;
      this.twitterModel.code_challenge = 'ST03s5';
      this.$store.dispatch("socialMedia/authorizeTwitter", this.twitterModel);
    }
    if(social === "tiktok"){
      let url = window.location.search;
      const urlParams = new URLSearchParams(url);
      let code = urlParams.get("code");
      this.tiktokModel.code = code;
      this.$store.dispatch("socialMedia/authorizeTiktok", this.tiktokModel);
    }
    if(social === "instagram"){
      let url = window.location.search;
      const urlParams = new URLSearchParams(url);
      let code = urlParams.get("code");
      this.tiktokModel.code = code;
      this.$store.dispatch("socialMedia/authorizeInstagram", this.tiktokModel);
    }

  },
  mounted() {

  }

};
</script>

<style scoped>
  .section{
    margin: 0;
  }


  h1,h2,h3,h4,h5,h6,p{
    margin: 0;
  }

  h1{
    font-size: 20px;
  }

  p{
    color: #475467;
    line-height: 1.375;
    font-size: 16px;
  }

  .btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 18px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    border-radius: 50rem;
    background-color: #489e85;
    color: #fff !important;
    font-weight: 600;
    text-decoration: none;
    background-image: none !important;
    width: 100%;
  }

  .btn:hover{
    color: #489e85 !important;
  }

</style>