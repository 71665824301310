<template>
<!--    /<div v-if="auth.regScreen !== 'success'" class="right-section-2">-->
<!--      <div class="social-media-signup-wrapper">-->
<!--        <div class="div-block-59-copy" style="margin-top: 30px; margin-bottom: -5px;">-->
<!--          <img src="@/assets/images/Logo.svg" loading="lazy" alt="">-->
<!--        </div>-->
<!--        <div class="create-account-block">-->
<!--          <h1 class="heading-21">Create your account</h1>-->
<!--          <socialite-auth-button type="register"/>-->
<!--          <div class="divide">-->
<!--            <div class="div-block-216"></div>-->
<!--            <div class="div-block-216-copy">-->
<!--              <div class="text-block-122">OR</div>-->
<!--            </div>-->
<!--            <div class="div-block-216"></div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <register-form/>-->
<!--        <div class="sign-up-block">-->
<!--          <router-link to="/logon" class="link-block-6 w-inline-block">-->
<!--            <div class="text-block-23">Already have an account? <span class="text-span-4">Log in</span></div>-->
<!--          </router-link>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  <div>
    <home-layout v-if="auth.regScreen === 'reg' || auth.regScreen === 'success'">
      <main class="main-wrapper" style="background-color: #FFFFFF">
        <div class="section">
          <div class="wrapper" style="display: grid">
            <div id="w-node-_87bd402b-7670-11cd-2caa-9c5a6de2e5dd-3b72a90b" class="col is-image" style="height: 100%">
              <div class="image_wrapper"></div>
            </div>
            <div id="w-node-ccead28d-c7cd-0dbc-2ebe-872781b23751-3b72a90b" class="col right">
              <div class="block">
                <a href="#" class="logo__block"><img src="../../../public/images/Apvertise-logo.svg" loading="lazy" alt=""></a>
                <div class="message__block">
                  <h3 class="form__heading">Well, Hello there!</h3>
                  <p class="form__p" style="font-size: 15px; font-weight: 300">We’re so glad you’re ready to join Apvertise. Let’s start by creating your account.</p>
                </div>
                <socialite-auth-button style="margin-bottom: -5px"/>
                <div class="form__block w-form">
                  <div class="or">
                    <div class="divider"></div>
                    <div>OR</div>
                    <div class="divider"></div>
                  </div>
                  <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form" @submit.prevent="initiateEnrollment">
                    <div class="_2-col">
                      <div class="form__item"><label for="First-Name" class="form__label">First name</label>
                        <div class="input"><input type="text" class="form__input w-input" style="text-transform: capitalize" maxlength="256" name="First-Name" data-name="First Name" placeholder="First Name" id="First-Name" required v-model="model.customerFirstName"><img src="../../../public/images/user.svg" loading="lazy" alt="" class="form__input-icon"></div>
                      </div>
                      <div class="form__item"><label for="First-Name-2" class="form__label">Last name</label>
                        <div class="input"><input type="text" class="form__input w-input" style="text-transform: capitalize" maxlength="256" name="Last-Name" data-name="Last Name" placeholder="Last Name" id="Last-Name" required v-model="model.customerLastName"><img src="../../../public/images/user.svg" loading="lazy" alt="" class="form__input-icon"></div>
                      </div>
                    </div>
                    <div class="form__item"><label for="First-Name-2" class="form__label">Email</label>
                      <div class="input"><input type="email" class="form__input w-input" maxlength="256" name="Email" data-name="Email" placeholder="name@someone.com" id="Email" required  v-model="model.customerEmail"><img src="../../../public/images/mail.svg" loading="lazy" alt="" class="form__input-icon"></div>
                    </div>
                    <div class="form__item"><label for="First-Name-2" class="form__label">Phone</label>
                      <div class="input"><input type="number" class="form__input is-phone w-input" maxlength="15" onKeyPress="if( this.value.length == 15 ) return false; "  name="Phone" data-name="Phone" placeholder="Enter your phone number" id="Phone-2" required style="padding-left: 70px" v-model="model.customerPhoneNumber"><select id="CountryCode" name="CountryCode" data-name="CountryCode" required="" class="country__code w-select" v-model="model.customerCountryCode">
                        <option value="234" selected>NGN</option>
                        <option value="1">CAD</option>
                        <option value="233">GHA</option>
                        <option value="1">USA</option>
                        <option value="250">RWA</option>
                      </select></div>
                    </div>
                    <div class="form__item"><label for="First-Name-2" class="form__label">Password</label>
                      <div class="input"  @click="focus1 = true"><input type="password" class="form__input w-input password" maxlength="6" minlength="6" name="Password" data-name="Password" placeholder="Enter a 6 digit passowrd" id="Password" required v-model="modelComplete.password" @keypress="isNumber"><img src="../../../public/images/lock.svg" loading="lazy" alt="" class="form__input-icon lock"></div>
                    </div>
                    <div class="form__item"><label for="First-Name-2" class="form__label">Country</label>
                      <div class="input" style="width: 100%"><select id="field" name="field" data-name="Field" style="width: 100%;" required="" class="form__input"  v-model="model.customerCountry">
                        <option value="Nigeria">Nigeria</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Canada">Canada</option>
                      </select><img src="../../../public/images/flag.svg" loading="lazy" alt="" class="form__input-icon"></div>
                    </div>
                    <div v-if="auth.authLoading" class="btn__submit w-button">
                      <b-spinner
                        small
                        style="color: #fff"
                      />
                    </div>
                    <input type="submit" value="Create account" data-wait="Please wait..." class="btn__submit w-button" v-else>
                  </form>
                  <div class="login__block">Already have an account?<a @click="goToLogin" class="login__link">Login</a>
                  </div>
                  <div class="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

<!--      <div data-w-id="d84ad800-4a62-6d77-00aa-5dbc2506cb90" class="success__modal">-->
<!--        <div class="pop-up-back"></div>-->
<!--        <div data-w-id="d84ad800-4a62-6d77-00aa-5dbc2506cb92" class="modal_content"><img src="../../../public/images/Exit-Icon.svg" loading="lazy" width="18" alt="" class="pop-up-exit-button">-->
<!--          <div class="modal_c"><img src="../../../public/images/success.svg" loading="lazy" alt="" class="modal__image">-->
<!--            <div class="modal_text">-->
<!--              <h1 class="modal_h">Success message</h1>-->
<!--              <p class="modal_p">We were unable to verify the URL. Kindly re-enter the correct URL.</p>-->
<!--            </div>-->
<!--            <a href="#" class="btn__submit w-button">Button CTA</a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </home-layout>
    <home-layout v-if="auth.regScreen === 'password'">
      <main class="main-wrapper">
        <div class="section">
          <div class="wrapper" style="display: grid">
            <div id="w-node-_87bd402b-7670-11cd-2caa-9c5a6de2e5dd-3b72a90b" class="col is-image" style="height: 100%">
              <div class="image_wrapper"></div>
            </div>
            <div id="w-node-ccead28d-c7cd-0dbc-2ebe-872781b23751-67120eb4" class="col right">
              <div class="block">
                <a href="#" class="logo__block w-inline-block"><img src="../../../public/images/Apvertise-logo.svg" loading="lazy" alt=""></a>
                <div class="message__block">
                  <h3 class="form__heading">Hello <span style="text-transform: capitalize">{{name}}</span>, you’ve got mail!</h3>
                  <p class="form__p" style="font-size: 15px; font-weight: 300"><span style="text-transform: capitalize">{{name}}</span>, a mail has been sent to you for verification. Please provide the 6 digit code sent to your email to proceed.</p>
                </div>
                <div class="form__block w-form">
                  <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form" @submit.prevent="completeEnrollment">
                    <div class="code__grid">
                      <v-otp-input
                        ref="otpInput"
                        input-classes="code__input"
                        separator=""
                        :num-inputs="6"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        :length="6"
                        v-model="modelComplete.otp"
                        @on-change="handleOnChange"
                        @on-complete="handleOnComplete"
                      />
                    </div>
                    <div v-if="auth.authLoading" class="btn__submit w-button">
                      <b-spinner
                        small
                        style="color: #fff"
                      />
                    </div>
                    <input type="submit" value="Verify Email" data-wait="Please wait..." class="btn__submit w-button" v-else>
                  </form>
                  <div class="login__block">Didn&#x27;t get the code?<a href="#" class="login__link" @click="sendOtp"><span v-if="auth.authLoading"></span><span v-else>Resend OTP</span></a>
                  </div>
                  <div class="w-form-done"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </home-layout>
    <successful-register v-if="auth.regScreen === 'success'"/>
  </div>
</template>

<script>
// import RegisterForm from "@/components/form/RegisterForm";
import SuccessfulRegister from "@/views/authentication/SuccessfulRegister";
import { mapState } from "vuex";
import HomeLayout from "@/views/layout/HomeLayout.vue";
import AuthRequest from "@/model/request/AuthRequest";
import SocialiteAuthButton from "@/components/form/input/SocialiteAuthButton.vue";
// import SocialiteAuthButton from "@/components/form/input/SocialiteAuthButton";

export default {
  name: "Register",
  computed:{
    ...mapState
    (["auth"])
  },
  components:{
    SocialiteAuthButton,
    HomeLayout,
    SuccessfulRegister,
  },
  data() {
    return {
      name: "",
      model: new AuthRequest().initiateEnrollment(),
      modelComplete: new AuthRequest().completeEnrollment(),
      timer: "2:00",
      timerCount: 0,
      focus1: false,
      isLogin: false,
    };
  },
  methods: {
    handleOnComplete(value) {
      console.log('OTP completed: ', value);
      localStorage.setItem("otp", value)
      this.$store.commit("auth/updateForgetPasswordScreen", "password");
    },
    handleOnChange(value) {
      console.log('OTP changed: ', value);
    },
    goToLogin(){
      this.$router.push("/logon")
    },
    async logOut() {
      const result = await this.$gAuth.signOut();
      this.isLogin = false;
      console.log(`result`, result);
    },
    async login() {
      const googleUser = await this.$gAuth.signIn();
      console.log("googleUser", googleUser);
      console.log("getId", googleUser.getId());
      console.log("getBaseProfile", googleUser.getBasicProfile());
      console.log("getAuthResponse", googleUser.getAuthResponse());
      console.log(
        "getAuthResponse$G",
        this.$gAuth.GoogleAuth.currentUser.get().getAuthResponse()
      );
      this.isLogin = this.$gAuth.isAuthorized;
    },
    onSelect({ name, iso2, dialCode }) {
      this.model.customerCountryCode = dialCode;
      console.log(name, iso2, dialCode);
    },
    goToOtp() {
      this.$store.commit("auth/updateRegScreen", "otp");
    },
    isNumber(event) {
      if (!/\d/.test(event.key) && event.key !== ".")
        return event.preventDefault();
    },
    initiateEnrollment() {
      localStorage.setItem("username", this.model.customerFirstName);
      localStorage.setItem("password",  this.modelComplete.password);
      this.name = localStorage.getItem("username");
      console.log(this.model);
      this.$store.dispatch("auth/initiateEnrollment", this.model);
    },
    completeEnrollment() {
      this.modelComplete.customerPhoneNumber = this.model.customerPhoneNumber;
      this.modelComplete.password = localStorage.getItem("password");
      this.modelComplete.passwordConfirmation = localStorage.getItem("password");
      this.modelComplete.otp = localStorage.getItem("otp");
      this.modelComplete.countryCode = this.model.customerCountryCode;
      if (localStorage.inviteId != null)
        this.modelComplete.hostId = localStorage.inviteId;
      this.modelComplete.referralCode = localStorage.referralCode;
      this.modelComplete.shareId = localStorage.shareId;
      console.log(this.modelComplete);
      this.$store.dispatch("auth/completeEnrollment", this.modelComplete);
    },
    sendOtp() {
      this.$store
        .dispatch("auth/resendOtpInApp", {
          phoneNumber: this.model.customerEmail,
          countryCode: "234",
        })
        .then(() => {
          console.log("Timer...");
          this.timerCount = 120;
        });
    },
    createPayoutAccount() {
      this.model.accountCustomerId = this.auth.userInfo.customerId;
      this.model.username = this.auth.userInfo.customerEmail;
      this.model.accountBankName = this.maintenance.bankListFlw.data.filter(
        (it) => it.code === this.model.accountBankCode
      )[0].name;
      this.$store
        .dispatch("accountPayout/createAccountPayout", this.model)
        .then(() => {
          this.$store.dispatch("accountPayout/updateAccountPayout", {
            customerId: this.auth.userInfo.customerId,
          });
          this.model.otp = "";
          this.timerCount = 0;
        });
    },
  },
  mounted() {
    this.model.customerCountryCode = "234";
    this.model.customerCountry = "Nigeria";
    if (this.$route.params.inviteId != null)
      localStorage.inviteId = this.$route.params.inviteId;
    this.$store.commit("auth/updateRegScreen", "reg");
    const eye = document.querySelector(".lock");
    const passwordField = document.querySelector("input[type=password]");

    eye.addEventListener("click", () => {
      if (passwordField.type === "password"){
        passwordField.type = "text";
      } else {
        passwordField.type = "password";
      }
    });
  }
};
</script>

<style scoped>
.section{
  margin: 0;
}
.wrapper{
  background: #FFFFFF !important;
}
h1, h2, h3, h4, h5, h6, p{
  margin: 0;
}

.w-input{
  padding: 10px 14px 10px 48px;
  margin: 0;
  height: 44px;
  border-style: solid;
  border-width: 1px;
  border-color: #d0d5dd;
  border-radius: 50rem;
  background-color: #fff;
  font-family: Figtree, sans-serif;
  font-size: 0.875rem;
  line-height: 1.7142857142857142;
  font-weight: 500;
}

.code__input{
  margin-right: 16px;
}

.w-input::placeholder{
  color: rgba(52, 64, 84, 0.6);
}

.btn__submit {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50rem;
  background-color: #489e85;
  font-family: Figtree, sans-serif;
  font-weight: 600;
  cursor: pointer;
}

.login__link{
  font-weight: 500;
}

.divider {
  margin: 0;
}
.btn__google-signup {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  padding: 10px 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  border-style: solid;
  border-width: 1px;
  border-color: #d0d5dd;
  border-radius: 50rem;
  font-family: Figtree, sans-serif;
  color: #344054;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.country__code {
  position: absolute;
  top: 50%;
  display: block;
  width: 66px;
  margin-bottom: 0px;
  padding-right: 0px;
  border: 1px none #000;
  background-color: transparent;
  outline-color: #333;
  outline-offset: 0px;
  outline-style: none;
  outline-width: 3px;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  border-radius: 20px;
}

.form__input {
  position: relative;
  height: 44px;
  margin-bottom: auto;
  padding: 10px 14px 10px 48px;
  border-style: solid;
  border-width: 1px;
  border-color: #d0d5dd;
  border-radius: 50rem;
  background-color: #fff;
  font-family: Figtree, sans-serif;
  font-size: 0.875rem;
  line-height: 1.7142857142857142;
  font-weight: 500;
}

.logo__block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}
</style>