<template>
  <div class="div-block-8"  style="background-color: #FFFFFF; margin: 0; padding-top: 115px">
    <div class="dashboard__wrapper" >
      <div class="dahboard-title-wrapper">
        <div class="left-title-block" style="width: 30%">
          <h5>Dashboard</h5>
          <div class="text-block-19">Hello, {{userInfo.customerFirstName}} {{userInfo.customerLastName}} 👋🏼</div>
        </div>
        <div class="right-title-block" style="width: 100%">
          <a href="#" class="button is-secondary" @click="goToWallet">View Wallet</a>
          <a href="#" class="button"  @click="goToCreateCampaign">Create Campaign</a>
        </div>
      </div>
      <div class="dashboard-cards-wrapper">
        <div id="w-node-c3cf6048-5ba5-5f99-9444-a6e39447d893-47d20157" class="div-block-16">
          <div>
            <div class="text-block-25">Number of Campaigns</div>
          </div>
          <div class="div-block-19">
            <h4 class="heading-5">{{campaign.myCampaigns.data.length}}</h4>
            <a href="#" class="link-block-5"><img src="../../assets/images/arrow-up.svg" loading="lazy" alt="">
              <div class="text-block-26">100%</div>
            </a>
          </div>
        </div>
        <div id="w-node-_464a41cb-34eb-f5cb-acf9-28da5928612b-47d20157" class="div-block-16">
          <div>
            <div class="text-block-25">Wallet Balance</div>
          </div>
          <div class="div-block-19">
            <div class="campaign-1">USD {{ balance | formatAmount }}</div>
            <a href="#" class="link-block-5"><img src="../../assets/images/arrow-up.svg" loading="lazy" alt="">
              <div class="text-block-26">100%</div>
            </a>
          </div>
        </div>
        <div id="w-node-c6aebc29-ff03-a911-e24a-f47eea7a9f5a-47d20157" class="div-block-16">
          <div>
            <div class="text-block-25">Earnings</div>
          </div>
          <div class="div-block-19">
            <h4 class="heading-5">USD {{ sbalance | formatAmount }}</h4>
            <a href="#" class="link-block-5"><img src="../../assets/images/arrow-up.svg" loading="lazy" alt="">
              <div class="text-block-26">100%</div>
            </a>
          </div>
        </div>
      </div>
      <div class="dashboard-campaign-details-wrapper">
        <div id="w-node-cf05465e-98f0-4afb-e62e-fca0ce10cc3d-47d20157" class="campaign-metrics">
          <div data-current="Tab 3" data-easing="ease" data-duration-in="300" data-duration-out="100" class="tabs-2 w-tabs">
            <div class="tabs-menu w-tab-menu" style="justify-content: left; border: none">
              <a data-w-tab="Tab 1" class="tabs w-tab-link" :class="{'w--current' : stage === 1}" @click="stage = 1">
                <div>My Campaigns</div>
              </a>
              <a data-w-tab="Tab 2" class="tabs w-tab-link" :class="{'w--current' : stage === 2}" @click="stage = 2">
                <div>Shared Campaigns</div>
              </a>
              <a data-w-tab="Tab 3" class="tabs w-tab-link" :class="{'w--current' : stage === 3}" @click="stage = 3">
                <div>Online Mall</div>
              </a>
            </div>
            <div class="tabs-content w-tab-content">
              <div data-w-tab="Tab 1" class="w-tab-pane" style="height: 540px; overflow: scroll" :class="{'w--tab-active' : stage === 1}">
                <div class="createdcampaigns" v-if="campaign.myCampaigns.data.length > 0">
                  <a href="#" class="created-campaigns-link" v-for="(campaign, index) in campaign.myCampaigns.data" :key="index" @click="selectCampaign(campaign.campaignId)">
                    <div class="created-campaigns">
                      <div class="left-campaign-block">
                        <div class="campaign-image-block"><img loading="lazy" :src="campaign.organisationLogo" alt="" style="width: 40px; height: 40px; border-radius: 50%"></div>
                        <div class="campaign-title-block">
                          <div class="title-wrapper">
                            <h6 style="text-transform: capitalize">{{campaign.campaignName}}</h6>
                            <div class="text-block-21" style="text-align: left">{{campaign.campaignDescription | formatCampaignDescriptionWithEllipsis}}</div>
                          </div>
                          <div class="category-wrapper">
                            <div class="category-tag">{{campaign.organisationType}}</div>
                            <div class="campaign-type awareness" v-if="campaign.campaignType.includes('AWARENESS')">Awareness Campaign</div>
                            <div class="campaign-type awareness" v-else>Acquisition Campaign</div>

                          </div>
                        </div>
                      </div>
                      <div class="right-campaign-block" style="display: flex; justify-content: space-between">
                        <div class="text-block-21">{{new Date(campaign.campaignStartDate).toDateString()}}</div>
                        <div class="div-block-243"><img src="../../assets/images/share-2_1.svg" loading="lazy" alt="">
                          <div class="text-block-39">{{campaign.campaignClickCount}}</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="empty-state home" v-else><img width="150" loading="lazy" src="../../assets/images/promotion-1_1.svg" alt="">
                  <div class="empty-state-content">
                    <div class="empty-state__text">
                      <h5 class="empty-mall__h">No campaign record</h5>
                      <p class="empty-state__p">Click on the <span class="text-span-3">“Create campaign”</span> button <br>to launch your first campaign</p>
                    </div>
                  </div>
                </div>

              </div>
              <div data-w-tab="Tab 2" class="w-tab-pane" style="height: 540px; overflow: scroll" :class="{'w--tab-active' : stage === 2}">
                <div class="sharedcampaigns" v-if="campaign.sharedCampaigns.data.length > 0">
                  <a href="#" class="created-campaigns-link" v-for="(campaign, index) in campaign.sharedCampaigns.data" :key="index" @click="selectCampaigns(campaign.campaignId, campaign.campaignBudgetExhausted)">
                    <div class="created-campaigns">
                      <div class="left-campaign-block">
                        <div class="campaign-image-block"><img loading="lazy" :src="campaign.organisationLogo" alt="" style="width: 40px; height: 40px; border-radius: 50%"></div>
                        <div class="campaign-title-block">
                          <div class="title-wrapper">
                            <h6 style="text-transform: capitalize">{{campaign.campaignName}}</h6>
                            <div class="text-block-21" style="text-align: left">{{campaign.campaignDescription | formatCampaignDescriptionWithEllipsis}}</div>
                          </div>
                          <div class="category-wrapper">
                            <div class="category-tag">{{campaign.organisationType}}</div>
                            <div class="campaign-type awareness" v-if="campaign.campaignType.includes('AWARENESS')">Awareness Campaign</div>
                            <div class="campaign-type awareness" v-else>Acquisition Campaign</div>

                          </div>
                        </div>
                      </div>
                      <div class="right-campaign-block" style="display: flex;justify-content: space-between">
                        <div class="text-block-21">{{new Date(campaign.campaignStartDate).toDateString()}}</div>
                        <div class="div-block-243"><img src="../../assets/images/share-2_1.svg" loading="lazy" alt="">
                          <div class="text-block-39">{{campaign.shareCount}}</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="empty-state home" v-else><img width="150" loading="lazy" src="../../assets/images/promotion-1_1.svg" alt="">
                  <div class="empty-state-content">
                    <div class="empty-state__text">
                      <h5 class="empty-mall__h">No campaign record</h5>
                      <p class="empty-state__p">Click on the <span class="text-span-3">“Create campaign”</span> button <br>to launch your first campaign</p>
                    </div>
                  </div>
                </div>

              </div>
              <div data-w-tab="Tab 3" class="w-tab-pane" style="height: 540px; overflow: scroll" :class="{'w--tab-active' : stage === 3}">
                <div class="createdcampaigns" v-if="malls.length > 0">
                  <a href="#" class="created-campaigns-link" v-for="(mall, index) in malls" :key="index" @click="viewMall(mall.organisationId)">
                    <div class="created-campaigns">
                      <div class="left-campaign-block">
                        <div class="campaign-image-block"><img loading="lazy" :src="mall.organisationLogo" alt="" style="width: 40px; height: 40px; border-radius: 50%"></div>
                        <div class="campaign-title-block">
                          <div class="title-wrapper">
                            <h6 style="text-transform: capitalize"><strong>{{mall.organisationName}}</strong></h6>
                            <div class="text-block-21" style="text-align: left">{{mall.organisationDescription | formatCampaignDescriptionWithEllipsis}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="empty-state home" v-else><img width="150" loading="lazy" src="../../assets/images/premium-store.svg" alt="">
                  <div class="empty-state-content">
                    <div class="empty-state__text">
                      <h5 class="empty-mall__h">No mall record</h5>
                      <p class="empty-state__p">Create a new mall</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div id="w-node-e6836ff2-1447-f402-9325-df1298f85665-47d20157" class="recent-transactions">
          <div class="recent-trnx-title-wrapper">
            <div class="text-block-23">Recent transactions</div>
            <a href="#" class="link-block-4" @click="goToWallet">
              <div class="text-block-24">View all</div>
            </a>
          </div>
          <div class="transaction-list-wrapper" v-if="transaction.transactions.transactions.length > 0" style="height: 540px; overflow: scroll">
            <div class="transaction-list" v-for="(trxn, index) in transaction.transactions.transactions" :key="index">
              <div class="left-transaction-block">
                <img src="../../assets/images/success_1.svg" loading="lazy" alt="" v-if="trxn.drCr === 'CR'">
                <img src="../../assets/images/error_1.svg" loading="lazy" alt="" v-else>
                <div>
                  <div class="trnx-title" style="text-align: left">{{ trxn.narration }}</div>
                  <div class="trnx-date" style="text-align: left">{{new Date(trxn.transactionDate).toDateString()}}</div>
                </div>
              </div>
              <div class="right-transaction-block">
                <div class="trnx-amount" v-if="trxn.drCr === 'CR'" style="width: 100px;">+ {{trxn.amount}} USD</div>
                <div class="trnx-amount debit" v-else style="width: 100px;">- {{trxn.amount}} USD</div>
              </div>
          </div>
           </div>
          <div class="empty-state home" v-else><img src="../../assets/images/money-1_1.svg" loading="lazy" width="150" alt="">
            <div class="empty-state-content">
              <div class="empty-state__text">
                <h5 class="empty-mall__h">Nothing to show</h5>
                <p class="empty-state__p">You have no recent transaction</p>
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrganisationRequest from "@/model/request/OrganisationRequest";

export default {
  name: "NewProfile",
  data(){
    return{
      model: OrganisationRequest.mallReadByCustomerIdRequest,
      stage: 1
    }
  },
  computed: {
    ...mapState({
      campaign: (state) => state.campaign,
      auth: (state) => state.auth,
      organisation: (state) => state.organisation,
      malls: (state) => state.organisation.malls.data,
      analytic: (state) => state.analytic,
      userInfo: (state) => state.auth.userInfo,
      transaction: (state) => state.transaction

    }),
    balance: function () {
      if (this.transaction.transactions.accounts.length < 1) return "0.00";
      else return this.transaction.transactions.accounts[1].accountBalance;
    },
    sbalance: function () {
      if (this.transaction.transactions.accounts.length < 1) return "0.00";
      else return this.transaction.transactions.accounts[0].accountBalance;
    },
  },
  watch: {
    async userInfo(){
      await this.$store.dispatch("campaign/updateMyCampaigns", {
        customerId: this.auth.userInfo.customerId,
      });
      this.$store.dispatch("transaction/queryTransaction", {
        customerId: this.auth.userInfo.customerId,
      });
      this.$store.dispatch("campaign/updateSharedCampaigns", {
        customerId: this.auth.userInfo.customerId,
      });
      this.model.customerId = localStorage.customerId;
      console.log(this.model);
      this.$store.dispatch("organisation/updateMalls", this.model);
    },
    customerId: function () {
      let startEndDate = this.getStartEndDate();
      this.fetchAnalytics(startEndDate.startDate, startEndDate.endDate);
    },
    clicksPerCampaign(){
      this.clicks = this.clicks + this.clicksPerCampaign.data.length;
    },
    sharesPerCampaign(){
      this.shares = this.shares + this.sharesPerCampaign.data.length;
    },
    impressionsPerCampaign(){
      this.impression = this.impression + this.impressionsPerCampaign.data.length;
    },
  },
  methods: {
    viewMall(mall) {
        localStorage.organisationId = mall;
        this.$router.push("/mall-registered");
    },
    goToWallet() {
      this.$router.push("/view-wallet");
    },
    goToCreateCampaign(){
      this.$router.push("/create-new-campaign")
    },
    selectCampaign(_id) {
      localStorage.campaignId = _id;
      this.$router.push(`/campaign-metrics/${_id.hexEncode()}`);
    },
    async selectCampaigns(_id, _campaignBudgetExhausted) {
      await this.$store.dispatch("campaign/updateCampaign", {
        campaignId: _id,
      });
      console.log(_campaignBudgetExhausted);
      localStorage.campaignId = _id;
      console.log(this.$route.path);
      if (!this.$route.path.includes("campaign-details"))
        this.$router.push(`/campaign-details/${_id.hexEncode()}`);

      // } else {
      //   store.commit("updateMessage", {
      //     type: "error",
      //     title: "Error",
      //     body: "Campaign budget has been exhausted!",
      //   });
      // }
    },

  },
  mounted() {
    this.$store.dispatch("campaign/updateMyCampaigns", {
      customerId: this.auth.userInfo.customerId,
    });
    this.$store.dispatch("transaction/queryTransaction", {
      customerId: this.auth.userInfo.customerId,
    });
    this.$store.dispatch("campaign/updateSharedCampaigns", {
      customerId: this.auth.userInfo.customerId,
    });
    this.model.customerId = localStorage.customerId;
    console.log(this.model);
    this.$store.dispatch("organisation/updateMalls", this.model);
  }
};
</script>

<style scoped>
@media screen and (max-width: 510px) {
  .right-campaign-block{
    flex-direction: row !important;
    justify-content: space-between !important;
    width: 100% !important;
  }
  .recent-transactions{
    display: none !important;
  }
}

@media screen and (max-width: 350px) {
  .recent-transactions{
    display: none !important;
  }
}

h1,h2,h3,h4,h5,h6,p{
  margin: 0;
}
.heading-3 {
  color: #1d2939;
  font-weight: 600;
}
h4{
  font-size: 22px;
}
h5 {
  color: #101828;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Faktum, sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
  text-align: left;
}

.campaign-detail-heading {
  color: #1d2939;
  font-family: Faktum, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
}

.campaign-1 {
  color: #fff;
  font-family: Faktum, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
}

h6 {
  font-size: 14px;
  line-height: 18px;
  text-align: left;
}

.text-block-21 {
  color: #344054;
  font-family: Figtree, sans-serif;
  font-size: 14px;
  font-weight: 300;
  margin-top: 4px;
}

.div-block-16 {
  height: 114px;
  background-color: #101828;
  background-image: url("../../assets/images/Ellipse-3.png"), url("../../assets/images/Rectangle.png");
  background-position: 100%, 0 0;
  background-size: cover, 339px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 24px;
  display: flex;
}

.div-block-19 {
  margin: 0;
}

.tabs {
  color: #475467;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 16px;
  font-size: .875rem;
  font-weight: 400;
}

.tabs.w--current {
  color: #387b68;
  background-color: #eaf5f2;
  border-radius: 8px;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
}

.transaction-list-wrapper::-webkit-scrollbar, .w-tab-pane::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.transaction-list-wrapper, .w-tab-pane{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.button:hover{
color: white;
}
.right-campaign-block{
  display: flex!important;
  flex-direction: column;
  justify-content: space-between!important;
}


</style>