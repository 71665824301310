<template>
  <div>
    <div class="fund_wallet">
      <div class="pop-up-back"></div>
      <div class="wallet_modal_content">
        <div class="wallet-modal-frame" style="gap: 8px">
          <div v-if="stage===1">
            <div class="f-sub-heading-regular">Fund your USD wallet, from any currency</div>
            <p class="wallet-modal_p">Enter the amount you would like to add to your wallet. The minimum amount to fund is <span class="text-span-4">$10.00</span></p>
            <div class="w-form" style="margin-bottom: 0" >
              <form  id="email-form" name="email-form" data-name="Email Form" method="get" class="wallet-form" @submit.prevent="stage++; makePayment" >
                <label for="name-2" class="wallet-input-label">Amount ($)</label><input type="number" class="wallet-input-round w-input" min="10" maxlength="256" name="name-2" data-name="Name 2" placeholder="Enter amount" id="name-2" v-model="model.payoutAmount" required>
                <div class="withdraw_modal" style="margin-top: 24px">
                  <button type="button" @click="close('.fund_wallet')" class="close_btn">Cancel</button>
                  <input type="submit"  style="cursor: pointer;" class="btn__submit w-button" v-if="!showLoader && fund === true" value="Continue">
                  <div v-else class="btn__submit w-button">
                    <b-spinner
                        small
                        style="color: #fff"
                    />
                  </div>

                </div>

              </form>
            </div>
          </div>
          <div class="options" v-else>
            <a data-w-id="5dd05fa0-7341-aeba-f177-f7142c7cbff4" href="#" class="link-block-2 " @click="close('.fund_wallet')"><img src="../../../public/images/x_1.svg" loading="lazy" width="24" alt=""></a>
            <div class="payment-method-h1">Select Payment Method</div>
            <p v-if="manualFunding.currency === 'NGN'" class="select-currency-1">
              NGN {{
                (auth.userInfo.ngnPayInRate * model.payoutAmount)
                    | formatAmount
              }}
              gives you USD {{ model.payoutAmount | formatAmount }}
            </p>
            <p v-if="manualFunding.currency === 'GHS'" class="select-currency-1">
              GHS {{
                (auth.userInfo.ghPayInRate * model.payoutAmount)
                    | formatAmount
              }}
              gives you USD {{ model.payoutAmount | formatAmount }}
            </p>
            <a
                :class="
                first ? 'payment__option option-active' : 'payment__option'
              "
                style="text-decoration: none"
                :href="href"
                v-if="manualFunding.currency === 'NGN' || manualFunding.currency === 'GHS'"
            >
              <div class="payment__header">
                <div class="payment__option-text">
                  <img
                      src="@/assets/images/card_avatar.svg"
                      loading="lazy"
                      alt=""
                      class="payment-option-icon"
                  />
                  <div class="text-block-13">Pay with card</div>
                </div>
                <img
                    src="@/assets/images/chevron-right_1.svg"
                    loading="lazy"
                    style="
                    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                      rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0);
                    -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                      rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0);
                    -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                      rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0);
                    transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                      rotateY(0) rotateZ(0deg) skew(0, 0);
                  "
                    alt=""
                    class="payment-toggle-icon"
                />
              </div>
            </a>
            <a
                :class="
                first ? 'payment__option option-active' : 'payment__option'
              "
                style="text-decoration: none"
                @click="makePayment"
                v-else
            >
              <div class="payment__header">
                <div class="payment__option-text">
                  <img
                      src="@/assets/images/card_avatar.svg"
                      loading="lazy"
                      alt=""
                      class="payment-option-icon"
                  />
                  <div class="text-block-13">Pay with card</div>
                </div>
                <img
                    src="@/assets/images/chevron-right_1.svg"
                    loading="lazy"
                    style="
                    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                      rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0);
                    -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                      rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0);
                    -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                      rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0);
                    transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                      rotateY(0) rotateZ(0deg) skew(0, 0);
                  "
                    alt=""
                    class="payment-toggle-icon"
                />
              </div>
            </a>
            <div
                :class="
                second ? 'payment__option option-active' : 'payment__option'
              "
                v-if="manualFunding.currency === 'NGN'"
            >
              <div
                  data-w-id="7fb41fea-8719-d4eb-0f4f-76d152516931"
                  class="faq6_question"
              >
                <div class="payment__header" @click="openSecond">
                  <div class="payment__option-text">
                    <img
                        src="@/assets/images/transfers_avatar.svg"
                        loading="lazy"
                        alt=""
                        class="payment-option-icon"
                    />
                    <div class="text-block-14">Pay with transfer</div>
                  </div>
                  <img
                      src="@/assets/images/chevron-right_1.svg"
                      loading="lazy"
                      alt=""
                      class="payment-toggle-icon"
                  />
                </div>
              </div>
              <!--          :class="first ? 'payment__option option-active' : 'payment__option'"-->
              <div :style="second ? show : hide1" class="faq6_answer">
                <div class="payment-option-content is-active">
                  <div class="transfers-wrapper">
                    <ul role="list" class="account-details w-list-unstyled">
                      <li class="account-item">
                        <p class="account-info">
                          Account Name:
                          <span class="account-text">{{
                              auth.userInfo.fundingAccountName
                            }}</span>
                        </p>
                      </li>
                      <li class="account-item">
                        <p class="account-info">
                          Bank Name:
                          <span class="account-text">{{
                              auth.userInfo.fundingBank
                            }}</span>
                        </p>
                      </li>
                      <li class="account-item">
                        <p class="account-info">
                          Account Number:
                          <span class="account-text">{{
                              auth.userInfo.fundingAccount
                            }}</span>
                        </p>
                        <button
                            id="tooltip-button-show-event-token"
                            type="button"
                            style="background-color: white; width: 30%;"
                            v-clipboard:copy="manualFundingModel.token"
                            v-clipboard:success="onCopyToken"
                            v-clipboard:error="onError"
                        >
                          <img
                              src="@/assets/images/copy_1_black.svg"
                              loading="lazy"
                              alt=""
                              class="copy__icon"
                          />
                        </button>

                      </li>
                      <li class="account-item">
                        <p class="account-info">
                          Narration:
                          <span class="account-text">{{
                              manualFundingModel.token
                            }}</span>
                        </p>
                        <button
                            id="tooltip-button-show-event-token"
                            type="button"
                            style="background-color: white; width: 30%;"
                            v-clipboard:copy="manualFundingModel.token"
                            v-clipboard:success="onCopyToken"
                            v-clipboard:error="onError"
                        >
                          <img
                              src="@/assets/images/copy_1_black.svg"
                              loading="lazy"
                              alt=""
                              class="copy__icon"
                          />
                        </button>
                      </li>
                    </ul>
                    <a
                        href="#"
                        class="payment_btn w-button"
                        @click="confirmPayment"
                    ><span v-if="!text1">I have paid</span><span v-else>Copied!</span></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "FundWallet",
  components: {},
  data(){
    return{
      currency: "NGN",
      screen: "one",
      text: "Click to copy",
      variant: "dark",
      text1: false,
      variant1: "dark",
      chargeCardModel: {
        amount: "",
        cvv: "",
        cardNumber: "",
        expirydate: "",
        expirymonth: "",
        expiryyear: "",
        pan: "",
        pin: "",
        reference: "",
      },
      chargeCardModelOtp: {
        action: "",
        amount: "",
        flwref: "",
        otp: "",
        pan: "",
        raveref: "",
        reference: "",
      },
      manualFundingModel: {
        creditAccountNumber: "",
        debitAccountNumber: "",
        creditAccountName: "",
        ftProductCode: "",
        reference: "",
        source: "",
        username: "",
        password: "",
        countryCode: "",
        trnAmount: "",
        token: "",
        trnNarration: "",
      },
      stage:1,
      amount: "",
      showLoader: false,
      userType: "user",
      model: {
        payoutReference: "",
        payoutCustomerId: "",
        payoutAmount: "",
      },
      first: false,
      second: false,
      href: "",
      show: {
        width: "100%",
        height: "auto",
      },
      hide1: {
        width: "100%",
        height: "0",
        transition: "height .5s ease-in-out",
      },
    }
  },
  props:["fund"],
  methods: {
    openSecond() {
      this.first = false;
      this.second = !this.second;
    },
    openFirst() {
      if (this.manualFunding.currency === "NGN" || this.manualFunding.currency === "GHS") {
        this.callFlutterwave();
      } else {
        this.first = !this.first;
        this.second = false;
      }
    },
    openPay(){
      this.showLoader = true;
      this.pay = true;
      if (this.model.payoutAmount !== ""){
        this.$store.dispatch("manualFunding/updateAmount", this.model.payoutAmount)
        document.querySelector('.fund_wallet').style.opacity = 0;
        document.querySelector('.payment-method_modal').style.display = "flex";
        setTimeout(()=>{
          document.querySelector('.fund_wallet').style.display = "none";
          document.querySelector('.payment-method_modal').style.opacity = 1;
        }, 100)
      }
      this.showLoader = false;
    },
    callback() {
      console.log("Payment made, verify payment");
    },
    async callFlutterwave() {
      let currency = this.manualFunding.currency;
      let amount;
      let customerId = this.auth.userInfo.customerId;
      console.log(customerId)
      if (currency === "NGN") {
        amount = this.model.payoutAmount * this.auth.userInfo.ngnPayInRate;
      } else {
        amount = this.model.payoutAmount * this.auth.userInfo.ghPayInRate;
      }
      console.log(amount)
      console.log(currency)

      let res = await this.$store
          .dispatch("transaction/callFlutterwaveTransaction", {
            amount: amount,
            currency: currency,
            customerId: customerId,
          })
          .then();
      console.log(res)
      this.href = res.location;
    },
    async confirmPayment() {
      this.manualFundingModel.creditAccountNumber = this.accountNumber();
      this.manualFundingModel.craccount = this.accountNumber();
      this.manualFundingModel.originatoraccountnumber = this.accountNumber();
      this.manualFundingModel.debitAccountNumber = this.accountNumber();
      this.manualFundingModel.creditAccountName =
          this.auth.userInfo.customerName;
      this.manualFundingModel.craccountname = this.auth.userInfo.customerName;
      this.manualFundingModel.ftProductCode = "FT_PRD";
      this.manualFundingModel.reference = this.reference();
      this.manualFundingModel.paymentreference =
          this.manualFundingModel.reference;
      this.manualFundingModel.sessionid = this.manualFundingModel.reference;
      this.manualFundingModel.source = "WEB";
      this.manualFundingModel.username = this.auth.userInfo.customerUsername;
      this.manualFundingModel.password = "000000";
      this.manualFundingModel.countryCode = "234";
      this.manualFundingModel.trnAmount = this.model.payoutAmount;
      this.manualFundingModel.amount = this.model.payoutAmount;
      this.manualFundingModel.trnNarration = "PAYMENT BY TRF";
      this.manualFundingModel.narration = "PAYMENT BY TRF";
      await this.$store.dispatch(
          "manualFunding/createManualFunding",
          this.manualFundingModel
      ).then(() => {
        this.close(".payment-method_modal")
      });
    },
    accountNumber: function () {
      if (this.transaction.transactions.accounts.length < 1) return "";
      else
        return this.transaction.transactions.accounts.filter(
            (it) => it.accountClass === "TIER_1_SAVINGS"
        )[0].accountNumber;
    },
    async makePayment() {
      if (this.manualFunding.currency === "NGN" || this.manualFunding.currency === "GHS") {
        console.log(this.currency);
        this.screen = "two";
        await this.callFlutterwave();
      } else {
        console.log(window.__env.app.flwKey);
        console.log(this.auth.userInfo.customerEmail.trim());
        window.FlutterwaveCheckout({
          public_key: window.__env.app.flwKey,
          tx_ref: `${this.reference()}-${this.auth.userInfo.customerId}-${
              this.auth.userInfo.accounts[0].accountNumber
          }-${this.auth.userInfo.customerBankCode}`,
          amount: this.model.payoutAmount,
          currency: "USD",
          payment_options: "card,mobilemoney,ussd",
          customer: {
            name: this.auth.userInfo.customerName, //this.name,
            email: this.auth.userInfo.customerEmail.trim(),
          },
          callback: (response) => this.callback(response),
          customizations: {
            title: "Wallet Funding",
            description: "Payment for items in cart",
            logo: window.__env.app.flwLogo,
          },
        });
      }
    },
    close(className) {
      this.model.payoutAmount = "";
      document.querySelector(className).style.opacity = 0;
      setTimeout(()=>{
        document.querySelector(className).style.display = "none";

      }, 500)
    },
    // eslint-disable-next-line no-unused-vars
    onCopyAcc: function (e) {
      this.variant1 = "success";
      this.text1 = true;
      // this.$refs.tooltipAcc.$emit("show");
      let self = this;
      window.setTimeout(function () {
        self.variant1 = "dark";
        self.text1 = false;
        self.$refs.tooltipAcc.$emit("show");
      }, 1000);
    },
    // eslint-disable-next-line no-unused-vars
    onCopyToken: function (e) {
      this.variant = "success";
      this.text1 = true;
      let self = this;
      window.setTimeout(function () {
        self.text1 = false;
        self.variant = "dark";
        self.text = "Click to copy";
      }, 1000);
    },
    hide() {
      this.$refs["payout-modal"].hide();
    },
    makePayout() {
      this.model.payoutReference = this.reference();
      this.model.payoutCustomerId = this.auth.userInfo.customerId;
      this.$store.dispatch("transaction/payoutCreate", this.model).then(() => {
        this.$store.dispatch("transaction/payoutReadByCustomerId", {
          customerId: this.auth.userInfo.customerId,
          readAll: "ALL",
        });
        document.querySelector('.fund_wallet').style.opacity = 0;
        setTimeout(()=>{
          document.querySelector('.fund_wallet').style.display = "none";
        }, 100)
      });
    },
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 30; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
    token() {
      let text = "";
      let possible = "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      this.manualFundingModel.token = text;
      return text;
    },
  },
  mounted() {
    this.currency = this.manualFunding.currency;
    this.model.payoutAmount = "";
    this.userType = localStorage.getItem("userType")
    this.token()
  },
  created() {
    const script = document.createElement("script");
    script.src =
      window.__env.app.stage === "dev"
        ? "https://ravemodal-dev.herokuapp.com/v3.js"
        : "https://checkout.payout.com/v3.js";
    document.getElementsByTagName("head")[0].appendChild(script);
  },
  computed: {
    ...mapState(["auth", "transaction","manualFunding"]),
    balance: function () {
      if (this.transaction.transactions.accounts.length < 1) return "0.00";
      else
        return this.transaction.transactions.accounts.filter(
          (it) => it.accountClass === "TIER_1_SAVINGS"
        )[0].accountBalance;
    },
    earnings: function () {
      if (this.transaction.transactions.accounts.length < 1) return "0.00";
      else
        return this.transaction.transactions.accounts.filter(
          (it) => it.accountClass !== "TIER_1_SAVINGS"
        )[0].accountBalance;
    },
  },
  watch: {
    // whenever question changes, this function will run
    show: function () {
      this.model = {
        payoutReference: "",
        payoutCustomerId: "",
        payoutAmount: "",
      };
      this.$refs["payout-modal"].show();
    },
    'model.payoutAmount': function() {
      this.makePayment();
    },
  },

}
</script>

<style scoped>

@media screen and (max-width: 520px) {
  .wallet_modal_content {
    width: 280px !important;
    height: 350px !important;
    overflow: scroll !important;
  }
  .withdraw_modal{
    margin-left: 0 !important;
  }
}

.fund_wallet {
  justify-content: center;
  align-items: center;
  display: none;
  opacity: 0;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  z-index: 99999;

}
.pop-up-back {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(32, 32, 32, 0.6);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
.wallet_modal_content {
  justify-content: center;
  justify-items: center;
  z-index: 99;
  width: 408px;
  background-color: #fff;
  border-radius: 16px;
  align-self: center;
  padding: 24px;
  position: relative;
}
.fund_wallet {
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
.wallet-modal-frame {
  padding: 0;
  width: 100%;
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;
  flex-direction: column;
  align-items: stretch;
  display: flex;
}
.wallet__modal-icon {
  height: 40px;
  align-self: flex-start;
  display: block;
}
.wallet-modal_text {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.wallet-modal_h {
  color: #1d2939;
  text-align: center;
  font-family: Figtree, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75;
}
.wallet-modal_p {
  color: #667085;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.42857;
}
.wallet-form {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  display: flex;
  border-radius: 8px;
}
.wallet-input-label {
  color: #344054;
  margin-bottom: 0;
  font-size: .875rem;
  line-height: 1.42857;
}
.btn__submit {
  height: 44px;
  width: 130px;
  color: #fff;
  cursor: pointer;
  background-color: #489e85;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-family: Figtree, sans-serif;
  font-weight: 600;
  text-decoration: none;
  display: flex;
}
a:hover{
  color: #fff;
  opacity: 1;
}
.btn__submit:hover{
  color: #fff;
  opacity: 1;
}
.w-button:hover{
  color: #fff;
  opacity: 1;
}
.w-inline-block:hover{
  opacity: 1;
  color: white;
}
.wallet-input-round {
  background-image: url("../../assets/images/Help-icon.svg");
  background-position: 98%;
  background-repeat: no-repeat;
  background-size: auto;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  margin-bottom: 0;
  height: 50px;
  padding: 10px 38px 10px 14px;
}

.visible{
  opacity: 1;
  display: flex;
}

.close_btn{
  //width: 30%;
  width: 130px;
  height: 44px;
  color: #160042;
  letter-spacing: -.02em;
  background-color: #fff;
  border: 1px solid #e4e6f1;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  transition: border-color .3s, background-color .3s;
  display: flex;
}

.withdraw_modal{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  margin-left: 24px;
}

.options {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  display: flex;
}
.payment__option {
  border: 1px solid #eaecf0;
  /*border:1px solid #489e85;*/
  border-radius: 12px;
  flex-direction: column;
  padding: 24px;
  transition: all 0.2s;
  display: flex;
  overflow: scroll;
}
.option-active {
  border: 1px solid #489e85;
}
.w-inline-block:hover {
  opacity: 1;
  color: white;
}
/*.payment__option {*/
/*  border: 1px solid #eaecf0;*/
/*  border-radius: 12px;*/
/*  flex-direction: column;*/
/*  padding: 24px;*/
/*  transition: all .2s;*/
/*  display: flex;*/
/*  overflow: hidden;*/
/*}*/
.faq6_question {
  color: #1d2939;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  font-weight: 700;
  display: flex;
}
.payment__header {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  display: flex;
  overflow: visible;
}
.payment-input {
  color: #101828;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  margin-bottom: 0;
  padding: 10px 14px;
}
.wallet-input-label {
  color: #344054;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.42857;
}
.payment-method-form {
}
.payment-form-grid {
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 112px;
  grid-auto-columns: 1fr;
  display: grid;
}
.payment-form-item {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  display: flex;
}
.payment-btn-group {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  display: flex;
}
.payment_btn {
  width: 100%;
  background-color: #489e85;
  border: 1px solid #489e85;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  display: flex;
  color: white;
}
.payment_btn.secondary {
  color: #344054;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #d0d5dd;
}
.payment-input.is-name {
  background-image: url("../../assets/images/Help-icon.svg");
  background-position: 95%;
  background-repeat: no-repeat;
  background-size: auto;
  padding-right: 38px;
}
.payment__option-text {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  color: #344054;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1;
  display: flex;
}
.payment-option-icon {
  width: 40px;
  height: 40px;
}
.text-block-13,
.text-block-14 {
  font-size: 1rem;
}
.payment-toggle-icon {
  width: 24px;
  height: 24px;
}
.faq6_answer {
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}
.payment-option-content {
  padding-top: 32px;
  display: block;
  overflow: hidden;
}
.faq6_question {
  color: #1d2939;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  font-weight: 700;
  display: flex;
}
.faq6_answer {
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}
.payment__header {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  display: flex;
  overflow: visible;
}
.payment-option-content.is-active {
  display: block;
}
.payment-input.is-cardnumber {
  background-image: url("../../assets/images/mastercard.svg");
  background-position: 10px;
  background-repeat: no-repeat;
  background-size: auto;
  padding-left: 58px;
}
.transfers-wrapper {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  flex-direction: column;
  display: flex;
}
.account-details {
  flex-direction: column;
  display: flex;
}
ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 1.5rem;
}
.account-item {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  align-items: center;
  margin-bottom: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
}
.account-info {
  color: #667085;
  font-size: 1rem;
  line-height: 1;
}
.account-text {
  color: #344054;
  font-weight: 500;
}
.copy__icon {
  width: 24px;
  height: 24px;
  object-fit: cover;
}

.visible {
  opacity: 1;
  display: flex;
}
.payment-method_modal {
  z-index: 99999;
}
.w-select {
  height: 51.19px;
  width: 358px;
}
.close_btn {
//width: 30%;
  width: 130px;
  height: 44px;
  color: #160042;
  letter-spacing: -0.02em;
  background-color: #fff;
  border: 1px solid #e4e6f1;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  transition: border-color 0.3s, background-color 0.3s;
  display: flex;
}
.btn__submit {
  font-size: 14px;
  height: 44px;
  width: 130px;
  color: #fff;
  cursor: pointer;
  background-color: #489e85;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-family: Figtree, sans-serif;
  font-weight: 600;
  text-decoration: none;
  display: flex;
}
.payment-method-btm {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  margin-left: 24px;
}
.select-currency-1 {
  color: #667085;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.42857;
}
.payment-method-h1 {
  color: #101828;
  letter-spacing: -0.03em;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
}
.modal-title-wrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 1.5rem;
  width: 100%;
  color: #160042;
  justify-content: space-between;
  position: relative;
}
.modal-title-wrapper-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.account-details{
  padding-left: 0!important;
}
</style>