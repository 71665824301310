import client from "./BaseService";

export default {
    callUpdateNotificationSettingsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("updateNotificationSettings", payload);
    },
    callReadNotificationSettingsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("readNotificationSettings", payload);
    },
    callGenerateInvite(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("generate-invite-id", payload);
    },
    callUnsubscribe(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("unsubscribe", payload);
    },
    callUpdatePicture(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("customer/update", payload);
    },
    callUpdateBio(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("customer/update", payload);
    },
};
