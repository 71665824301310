<template>
  <div class="section start">
    <div class="switch_user-wrapper">
      <div class="content">
        <a href="#" class="logo__image w-inline-block"
          ><img
            src="../../../public/images/Apvertise-white-Logo.svg"
            loading="lazy"
            alt=""
        /></a>
        <div class="text_content">
          <h1 class="switch_user-h1">
            Hi {{ auth.userInfo.customerFirstName }} 👋,
          </h1>
          <p class="switch_user-p">What would you like to do today?</p>
        </div>
        <div class="card__grid">
          <a href="#" class="card" @click="goToUserDashboard"
            ><img
              src="../../../public/images/dollar-1.png"
              loading="lazy"
              alt=""
              class="card__image"
            />
            <h5 class="card__title">Share &amp; Earn</h5>
          </a>
          <a href="#" class="card" @click="goToBusinessDashboard"
            ><img
              src="../../../public/images/megaphone-1.png"
              loading="lazy"
              alt=""
              class="card__image"
            />
            <h5 class="card__title">Promote your brand</h5>
          </a>
        </div>
      </div>
      <div class="footnote">Crafted with 💚 by Apvertise️</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SwitchUser",
  methods: {
    goToUserDashboard() {
      localStorage.setItem("userType", "user");
      this.$router.push("/onboarding/new-user");
    },
    goToBusinessDashboard() {
      localStorage.setItem("userType", "business");
      this.$router.push("/onboarding/new-business");
    },
  },
  computed: {
    ...mapState(["auth"]),
  },
};
</script>

<style scoped>
.section.start {
  margin-top: 0px;
}

.card {
  display: flex;
  width: 248px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 100%, 0.1);
  border-radius: 8px;
  transition: background-color 200ms ease;
  text-decoration: none;
  background: none;
}

.switch_user-wrapper {
  padding: 0;
}

.footnote {
  position: initial;
  margin-top: 70px;
}
</style>
