import { render, staticRenderFns } from "./PaymentMethodModal.vue?vue&type=template&id=ede97dbc&scoped=true"
import script from "./PaymentMethodModal.vue?vue&type=script&lang=js"
export * from "./PaymentMethodModal.vue?vue&type=script&lang=js"
import style0 from "./PaymentMethodModal.vue?vue&type=style&index=0&id=ede97dbc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ede97dbc",
  null
  
)

export default component.exports