<template>
  <div>
    <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar w-nav">
      <div class="div-block">
        <a href="#" class="w-nav-brand"><img src="../../assets/images/apvertise-api-logo.svg" loading="lazy" alt=""></a>
        <nav role="navigation" class="w-nav-menu">
          <router-link to="/" class="nav-link w-nav-link">Back to home</router-link>
        </nav>
        <div class="w-nav-button">
          <div class="w-icon-nav-menu"></div>
        </div>
      </div>
    </div>
    <div class="wrapper wf-section">
      <div class="div-block-2">
        <div class="div-block-3">
          <div class="paragraph">On this page</div>
          <a href="#Introduction" class="w-inline-block link-200">
            <div class="paragraph">Introduction</div>
          </a>
          <a href="#Campaign-Callback" class="link-block w-inline-block">
            <div class="paragraph">Campaign Callback</div>
          </a>
        </div>
        <div class="div-block-4">
          <div id="Introduction" class="introduction">
            <div class="header-block">
              <h1 class="heading">Apvertise API Documentation</h1>
              <h2 class="heading-2">API DOCUMENTATION – SIGNUP EVENT</h2>
            </div>
            <div class="div-block-5">
              <div class="div-block-6">
                <h3 class="heading-3">Base URL:</h3>
              </div>
              <div class="div-block-7">
                <div class="paragraph-copy">https://lan9a6pqp3.execute-api.us-east-1.amazonaws.com</div>
              </div>
            </div>
            <div class="div-block-5">
              <div class="div-block-6">
                <h3 class="heading-3">Header:</h3>
              </div>
              <div class="div-block-7">
                <div class="paragraph-copy">Use basic Authentication e.g: Authorization: “Basic Base64(Username:Password)”</div>
              </div>
            </div>
            <div class="div-block-5">
              <div class="div-block-6">
                <h3 class="heading-3">Response Codes:</h3>
              </div>
              <div class="div-block-7">
                <div class="div-block-8">
                  <h4 class="heading-4">Code</h4>
                  <div class="text-block">00</div>
                  <div class="text-block">12</div>
                  <div class="text-block">14</div>
                  <div class="text-block">15</div>
                  <div class="text-block">92</div>
                </div>
                <div>
                  <h4 class="heading-4">Description</h4>
                  <div class="text-block-2">Successful</div>
                  <div class="text-block-2">Invalid Share ID</div>
                  <div class="text-block-2">Insufficient funds</div>
                  <div class="text-block-2">Customer is blacklisted</div>
                  <div class="text-block-2">Invalid Authorization Token</div>
                </div>
              </div>
            </div>
            <div>
              <h1 class="heading-3">Field Description:</h1>
              <ol role="list" class="list">
                <li class="list-item">Event Type: Expected values – download, signup Event type is “download” when the user first opens the app; Event type is “signup” when the user has completed registration on the app</li>
                <li class="list-item">Referral Code: It is in the query parameter of the deep link. It begins with “APT, example “APT000099”. Links with the referral code starting with “APT” requires an API call to Apvertise.</li>
                <li class="list-item">Share ID: It is in the query parameter of the deep link. Example “syyeli89002nbdg”</li>
                <li class="list-item">Device ID: The device ID of the user’s phone</li>
                <li class="list-item">Meta Data: The meta data, derived per the nomenclature below:<br><br>Metadata Nomenclature: - Signup Phone Number | Email Address | User Name Example: 08023553004|james@gmail.com| James, Ibori<br><br>Metadata Nomenclature: - Download 000000000 | info@apvertise.com | Info, Apvertise. It’s a constant for “download” event.<br><br>Note: User Name = Firstname, Lastname</li>
              </ol>
            </div>
            <div class="div-block-9">
              <div class="div-block-11">
                <h1 class="heading-3-copy">AUTHORIZATION</h1>
                <div class="text-block-4">Basic Auth</div>
              </div>
              <div class="div-block-12">
                <div class="div-block-13">
                  <h3 class="heading-3">Username</h3>
                </div>
                <div>
                  <div class="paragraph-copy">&lt;username&gt;</div>
                </div>
              </div>
              <div class="div-block-12">
                <div class="div-block-13">
                  <h3 class="heading-3">Password</h3>
                </div>
                <div>
                  <div class="paragraph-copy">&lt;password&gt;</div>
                </div>
              </div>
            </div>
          </div>
          <div id="Campaign-Callback" class="campaign-callback">
            <div class="div-block-14">
              <div class="div-block-15">
                <div class="text-block-5">POST</div>
              </div>
              <div>
                <div class="text-block-6">Campaign Callback</div>
              </div>
            </div>
            <div class="div-block-16">
              <div class="text-block-7">https://lan9a6pqp3.execute-api.us-east-1.amazonaws.com/dev/campaignCallBack</div>
            </div>
            <div class="div-block-9-copy">
              <div class="div-block-11">
                <h1 class="heading-3-copy">AUTHORIZATION</h1>
                <div class="text-block-4">Basic Auth</div>
              </div>
              <div class="div-block-17">
                <div class="paragraph-copy">This request is using Basic Auth from collectionApvertise API Documentation</div>
              </div>
            </div>
            <div>
              <div class="div-block-11">
                <h1 class="heading-3-copy">BODY</h1>
                <div class="text-block-4">raw</div>
              </div>
              <div class="div-block-16">
                <div class="text-block-7">// eventType can be DOWNLOAD or SIGNUP<br>{<br> &nbsp; &nbsp; &quot;eventType&quot;: &quot;DOWNLOAD&quot;,<br> &nbsp; &nbsp; &quot;referralCode&quot;: &quot;APT000099&quot;,<br> &nbsp; &nbsp; &quot;shareId&quot;: &quot;xy34ewrqsyt&quot;,<br> &nbsp; &nbsp; &quot;deviceId&quot;: &quot;89936099452&quot;,<br> &nbsp; &nbsp; &quot;metaData&quot;: &quot;08023553004|james@gmail.com| James, Ibori&quot;<br>}</div>
              </div>
            </div>
            <div class="div-block-18">
              <div data-current="Tab 1" data-easing="ease-in-out" data-duration-in="300" data-duration-out="300" class="tabs w-tabs">
                <div class="tabs-menu w-tab-menu">
                  <a data-w-tab="Tab 1" class="tab w-inline-block w-tab-link w--current">
                    <div>CUrl</div>
                  </a>
                  <a data-w-tab="Tab 2" class="tab w-inline-block w-tab-link">
                    <div>NodeJS</div>
                  </a>
                  <a data-w-tab="Tab 3" class="tab w-inline-block w-tab-link">
                    <div>PHP</div>
                  </a>
                </div>
                <div class="w-tab-content">
                  <div data-w-tab="Tab 1" class="tab-pane-tab-1 w-tab-pane w--tab-active">
                    <div class="div-block-24">
                      <div class="text-block-8">curl --location --request POST &#x27;https://lan9a6pqp3.execute-api.us-east-1.amazonaws.com/dev/campaignCallBack&#x27; \<br>--data-raw &#x27;// eventType can be DOWNLOAD or SIGNUP<br>{<br> &nbsp; &nbsp; &quot;eventType&quot;: &quot;DOWNLOAD&quot;,<br> &nbsp; &nbsp; &quot;referralCode&quot;: &quot;APT000099&quot;,<br> &nbsp; &nbsp; &quot;shareId&quot;: &quot;xy34ewrqsyt&quot;,<br> &nbsp; &nbsp; &quot;deviceId&quot;: &quot;89936099452&quot;,<br> &nbsp; &nbsp;&quot;metaSata&quot;: &quot;08023553004|james@gmail.com| James, Ibori&quot;<br>}&#x27;</div>
                      <div class="div-block-22">
                        <a  data-w-id="16cfa0cb-be81-0860-9d98-55ba84b5854d" href="#" class="nodejs-button w-inline-block">
                          <div class="div-block-21"><img loading="lazy" src="../../assets/images/CopySimple.svg" alt=""></div>
                        </a>
                        <div>
                          <div style="display:none;-webkit-transform:translate3d(50px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(50px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(50px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(50px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0" class="text-block-9">Copied 🥳</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div data-w-tab="Tab 2" class="tab-pane-tab-2 w-tab-pane">
                    <div class="div-block-23">
                      <div class="text-block-8">var https = require(&#x27;follow-redirects&#x27;).https;<br>var fs = require(&#x27;fs&#x27;);<br><br>var options = {<br> &nbsp; &#x27;method&#x27;: &#x27;POST&#x27;,<br> &nbsp;&#x27;hostname&#x27;: &#x27;lan9a6pqp3.execute-api.us-east-1.amazonaws.com&#x27;,<br> &nbsp;&#x27;path&#x27;: &#x27;/dev/campaignCallBack&#x27;,<br> &nbsp;&#x27;headers&#x27;: {<br> &nbsp;,<br> &nbsp; &#x27;maxRedirects&#x27;: 20<br>};<br><br>var req = https.request(options, function (res) {<br> &nbsp;var chunks = [];<br><br> &nbsp;res.on(&quot;data&quot;, function (chunk) {<br> &nbsp; &nbsp; chunks.push(chunk);<br> &nbsp;});<br><br> &nbsp;res.on(&quot;end&quot;, function (chunk) {<br> &nbsp; &nbsp; var body = Buffer.concat(chunks);<br> &nbsp; &nbsp; console.log(body.toString());<br> });<br><br> res.on(&quot;error&quot;, function (error) {<br> console.error(error);<br> });<br>});<br><br>var postData = &quot;// eventType can be DOWNLOAD or SIGNUP\n{\n \&quot;eventType\&quot;: \&quot;DOWNLOAD\&quot;,\n \&quot;referralCode\&quot;: \&quot;APT000099\&quot;,\n \&quot;shareId\&quot;: \&quot;xy34ewrqsyt\&quot;,\n \&quot;deviceId\&quot;: \&quot;89936099452\&quot;,\n \&quot;metaSata\&quot;: \&quot;08023553004|james@gmail.com| James, Ibori\&quot;\n}&quot;;<br><br>req.write(postData);<br><br>req.end();</div>
                      <div>
                        <div class="div-block-22">
                          <a  data-w-id="16cfa0cb-be81-0860-9d98-55ba84b5854d" href="#" class="nodejs-button w-inline-block">
                            <div class="div-block-21"><img loading="lazy" src="../../assets/images/CopySimple.svg" alt=""></div>
                          </a>
                          <div>
                            <div style="display:none;-webkit-transform:translate3d(50px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(50px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(50px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(50px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0" class="text-block-9">Copied 🥳</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div data-w-tab="Tab 3" class="tab-pane-tab-3 w-tab-pane">
                    <div class="div-block-20">
                      <div class="text-block-8">&lt;?php<br><br>$curl = curl_init();<br><br>curl_setopt_array($curl, array(<br> CURLOPT_URL =&gt; &#x27;https://lan9a6pqp3.execute-api.us-east-1.amazonaws.com/dev/campaignCallBack&#x27;,<br> CURLOPT_RETURNTRANSFER =&gt; true,<br> CURLOPT_ENCODING =&gt; &#x27;&#x27;,<br> CURLOPT_MAXREDIRS =&gt; 10,<br> CURLOPT_TIMEOUT =&gt; 0,<br> CURLOPT_FOLLOWLOCATION =&gt; true,<br> CURLOPT_HTTP_VERSION =&gt; CURL_HTTP_VERSION_1_1,<br> CURLOPT_CUSTOMREQUEST =&gt; &#x27;POST&#x27;,<br> CURLOPT_POSTFIELDS =&gt;&#x27;// eventType can be DOWNLOAD or SIGNUP<br>{<br> &quot;eventType&quot;: &quot;DOWNLOAD&quot;,<br> &quot;referralCode&quot;: &quot;APT000099&quot;,<br> &quot;shareId&quot;: &quot;xy34ewrqsyt&quot;,<br> &quot;deviceId&quot;: &quot;89936099452&quot;,<br> &quot;metaSata&quot;: &quot;08023553004|james@gmail.com| James, Ibori&quot;<br>}&#x27;,<br>));<br><br>$response = curl_exec($curl);<br><br>curl_close($curl);<br>echo $response;</div>
                      <div class="div-block-22">
                        <a  data-w-id="ae75b7f4-0d53-123b-61b3-7fff758743c8" href="#" class="php-button w-inline-block">
                          <div class="div-block-21"><img loading="lazy" src="../../assets/images/CopySimple.svg" alt=""></div>
                        </a>
                        <div>
                          <div style="display:none;-webkit-transform:translate3d(50px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(50px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(50px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(50px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0" class="text-block-9">Copied 🥳</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="div-block-19">
                <div data-current="Tab 1" data-easing="ease" data-duration-in="300" data-duration-out="100" class="tabs-2 w-tabs">
                  <div class="tabs-menu-2 w-tab-menu">
                    <a data-w-tab="Tab 1" class="tab1 w-inline-block w-tab-link w--current">
                      <div>Ex_Error 12</div>
                    </a>
                    <a data-w-tab="Tab 2" class="tab1 w-inline-block w-tab-link">
                      <div>Campaign Callback_ex</div>
                    </a>
                    <a data-w-tab="Tab 3" class="tab1 w-inline-block w-tab-link">
                      <div>Campaign Callback_00<br></div>
                    </a>
                  </div>
                  <div class="w-tab-content">
                    <div data-w-tab="Tab 1" class="w-tab-pane w--tab-active">
                      <div data-current="Tab 1" data-easing="ease" data-duration-in="300" data-duration-out="100" class="tabs w-tabs">
                        <div class="tabs-menu w-tab-menu">
                          <a data-w-tab="Tab 1" class="tab w-inline-block w-tab-link w--current">
                            <div>Body</div>
                          </a>
                          <a data-w-tab="Tab 2" class="tab w-inline-block w-tab-link">
                            <div>Header (9)</div>
                          </a>
                        </div>
                        <div class="w-tab-content">
                          <div data-w-tab="Tab 1" class="tab-pane-tab-1 w-tab-pane w--tab-active">
                            <div class="text-block-8">{<br> &quot;responseCode&quot;: &quot;12&quot;,<br> &quot;responseMessage&quot;: &quot;Invalid Share ID&quot;<br>}</div>
                          </div>
                          <div data-w-tab="Tab 2" class="tab-pane-tab-2 w-tab-pane">
                            <div class="text-block-8">Date: Tue, 23 Nov 2021 08:29:08 GMT<br>Content-Type: application/json<br>Content-Length: 58<br>Connection: keep-alive<br>x-amzn-RequestId: 75ef6f47-d4d6-4c77-af85-db3780fcf4e3<br>Access-Control-Allow-Origin: *<br>x-amz-apigw-id: JP7tGHO5IAMFv5g=<br>x-custom-header: my custom header value<br>X-Amzn-Trace-Id: Root=1-619ca653-777dc350024fcfe07410a0c0;Sampled=0</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div data-w-tab="Tab 2" class="w-tab-pane">
                      <div data-current="Tab 1" data-easing="ease" data-duration-in="300" data-duration-out="100" class="tabs w-tabs">
                        <div class="tabs-menu w-tab-menu">
                          <a data-w-tab="Tab 1" class="tab w-inline-block w-tab-link w--current">
                            <div>Body</div>
                          </a>
                          <a data-w-tab="Tab 2" class="tab w-inline-block w-tab-link">
                            <div>Header (9)</div>
                          </a>
                        </div>
                        <div class="w-tab-content">
                          <div data-w-tab="Tab 1" class="tab-pane-tab-1 w-tab-pane w--tab-active">
                            <div class="text-block-8">{<br> &quot;responseCode&quot;: &quot;99&quot;,<br> &quot;responseMessage&quot;: &quot;Unauthorized campaign&quot;<br>}</div>
                          </div>
                          <div data-w-tab="Tab 2" class="tab-pane-tab-2 w-tab-pane">
                            <div class="text-block-8">Date: Tue, 23 Nov 2021 08:29:08 GMT<br>Content-Type: application/json<br>Content-Length: 58<br>Connection: keep-alive<br>x-amzn-RequestId: 75ef6f47-d4d6-4c77-af85-db3780fcf4e3<br>Access-Control-Allow-Origin: *<br>x-amz-apigw-id: JP7tGHO5IAMFv5g=<br>x-custom-header: my custom header value<br>X-Amzn-Trace-Id: Root=1-619ca653-777dc350024fcfe07410a0c0;Sampled=0</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div data-w-tab="Tab 3" class="w-tab-pane">
                      <div data-current="Tab 1" data-easing="ease" data-duration-in="300" data-duration-out="100" class="tabs w-tabs">
                        <div class="tabs-menu w-tab-menu">
                          <a data-w-tab="Tab 1" class="tab w-inline-block w-tab-link w--current">
                            <div>Body</div>
                          </a>
                          <a data-w-tab="Tab 2" class="tab w-inline-block w-tab-link">
                            <div>Header (9)</div>
                          </a>
                        </div>
                        <div class="w-tab-content">
                          <div data-w-tab="Tab 1" class="tab-pane-tab-1 w-tab-pane w--tab-active">
                            <div class="text-block-8">{<br> &quot;responseCode&quot;: &quot;00&quot;,<br> &quot;responseMessage&quot;: &quot;Success&quot;<br>}</div>
                          </div>
                          <div data-w-tab="Tab 2" class="tab-pane-tab-2 w-tab-pane">
                            <div class="text-block-8">Date: Tue, 23 Nov 2021 08:29:08 GMT<br>Content-Type: application/json<br>Content-Length: 58<br>Connection: keep-alive<br>x-amzn-RequestId: 75ef6f47-d4d6-4c77-af85-db3780fcf4e3<br>Access-Control-Allow-Origin: *<br>x-amz-apigw-id: JP7tGHO5IAMFv5g=<br>x-custom-header: my custom header value<br>X-Amzn-Trace-Id: Root=1-619ca653-777dc350024fcfe07410a0c0;Sampled=0</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ApiDocumentation",
  mounted() {
    console.log(this.$route.path)
  }
};
</script>

<style scoped>
.div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1160px;
  margin-right: auto;
  margin-left: auto;
  height: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-link {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  background-color: #63b8a0;
  color: #fff;
  font-size: 14px;
}

.navbar {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
  display: block;
}

.wrapper {
  padding-top: 60px;
}

.div-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1160px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 60px;
}

.div-block-3 {
  position: -webkit-sticky;
  position: sticky;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  align-items: baseline;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.div-block-4 {
  width: 75%;
  padding-bottom: 220px;
  background: none;
}

.paragraph {
  margin-bottom: 20px;
  color: #2a3459;
  font-size: 16px;
}

.link-block {
  line-height: 1;
  text-decoration: none;
}

.heading {
  margin-top: 0px;
  color: #2a3459;
  font-size: 30px;
  line-height: 1;
  text-align: left;
}

.heading-2 {
  margin-bottom: 0px;
  color: #2a3459;
  font-size: 20px;
  line-height: 1;
}

.header-block {
  margin-bottom: 40px;
}

.div-block-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: left;
  align-items: baseline;
}

.heading-3 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #2a3459;
  font-size: 16px;
  line-height: 1;
}

.div-block-6 {
  width: 128px;
  margin-right: 40px;
  margin-bottom: 0;
}

.div-block-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.paragraph-copy {
  margin-bottom: 0px;
  color: #2a3459;
  font-size: 16px;
}

.div-block-8 {
  margin-right: 40px;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}

.heading-4 {
  margin-top: 0px;
  color: #2a3459;
}

.heading-5 {
  margin-top: 0px;
}

.text-block {
  margin-bottom: 10px;
  color: #2a3459;
  font-size: 16px;
}

.text-block-2 {
  margin-bottom: 10px;
  color: #2a3459;
  font-size: 16px;
}

.text-block-3 {
  margin-top: 20px;
}

.list-item {
  margin-bottom: 20px;
  color: #2a3459;
  font-size: 16px;
  line-height: 22px;
  list-style-type: lower-alpha;
}

.list {
  margin-top: 20px;
  padding-left: 40px;
}

.heading-3-copy {
  display: inline-block;
  margin-top: 0px;
  margin-right: 20px;
  margin-bottom: 0px;
  color: #2a3459;
  font-size: 16px;
  line-height: 1;
}

.div-block-9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  padding-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom: 1px solid rgba(42, 52, 89, 0.14);
}

.text-block-4 {
  color: #2a3459;
  font-size: 16px;
}

.campaign-callback {
  margin-top: 40px;
}

.div-block-11 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-12 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
}

.div-block-13 {
  width: 100px;
  margin-right: 20px;
}

.div-block-14 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-15 {
  margin-right: 20px;
  padding: 10px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-radius: 5px;
  background-color: rgba(5, 183, 35, 0.1);
  margin-bottom: 0;
}

.text-block-5 {
  color: #05b723;
}

.text-block-6 {
  font-size: 18px;
  font-weight: 600;
}

.div-block-16 {
  margin-top: 20px;
  padding: 10px 10px 10px 20px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(42, 52, 89, 0.13);
  border-radius: 5px;
  background-color: rgba(42, 52, 89, 0.05);
}

.text-block-7 {
  display: inline-block;
  font-family: 'Courier Prime', sans-serif;
  font-size: 14px;
  color: #333;
}

.div-block-17 {
  margin-top: 20px;
}

.div-block-9-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  padding-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom: 1px none rgba(42, 52, 89, 0.14);
}

.div-block-18 {
  margin-top: 40px;
}

.tabs-menu {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #1b233f;
  justify-content: left;
}

.tab {
  padding-right: 10px;
  padding-left: 10px;
  background-color: transparent;
  color: #94a2ab;
}

.tab.w--current {
  padding-right: 10px;
  background-color: transparent;
  color: #63b8a0;
}

.tab-pane-tab-1 {
  padding: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #2a3459;
}

.tab-pane-tab-2 {
  padding: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #2a3459;
}

.tab-pane-tab-3 {
  padding: 20px 0px 20px 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #2a3459;
}

.tabs {
  margin-bottom: 20px;
  border-radius: 10px;
  display: block;
}

.text-block-8 {
  width: auto;
  padding-right: 0px;
  font-family: 'Courier Prime', sans-serif;
  color: #fff;
}

.div-block-19 {
  margin-bottom: 20px;
}

.tab1 {
  background-color: transparent;
  color: rgba(51, 51, 51, 0.69);
}

.tab1.w--current {
  border-radius: 5px;
  background-color: #63b89f;
  color: #fff;
}

.tabs-2 {
  margin-top: 40px;
}

.tabs-menu-2 {
  margin-bottom: 20px;
}

.div-block-20 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: baseline;
}

.div-block-21 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: hsla(0, 0%, 100%, 0.11);
  margin-top: 0;
}

.text-block-9 {
  position: absolute;
  left: 758.094px;
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 80px;
  background-color: hsla(0, 0%, 100%, 0.06);
  color: #fff;
  font-size: 14px;
}

.div-block-22 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.div-block-23 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  margin-right: 0;
  border-radius: 0;
}

.div-block-24 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 991px) {
  .body {
    padding-right: 40px;
    padding-left: 40px;
  }

  .div-block {
    width: 100%;
    padding-right: 40px;
    padding-left: 40px;
  }

  .div-block-2 {
    width: 100%;
  }

  .div-block-3 {
    display: none;
  }

  .div-block-4 {
    width: 100%;
  }

  .div-block-25 {
    width: 100%;
  }

  .introduction {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .tab1 {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.link-200{
  text-decoration: none;
}
</style>