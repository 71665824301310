import client from "./BaseService";

export default {
    callCreateAccountPayoutApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("accountPayoutCreate", payload);
    },
    callReadSingleByUserAccountPayoutApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("readSingleByCustomerId", payload);
    },
    callMakeAccountActiveApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("readSingleByCustomerId", payload);
    },
};
