import { render, staticRenderFns } from "./AddProducts.vue?vue&type=template&id=158b0ae6&scoped=true"
import script from "./AddProducts.vue?vue&type=script&lang=js"
export * from "./AddProducts.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "158b0ae6",
  null
  
)

export default component.exports