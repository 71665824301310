<script >
import DashboardNew from "@/components/menu/DashboardNew.vue";
import {mapState} from "vuex";
import MessagingRequest from "@/model/request/MessagingRequest";
import store from "@/store/store";
import BaseLoader from "@/components/loader/BaseLoader.vue";
import s3Request from "@/model/request/S3Request";


export default {
  name:'InfluencerPimChat',
  components: {BaseLoader, DashboardNew},
  data(){
    return{
      showPrice: false,
      uploadmodel: s3Request.prototype.uploadBase64(),
      base64: "",
      show:false,
      url: "",
      urls: [],
      description: "",
      fileName: "",
      price: "",
      fileSizeLarge: false,
      isLoader: false,
      model:MessagingRequest.prototype.create(),
      groupModel:MessagingRequest.prototype.readByGroupId(),
      showLoading: false,
      dropdown:false,
      current:false,
      messageOne:true,
      messageTwo:false,
      messageThree:false,
      selectedUser: "",
      selectedUserId:"",
      search: "",
      intervals: [
        { label: 'year', seconds: 31536000 },
        { label: 'month', seconds: 2592000 },
        { label: 'day', seconds: 86400 },
        { label: 'hour', seconds: 3600 },
        { label: 'minute', seconds: 60 },
        { label: 'second', seconds: 1 }
      ],
      show1: false,
      organisationName: ""
    }
  },
  methods:{
    uploadFile(id) {
      this.fileSizeLarge = false;
      this.isLoader = true;
      let input = document.getElementById(id)
      console.log(input)
      let files = input.files;
      console.log(files)
      this.fileName = files[0].name;
      let size = files[0].size / 1000000;
      console.log(size)
      if (size > 0.5){
        this.fileSizeLarge = true;
        this.isLoader = false;
      }
      else{
        const reader = new FileReader();
        try {
          reader.onload = (e) => {
            this.base64 = e.target.result;
            this.uploadOfficerImage();
          };
          reader.readAsDataURL(files[0]);
          this.$emit("input", files[0]);
        } catch (e) {
          console.warn(e.message);
        }
      }
    },
    async uploadOfficerImage() {
      this.isLoader = true;
      this.uploadmodel.username = this.fileName;
      this.uploadmodel.base64 = this.base64;
      await this.$store.dispatch(
          "sbucket/uploadEncodedFile",
          this.uploadmodel,
          { root: true }
      );
      this.url = this.sbucket.s3bucketResponse.url;
      let object = {
        name: this.fileName,
        img: this.url
      }
      this.urls.push(object)
      console.log(this.url)
      this.isLoader = false;
    },
    async saveChanges(){
      this.isLoader = true;
      await this.$store.dispatch("pimCampaign/review", {pimCampaignId: this.messages.data[0].messageGroupId, pimCampaignComment: this.description, pimCampaignPrice: this.price}).then((res) => {
        if (res.responseCode === "00"){
          this.$store.dispatch("pimCampaign/readPimCampaign", {pimCampaignId: this.messages.data[0].messageGroupId})
          this.close(".custom-quote-modal");
        }
      });
      this.isLoader = false;
    },
    timeSince(date) {
      let newdate = new Date(date)
      const seconds = Math.floor((Date.now() - newdate.getTime()) / 1000);
      const interval = this.intervals.find(i => i.seconds < seconds);
      const count = Math.floor(seconds / interval.seconds);
      return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
    },
    returnInitials(name){
      return name.split(' ')[0].charAt(0).toUpperCase() + name.split(' ')[1].charAt(0).toUpperCase()
    },
    activateMessageOne(id, user, userId, messageId){
      this.selectedUserId= userId
      this.selectedUser = user
      this.groupModel.messageGroupId = id;
      store.dispatch("message/readByGroupId", this.groupModel)
      store.dispatch("message/update", {messageId: messageId}).then(() => {
        store.dispatch("message/readByCustomerId", {customerId: this.auth.userInfo.customerId})
      })
      this.$store.dispatch("pimCampaign/readPimCampaign", {pimCampaignId: id})

      this.messageOne = true
      this.messageTwo = false
      this.messageThree = false
      this.current=true
    },
    createMessage(){
      this.model.messageTo = this.selectedInfluencer.customerId || this.selectedUserId;
      this.model.messageGroupId = this.messages.data[0].messageGroupId
      store.dispatch("message/create", this.model).then((res) => {
        store.dispatch(
          "message/readByCustomerId",
          this.auth.userInfo.customerId
        );
        if (res.responseCode === "00"){
          this.model.messageBody = ""
        }
      })


    },
    toggleDropdown(){
      this.dropdown = true
    },
    activateMessageTwo(){
      this.messageOne = false
      this.messageTwo = true
      this.messageThree = false
    },
    activateMessageThree(){
      this.messageOne = false
      this.messageTwo = false
      this.messageThree = true
    },
    async completeMessaging(){
      this.showLoading = true;
      await this.$store.dispatch("pimCampaign/complete", {pimCampaignId: this.messages.data[0].messageGroupId}).then((res) => {
        if (res.responseCode === "00"){
          this.$store.dispatch("pimCampaign/readPimCampaign", {pimCampaignId: this.messages.data[0].messageGroupId})
          this.dropdown = false;
        }
      })
      this.showLoading = false;

    },
    openModal(className) {
      document.querySelector(className).style.opacity = 1;
      setTimeout(()=>{
        document.querySelector(className).style.display = "flex";
      }, 500)
      setTimeout(() => {
        this.show1 = true;
      }, 650)
    },
    close(className) {
      document.querySelector(className).style.opacity = 0;
      setTimeout(()=>{
        document.querySelector(className).style.display = "none";

      }, 500)
    },
  },
  computed:{
    ...mapState({
      message:(state)=>state.message.message,
      messages:(state)=>state.message.messages,
      loading:(state)=>state.message.loading,
      selectedInfluencer: (state) => state.pimCampaign.selectedInfluencer,
      selectedPackage: (state) => state.pimCampaign.selectedPackage,
      auth: (state) => state.auth,
      organisation: (state) => state.organisation.organisations,
      sbucket: (state) => state.sbucket,
      pimPackages: (state) => state.pimCampaign.pimPackages,
      pimCampaigns: (state) => state.pimCampaign.pimCampaign,
    }),
  },
  mounted(){
    if (this.messages.data.length <= 0){
      store.dispatch("message/readByCustomerId", {customerId: this.auth.userInfo.customerId}).then(() => {
        this.selectedUser = this.message.data[0].messageInfluencerName;
        this.groupModel.messageGroupId = this.message.data[0].messageGroupId;
        store.dispatch("message/readByGroupId", this.groupModel)
      })
    }
    else {
      this.groupModel.messageGroupId = this.message.data[0].messageGroupId;
      store.dispatch("message/readByGroupId", this.groupModel)
    }
  },
  watch: {
    message(){
      if (!this.selectedUser){
        this.selectedUser = this.message.data[0].messageInfluencerName;
      }
      if (this.messages.data.length > 0 && this.selectedUser !== this.message.data[0].messageInfluencerName){
        this.selectedUser = this.message.data[0].messageInfluencerName;
        this.groupModel.messageGroupId = this.message.data[0].messageGroupId;
        store.dispatch("message/readByGroupId", this.groupModel)
      }
    },
    search(){
      if (this.search !== ""){
        let messages = {
          data: []
        }
        this.message.data.forEach((message) => {
          if (message.messageInfluencerName.toLowerCase().includes(this.search.toLowerCase())){
            messages.data.push(message)
          }
        })
        store.commit("message/updateMessage", messages)

      }
      else{
        store.dispatch("message/readByCustomerId", {customerId: this.auth.userInfo.customerId})
      }
    }
  }

}
</script>

<template>
<div>
  <dashboard-new/>
  <div class="section" style="height: 100vh">
    <section class="qoo-modal custom-quote-modal">
      <div class="padding-global-2">
        <div class="container-large-2">
          <div class="padding-section-large-2">
            <div class="qoo-modal  contact-modal1_component" >
              <div style="-webkit-transform:translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" class="contact-modal1_content-wrapper"  :class="{'new-modal': show1}">
                <h5 class="influencer-modal-title">Get a quote</h5>
                <div class="pim-quote-modal-greeting">
                  <div class="modal-influncer-image" v-if="messages.data.length > 0">{{messages.data[0].messageSenderName.split(' ')[0].charAt(0) + messages.data[0].messageSenderName.split(' ')[1].charAt(0)}}</div>
                  <div>
                    <div class="pim-modal-influencer-name" v-if="messages.data.length > 0">{{messages.data[0].messageSenderName}}</div>
                    <div class="pim-modal-greet">Hi, please provide your request details below and I’ll get back to you.</div>
                  </div>
                </div>
                <div class="contact-modal1_form-block w-form">
                  <form id="wf-form-Contact-1-Form" name="wf-form-Contact-1-Form" data-name="Contact 1 Form" method="get" class="contact-modal1_form" data-wf-page-id="64c95413605c1acf75a828e3" data-wf-element-id="ef0a6755-c122-b69b-b4c6-683e1dd7c7a6">
                    <div class="rate-card-form-item"><label for="field" class="rate-card-form-label">Describe the service you&#x27;re looking for - please be as detailed as possible</label><textarea id="field" v-model="description" name="field" maxlength="5000" data-name="Field" placeholder="I'm looking for..." class="rate-card-form-input is-text-area w-input"></textarea>
                    </div>
                    <div class="rate-card-form-item">
                      <label for="business" class="rate-card-form-label">Business Name</label>
                      <input :value="organisationName" readonly class="rate-card-form-input w-input"/>
                    </div>
                    <div class="rate-card-form-item">
                      <label class="link-block-28 w-inline-block" for="file" style="cursor: pointer">
                        <input type="file" style="display: none;" id="file" @change="uploadFile('file')">
                        <div style="display: flex; align-items: center; justify-content: center; width: 100%; flex-direction: column; gap: 8px">
                          <img src="../../assets/images/Vector.svg" alt="File"/>
                          <span v-if="!isLoader" style="color: #272835; font-size: 14px; font-weight: 600">Click here to upload</span>
                          <span v-if="!isLoader" style="font-size: 12px;color:#666D80;">PNG, PDF, JPG up to 5MB</span>
                          <p v-else>
                            Uploading file...
                            <base-loader style="" :show="!isLoader"></base-loader>
                          </p>
                        </div>
                        <p style="color: #EA4335; font-size: 12px; width: 100%; text-align: center" v-if="fileSizeLarge"><img src="../../assets/images/info-circle.svg" alt="Caution"/>The file is Larger than 5mb. Please try again</p>
                      </label>
                      <ul class="url-container" v-if="urls.length > 0">
                        <li v-for="(url, index) in urls" :key="index">
                          <img src="../../assets/images/excel.svg" :alt="url.name" v-if="url.name.includes('xlsx') || url.name.includes('xlsm') || url.name.includes('csv')" style="width: 100%; height: 100%; object-fit: cover; border-radius: 16px"/>
                          <img src="../../assets/images/pdf.svg" :alt="url.name" v-else-if="url.name.includes('pdf')" style="width: 100%; height: 100%; object-fit: cover; border-radius: 16px"/>
                          <img src="../../assets/images/word.svg" :alt="url.name" v-else-if="url.name.includes('doc') || url.name.includes('docx')" style="width: 100%; height: 100%; object-fit: cover; border-radius: 16px"/>
                          <img :src="url.img" :alt="url.name" v-else style="width: 100%; height: 100%; object-fit: cover; border-radius: 16px"/>
                          <a @click="urls.splice(index, 1)"><img src="../../assets/images/close-white.svg" alt="Close" style="position:absolute; right: 16px; top: 16px"></a>
                        </li>
                      </ul>
                    </div>
                    <div class="rate-card-form-item"><label for="name-3" class="rate-card-form-label">What is your budget for this service?</label><input type="text" v-model="price" class="rate-card-form-input price w-input" maxlength="256" name="name-2" data-name="Name 2" placeholder="" id="name-2"></div>
                  </form>
                  <button @click="saveChanges" style="margin-top: 24px" class="btn small align-right w-button" v-if="!isLoader">Send Request</button>
                  <base-loader v-else style="align-self: center"/>

                  <a data-w-id="445de229-9148-6f4e-3ea6-e15783437aa9" href="#" class="close-topup-modal" @click="close('.custom-quote-modal')" style="position: absolute; right: 10px; top: 10px"><img src="../../assets/images/close-modal.svg" loading="lazy" alt=""></a>
                </div>
              </div>
              <a data-w-id="ef0a6755-c122-b69b-b4c6-683e1dd7c7c1" class="contact-modal1_close-button w-inline-block">
                <div class="icon-embed-small hide-mobile-landscape w-embed"><svg width="100%" height="100%" viewbox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24.056 23.5004L23.5004 24.056C23.1935 24.3628 22.696 24.3628 22.3892 24.056L16 17.6668L9.61078 24.056C9.30394 24.3628 8.80645 24.3628 8.49961 24.056L7.94403 23.5004C7.63719 23.1936 7.63719 22.6961 7.94403 22.3892L14.3332 16L7.94403 9.61081C7.63719 9.30397 7.63719 8.80648 7.94403 8.49964L8.49961 7.94406C8.80645 7.63721 9.30394 7.63721 9.61078 7.94406L16 14.3333L22.3892 7.94404C22.6961 7.6372 23.1935 7.6372 23.5004 7.94404L24.056 8.49963C24.3628 8.80647 24.3628 9.30395 24.056 9.61079L17.6667 16L24.056 22.3892C24.3628 22.6961 24.3628 23.1936 24.056 23.5004Z" fill="currentColor"></path>
                </svg></div><img src="https://uploads-ssl.webflow.com/624380709031623bfe4aee60/624380709031623afe4aee7e_icon_close-modal.svg" loading="lazy" alt="" class="show-mobile-landscape">
              </a>
              <div data-w-id="ef0a6755-c122-b69b-b4c6-683e1dd7c7c4" class="contact-modal1_background-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="dashboard-wrapper">
      <div class="chat-box-wrapper">
        <div class="chat-frame-grid">
          <div id="w-node-_7f2f5710-63f9-76ea-cb62-6e5960164aa9-e7592d84" class="chat-side-bar">
            <div class="chat-tab-list">
              <div class="chat-tab is-vertical">
                <div class="section-header2_form-block w-form">
                  <form id="wf-form-Filter-1" name="wf-form-Filter-1" data-name="Filter 1" method="get" fs-cmsfilter-element="filters" class="section-header2_form" data-wf-page-id="64cb40cb8700d259e7592d84" data-wf-element-id="e474e91a-4f4a-efc2-dbda-92e8ef385148">
                    <div class="section-header2_search"><input type="text" class="form-input is-search-input w-input" v-model="search" maxlength="256" name="field-2" fs-cmsfilter-field="IDENTIFIER" data-name="Field 2" placeholder="Search" fs-cmsfilter-debounce="200" id="field-2">
                      <div class="search-icon w-embed"><svg width="100%" height="100%" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 18C11.775 17.9996 13.4988 17.4054 14.897 16.312L19.293 20.708L20.707 19.294L16.311 14.898C17.405 13.4997 17.9996 11.7754 18 10C18 5.589 14.411 2 10 2C5.589 2 2 5.589 2 10C2 14.411 5.589 18 10 18ZM10 4C13.309 4 16 6.691 16 10C16 13.309 13.309 16 10 16C6.691 16 4 13.309 4 10C4 6.691 6.691 4 10 4Z" fill="currentColor"></path>
                      </svg></div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="chat-list-group">
                <a href="#" class="chat-tab is-active w-inline-block"><img alt="" loading="lazy" src="../../assets/images/DBS_Amira_web_0037.jpg" class="recipient-image">
                  <div class="chat-text">
                    <div class="chat-preview">
                      <div class="contact-name">Tanyer Assler</div>
                      <div class="chat-preview-text">Hello Seun, Thanks for reac...</div>
                    </div>
                    <div class="last-message-time">
                      <div class="online-status"></div>
                      <div>5mins</div>
                    </div>
                  </div>
                </a>
                <a href="#" class="chat-tab w-inline-block"><img alt="" loading="lazy" src="../../assets/images/DBS_Amira_web_0037.jpg" class="recipient-image">
                  <div class="chat-text">
                    <div class="chat-preview">
                      <div class="contact-name">Tanyer Assler</div>
                      <div class="chat-preview-text">Hello Seun, Thanks for reac...</div>
                    </div>
                    <div class="last-message-time">
                      <div class="online-status"></div>
                      <div>5mins</div>
                    </div>
                  </div>
                </a>
                <a href="#" class="chat-tab w-inline-block"><img alt="" loading="lazy" src="../../assets/images/DBS_Amira_web_0037.jpg" class="recipient-image">
                  <div class="chat-text">
                    <div class="chat-preview">
                      <div class="contact-name">Tanyer Assler</div>
                      <div class="chat-preview-text">Hello Seun, Thanks for reac...</div>
                    </div>
                    <div class="last-message-time">
                      <div class="online-status"></div>
                      <div>5mins</div>
                    </div>
                  </div>
                </a>
                <a href="#" class="chat-tab w-inline-block"><img alt="" loading="lazy" src="../../assets/images/DBS_Amira_web_0037.jpg" class="recipient-image">
                  <div class="chat-text">
                    <div class="chat-preview">
                      <div class="contact-name">Tanyer Assler</div>
                      <div class="chat-preview-text">Hello Seun, Thanks for reac...</div>
                    </div>
                    <div class="last-message-time">
                      <div class="online-status"></div>
                      <div>5mins</div>
                    </div>
                  </div>
                </a>
                <a href="#" class="chat-tab w-inline-block"><img alt="" loading="lazy" src="../../assets/images/DBS_Amira_web_0037.jpg" class="recipient-image">
                  <div class="chat-text">
                    <div class="chat-preview">
                      <div class="contact-name">Tanyer Assler</div>
                      <div class="chat-preview-text">Hello Seun, Thanks for reac...</div>
                    </div>
                    <div class="last-message-time">
                      <div class="online-status"></div>
                      <div>5mins</div>
                    </div>
                  </div>
                </a>
                <a href="#" class="chat-tab w-inline-block"><img alt="" loading="lazy" src="../../assets/images/DBS_Amira_web_0037.jpg" class="recipient-image">
                  <div class="chat-text">
                    <div class="chat-preview">
                      <div class="contact-name">Tanyer Assler</div>
                      <div class="chat-preview-text">Hello Seun, Thanks for reac...</div>
                    </div>
                    <div class="last-message-time">
                      <div class="online-status"></div>
                      <div>5mins</div>
                    </div>
                  </div>
                </a>
                <a href="#" class="chat-tab w-inline-block"><img alt="" loading="lazy" src="../../assets/images/DBS_Amira_web_0037.jpg" class="recipient-image">
                  <div class="chat-text">
                    <div class="chat-preview">
                      <div class="contact-name">Tanyer Assler</div>
                      <div class="chat-preview-text">Hello Seun, Thanks for reac...</div>
                    </div>
                    <div class="last-message-time">
                      <div class="online-status"></div>
                      <div>5mins</div>
                    </div>
                  </div>
                </a>
                <a href="#" class="chat-tab w-inline-block"><img alt="" loading="lazy" src="../../assets/images/DBS_Amira_web_0037.jpg" class="recipient-image">
                  <div class="chat-text">
                    <div class="chat-preview">
                      <div class="contact-name">Tanyer Assler</div>
                      <div class="chat-preview-text">Hello Seun, Thanks for reac...</div>
                    </div>
                    <div class="last-message-time">
                      <div class="online-status"></div>
                      <div>5mins</div>
                    </div>
                  </div>
                </a>
                <a href="#" class="chat-tab w-inline-block"></a>
              </div>
            </div>
            <div class="chat-support"><img src="../../assets/images/whatsapp_2.svg" loading="lazy" alt="">
              <div class="chat-support-text">
                <div>Support - 0901234567890</div>
                <div class="text-block-42">Contact us for immediate assistance</div>
              </div>
            </div>
          </div>
          <div class="chat-screen">
            <div class="chat-header"></div>
            <div class="chat-header">
              <div class="chat-wrapper">
                <div class="chat-screen-header">
                  <div class="chat-contact">
                    <div class="chat-profile-image">
                      <div v-if="selectedUser">{{ selectedInfluencer.customerName ? returnInitials(selectedInfluencer.customerName) : returnInitials(selectedUser) }}</div>
                    </div>
                    <div class="chat-profile-name">{{ selectedInfluencer.customerName ? selectedInfluencer.customerName : selectedUser}}</div>
                  </div>
                  <div class="chat-header-cta-group" :class="{'w--open' : dropdown}">
                    <a @click="dropdown = !dropdown"  data-hover="false" data-delay="200" data-w-id="a2a82930-7979-0345-44fc-844cb54e761f" class="dropdown1_component w-dropdown" :class="{'w--open' : dropdown}">
                      <div class="dropdown1_toggle w-dropdown-toggle" :class="{'w--open':dropdown}" id="w-dropdown-toggle-2" aria-controls="w-dropdown-list-2" aria-haspopup="menu" aria-expanded="true" role="button" tabindex="0"><img src="../../assets/images/more-horizontal.svg" loading="lazy" width="24" alt=""></div>
                      <nav style="transform: translate3d(0px, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 0; transform-style: preserve-3d;" class="dropdown1_dropdown-list-3 w-dropdown-list" :class="{'w--open show':dropdown}" id="w-dropdown-list-2" aria-labelledby="w-dropdown-toggle-2">
                        <a href="#" class="dropdown1_dropdown-link-3 w-dropdown-link" tabindex="0">Mark as completed</a>
                        <a href="#" data-w-id="5002deb7-5c8a-774c-7610-305e555f2abb" class="dropdown1_dropdown-link-3 w-dropdown-link" tabindex="0">Create Custom order</a>
                        <a href="#" data-w-id="a2a82930-7979-0345-44fc-844cb54e7627" class="dropdown1_dropdown-link-3 w-dropdown-link" tabindex="0">Raise Dispute</a>
                        <a href="#" class="close-chat w-dropdown-link" tabindex="0">Close Chat</a>
                      </nav>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="chat-wrapper">
                <div class="chat-message-list">
                  <div class="chat-message-box">
                    <div class="chat-message-image">TA</div>
                    <div class="chat-message">
                      <div class="chat-message-title">
                        <div class="chat-message-contact-name">Tanyer Assler</div>
                        <div class="chat-message-date">July 27, 2023</div>
                      </div>
                      <p class="chat-message-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</p>
                    </div>
                  </div>
                  <div class="chat-message-box">
                    <div class="chat-message-image">TA</div>
                    <div class="chat-message">
                      <div class="chat-message-title">
                        <div class="chat-message-contact-name">Tanyer Assler</div>
                        <div class="chat-message-date">July 27, 2023</div>
                      </div>
                      <p class="chat-message-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</p>
                    </div>
                  </div>
                  <div class="chat-message-box">
                    <div class="chat-message-image">TA</div>
                    <div class="chat-message">
                      <div class="chat-message-title">
                        <div class="chat-message-contact-name">Tanyer Assler</div>
                        <div class="chat-message-date">July 27, 2023</div>
                      </div>
                      <p class="chat-message-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chat">
          <div id="w-node-_6dda2c98-9a8b-2343-e8b3-caecf3a361c8-e7592d84" class="chat-grid">
            <div class="chat-tab is-vertical">
              <div class="section-header2_form-block w-form">
                <form id="wf-form-Filter-1" name="wf-form-Filter-1" data-name="Filter 1" method="get" fs-cmsfilter-element="filters" class="section-header2_form" data-wf-page-id="64cb40cb8700d259e7592d84" data-wf-element-id="5e7779df-969e-44d7-11fb-555f9a53b6ab">
                  <div class="section-header2_search"><input type="text" class="form-input is-search-input w-input" v-model="search" maxlength="256" name="field-2" fs-cmsfilter-field="IDENTIFIER" data-name="Field 2" placeholder="Search" fs-cmsfilter-debounce="200" id="field-2">
                    <div class="search-icon w-embed"><svg width="100%" height="100%" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 18C11.775 17.9996 13.4988 17.4054 14.897 16.312L19.293 20.708L20.707 19.294L16.311 14.898C17.405 13.4997 17.9996 11.7754 18 10C18 5.589 14.411 2 10 2C5.589 2 2 5.589 2 10C2 14.411 5.589 18 10 18ZM10 4C13.309 4 16 6.691 16 10C16 13.309 13.309 16 10 16C6.691 16 4 13.309 4 10C4 6.691 6.691 4 10 4Z" fill="currentColor"></path>
                    </svg></div>
                  </div>
                </form>
              </div>
            </div>
            <div id="w-node-_8be726aa-1ea9-2fda-35b6-0b143d243602-e7592d84" class="chat-head">
              <div class="chat-wrapper">
                <div class="chat-screen-header">
                  <div class="chat-contact">
                    <div class="chat-profile-image">
                      <div v-if="selectedUser">{{ selectedInfluencer.customerName ? returnInitials(selectedInfluencer.customerName) : returnInitials(selectedUser) }}</div>
                    </div>
                    <div class="chat-profile-name">{{ selectedInfluencer.customerName ? selectedInfluencer.customerName : selectedUser}}</div>
                  </div>
                  <div class="chat-header-cta-group" :class="{'w--open' : dropdown}">
                    <a @click="dropdown = !dropdown"  data-hover="false" data-delay="200" data-w-id="a2a82930-7979-0345-44fc-844cb54e761f" class="dropdown1_component w-dropdown" :class="{'w--open' : dropdown}">
                      <div class="dropdown1_toggle" :class="{'w--open':dropdown}" id="w-dropdown-toggle-2" aria-controls="w-dropdown-list-2" aria-haspopup="menu" aria-expanded="true" role="button" tabindex="0"><img src="../../assets/images/more-horizontal.svg" loading="lazy" width="24" alt=""></div>
                      <nav style="position: absolute;display: none; transform: translate3d(0px, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 0; transform-style: preserve-3d;" class="dropdown1_dropdown-list-3" :class="{'w--open show':dropdown}" id="w-dropdown-list-2" aria-labelledby="w-dropdown-toggle-2">
                        <a @click="completeMessaging" href="#" class="dropdown1_dropdown-link-3 w-dropdown-link" tabindex="0" v-if="!showLoading">Mark as completed</a>
                        <a class="dropdown1_dropdown-link-3 w-dropdown-link" tabindex="0" v-else>Loading...</a>
                        <a @click="openModal('.custom-quote-modal')" href="#" data-w-id="5002deb7-5c8a-774c-7610-305e555f2abb" class="dropdown1_dropdown-link-3 w-dropdown-link" tabindex="0">Create Custom order</a>
                        <a href="#" data-w-id="a2a82930-7979-0345-44fc-844cb54e7627" class="dropdown1_dropdown-link-3 w-dropdown-link" tabindex="0">Raise Dispute</a>
                        <a @click="completeMessaging" href="#" class="close-chat w-dropdown-link" tabindex="0">Close Chat</a>
                      </nav>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="w-node-_1d48776b-ce3b-f171-facb-34678d84ab60-e7592d84" data-current="Tab 2" data-easing="ease" data-duration-in="300" data-duration-out="100" class="chat-grid w-tabs">
            <div id="w-node-_1d48776b-ce3b-f171-facb-34678d84ab61-e7592d84" class="chat-list-group w-tab-menu" >
              <a v-for="(messaged,index) in message.data" :key="index"  @click="activateMessageOne(messaged.messageGroupId, messaged.messageInfluencerName, '', messaged.messageId);organisationName = messaged.organisationName" data-w-tab="Tab 1" class="chat-tab w-inline-block w-tab-link" :class="{'w--current':messaged.messageParticipantName===selectedUser }"><img alt="" loading="lazy" :src="messaged.messageInfluencerPicture" class="recipient-image">
                <div class="chat-text">
                  <div class="chat-preview">
                    <div class="contact-name">{{messaged.messageInfluencerName}}</div>
                    <div class="chat-preview-text" style="text-transform: capitalize">{{ messaged.messageBody }}</div>
                  </div>
                  <div class="last-message-time" >
                    <div class="online-status"></div>
                    <div style="width: max-content" v-if="messaged.messageCreatedAt">{{timeSince(messaged.messageCreatedAt)}}</div>
                  </div>
                </div>
              </a>
<!--              <a @click="activateMessageTwo" data-w-tab="Tab 2" class="chat-tab w-inline-block w-tab-link" :class="{'w&#45;&#45;current':messageTwo===true}"><img alt="" loading="lazy" src="../../assets/images/DBS_Amira_web_0037.jpg" class="recipient-image">-->
<!--                <div class="chat-text">-->
<!--                  <div class="chat-preview">-->
<!--                    <div class="contact-name">Tanyer Assler</div>-->
<!--                    <div class="chat-preview-text">Hello Seun, Thanks for reac...</div>-->
<!--                  </div>-->
<!--                  <div class="last-message-time">-->
<!--                    <div class="online-status"></div>-->
<!--                    <div>5mins</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </a>-->
<!--              <a @click="activateMessageThree" data-w-tab="Tab 3" class="chat-tab w-inline-block w-tab-link" :class="{'w&#45;&#45;current':messageThree===true}"><img alt="" loading="lazy" src="../../assets/images/DBS_Amira_web_0037.jpg" class="recipient-image">-->
<!--                <div class="chat-text">-->
<!--                  <div class="chat-preview">-->
<!--                    <div class="contact-name">Tanyer Assler</div>-->
<!--                    <div class="chat-preview-text">Hello Seun, Thanks for reac...</div>-->
<!--                  </div>-->
<!--                  <div class="last-message-time">-->
<!--                    <div class="online-status"></div>-->
<!--                    <div>5mins</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </a>-->
            </div>
            <div class="w-tab-content" v-if="selectedInfluencer.customerName"></div>
            <div class="w-tab-content" v-if="messages.data.length>0">
              <div data-w-tab="Tab 1" class="w-tab-pane" :class="{'w--tab-active':messageOne===true}" >
                <div class="chat-messages">
                  <div class="chat-wrapper">
                    <div class="chat-message-list">
                      <div v-for="(messaged,index) in messages.data" :key="index" class="chat-message-box">
                        <div class="chat-message-image" v-if="messaged.messageSenderName">{{ returnInitials(messaged.messageSenderName) }}</div>
                        <div class="chat-message">
                          <div class="chat-message-title">
                            <div class="chat-message-contact-name">{{messaged.messageSenderName}}</div>
                            <div class="chat-message-date"> {{new Date(messaged.messageCreatedAt).getDate()}} {{new Date(messaged.messageCreatedAt).toLocaleString('en-us',{month:'short'})}} {{new Date(messaged.messageCreatedAt).getFullYear()}}</div>
                          </div>
                          <p class="chat-message-text">{{messaged.messageBody}}</p>
                        </div>
                      </div>

                    </div>
                    <div class="chat-message-completed" v-if="pimCampaigns.pimCampaignStatus.includes('COMPLETE')" style="width: 100%; display: flex; gap: 16px; align-items: center">
                      <div class="chat-message-grey-border" style="width: 100%;border: 1px solid #EAECF0;"></div>
                      <div class="chat-message-grey-text" style="width: max-content; background: #F2F4F7; padding: 4px 8px; border-radius: 4px;font-family: Figtree;font-size: 14px;font-weight: 500;line-height: 24px;letter-spacing: 0.01em;text-align: left;color:#1D2939; width: 44%">Work completed</div>
                      <div class="chat-message-grey-border" style="width: 100%;border: 1px solid #EAECF0;"></div>
                    </div>
                  </div>
                </div>
              </div>
<!--              <div data-w-tab="Tab 2" class="w-tab-pane" :class="{'w&#45;&#45;tab-active':messageTwo===true}">-->
<!--                <div class="chat-messages">-->
<!--                  <div class="chat-wrapper">-->
<!--                    <div class="chat-message-list">-->
<!--                      <div class="chat-message-box">-->
<!--                        <div class="chat-message-image">TA</div>-->
<!--                        <div class="chat-message">-->
<!--                          <div class="chat-message-title">-->
<!--                            <div class="chat-message-contact-name">Tanyer Assler</div>-->
<!--                            <div class="chat-message-date">July 27, 2023</div>-->
<!--                          </div>-->
<!--                          <p class="chat-message-text">Affiliate marketing is an excellent way to earn passive income. this type of website doesn&#x27;t require any extra maintenance or technical knowledge to run.Every product will be linked with your unique affiliate ID so that you will earn commissions on every sale.</p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="chat-message-box">-->
<!--                        <div class="chat-message-image">TA</div>-->
<!--                        <div class="chat-message">-->
<!--                          <div class="chat-message-title">-->
<!--                            <div class="chat-message-contact-name">Tanyer Assler</div>-->
<!--                            <div class="chat-message-date">July 27, 2023</div>-->
<!--                          </div>-->
<!--                          <p class="chat-message-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="chat-message-box">-->
<!--                        <div class="chat-message-image">TA</div>-->
<!--                        <div class="chat-message">-->
<!--                          <div class="chat-message-title">-->
<!--                            <div class="chat-message-contact-name">Tanyer Assler</div>-->
<!--                            <div class="chat-message-date">July 27, 2023</div>-->
<!--                          </div>-->
<!--                          <p class="chat-message-text">Affiliate marketing is an excellent way to earn passive income. this type of website doesn&#x27;t require any extra maintenance or technical knowledge to run.Every product will be linked with your unique affiliate ID so that you will earn commissions on every sale.</p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div data-w-tab="Tab 3" class="w-tab-pane" :class="{'w&#45;&#45;tab-active':messageThree===true}">-->
<!--                <div class="chat-messages">-->
<!--                  <div class="chat-wrapper">-->
<!--                    <div class="chat-message-list">-->
<!--                      <div class="chat-message-box">-->
<!--                        <div class="chat-message-image">TA</div>-->
<!--                        <div class="chat-message">-->
<!--                          <div class="chat-message-title">-->
<!--                            <div class="chat-message-contact-name">Tanyer Assler</div>-->
<!--                            <div class="chat-message-date">July 27, 2023</div>-->
<!--                          </div>-->
<!--                          <p class="chat-message-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="chat-message-box">-->
<!--                        <div class="chat-message-image">TA</div>-->
<!--                        <div class="chat-message">-->
<!--                          <div class="chat-message-title">-->
<!--                            <div class="chat-message-contact-name">Tanyer Assler</div>-->
<!--                            <div class="chat-message-date">July 27, 2023</div>-->
<!--                          </div>-->
<!--                          <p class="chat-message-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
            <div v-else-if="loading" style="display: flex;align-items: center;justify-content: center">Loading Messages...</div>
            <div v-else style="display: flex; align-items: center; justify-content: center; flex-direction: column">
              <div class="empty-state-img" style="margin-bottom: 24px">
                <img src="../../assets/images/empty-state.png" height="64" width="64"/>
              </div>
              <h5 style="font-size: 18px; font-weight: 500; margin-bottom: 8px">Your chat is empty</h5>
              <p style="font-size: 12px; font-weight: 400">Your chat will be open when a business sends a request </p>
            </div>

          </div>
          <div id="w-node-dc2ebea3-403b-59ea-d868-b33ac3668955-e7592d84" class="chat-grid">
            <div class="chat-support alt"><img src="../../assets/images/whatsapp_2.svg" loading="lazy" alt="">
              <div class="chat-support-text">
                <div class="text-block-43">Support - 0901234567890</div>
                <div class="text-block-42">Contact us for immediate assistance</div>
              </div>
            </div>
            <div id="w-node-_162b2b24-5d48-4190-8573-a34d1a135908-e7592d84" class="div-block-251"  v-if="!pimCampaigns.pimCampaignStatus.includes('COMPLETE')">
              <div class="section-header2_form-block w-form">
                <form id="wf-form-Filter-1" name="wf-form-Filter-1" data-name="Filter 1" method="get" fs-cmsfilter-element="filters" class="section-header2_form" data-wf-page-id="64cb40cb8700d259e7592d84" data-wf-element-id="18f1b248-89c6-422f-179a-1c2e326da788">
                  <div class="section-type-message">  <input type="text" v-model="model.messageBody" class="form-input is-message-input w-input" maxlength="256" name="field-2" fs-cmsfilter-field="IDENTIFIER" data-name="Field 2" placeholder="Type a message here..." fs-cmsfilter-debounce="200" id="field-2">
                    <div class="search-icon w-embed"><svg width="100%" height="100%" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 18C11.775 17.9996 13.4988 17.4054 14.897 16.312L19.293 20.708L20.707 19.294L16.311 14.898C17.405 13.4997 17.9996 11.7754 18 10C18 5.589 14.411 2 10 2C5.589 2 2 5.589 2 10C2 14.411 5.589 18 10 18ZM10 4C13.309 4 16 6.691 16 10C16 13.309 13.309 16 10 16C6.691 16 4 13.309 4 10C4 6.691 6.691 4 10 4Z" fill="currentColor"></path>
                    </svg></div>
                    <a @click="createMessage" class="link-block-29 w-inline-block"><img src="../../assets/images/send.svg" loading="lazy" alt="" v-if="!loading"><b-spinner v-else small
                                                                                                                                                                            style="color: #fff"/></a>
                  </div>
                </form>
              </div>
            </div>
            <div v-else style="background: #F2F4F7; padding: 16px 10px; color:#1D2939; width: 100%; display: flex; align-items: center">
              You can’t send messages to this business. Your work has been marked as done by the business.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>
.last-message-time{
  align-self: center;
}
.form-input{
  color: black;
}
.search-icon{
  display: flex;
}
.more-message{
  z-index: 901;
}
.show{
  transform: translate3d(0px, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !important;
  transform-style: preserve-3d !important;
  display:block !important;
  opacity: 1 !important;
}
.dropdown1_dropdown-list-3.w--open {
  background-color: #fff;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  padding: 0.5rem;
  display:block !important;
}
.w-dropdown-link {
  padding: 10px 20px;
  display: block;
  color: #222222;
}
.w-dropdown-btn, .w-dropdown-toggle, .w-dropdown-link {
  position: relative;
  vertical-align: top;
  text-decoration: none;
  color: #222222;
  padding: 20px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
}
.close-chat {
  color: #f04438;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  font-size: .875rem;
  font-weight: 500;
}
.dropdown1_dropdown-link-3 {
  border-radius: 8px;
  padding: 0.5rem 1rem;
  font-size: .875rem;
  font-weight: 500;
}
.dropdown1_dropdown-list-3 {
  top: 113%;
  bottom: auto;
  left: auto;
  right: 0%;
  transition: all 0.2s ease-in;
}
.dropdown1_dropdown-link-3:hover {
  background-color: #f2f4f7;
}

.new-modal{
  transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !important;
  transform-style: preserve-3d !important;
}
</style>