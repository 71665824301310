class CampaignRequest {
  campaignCreateRequest() {
    return {
      campaignName: null,
      campaignType: null,
      campaignDescription: null,
      campaignClickCount: null,
      campaignStartDate: null,
      campaignView: null,
      campaignChannel: null,
      campaignEndDate: null,
      campaignCategory: [],
      campaignBudget: null,
      campaignAudience: null,
    };
  }
  campaignReadRequest() {
    return {
      customerId: null,
      category: null,
      readAll: "YES",
      page: 1,
      noPerPage: 21,
    };
  }
  campaignShareRequest() {
    return {
      customerId: null,
      campaignId: null,
    };
  }
  campaignReadSingleRequest() {
    return {
      campaignId: null,
    };
  }
  campaignUpdateRequest() {
    return {
      campaignId: null,
      campaignName: null,
      campaignType: null,
      campaignClickCount: null,
      campaignStartDate: null,
      campaignView: null,
      campaignChannel: null,
      campaignEndDate: null,
      campaignCategory: null,
      campaignBudget: null,
      campaignAudience: null,
    };
  }
  campaignUpdateStatusRequest() {
    return {
      campaignId: null,
      campaignStatus: null,
    };
  }
}

export default CampaignRequest;
