import NotificationService from "@/service/NotificationService";
// import NotificationRequest from "@/model/request/NotificationRequest";
import NotificationResponse from "@/model/response/NotificationResponse";
import swal from "sweetalert";
import store from "@/store/store";
// import InfluencerResponse from "@/model/response/InfluencerResponse";


export const state={
    notifications:NotificationResponse.prototype.updateStatus(),
    loading:false
}

export const getters={
    getLoading:(state)=>{
        return state.loading;
    }
};

export const mutations={
    updateLoading(state, payload) {
        state.loading = payload;
    },
    updateNotification(state, payload){
        state.notifications = payload;
    }
}

export const actions={

    read:({commit})=>{
        commit("updateLoading", true);
        return NotificationService.callReadApi()
            .then((response)=> {
                commit("updateLoading", false);
                let responseData = response.data;
                // console.log(response)
                if (responseData.responseCode === "00") {
                    commit("updateNotification", responseData);
                } else {
                    // swal("Error", responseData.responseMessage, "error");
                }
            })
            .catch((err) => {
                commit("updateLoading", false);
                console.log(err);
            });

    },
    readPending:({commit})=>{
        commit("updateLoading", true);
        return NotificationService.callReadPendingApi()
            .then((response)=> {
                commit("updateLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updateNotification", responseData);
                } else {
                    // swal("Error", responseData.responseMessage, "error");
                }
            })
            .catch((err) => {
                commit("updateLoading", false);
                console.log(err);
            });
    },
        updateStatus:({ commit },payload) => {
        commit("updateLoading", true);
        return NotificationService.callUpdateStatusApi(payload)
            .then((response)=> {
                commit("updateLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    store.dispatch("notification/read")
                    commit("updateLoading", false)
                    // swal("Success", responseData.responseMessage, "success");
                    // BaseNotification.fireToast("success", responseData.responseMessage).then()

                } else {
                    commit("updateLoading", false)
                }
            }).catch((error) => {
                commit("updateLoading", false)
                swal("Error", error, "error");


    });
}
}

