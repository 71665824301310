
import BaseResponse from "@/model/response/BaseResponse";
class InfluencerResponse extends BaseResponse {
   readInfluencer(){
       return{
           data: [],
           responseCode: null,
           responseMessage: null,
       }
   }

}
export default InfluencerResponse