<template>
  <div>
    <div class="dashboard-nav wf-section">
      <div class="main-dashboard-section wf-section">
        <div class="main-dashboard-body mall-copy">
          <div class="get-started-wrapper"></div>
          <div class="container-v2">
            <div class="create-campaign-form-container">
              <div class="back-button-container">
                <a @click="$router.go(-1)" class="link-block-11 w-inline-block">
                  <div class="text-block-69">Back</div>
                </a>
              </div>
              <div class="create-campaign-header">
                <div>
                  <h1 class="heading-23">Create new product</h1>
                </div>
                <div class="div-block-90">
                  <div class="text-block-41">
                    Please provide your basic product details.
                  </div>
                </div>
                <div class="div-block-87">
                  <div class="campaigndetail-copy">
                    <img
                      :src="require('@/assets/images/1.svg')"
                      loading="lazy"
                      alt=""
                      class="image-28"
                    />
                    <div class="text-block-72">Product Details</div>
                  </div>
                  <div class="mediaupload">
                    <img
                      :src="require('@/assets/images/2.svg')"
                      loading="lazy"
                      alt=""
                      class="image-29"
                    />
                    <div class="text-block-73">Media Uploads</div>
                  </div>
                </div>
              </div>
              <div class="new-campaign-block2">
                <div class="w-form">
                  <form
                    @submit.prevent="navigate"
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    method="get"
                  >
                    <div class="campaign-name">
                      <label for="Campaign-Name-2" class="field-label-8"
                      >Enter name of product</label
                      ><input
                      type="text"
                      v-model="model.productName"
                      class="text-field-10 w-input"
                      maxlength="256"
                      name="Campaign-Name-2"
                      data-name="Campaign Name 2"
                      placeholder="Enter name of product "
                      id=""
                      required
                    />
                    </div>
                    <div class="description-copy">
                      <label for="Campaign-Description-2" class="field-label-9"
                      >Enter product description</label>
                      <textarea
                        placeholder="A brief description about the product"
                        maxlength="5000"
                        v-model="model.productDescription"
                        id="Campaign-Description-2"
                        name="Campaign-Description-2"
                        required
                        data-name="Campaign Description 2"
                        class="textarea-copy w-input">
                    </textarea>
                    </div>
                    <div class="campaign-name mall">
                      <div class="div-block-264">
                        <label for="Campaign-Name-9" class="field-label-8">Price in Local Currency</label></div>
                      <input v-model="model.productLocalCurrencyPrice" type="number"
                             class="text-field-10 w-input"
                             maxlength="256"
                             @input="filterBadChar()"
                             name="Campaign-Name-2" data-name="Campaign Name 2"
                             placeholder="Enter Price in local currency" id="Campaign-Name-9"
                             required="">
                    </div>
                    <div class="campaign-name mall">
                      <b-tooltip target="target-1" triggers="hover">
                        <p class="text-block-154">Price in USD</p>
                        <p class="text-block-155"> This is the dollar equivalent of the price of your product. <br><br><strong>NOTE: </strong>When withdrawing, Apvertise will pay you based on the official excahange rate for your local currency</p>
                      </b-tooltip>
                      <div class="div-block-264">
                        <label class="field-label-8">Price in USD</label>
                        <img :src="require('@/assets/images/Question.svg')"  id="target-1" loading="lazy" data-w-id="f8f26b2d-1c4d-8c64-9140-0b64d12f1d78" alt=""></div>
                      <input v-model="model.productUsdPrice"
                             type="number"
                             class="text-field-10 w-input"
                             @input="filterBadChar()"
                             maxlength="256" name="Campaign-Name-2" data-name="Campaign Name 2" placeholder="Enter USD Price" id="Campaign-Name-2" required="">
                    </div>
                    <!--                  <div class="campaign-name mall">-->
                    <!--                    <div class="div-block-264">-->
                    <!--                      <label class="field-label-8">Quantity in Stock</label>-->
                    <!--                    </div>-->
                    <!--                    <input v-model="model.productQuantity"-->
                    <!--                           type="number"-->
                    <!--                           class="text-field-10 w-input"-->
                    <!--                           maxlength="256"-->
                    <!--                           @input="filterBadChar()"-->
                    <!--                           name="Campaign-Name-2"-->
                    <!--                           data-name="Campaign Name 2" placeholder="Input quantity in stock" id="Campaign-Name-29" required="">-->
                    <!--                  </div>-->
                    <!--                  <div class="campaign-name mall">-->
                    <!--                    <div class="div-block-264">-->
                    <!--                      <label class="field-label-8">Product Maximum Delivery time(Days)</label>-->
                    <!--                    </div>-->
                    <!--                    <input v-model="model.productMaxDeliveryTime"-->
                    <!--                           type="number"-->
                    <!--                           class="text-field-10 w-input"-->
                    <!--                           @input="filterBadChar()"-->
                    <!--                           maxlength="256"-->
                    <!--                           name="Campaign-Name-2"-->
                    <!--                           data-name="Campaign Name 2"-->
                    <!--                           placeholder="Enter Product Maximum Delivery time" id="Campaign-Name-209" required>-->
                    <!--                  </div>-->
                    <!--                  <div class="campaign-name mall">-->
                    <!--                    <div class="div-block-264">-->
                    <!--                      <label v-if="model.productMinDeliveryTime <= model.productMaxDeliveryTime" class="field-label-8">Product Minimum Delivery time(Days)</label>-->
                    <!--                      <label v-else style="color: red" class="field-label-8">Product minimum delivery time must be less than the maximum delivery time </label>-->
                    <!--                    </div>-->
                    <!--                    <input v-model="model.productMinDeliveryTime"-->
                    <!--                           type="number" class="text-field-10 w-input"-->
                    <!--                           maxlength="256"-->
                    <!--                           @input="filterBadChar()"-->
                    <!--                           name="Campaign-Name-2"-->
                    <!--                           data-name="Campaign Name 2"-->
                    <!--                           placeholder="Enter Product Minimum Delivery time"-->
                    <!--                           id="Campaign-Name-290" required>-->
                    <!--                  </div>-->

                    <input
                      type="submit"
                      value="Proceed"
                      data-wait="Please wait..."
                      class="submit-button-7 w-button"
                    />
                    <button type="submit" class="button-16-copy-copy w-button">Proceed</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductRequest from "@/model/request/ProductRequest";
import { mapState } from "vuex";

export default {
  name: "AddProductFormOne",
  data() {
    return {
      model: ProductRequest.productCreateRequest,
    };
  },
  computed:{
    ...mapState(["organisation", "product", "auth"]),

  ...mapState ({ organisations:state => state.organisation.organisations[0].organisationId })
  },
  methods:{

    filterBadChar(){
      this.model.productLocalCurrencyPrice = this.model.productLocalCurrencyPrice.replace(/[^A-Z0-9\s]/i, '')
      this.model.productUsdPrice = this.model.productUsdPrice.replace(/[^A-Z0-9\s]/i, '')
      this.model.productQuantity = this.model.productQuantity.replace(/[^A-Z0-9\s]/i, '')
      this.model.productMaxDeliveryTime = this.model.productMaxDeliveryTime.replace(/[^A-Z0-9\s]/i, '')
      this.model.productMinDeliveryTime = this.model.productMinDeliveryTime.replace(/[^A-Z0-9\s]/i, '')
    },
    navigate() {
      // let productObject = {
      //   productPrice: this.model.productPrice,
      //   productName: this.model.productName,
      //   productOrganisationId: localStorage.getItem('organisationId'),
      //   productDescription: this.model.productDescription,
      // };
      // console.log(productObject)
      // this.$store.commit("product/updateProductFormObject", productObject);
      //
      //   {
        this.$store.commit("product/updateScreen", "two");
      }
    },

  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "https://play-apvertise.com/js/webflow.js");
    plugin.async = true;
    document.head.appendChild(plugin);
  },

  created() {
    const script = document.createElement("script");
    script.src = "https://play-apvertise.com/js/webflow.js";
  },
};
</script>

<style scoped>

</style>