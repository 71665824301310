import BaseResponse from "@/model/response/BaseResponse";
class NotificationResponse extends BaseResponse {
    updateStatus(){
        return{
            data: [],
            responseCode: null,
            responseMessage: null,
        }
    }

}
export default NotificationResponse