<template>
  <div class="payment-method_modal">
    <div class="pop-up-back"></div>
    <div class="wallet_modal_content" style="height: auto">
      <div class="wallet-modal-frame" style="gap: 12px">
        <div class="modal-title-wrapper">
          <div class="modal-title-wrapper-1">
            <div class="payment-method-h1">Select Payment Method</div>
          </div>
          <a
            href="#"
            class="f-modal-close-link w-inline-block"
            @click="close('.payment-method_modal')"
          >
<!--            <div class="f-icon-regular w-embed">-->
<!--              <svg-->
<!--                width="420"-->
<!--                height="420"-->
<!--                viewBox="0 0 24 24"-->
<!--                fill="none"-->
<!--                xmlns="http://www.w3.org/2000/svg"-->
<!--              >-->
<!--                <path-->
<!--                  d="M12.0002 10.586L16.9502 5.63599L18.3642 7.04999L13.4142 12L18.3642 16.95L16.9502 18.364L12.0002 13.414L7.05023 18.364L5.63623 16.95L10.5862 12L5.63623 7.04999L7.05023 5.63599L12.0002 10.586Z"-->
<!--                  fill="currentColor"-->
<!--                ></path>-->
<!--              </svg>-->
<!--            </div>-->
          </a>
        </div>
        <div class="payment-method-form">
          <div style="width: 100%" v-if="stage === 1">
            <label for="name" class="field-label-15"
              >What currency are you paying with?</label
            >
            <!--        <input v-cardformat:formatCardExpiry>-->
            <select
              id="name"
              v-model="currency"
              name="field"
              class="select-field-3 w-select"
              required
            >
              <!--          <option value="">Select card currency</option>-->
              <option
                v-for="currency in currencies.data"
                v-bind:key="currency.cc"
                :value="currency.cc"
              >
                {{ currency.cc }}
              </option>
            </select>
          </div>
<!--          <div class="options" v-else>-->
<!--            <a-->
<!--              :class="-->
<!--                first ? 'payment__option option-active' : 'payment__option'-->
<!--              "-->
<!--              style="text-decoration: none"-->
<!--              :href="href"-->
<!--              v-if="currency === 'NGN' || currency === 'GHS'"-->
<!--            >-->
<!--              <div class="payment__header">-->
<!--                <div class="payment__option-text">-->
<!--                  <img-->
<!--                    src="@/assets/images/card_avatar.svg"-->
<!--                    loading="lazy"-->
<!--                    alt=""-->
<!--                    class="payment-option-icon"-->
<!--                  />-->
<!--                  <div class="text-block-13">Pay with card</div>-->
<!--                </div>-->
<!--                <img-->
<!--                  src="@/assets/images/chevron-right_1.svg"-->
<!--                  loading="lazy"-->
<!--                  style="-->
<!--                    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)-->
<!--                      rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0);-->
<!--                    -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)-->
<!--                      rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0);-->
<!--                    -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)-->
<!--                      rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0);-->
<!--                    transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)-->
<!--                      rotateY(0) rotateZ(0deg) skew(0, 0);-->
<!--                  "-->
<!--                  alt=""-->
<!--                  class="payment-toggle-icon"-->
<!--                />-->
<!--              </div>-->
<!--            </a>-->
<!--            <a-->
<!--              :class="-->
<!--                first ? 'payment__option option-active' : 'payment__option'-->
<!--              "-->
<!--              style="text-decoration: none"-->
<!--              @click="makePayment"-->
<!--              v-else-->
<!--            >-->
<!--              <div class="payment__header">-->
<!--                <div class="payment__option-text">-->
<!--                  <img-->
<!--                    src="@/assets/images/card_avatar.svg"-->
<!--                    loading="lazy"-->
<!--                    alt=""-->
<!--                    class="payment-option-icon"-->
<!--                  />-->
<!--                  <div class="text-block-13">Pay with card</div>-->
<!--                </div>-->
<!--                <img-->
<!--                  src="@/assets/images/chevron-right_1.svg"-->
<!--                  loading="lazy"-->
<!--                  style="-->
<!--                    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)-->
<!--                      rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0);-->
<!--                    -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)-->
<!--                      rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0);-->
<!--                    -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)-->
<!--                      rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0);-->
<!--                    transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)-->
<!--                      rotateY(0) rotateZ(0deg) skew(0, 0);-->
<!--                  "-->
<!--                  alt=""-->
<!--                  class="payment-toggle-icon"-->
<!--                />-->
<!--              </div>-->
<!--            </a>-->
<!--            <div-->
<!--              :class="-->
<!--                second ? 'payment__option option-active' : 'payment__option'-->
<!--              "-->
<!--              v-if="currency === 'NGN'"-->
<!--            >-->
<!--              <div-->
<!--                data-w-id="7fb41fea-8719-d4eb-0f4f-76d152516931"-->
<!--                class="faq6_question"-->
<!--              >-->
<!--                <div class="payment__header" @click="openSecond">-->
<!--                  <div class="payment__option-text">-->
<!--                    <img-->
<!--                      src="@/assets/images/transfers_avatar.svg"-->
<!--                      loading="lazy"-->
<!--                      alt=""-->
<!--                      class="payment-option-icon"-->
<!--                    />-->
<!--                    <div class="text-block-14">Pay with transfer</div>-->
<!--                  </div>-->
<!--                  <img-->
<!--                    src="@/assets/images/chevron-right_1.svg"-->
<!--                    loading="lazy"-->
<!--                    alt=""-->
<!--                    class="payment-toggle-icon"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash;          :class="first ? 'payment__option option-active' : 'payment__option'"&ndash;&gt;-->
<!--              <div :style="second ? show : hide1" class="faq6_answer">-->
<!--                <div class="payment-option-content is-active">-->
<!--                  <div class="transfers-wrapper">-->
<!--                    <ul role="list" class="account-details w-list-unstyled">-->
<!--                      <li class="account-item">-->
<!--                        <p class="account-info">-->
<!--                          Account Name:-->
<!--                          <span class="account-text">{{-->
<!--                              auth.userInfo.fundingAccountName-->
<!--                            }}</span>-->
<!--                        </p>-->
<!--                      </li>-->
<!--                      <li class="account-item">-->
<!--                        <p class="account-info">-->
<!--                          Bank Name:-->
<!--                          <span class="account-text">{{-->
<!--                              auth.userInfo.fundingBank-->
<!--                            }}</span>-->
<!--                        </p>-->
<!--                      </li>-->
<!--                      <li class="account-item">-->
<!--                        <p class="account-info">-->
<!--                          Account Number:-->
<!--                          <span class="account-text">{{-->
<!--                              auth.userInfo.fundingAccount-->
<!--                            }}</span>-->
<!--                        </p>-->
<!--                        <button-->
<!--                          id="tooltip-button-show-event-acc"-->
<!--                          type="button"-->
<!--                          style="background-color: white"-->
<!--                          v-clipboard:copy="auth.userInfo.fundingAccount"-->
<!--                          v-clipboard:success="onCopyAcc"-->
<!--                          v-clipboard:error="onError"-->
<!--                        >-->
<!--                          <img-->
<!--                            src="@/assets/images/copy_1_black.svg"-->
<!--                            loading="lazy"-->
<!--                            alt=""-->
<!--                            class="copy__icon"-->
<!--                          />-->
<!--                        </button>-->
<!--                      </li>-->
<!--                      <li class="account-item">-->
<!--                        <p class="account-info">-->
<!--                          Narration:-->
<!--                          <span class="account-text">{{-->
<!--                              manualFundingModel.token-->
<!--                            }}</span>-->
<!--                        </p>-->
<!--                        <button-->
<!--                          id="tooltip-button-show-event-token"-->
<!--                          type="button"-->
<!--                          style="background-color: white"-->
<!--                          v-clipboard:copy="manualFundingModel.token"-->
<!--                          v-clipboard:success="onCopyToken"-->
<!--                          v-clipboard:error="onError"-->
<!--                        >-->
<!--                          <img-->
<!--                            src="@/assets/images/copy_1_black.svg"-->
<!--                            loading="lazy"-->
<!--                            alt=""-->
<!--                            class="copy__icon"-->
<!--                          />-->
<!--                        </button>-->
<!--                      </li>-->
<!--                    </ul>-->
<!--                    <a-->
<!--                      href="#"-->
<!--                      class="payment_btn w-button"-->
<!--                      @click="confirmPayment"-->
<!--                    ><span v-if="!text1">I have paid</span><span v-else>Copied!</span></a-->
<!--                    >-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="payment-method-btm">
            <button
              type="button"
              @click="close('.payment-method_modal')"
              class="close_btn"
            >
              Cancel
            </button>
            <button type="button" class="btn__submit" @click="uploadProfile('.fund_wallet')" v-if="stage < 2">
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
    <fund-wallet :fund ="fund"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CountryResponse from "@/model/response/CountryResponse";
import store from "@/store/store";
import FundWallet from "@/components/modal/FundwalletModal.vue";

export default {
  name: "PaymentMethodModal",
  components: {FundWallet},
  props: ["pay"],
  computed: {
    ...mapState(["auth", "transaction", "manualFunding", "card"]),
    currencies() {
      return CountryResponse.prototype.currencies();
    },
  },
  data() {
    return {
      stage: 1,
      fund: true,
      model: {
        amount: "",
      },
      first: false,
      second: false,
      show: {
        width: "100%",
        height: "auto",
      },
      hide1: {
        width: "100%",
        height: "0",
        transition: "height .5s ease-in-out",
      },
      message: "",
      currency: "NGN",
      screen: "one",
      text: "Click to copy",
      variant: "dark",
      text1: false,
      variant1: "dark",
      chargeCardModel: {
        amount: "",
        cvv: "",
        cardNumber: "",
        expirydate: "",
        expirymonth: "",
        expiryyear: "",
        pan: "",
        pin: "",
        reference: "",
      },
      chargeCardModelOtp: {
        action: "",
        amount: "",
        flwref: "",
        otp: "",
        pan: "",
        raveref: "",
        reference: "",
      },
      manualFundingModel: {
        creditAccountNumber: "",
        debitAccountNumber: "",
        creditAccountName: "",
        ftProductCode: "",
        reference: "",
        source: "",
        username: "",
        password: "",
        countryCode: "",
        trnAmount: "",
        token: "",
        trnNarration: "",
      },
      href: "",
    };
  },
  methods: {
    close(className) {
      this.stage = 1;
      document.querySelector(className).style.opacity = 0;
      setTimeout(() => {
        document.querySelector(className).style.display = "none";
      }, 500);
    },
    uploadProfile(className) {
      document.querySelector('.payment-method_modal').style.opacity = 0;
      setTimeout(() => {
        document.querySelector('.payment-method_modal').style.display = "none";
      }, 500);
      document.querySelector(className).style.display = "flex";
      setTimeout(() => {
        document.querySelector(className).style.opacity = 1;
      }, 100);
    },
    openSecond() {
      this.first = false;
      this.second = !this.second;
    },
    async callFlutterwave() {
      let currency = this.currency;
      let amount;
      let customerId = this.auth.userInfo.customerId;
      console.log(customerId)
      if (currency === "NGN") {
        amount = this.manualFunding.amount * this.auth.userInfo.ngnPayInRate;
      } else {
        amount = this.manualFunding.amount * this.auth.userInfo.ghPayInRate;
      }
      console.log(amount)
      console.log(currency)

      let res = await this.$store
        .dispatch("transaction/callFlutterwaveTransaction", {
          amount: amount,
          currency: currency,
          customerId: customerId,
        })
        .then();
      console.log(res)
      this.href = res.location;
    },
    callback() {
      console.log("Payment made, verify payment");
    },
    hide() {
      this.$refs["select-card-currency-modal"].hide();
    },
    // eslint-disable-next-line no-unused-vars
    onError: function (e) {
      console.log(e)
      this.$bvToast.toast("Failed to copy the text to the clipboard", {
        title: `Copy - Error`,
        variant: "error",
        autoHideDelay: 100000,
        solid: true,
      });
    },
    async chargeCard() {
      this.chargeCardModel.expirymonth = this.chargeCardModel.expirydate
        .split("/")[0]
        .trim();
      this.chargeCardModel.expiryyear = this.chargeCardModel.expirydate
        .split("/")[1]
        .trim();
      this.chargeCardModel.reference = this.reference();
      this.chargeCardModel.pan = this.chargeCardModel.cardNumber.replace(
        /\s+/g,
        ""
      );
      this.chargeCardModel.amount = this.manualFunding.amount;
      await this.$store.dispatch(
        "card/rubiesCardFunding",
        this.chargeCardModel
      );
      if (this.card.response.responseCode === "00") {
        this.screen = "three";
        this.message = this.card.response.responseMessage;
      }
      this.chargeCardModelOtp = this.card.response;
    },
    async chargeCardOtp() {
      this.chargeCardModelOtp.amount = this.model.amount;
      this.chargeCardModelOtp.reference = this.chargeCardModel.reference;
      this.chargeCardModelOtp.action = "SENDOTP";
      await this.$store.dispatch(
        "card/rubiesCardFundingOtp",
        this.chargeCardModelOtp
      );
      if (this.card.response.responseCode === "00") {
        store.commit("updateMessage", {
          action: "reload",
          type: "success",
          title: "Success",
          body: this.card.response.responseMessage,
        });
        this.hide();
        this.$refs["flutterwave-modal"].hide();
        this.$store.dispatch("transaction/queryTransaction", {
          customerId: this.auth.userInfo.customerId,
        });
      }
      this.chargeCardModelOtp.responseCode = this.card.response.responseCode;
      this.chargeCardModelOtp.responseMessage =
        this.card.response.responseMessage;
    },
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 30; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
    token() {
      let text = "";
      let possible = "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      this.manualFundingModel.token = text;
      return text;
    },

    async makePayment() {
      if (this.currency === "NGN" || this.currency === "GHS") {
        console.log(this.currency);
        this.screen = "two";
        await this.callFlutterwave();
      } else {
        console.log(window.__env.app.flwKey);
        console.log(this.auth.userInfo.customerEmail.trim());
        window.FlutterwaveCheckout({
          public_key: window.__env.app.flwKey,
          tx_ref: `${this.reference()}-${this.auth.userInfo.customerId}-${
            this.auth.userInfo.accounts[0].accountNumber
          }-${this.auth.userInfo.customerBankCode}`,
          amount: this.manualFunding.amount,
          currency: "USD",
          payment_options: "card,mobilemoney,ussd",
          customer: {
            name: this.auth.userInfo.customerName, //this.name,
            email: this.auth.userInfo.customerEmail.trim(),
          },
          callback: (response) => this.callback(response),
          customizations: {
            title: "Wallet Funding",
            description: "Payment for items in cart",
            logo: window.__env.app.flwLogo,
          },
        });
      }
    },
  },
  mounted() {
    this.$store.commit("manualFunding/updateCurrency", this.currency);
    this.model.amount = this.manualFunding.amount;
    this.token();
    this.makePayment();
    window.onclick = function (event) {
      if (event.target.className === "pop-up-back") {
        document.querySelector(".payment-method_modal").style.opacity = 0;
        document.querySelector(".funding_modal").style.opacity = 0;
        setTimeout(() => {
          document.querySelector(".payment-method_modal").style.display =
            "none";
          document.querySelector(".funding_modal").style.display = "none";
        }, 500);
      }
    };
  },
  watch: {
    currency: function(){
      this.$store.commit("manualFunding/updateCurrency", this.currency);
    },
    'manualFunding.amount': function() {
      this.makePayment();
    },
    // whenever question changes, this function will run
    pay: function () {
      this.screen = "one";
      this.manualFundingModel.token = this.token();
      this.chargeCardModel = {
        amount: "",
        cvv: "",
        cardNumber: "",
        expirydate: "",
        expirymonth: "",
        expiryyear: "",
        pan: "",
        pin: "",
        reference: "",
      };
      this.chargeCardModelOtp = {
        action: "",
        amount: "",
        flwref: "",
        otp: "",
        pan: "",
        raveref: "",
        reference: "",
      };
      this.$store.commit("card/updateResponse", {});
      this.$refs["select-card-currency-modal"].show();
    },
  },

};
</script>

<style scoped>

@media screen and (max-width: 520px) {
  .wallet_modal_content {
    width: 280px !important;
    height: 350px !important;
    overflow: scroll !important;
  }
  .select-field-3.w-select{
    width: 100%
  }
  .payment-method-btm{
    width: 100% !important;
    justify-content: center !important;
    margin-left: 0 !important;
    margin-top: 40px !important;
  }
}
.btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 18px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  margin-top: 30px;
  background-color: #489e85;
  color: #fff !important;
  font-weight: 600;
  text-decoration: none;
  background-image: none !important;
  width: 100%;
}

.btn:hover {
  color: #489e85 !important;
  background-color: rgba(0, 0, 0, 0);
  border-color: #489e85;
}
.payment-method_modal {
  justify-content: center;
  align-items: center;
  display: none;

  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
.pop-up-back {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(32, 32, 32, 0.6);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
.wallet-modal-frame {
  width: auto;
  gap: 30px;
  flex-direction: column;
  align-items: stretch;
  display: flex;
}
.wallet_modal_content {
  z-index: 99999999999;
  background-color: #fff;
  border-radius: 16px;
  align-self: center;
  padding: 24px;
  position: relative;
  height: 330px;
  width: 408px;
}
.payment-method-h1 {
  color: #101828;
  letter-spacing: -0.03em;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
}
.options {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  display: flex;
}
.payment__option {
  border: 1px solid #eaecf0;
  /*border:1px solid #489e85;*/
  border-radius: 12px;
  flex-direction: column;
  padding: 24px;
  transition: all 0.2s;
  display: flex;
  overflow: scroll;
}
.option-active {
  border: 1px solid #489e85;
}
.w-inline-block:hover {
  opacity: 1;
  color: white;
}
/*.payment__option {*/
/*  border: 1px solid #eaecf0;*/
/*  border-radius: 12px;*/
/*  flex-direction: column;*/
/*  padding: 24px;*/
/*  transition: all .2s;*/
/*  display: flex;*/
/*  overflow: hidden;*/
/*}*/
.faq6_question {
  color: #1d2939;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  font-weight: 700;
  display: flex;
}
.payment__header {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  display: flex;
  overflow: visible;
}
.payment-input {
  color: #101828;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  margin-bottom: 0;
  padding: 10px 14px;
}
.wallet-input-label {
  color: #344054;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.42857;
}
.payment-method-form {
}
.payment-form-grid {
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 112px;
  grid-auto-columns: 1fr;
  display: grid;
}
.payment-form-item {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  display: flex;
}
.payment-btn-group {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  display: flex;
}
.payment_btn {
  width: 100%;
  background-color: #489e85;
  border: 1px solid #489e85;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  display: flex;
  color: white;
}
.payment_btn.secondary {
  color: #344054;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #d0d5dd;
}
.payment-input.is-name {
  background-image: url("../../assets/images/Help-icon.svg");
  background-position: 95%;
  background-repeat: no-repeat;
  background-size: auto;
  padding-right: 38px;
}
.payment__option-text {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  color: #344054;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1;
  display: flex;
}
.payment-option-icon {
  width: 40px;
  height: 40px;
}
.text-block-13,
.text-block-14 {
  font-size: 1rem;
}
.payment-toggle-icon {
  width: 24px;
  height: 24px;
}
.faq6_answer {
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}
.payment-option-content {
  padding-top: 32px;
  display: block;
  overflow: hidden;
}
.faq6_question {
  color: #1d2939;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  font-weight: 700;
  display: flex;
}
.faq6_answer {
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}
.payment__header {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  display: flex;
  overflow: visible;
}
.payment-option-content.is-active {
  display: block;
}
.payment-input.is-cardnumber {
  background-image: url("../../assets/images/mastercard.svg");
  background-position: 10px;
  background-repeat: no-repeat;
  background-size: auto;
  padding-left: 58px;
}
.transfers-wrapper {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  flex-direction: column;
  display: flex;
}
.account-details {
  flex-direction: column;
  display: flex;
}
ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 1.5rem;
}
.account-item {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  align-items: center;
  margin-bottom: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
}
.account-info {
  color: #667085;
  font-size: 1rem;
  line-height: 1;
}
.account-text {
  color: #344054;
  font-weight: 500;
}
.copy__icon {
  width: 24px;
  height: 24px;
  object-fit: cover;
}

.visible {
  opacity: 1;
  display: flex;
}
.payment-method_modal {
  z-index: 99999;
}
.w-select {
  height: 51.19px;
  width: 358px;
}
.close_btn {
  //width: 30%;
  width: 130px;
  height: 44px;
  color: #160042;
  letter-spacing: -0.02em;
  background-color: #fff;
  border: 1px solid #e4e6f1;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  transition: border-color 0.3s, background-color 0.3s;
  display: flex;
}
.btn__submit {
  font-size: 14px;
  height: 44px;
  width: 130px;
  color: #fff;
  cursor: pointer;
  background-color: #489e85;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-family: Figtree, sans-serif;
  font-weight: 600;
  text-decoration: none;
  display: flex;
}
.payment-method-btm {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  margin-left: 24px;
}
.select-currency-1 {
  color: #667085;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.42857;
}
.payment-method-h1 {
  color: #101828;
  letter-spacing: -0.03em;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
}
.modal-title-wrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 1.5rem;
  width: 100%;
  color: #160042;
  justify-content: space-between;
  position: relative;
}
.modal-title-wrapper-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
