<template>
  <div data-w-id="d84ad800-4a62-6d77-00aa-5dbc2506cb90" class="qoo-modal success__modal" style="display: flex">
    <div class="pop-up-back" ></div>
    <div data-w-id="d84ad800-4a62-6d77-00aa-5dbc2506cb92" class="modal_content">
      <div class="modal_c"><img src="../../../public/images/success.svg" loading="lazy" alt="" class="modal__image">
        <div class="modal_text">
          <h1 class="modal_h">Sign up successful 🥳</h1>
          <p class="modal_p">Your account has been created <br>successfully</p>
        </div>
        <router-link to="/logon" class="btn__submit">Proceed to login</router-link>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessfulRegister"
};
</script>

<style scoped>
.button-10:hover{
  color: #FFFFFF !important;
}

.btn__submit {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50rem;
  background-color: #489e85;
  font-family: Figtree, sans-serif;
  font-weight: 600;
  cursor: pointer;
}

</style>



<!--"_PaymentType": "string",-->
<!--"_TicketType": "string",-->
<!--"_PaymentTexts": [-->
<!--{-->
<!--"Name": "string",-->
<!--"Text": "string"-->
<!--}-->
<!--],-->
<!--"_NumberOfBaggages": 0,-->
<!--"_NumberOfBaggages1": 0,-->
<!--"_HandLuggages": 0,-->
<!--"_HandLuggages1": 0,-->
<!--"_AmountPaid": 0,-->