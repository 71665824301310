<template>
  <span>
<!--    <a href="#"  class="button-16 w-button">{{ title }}</a>-->
<!--    <input-->
<!--      v-if="!loading"-->
<!--      type="submit"-->
<!--      :value="title"-->
<!--      data-wait="Please wait..."-->
<!--      class="submit-button-5 w-button"-->
<!--    />-->
    <b-button v-if="!loading" :disabled="disabled" type="submit" style="background: #63b8a0; border-color: #63b8a0; color: #fff !important; height: 50px; border-radius: 8px" class="form-control">{{ title }}</b-button>
    <b-button v-else disabled type="submit" style="background: #63b8a0; border-color: #63b8a0; border-radius: 8px" class="form-control"><b-spinner small/></b-button>
<!--    <a href="#" v-else disabled="true" class="submit-button-5 w-button"><b-spinner/></a>-->
<!--    <a href="#" v-else disabled="true" class="button-16 w-button"><i class="w-lightbox-spinner"></i></a>-->
  </span>
</template>

<script>
//submit-button-4 w-button
//button-16 w-button
//button-16-copy w-button
export default {
  name: "BaseButton",
  props:["loading", "title", "disabled"]
};
</script>

<style scoped>

</style>