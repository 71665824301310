class PimCampaignRequest{
    create() {
        return{
            pimCampaignInfluencerId: null,
            pimCampaignBusinessId: null,
            pimPackageId: null,
            pimCampaignDescription: null,
            pimCampaignMedia: null,
            pimCampaignPrice: null
        }
    }
    accept(){
        return{
            pimCampaignId: null
        }
    }
    decline(){
        return{
            pimCampaignId: null
        }
    }
    complete(){
        return{
            pimCampaignId: null
        }
    }
    readSingleCustomer(){

    }
    createPackage(){
        return{
            packageType: null,
            packageName: null,
            packageDescription: null,
            packageContent: null,
            packagePrice: null
        }

    }
    readPackage(){
        return{
            packageCustomerId:null
        }
    }

}

export default PimCampaignRequest