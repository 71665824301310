import client from "./BaseService";

export default {
    callCreateApi(payload){
        return client.apiClient.post("message/create", payload)
    },
    callUpdateApi(payload){
        return client.apiClient.post("message/update", payload)
    },
    callReadByCustomerIdApi(payload){
        return client.apiClient.post("message/read-by-customer-id", payload)
    },
    callReadByGroupIdApi(payload){
        return client.apiClient.post("message/read-by-group-id", payload)
    }

}