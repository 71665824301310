<template>
  <div>
    <dashboard-header></dashboard-header>
    <div class="hero-section wf-section">
    <div class="explainer hero-copy">
      <div class="div-block-256">
        <div class="text-block-151">This campaign is unavailable <br>in your region😔</div>
    <div>

    </div>
      </div>
      <div class="section-header13">
        <div class="header13_component">
          <div class="header13_lightbox-wrapper">
            <a href="#" class="header13_lightbox w-inline-block w-lightbox">
              <img :src="require('@/assets/images/banner.png')" loading="lazy" width="1696"  alt="" class="header13_lightbox-thumbnail">
              <div class="overlay">

              </div>
            </a>
          </div>
          <div class="page-padding">
            <div class="container-large">
              <div class="padding-vertical padding-xxlarge"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="video-overlay-layer" v-if="isClosed == 'NO'" @click="closeOverlay">
      <div v-html="items.html"></div>
    </div>
    <div class="footer wf-section">
      <div data-w-id="43c34cce-d416-5d79-c87b-99d895eaaecc" class="container footer">
        <div class="divider">
        </div>
        <div class="w-layout-grid footer-grid">
          <div class="grid-one">
            <img :src="require('@/assets/images/White-logo_1.svg')" loading="lazy" alt="" class="image-13">
            <div class="social-links">
              <a href="#" class="w-inline-block"><img :src="require('@/assets/images/Group-4736.svg')" loading="lazy" alt="" class="image-14"></a>
              <a href="#" class="w-inline-block"><img :src="require('@/assets/images/Group-4737.svg')" loading="lazy" alt="" class="image-14"></a>
            </div>
            <div class="mobile-app-link">
              <a href="https://apps.apple.com/ng/app/apvertise/id1600188010" target="_blank" class="link-block-24 w-inline-block"><img :src="require('@/assets/images/Badge-iOS.svg')" loading="lazy" alt="" class="app-store"></a>
              <a href="https://play.google.com/store/apps/details?id=com.qucoon.appvertise" target="_blank" class="w-inline-block"><img :src="require('@/assets/images/Badge-Android.svg')" loading="lazy" alt="" class="playstore"></a>
            </div>
          </div>
          <div class="grid-two">
            <h1 class="heading-11">Links</h1>
            <div class="div-block-13">
              <a href="#" class="link-block w-inline-block">
                <div class="text-block-3">Home</div>
              </a>
              <a href="#" class="link-block w-inline-block">
                <div class="text-block-3">For Business</div>
              </a>
              <a href="#" class="link-block w-inline-block">
                <div class="text-block-3">For Micro Influencers</div>
              </a>
            </div>
          </div>
          <div class="grid-three">
            <h1 class="heading-11">Get Help</h1>
            <div class="div-block-13">
              <router-link to="/contact-us" class="link-block w-inline-block">
                <div class="text-block-3">Support (support@apvertise.com)</div>
              </router-link>
              <a href="#" class="link-block w-inline-block">
                <div class="text-block-3">FAQ’s</div>
              </a>
              <a href="#" class="link-block w-inline-block">
                <div class="text-block-3">Partnership</div>
              </a>
            </div>
          </div>
          <div class="grid-four">
            <h1 class="heading-11">Privacy</h1>
            <div class="div-block-13">
              <router-link to="/influencer-tc" class="link-block w-inline-block">
                <div class="text-block-3">Influencers T&amp;C&#x27;s</div>
              </router-link>
              <router-link to="/company-tc" class="link-block w-inline-block">
                <div class="text-block-3">Companies T&amp;C&#x27;s</div>
              </router-link>
              <router-link to="/privacy-policy" class="link-block w-inline-block">
                <div class="text-block-3">Privacy Policy</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lightbox-play-icon w-embed" @click="openOverlay">
         <svg width="64" height="64" viewbox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" style="cursor: pointer">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.33301 32C5.33301 17.2724 17.2721 5.33334 31.9997 5.33334C39.0721 5.33334 45.8549 8.14286 50.8559 13.1438C55.8568 18.1448 58.6663 24.9276 58.6663 32C58.6663 46.7276 46.7273 58.6667 31.9997 58.6667C17.2721 58.6667 5.33301 46.7276 5.33301 32ZM27.1198 43.4134L42.6664 33.7067C43.2482 33.3341 43.6001 32.6909 43.6001 32C43.6001 31.3092 43.2482 30.6659 42.6664 30.2934L27.0664 20.5867C26.452 20.1993 25.6758 20.1755 25.0388 20.5244C24.4018 20.8734 24.004 21.5403 23.9998 22.2667V41.7334C23.9912 42.4774 24.3963 43.1647 25.0514 43.5174C25.7065 43.8702 26.5033 43.8301 27.1198 43.4134Z" fill="CurrentColor"></path>
      </svg>
    </div>
  <div data-w-id="fa2ff92f-1337-2613-6fe9-3fa8c96e2682" class="notice-delete">
    <div class="notice-wrapper">
      <div class="flowbase-left-wrap">
        <div class="flowbase-icon-wrap"><img :src="require('@/assets/images/icon.svg')" width="25" alt="" class="flowbase-icon"></div>
        <div class="flowbase-title-wrap">
          <div class="flowbase-title">Download our Mobile App</div>
          <a href="https://webflow.com/Flowbase" target="_blank" class="flowbase-author">Available on both Android &amp; iOS<span class="highlight-text"></span><span class="flowbase-underline"></span></a>
        </div>
      </div>
      <div class="mobile-app-link-copy">
        <a href="https://apps.apple.com/ng/app/apvertise/id1600188010" target="_blank" class="w-inline-block"><img :src="require('@/assets/images/Badge-iOS.svg')" loading="lazy" alt="" class="app-store"></a>
        <a href="https://play.google.com/store/apps/details?id=com.qucoon.appvertise" target="_blank" class="w-inline-block"><img :src="require('@/assets/images/Badge-Android.svg')" loading="lazy" alt="" class="playstore"></a>
      </div>
      <div data-w-id="fa2ff92f-1337-2613-6fe9-3fa8c96e2693" class="flowbase-close-card"><img src="https://uploads-ssl.webflow.com/5aed5f7ff167d632686865b0/5beac8ffed94f5c015f87bb8_x.svg" width="15" height="15" alt="" class="flowbase-exit"></div>
    </div>
  </div>
  </div>
</template>

<script>
import DashboardHeader from "@/components/menu/DashboardHeader";
export default {
  name: "Explainer",
  components:{
    DashboardHeader
  },
  data(){
    return{
      jitems:
        {
          "url": "https://www.youtube.com/watch?v=BQYNRux_w74",
          "originalUrl": "https://www.youtube.com/watch?v=BQYNRux_w74",
          "width": 940,
          "height": 528,
          "thumbnailUrl": "https://i.ytimg.com/vi/BQYNRux_w74/hqdefault.jpg",
          "html": "<iframe class=\"embedly-embed\" src=\"//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FBQYNRux_w74%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DBQYNRux_w74&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FBQYNRux_w74%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube\" width=\"940\" height=\"528\" scrolling=\"no\" title=\"YouTube embed\" frameborder=\"0\" allow=\"autoplay; fullscreen\" allowfullscreen=\"true\"></iframe>",
          "type": "video"
        }
      ,
      "group": "",
      "isClosed":""
    }
  },

  methods:{
    closeOverlay(){
      this.isClosed = "YES"
    },
    openOverlay(){
      this.isClosed = "NO"
    }
  },
  created() {
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "https://play-apvertise.com/js/webflow.js");
    plugin.async = true;
    document.head.appendChild(plugin);
  },
};
</script>

<style scoped>
</style>