<script >

import DashboardNew from "@/components/menu/DashboardNew.vue";
import {mapState} from "vuex";
import PimCampaignRequest from "@/model/request/PimCampaignRequest";
import s3Request from "@/model/request/S3Request";
import BaseLoader from "@/components/loader/BaseLoader.vue";

export default {
  name: "InfluencerProfile",
  components: {BaseLoader, DashboardNew},
  data(){
    return{
      selectedSocial: {},
      showPrice: false,
      selectedPackage: "basic",
      uploadmodel: s3Request.prototype.uploadBase64(),
      base64: "",
      show:false,
      fileSizeLarge: false,
      isLoader: false,
      basic: true,
      standard: false,
      premium:false,
      show1: false,
      url: "",
      urls: [],
      fileName: "",
      socials:false,
      socialMedia: "Socials",
      model: PimCampaignRequest.prototype.create()
    }
  },
  computed:{
    ...mapState({
      influencers: (state) => state.influencer.influencers,
      pimPackages: (state) => state.pimCampaign.pimPackages,
      pimCampaigns: (state) => state.pimCampaign.pimCampaigns,
      userInfo: (state) => state.auth.userInfo,
      organisation: (state) => state.organisation.organisations,
      sbucket: (state) => state.sbucket,
      auth: (state) => state.auth,
      recentCampaigns: (state) => state.pimCampaign.recentCampaigns,
    }),
    selectedInfluencer(){
      let id = this.$route.params.id.hexDecode();
      let influencer = {};
      this.influencers.data.forEach((item) => {
        if (item.customerId === id){
          influencer = item
        }
      })
      return influencer
    },
    basicPackage(){
      let packaging = {
        packageContent: ""
      }
      this.pimPackages.data.forEach((packages) => {
        if (packages.packageType === "BASIC"){
          packaging = packages
        }
      })
      return packaging
    },
    standardPackage(){
      let packaging = {
        packageContent: ""
      }
      this.pimPackages.data.forEach((packages) => {
        if (packages.packageType === "STANDARD"){
          packaging = packages
        }
      })
      return packaging
    },
    premiumPackage(){
      let packaging ={
        packageContent: ""
      }
      this.pimPackages.data.forEach((packages) => {
        if (packages.packageType === "PREMIUM"){
          packaging = packages
        }
      })
      return packaging
    },
    socialName(){
      let socialName = "Socials";
      if (this.selectedInfluencer.socialDetails.length > 0){
        socialName = this.selectedInfluencer.socialDetails[0].socialMediaDetailsPlatform
      }
      return socialName
    },
  },
  methods:{
    uploadFile(id) {
      this.fileSizeLarge = false;
      this.isLoader = true;
      let input = document.getElementById(id)
      console.log(input)
      let files = input.files;
      console.log(files)
      this.fileName = files[0].name;
      let size = files[0].size / 1000000;
      console.log(size)
      if (size > 0.5){
        this.fileSizeLarge = true;
        this.isLoader = false;
      }
      else{
        const reader = new FileReader();
        try {
          reader.onload = (e) => {
            this.base64 = e.target.result;
            this.uploadOfficerImage();
          };
          reader.readAsDataURL(files[0]);
          this.$emit("input", files[0]);
        } catch (e) {
          console.warn(e.message);
        }
      }
    },
    async uploadOfficerImage() {
      this.isLoader = true;
      this.uploadmodel.username = this.fileName;
      this.uploadmodel.base64 = this.base64;
      await this.$store.dispatch(
          "sbucket/uploadEncodedFile",
          this.uploadmodel,
          { root: true }
      );
      this.url = this.sbucket.s3bucketResponse.url;
      let object = {
        name: this.fileName,
        img: this.url
      }
      this.urls.push(object)
      console.log(this.url)
      this.isLoader = false;
    },
    openModal(className) {
      document.querySelector(className).style.opacity = 1;
      setTimeout(()=>{
        document.querySelector(className).style.display = "flex";
      }, 500)
      setTimeout(() => {
        this.show1 = true;
      }, 650)
    },
    async saveChanges(){
      this.isLoader = true;
      let packageId = ""
      if (this.selectedPackage === "basic"){
        packageId = this.basicPackage.packageId;
        this.model.pimCampaignInfluencerId = this.basicPackage.packageCustomerId;
        if (this.showPrice === false){
          this.model.pimCampaignPrice = this.basicPackage.pimCampaignPrice
        }
      }
      else  if (this.selectedPackage === "standard"){
        packageId = this.standardPackage.packageId;
        this.model.pimCampaignInfluencerId = this.standardPackage.packageCustomerId;
        if (this.showPrice === false){
          this.model.pimCampaignPrice = this.standardPackage.pimCampaignPrice
        }
      }
      else{
        packageId = this.premiumPackage.packageId;
        this.model.pimCampaignInfluencerId = this.premiumPackage.packageCustomerId;
        if (this.showPrice === false){
          this.model.pimCampaignPrice = this.premiumPackage.pimCampaignPrice;
        }
      }
      this.model.pimPackageId = packageId;
      let url = []
      this.urls.forEach((urls) => {
        url.push(urls.img)
      })
      this.model.pimCampaignMedia = url.join();
      await this.$store.dispatch("pimCampaign/create", this.model );
      this.close(".custom-quote-modal");
      this.isLoader = false;
    },
    toggleShow(){
      this.show =!this.show
    },
    activateBasic(){
      this.selectedPackage = "basic"
      this.show = false;
      this.basic = true
      this.standard = false
      this.premium = false
    },
    activateStandard(){
      this.selectedPackage = "standard";
      this.show = false;
      this.basic = false
      this.standard = true
      this.premium = false
    },
    activatePremium(){
      this.selectedPackage = "premium";
      this.show = false;
      this.basic = false
      this.standard = false
      this.premium = true
    },
    close(className) {
      document.querySelector(className).style.opacity = 0;
      setTimeout(()=>{
        document.querySelector(className).style.display = "none";

      }, 500)
    },
    showSocials(){
      this.socials = !this.socials
    },
    addSocials(social){
      this.socialMedia = social.socialMediaDetailsPlatform;
      this.selectedSocial = social
    },
    viewInfluencer() {
      this.$router.push("/influencer/view");
    },
    goToVibes(){
      this.$router.push("/influencers");
    },
    goToMessaging(){
      if (this.selectedPackage === "basic"){
        this.$store.commit("pimCampaign/updateSelectedPackage", this.basicPackage)
      }
      else if (this.selectedPackage === "standard"){
        this.$store.commit("pimCampaign/updateSelectedPackage", this.standardPackage)
      }
      else{
        this.$store.commit("pimCampaign/updateSelectedPackage", this.premiumPackage)
      }
      this.$store.commit("pimCampaign/updateSelectedInfluencer", this.selectedInfluencer)
      this.$router.push("/influencer-pim-chat");
    }

  },
  mounted() {
    this.model.pimCampaignBusinessId = this.organisation[0].organisationId;
    this.$store.dispatch("pimCampaign/readPackage", {packageCustomerId: this.selectedInfluencer.customerId});
    window.onclick = function(event) {
      if (event.target === document.querySelector('.contact-modal1_background-overlay')) {
        document.querySelector('.qoo-modal').style.opacity = 0;
        setTimeout(()=>{
          document.querySelector('.qoo-modal').style.display = "none";
        }, 500)
      }

    }

    this.selectedSocial = this.selectedInfluencer.socialDetails[0];
    this.socialMedia = this.selectedSocial.socialMediaDetailsPlatform;
    this.$store.dispatch("pimCampaign/readRecentCampaign", {customerId: this.selectedInfluencer.customerId})
    if (this.selectedInfluencer.socialDetails.length > 0) {
      this.selectedSocial = this.selectedInfluencer.socialDetails[0];
    }
  },
  watch: {
    selectedInfluencer(){
      this.$store.dispatch("pimCampaign/readRecentCampaign", {customerId: this.selectedInfluencer.customerId})
      this.$store.dispatch("pimCampaign/readPackage", {packageCustomerId: this.selectedInfluencer.customerId});
      this.model.pimCampaignBusinessId = this.organisation[0].organisationId;
      this.selectedSocial = this.selectedInfluencer.socialDetails[0];
      this.socialMedia = this.selectedSocial.socialMediaDetailsPlatform;
    },
    organisation(){
      this.model.pimCampaignBusinessId = this.organisation[0].organisationId;
    }
  }
}
</script>

<template>
  <div>
    <dashboard-new/>
    <section class="qoo-modal custom-quote-modal">
      <div class="padding-global-2">
        <div class="container-large-2">
          <div class="padding-section-large-2">
            <div class="qoo-modal  contact-modal1_component" >
              <div style="-webkit-transform:translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" class="contact-modal1_content-wrapper"  :class="{'new-modal': show1}">
                <h5 class="influencer-modal-title">Get a quote</h5>
                <div class="pim-quote-modal-greeting">
                  <div class="modal-influncer-image" v-if="selectedInfluencer.customerFirstName">{{selectedInfluencer.customerFirstName.charAt(0) + selectedInfluencer.customerLastName.charAt(0)}}</div>
                  <div>
                    <div class="pim-modal-influencer-name">{{selectedInfluencer.customerFirstName + " " + selectedInfluencer.customerLastName}}</div>
                    <div class="pim-modal-greet">Hi, please provide your request details below and I’ll get back to you.</div>
                  </div>
                </div>
                <div class="contact-modal1_form-block w-form">
                  <form id="wf-form-Contact-1-Form" name="wf-form-Contact-1-Form" data-name="Contact 1 Form" method="get" class="contact-modal1_form" data-wf-page-id="64c95413605c1acf75a828e3" data-wf-element-id="ef0a6755-c122-b69b-b4c6-683e1dd7c7a6">
                    <div class="rate-card-form-item"><label for="field" class="rate-card-form-label">Describe the service you&#x27;re looking for - please be as detailed as possible</label><textarea id="field" v-model="model.pimCampaignDescription" name="field" maxlength="5000" data-name="Field" placeholder="I'm looking for..." class="rate-card-form-input is-text-area w-input"></textarea>
                    </div>
                    <div class="rate-card-form-item">
                      <label for="business" class="rate-card-form-label">Select Business</label>
                      <select id="business" v-model="model.pimCampaignBusinessId" required class="rate-card-form-input w-input">
                        <option :value="organisations.organisationId"  v-for="organisations in organisation" :key="organisations.organisationId">{{organisations.organisationName}}</option>
                      </select>
                    </div>
                    <div class="rate-card-form-item">
                      <label class="link-block-28 w-inline-block" for="file" style="cursor: pointer">
                        <input type="file" style="display: none;" id="file" @change="uploadFile('file')">
                        <div style="display: flex; align-items: center; justify-content: center; width: 100%; flex-direction: column; gap: 8px">
                          <img src="../../assets/images/Vector.svg" alt="File"/>
                          <span v-if="!isLoader" style="color: #272835; font-size: 14px; font-weight: 600">Click here to upload</span>
                          <span v-if="!isLoader" style="font-size: 12px;color:#666D80;">PNG, PDF, JPG up to 5MB</span>
                          <p v-else>
                            Uploading file...
                            <base-loader style="" :show="!isLoader"></base-loader>
                          </p>
                        </div>
                        <p style="color: #EA4335; font-size: 12px; width: 100%; text-align: center" v-if="fileSizeLarge"><img src="../../assets/images/info-circle.svg" alt="Caution"/>The file is Larger than 5mb. Please try again</p>
                      </label>
                      <ul class="url-container" v-if="urls.length > 0">
                        <li v-for="(url, index) in urls" :key="index">
                          <img src="../../assets/images/excel.svg" :alt="url.name" v-if="url.name.includes('xlsx') || url.name.includes('xlsm') || url.name.includes('csv')" style="width: 100%; height: 100%; object-fit: cover; border-radius: 16px"/>
                          <img src="../../assets/images/pdf.svg" :alt="url.name" v-else-if="url.name.includes('pdf')" style="width: 100%; height: 100%; object-fit: cover; border-radius: 16px"/>
                          <img src="../../assets/images/word.svg" :alt="url.name" v-else-if="url.name.includes('doc') || url.name.includes('docx')" style="width: 100%; height: 100%; object-fit: cover; border-radius: 16px"/>
                          <img :src="url.img" :alt="url.name" v-else style="width: 100%; height: 100%; object-fit: cover; border-radius: 16px"/>
                          <a @click="urls.splice(index, 1)"><img src="../../assets/images/close-white.svg" alt="Close" style="position:absolute; right: 16px; top: 16px"></a>
                        </li>
                      </ul>
                    </div>

                    <div class="rate-card-form-item" v-if="showPrice"><label for="name-3" class="rate-card-form-label">What is your budget for this service?</label><input type="text" v-model="model.pimCampaignPrice" class="rate-card-form-input price w-input" maxlength="256" name="name-2" data-name="Name 2" placeholder="" id="name-2"></div>
                  </form>
                  <button @click="saveChanges" style="margin-top: 24px" class="btn small align-right w-button" v-if="!isLoader"> Send Request</button>
                  <base-loader v-else style="align-self: center"/>

                  <a data-w-id="445de229-9148-6f4e-3ea6-e15783437aa9" href="#" class="close-topup-modal" @click="close('.custom-quote-modal')" style="position: absolute; right: 10px; top: 10px"><img src="../../assets/images/close-modal.svg" loading="lazy" alt=""></a>
                </div>
              </div>
              <a data-w-id="ef0a6755-c122-b69b-b4c6-683e1dd7c7c1" class="contact-modal1_close-button w-inline-block">
                <div class="icon-embed-small hide-mobile-landscape w-embed"><svg width="100%" height="100%" viewbox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24.056 23.5004L23.5004 24.056C23.1935 24.3628 22.696 24.3628 22.3892 24.056L16 17.6668L9.61078 24.056C9.30394 24.3628 8.80645 24.3628 8.49961 24.056L7.94403 23.5004C7.63719 23.1936 7.63719 22.6961 7.94403 22.3892L14.3332 16L7.94403 9.61081C7.63719 9.30397 7.63719 8.80648 7.94403 8.49964L8.49961 7.94406C8.80645 7.63721 9.30394 7.63721 9.61078 7.94406L16 14.3333L22.3892 7.94404C22.6961 7.6372 23.1935 7.6372 23.5004 7.94404L24.056 8.49963C24.3628 8.80647 24.3628 9.30395 24.056 9.61079L17.6667 16L24.056 22.3892C24.3628 22.6961 24.3628 23.1936 24.056 23.5004Z" fill="currentColor"></path>
                </svg></div><img src="https://uploads-ssl.webflow.com/624380709031623bfe4aee60/624380709031623afe4aee7e_icon_close-modal.svg" loading="lazy" alt="" class="show-mobile-landscape">
              </a>
              <div data-w-id="ef0a6755-c122-b69b-b4c6-683e1dd7c7c4" class="contact-modal1_background-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section" style="height: 100%; background: #fff">
      <div class="container-large">
        <div class="div-block-249">
          <div class="breadcrumb_component">
            <a @click="goToVibes" class="breadcrumb-link w-inline-block">
              <div>Home</div>
            </a>
            <div class="breadcrumb-divider w-embed"><svg width="16" height="16" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 3L11 8L6 13" stroke="CurrentColor" stroke-width="1.5"></path>
            </svg></div>
            <a @click="viewInfluencer" aria-current="page" class="breadcrumb-link is-active w-inline-block w--current">
              <div>Influencer&#x27;s Profile</div>
            </a>
          </div>
          <div class="db-wrapper">
            <div class="influencer-profile-grid">
              <div id="w-node-_329cc3ac-109e-afdb-629a-e9708d41a8f7-75a828e3" class="influencer-profile-wrapper">
                <div class="influencer-profile-details">
                  <div class="influencer-profile-image">
                    <div class="text-block-41" v-if="selectedInfluencer.customerFirstName">{{selectedInfluencer.customerFirstName.charAt(0) + selectedInfluencer.customerLastName.charAt(0)}}</div>
                    <div class="profile-badge"><img src="../../assets/images/Platinum.svg" loading="lazy" alt=""></div>
                  </div>
                  <div class="influencer-profile-text">
                    <h5 class="influencer-name">{{selectedInfluencer.customerFirstName + " " + selectedInfluencer.customerLastName}}</h5>
                    <div class="text-block-40">Content Creator</div>
                  </div>
                </div>
                <div class="div-block-247">
                  <div class="profile-description">
                    <div class="profile-desc-item">
                      <div>Rank:</div>
                      <div class="profile-desc-tag">{{selectedInfluencer.customerLevel}}</div>
                    </div>
                    <div class="profile-desc-item">
                      <div>Country:</div>
                      <div class="profile-desc-tag">{{selectedInfluencer.customerCountry}}</div>
                    </div>
                    <div class="profile-desc-item" v-if="selectedInfluencer.customerInterests">
                      <div>Niche:</div>
                      <div class="profile-desc-tag" v-if="selectedInfluencer.customerInterests.split(',').length > 0">{{selectedInfluencer.customerInterests.split(",")[0]}}</div>
                      <div class="profile-desc-tag"  v-if="selectedInfluencer.customerInterests.split(',').length > 1">{{selectedInfluencer.customerInterests.split(",")[1]}}</div>
                      <div class="profile-desc-tag"  v-if="selectedInfluencer.customerInterests.split(',').length > 2">{{selectedInfluencer.customerInterests.split(",")[2]}}</div>

                    </div>
                  </div>
                  <div class="influencer-text is-about">
                    <h6 class="influencer-text-heading">About</h6>
                    <p class="influencer-text-p" v-if="selectedInfluencer.customerBio">{{selectedInfluencer.customerBio}}</p>
                    <p class="influencer-text-p" v-else>Affiliate marketing is an excellent way to earn passive income. this type of website doesn&#x27;t require any extra maintenance or technical knowledge to run.<br>Every product will be linked with your unique affiliate ID so that you will earn commissions on every sale.</p>
                  </div>
                </div>
                <div class="influencer-text">
                  <h6 class="influencer-text-heading">Insights</h6>
                  <div @click="showSocials" data-hover="false" data-delay="200" data-w-id="6f83808f-ac3f-0310-3e57-54450d716e57" class="dropdown2_component w-dropdown" :class="{'new-socials':socials===true}">
                    <div class="dropdown2_toggle-3 w-dropdown-toggle" :class="{'w--open': socials===true}">
                      <div v-if="socials===true" class="dropdown2_dropdown-icon w-embed" style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(180deg) skew(0deg, 0deg); transform-style: preserve-3d;"><svg width=" 100%" height=" 100%" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.55806 6.29544C2.46043 6.19781 2.46043 6.03952 2.55806 5.94189L3.44195 5.058C3.53958 4.96037 3.69787 4.96037 3.7955 5.058L8.00001 9.26251L12.2045 5.058C12.3021 4.96037 12.4604 4.96037 12.5581 5.058L13.4419 5.94189C13.5396 6.03952 13.5396 6.19781 13.4419 6.29544L8.17678 11.5606C8.07915 11.6582 7.92086 11.6582 7.82323 11.5606L2.55806 6.29544Z" fill="currentColor"></path>
                      </svg></div>
                      <div v-else class="dropdown2_dropdown-icon w-embed"><svg width=" 100%" height=" 100%" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.55806 6.29544C2.46043 6.19781 2.46043 6.03952 2.55806 5.94189L3.44195 5.058C3.53958 4.96037 3.69787 4.96037 3.7955 5.058L8.00001 9.26251L12.2045 5.058C12.3021 4.96037 12.4604 4.96037 12.5581 5.058L13.4419 5.94189C13.5396 6.03952 13.5396 6.19781 13.4419 6.29544L8.17678 11.5606C8.07915 11.6582 7.92086 11.6582 7.82323 11.5606L2.55806 6.29544Z" fill="currentColor"></path>
                      </svg></div>
                      <div class="social-insight-dropdown-item">
                        <img src="../../assets/images/instagram_2.svg" v-if="socialMedia === 'Instagram'" loading="lazy" alt="" class="insight-social-icon">
                        <img src="../../assets/images/twitter_2.svg" v-if="socialMedia === 'Twitter'" loading="lazy" alt="" class="insight-social-icon">
                        <img src="../../assets/images/tiktok.svg" v-if="socialMedia === 'Tiktok'" loading="lazy" alt="" class="insight-social-icon">
                        <div>{{socialMedia}}</div>
                      </div>
                    </div>
                    <nav  style="display:none; position: absolute; min-width: 100%; background-color:#fff; -webkit-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0" class="dropdown2_dropdown-list-3 " :class="{'w--open drop': socials===true}">
                      <a @click="addSocials(social)" style="text-decoration: none" class="insight-dropdown-item" v-for="(social, index) in selectedInfluencer.socialDetails" :key="index">
                        <div>{{social.socialMediaDetailsPlatform}}</div>
                      </a>
                    </nav>
                  </div>
                  <div class="social-insight-group">
                    <div class="social-insight">
                      <div class="insight-title">followers</div>
                      <div class="insight-stat">{{selectedSocial.socialMediaDetailsFollowers.toLocaleString("en-US")}}</div>
                    </div>
                    <div class="social-inisght-divider"></div>
                    <div class="social-insight">
                      <div class="insight-title">following</div>
                      <div class="insight-stat">{{selectedSocial.socialMediaDetailsFollowing.toLocaleString("en-US")}}</div>
                    </div>
                    <div class="social-inisght-divider"></div>
                    <div class="social-insight">
                      <div class="insight-title">Engagements</div>
                      <div class="insight-stat">0%</div>
                    </div>
                    <div class="social-inisght-divider"></div>
                    <div class="social-insight">
                      <div class="insight-title">Average interactions</div>
                      <div class="insight-stat">0</div>
                    </div>
                  </div>
                </div>
                <div class="influencer-text" style="padding-bottom: 50px">
                  <h6 class="influencer-text-heading">Recent Campaigns</h6>
                  <div class="recent-campaigns-block" v-if="recentCampaigns.data.length > 0">
                    <a id="w-node-_5e85f1da-49ae-04dc-978b-20270f63ad00-75a828e3" href="#" class="recent-campaign-item w-inline-block" v-for="(campaign, index) in recentCampaigns.data.slice(0,6)" :key="index"><img :src="campaign.organisationLogo" loading="lazy" alt="" class="recent-campaign-img">
                      <p class="recent-campaign-title">{{campaign.organisationName}}</p>
                    </a>
                  </div>
                  <div class="empty-state home" v-else style="align-items: center; width: 100%"><img width="150" alt="" src="../../assets/images/promotion-1_1.svg" loading="lazy">
                    <div class="empty-state-content">
                      <div class="empty-state__text">
                        <h5 class="empty-mall__h">No recent campaign</h5>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div id="w-node-_6c78acf6-8e2b-1420-6409-8526e9eb374b-75a828e3" class="rate-card">
                <div data-current="Tab 3" class="tab1_component w-tabs">
                  <div class="tab1_tabs-menu no-scrollbar w-tab-menu">
                    <a @click="activateBasic" data-w-tab="Tab 1" :class="basic ? 'tab1_tab-link w-inline-block w-tab-link w--current' : 'tab1_tab-link w-inline-block w-tab-link'">
                      <div>Basic</div>
                    </a>
                    <a @click="activateStandard" data-w-tab="Tab 2" :class="standard ? 'tab1_tab-link w-inline-block w-tab-link w--current' :'tab1_tab-link w-inline-block w-tab-link'">
                      <div>Standard</div>
                    </a>
                    <a @click="activatePremium" data-w-tab="Tab 3" :class="premium ? 'tab1_tab-link w-inline-block w-tab-link w--current': 'tab1_tab-link w-inline-block w-tab-link'">
                      <div>Premium</div>
                    </a>
                  </div>
                  <div class="tab1_tabs-content w-tab-content">
                    <div data-w-tab="Tab 1" :class=" basic ? 'tab1_tab-pane w-tab-pane w--tab-active' : 'tab1_tab-pane w-tab-pane'">
                      <div class="rate-item" v-if="basicPackage.packageName">
                        <div class="rate-card-wrapper">
                          <div class="rate-card-description">
                            <div class="rate-card-heading">
                              <h5 class="rate-card-title" style="text-transform: capitalize">{{basicPackage.packageName}}</h5>
                              <p class="rate-card-price">${{basicPackage.packagePrice}}</p>
                            </div>
                            <p class="rate-card-desc">{{basicPackage.packageDescription}}</p>
                          </div>
                          <ul role="list" class="rate-list" v-if="basicPackage.packageContent.includes(',')">
                            <li class="rate-list-item" v-for="(content, index) in basicPackage.packageContent.split(',')" :key="index">{{content}}</li>
                          </ul>
                          <ul role="list" class="rate-list" v-else-if="basicPackage.packageContent">
                            <li class="rate-list-item">{{basicPackage.packageContent}}</li>
                          </ul>
                          <div class="rate-card-cta-group">
                            <a class="btn small w-button" @click="openModal('.custom-quote-modal'); showPrice = false">Select Package</a>

                            <a @click="toggleShow" data-delay="200" data-hover="false" data-w-id="0f39c512-35be-41dc-d2dc-35affb773f2a" class="dropdown2_component w-dropdown" :class="{'new-socials':show===true}" style="text-decoration: none">
                              <div class="dropdown2_toggle-4 w-dropdown-toggle" :class="{'w--open':show===true}">
                                <div>Contact me</div>
                                <div v-if="show===true" class="dropdown2_dropdown-icon-2 w-embed" style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(180deg) skew(0deg, 0deg) !important; transform-style: preserve-3d;"><svg width=" 100%" height=" 100%" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.55806 6.29544C2.46043 6.19781 2.46043 6.03952 2.55806 5.94189L3.44195 5.058C3.53958 4.96037 3.69787 4.96037 3.7955 5.058L8.00001 9.26251L12.2045 5.058C12.3021 4.96037 12.4604 4.96037 12.5581 5.058L13.4419 5.94189C13.5396 6.03952 13.5396 6.19781 13.4419 6.29544L8.17678 11.5606C8.07915 11.6582 7.92086 11.6582 7.82323 11.5606L2.55806 6.29544Z" fill="currentColor"></path>
                                </svg></div>
                                <div v-else class="dropdown2_dropdown-icon-2 w-embed"><svg width=" 100%" height=" 100%" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.55806 6.29544C2.46043 6.19781 2.46043 6.03952 2.55806 5.94189L3.44195 5.058C3.53958 4.96037 3.69787 4.96037 3.7955 5.058L8.00001 9.26251L12.2045 5.058C12.3021 4.96037 12.4604 4.96037 12.5581 5.058L13.4419 5.94189C13.5396 6.03952 13.5396 6.19781 13.4419 6.29544L8.17678 11.5606C8.07915 11.6582 7.92086 11.6582 7.82323 11.5606L2.55806 6.29544Z" fill="currentColor"></path>
                                </svg></div>
                              </div>
                              <nav :class="{'w--open open-modal': show===true}" style="-webkit-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0" class="dropdown2_dropdown-list-4 w-dropdown-list" >
                                <div class="rate-card-contact-item is-title">How can i help?</div>
                                <a @click="openModal('.custom-quote-modal'); showPrice = true" data-w-id="0f39c512-35be-41dc-d2dc-35affb773f32" class="rate-card-contact-item w-inline-block">
                                  <div>Get a custom quote</div><img loading="lazy" src="../../assets/images/chevron-right_1.svg" alt="">
                                </a>
                                <a v-if="organisation.length>0" @click="goToMessaging" href="#" class="rate-card-contact-item w-inline-block" >
                                  <div>Chat with me</div><img loading="lazy" src="../../assets/images/chevron-right_1.svg" alt="">
                                </a>
                              </nav>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="rate-item-empty-state" v-else>
                        <div class="rate-card-wrapper">
                          <div class="empty-rate-card-description"><img loading="lazy" src="../../assets/images/empty-rate-card.svg" alt="" class="empty-rate-card-img">
                            <div class="empty-rate-card-text">
                              <h6 class="heading-31">Contact influencer</h6>
                              <p class="paragraph-6">Influencer is yet to set package. Click on contact me to speak to influencer</p>
                            </div>
                          </div>
                          <div class="rate-card-cta-group">
                            <a href="#" class="btn small is-disabled w-button">Select Package</a>
                            <div data-delay="200" data-hover="false" data-w-id="96ac7db4-6d3c-b9a0-a171-f56f52e35502" class="dropdown2_component w-dropdown">
                              <div class="dropdown2_toggle-4 w-dropdown-toggle">
                                <div>Contact me</div>
                                <div class="dropdown2_dropdown-icon-2 w-embed"><svg width=" 100%" height=" 100%" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.55806 6.29544C2.46043 6.19781 2.46043 6.03952 2.55806 5.94189L3.44195 5.058C3.53958 4.96037 3.69787 4.96037 3.7955 5.058L8.00001 9.26251L12.2045 5.058C12.3021 4.96037 12.4604 4.96037 12.5581 5.058L13.4419 5.94189C13.5396 6.03952 13.5396 6.19781 13.4419 6.29544L8.17678 11.5606C8.07915 11.6582 7.92086 11.6582 7.82323 11.5606L2.55806 6.29544Z" fill="currentColor"></path>
                                </svg></div>
                              </div>
                              <nav style="-webkit-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0" class="dropdown2_dropdown-list-4 w-dropdown-list">
                                <div class="rate-card-contact-item is-title">How can i help?</div>
                                <a data-w-id="96ac7db4-6d3c-b9a0-a171-f56f52e3550a" href="#" class="rate-card-contact-item w-inline-block">
                                  <div  @click="openModal('.contact-modal1_content-wrapper')">Get a custom quote</div><img loading="lazy" src="../../assets/images/chevron-right_1.svg" alt="">
                                </a>
                                <a href="#" class="rate-card-contact-item w-inline-block">
                                  <div>Chat with me</div><img loading="lazy" src="../../assets/images/chevron-right_1.svg" alt="">
                                </a>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div data-w-tab="Tab 2" :class=" standard? 'tab1_tab-pane w-tab-pane w--tab-active':'tab1_tab-pane w-tab-pane' ">
                      <div v-if="standardPackage.packageName" class="rate-item">
                        <div class="rate-card-wrapper">
                          <div class="rate-card-description">
                            <div class="rate-card-heading">
                              <h5 class="rate-card-title">{{standardPackage.packageName}}</h5>
                              <p class="rate-card-price">${{standardPackage.packagePrice}}</p>
                            </div>
                            <p class="rate-card-desc">{{standardPackage.packageDescription}}</p>
                          </div>
                          <ul role="list" class="rate-list" v-if="standardPackage.packageContent.includes(',')">
                            <li class="rate-list-item" v-for="(content, index) in standardPackage.packageContent.split(',')" :key="index">{{content}}</li>
                          </ul>
                          <ul role="list" class="rate-list" v-else-if="standardPackage.packageContent">
                            <li class="rate-list-item">{{standardPackage.packageContent}}</li>
                          </ul>
                          <div class="rate-card-cta-group">
                            <a class="btn small w-button" @click="openModal('.custom-quote-modal')">Select Package</a>
                            <div  @click="toggleShow" data-delay="200" data-hover="false" data-w-id="ff86300e-8e8a-453f-de3b-9cf06aa3ea4b" class="dropdown2_component w-dropdown">
                              <div class="dropdown2_toggle-4 w-dropdown-toggle" :class="{'w--open':show===true}">
                                <div>Contact me</div>
                                <div v-if="show===true" class="dropdown2_dropdown-icon-2 w-embed" style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(180deg) skew(0deg, 0deg) !important; transform-style: preserve-3d;"><svg width=" 100%" height=" 100%" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.55806 6.29544C2.46043 6.19781 2.46043 6.03952 2.55806 5.94189L3.44195 5.058C3.53958 4.96037 3.69787 4.96037 3.7955 5.058L8.00001 9.26251L12.2045 5.058C12.3021 4.96037 12.4604 4.96037 12.5581 5.058L13.4419 5.94189C13.5396 6.03952 13.5396 6.19781 13.4419 6.29544L8.17678 11.5606C8.07915 11.6582 7.92086 11.6582 7.82323 11.5606L2.55806 6.29544Z" fill="currentColor"></path>
                                </svg></div>
                                <div v-else class="dropdown2_dropdown-icon-2 w-embed"><svg width=" 100%" height=" 100%" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.55806 6.29544C2.46043 6.19781 2.46043 6.03952 2.55806 5.94189L3.44195 5.058C3.53958 4.96037 3.69787 4.96037 3.7955 5.058L8.00001 9.26251L12.2045 5.058C12.3021 4.96037 12.4604 4.96037 12.5581 5.058L13.4419 5.94189C13.5396 6.03952 13.5396 6.19781 13.4419 6.29544L8.17678 11.5606C8.07915 11.6582 7.92086 11.6582 7.82323 11.5606L2.55806 6.29544Z" fill="currentColor"></path>
                                </svg></div>
                              </div>
                              <nav :class="{'w--open open-modal': show===true}" style="-webkit-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0" class="dropdown2_dropdown-list-4 w-dropdown-list">
                                <div class="rate-card-contact-item is-title">How can i help?</div>
                                <a @click="openModal('.custom-quote-modal')" data-w-id="751f4d49-b564-345d-9ad5-f9b44308106a" href="#" class="rate-card-contact-item w-inline-block">
                                  <div>Get a custom quote</div><img loading="lazy" src="../../assets/images/chevron-right_1.svg" alt="">
                                </a>
                                <a href="#" class="rate-card-contact-item w-inline-block">
                                  <div>Chat with me</div><img loading="lazy" src="../../assets/images/chevron-right_1.svg" alt="">
                                </a>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else class="rate-item-empty-state ">
                        <div class="rate-card-wrapper">
                          <div class="empty-rate-card-description"><img loading="lazy" src="../../assets/images/empty-rate-card.svg" alt="" class="empty-rate-card-img">
                            <div class="empty-rate-card-text">
                              <h6 class="heading-31">Contact influencer</h6>
                              <p class="paragraph-6">Influencer is yet to set package. Click on contact me to speak to influencer</p>
                            </div>
                          </div>
                          <div class="rate-card-cta-group">
                            <a href="#" class="btn small is-disabled w-button">Select Package</a>
                            <div data-delay="200" data-hover="false" data-w-id="2b882563-5346-2645-42f4-0088cc7a7bc6" class="dropdown2_component w-dropdown">
                              <div class="dropdown2_toggle-4 w-dropdown-toggle">
                                <div>Contact me</div>
                                <div class="dropdown2_dropdown-icon-2 w-embed"><svg width=" 100%" height=" 100%" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.55806 6.29544C2.46043 6.19781 2.46043 6.03952 2.55806 5.94189L3.44195 5.058C3.53958 4.96037 3.69787 4.96037 3.7955 5.058L8.00001 9.26251L12.2045 5.058C12.3021 4.96037 12.4604 4.96037 12.5581 5.058L13.4419 5.94189C13.5396 6.03952 13.5396 6.19781 13.4419 6.29544L8.17678 11.5606C8.07915 11.6582 7.92086 11.6582 7.82323 11.5606L2.55806 6.29544Z" fill="currentColor"></path>
                                </svg></div>
                              </div>
                              <nav style="-webkit-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0" class="dropdown2_dropdown-list-4 w-dropdown-list">
                                <div class="rate-card-contact-item is-title">How can i help?</div>
                                <a data-w-id="2b882563-5346-2645-42f4-0088cc7a7bce" href="#" class="rate-card-contact-item w-inline-block">
                                  <div>Get a custom quote</div><img loading="lazy" src="../../assets/images/chevron-right_1.svg" alt="">
                                </a>
                                <a href="#" class="rate-card-contact-item w-inline-block">
                                  <div>Chat with me</div><img loading="lazy" src="../../assets/images/chevron-right_1.svg" alt="">
                                </a>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div data-w-tab="Tab 3" :class=" premium ? 'tab1_tab-pane w-tab-pane w--tab-active': 'tab1_tab-pane w-tab-pane'">
                      <div class="rate-item" v-if="premiumPackage.packageName">
                        <div class="rate-card-wrapper">
                          <div class="rate-card-description">
                            <div class="rate-card-heading">
                              <h5 class="rate-card-title">{{premiumPackage.packageName}}</h5>
                              <p class="rate-card-price">${{premiumPackage.packagePrice}}</p>
                            </div>
                            <p class="rate-card-desc">{{premiumPackage.packageDescription}}</p>
                          </div>
                          <ul role="list" class="rate-list" v-if="premiumPackage.packageContent.includes(',')">
                            <li class="rate-list-item" v-for="(content, index) in premiumPackage.packageContent.split(',')" :key="index">{{content}}</li>
                          </ul>
                          <ul role="list" class="rate-list" v-else-if="premiumPackage.packageContent">
                            <li class="rate-list-item">{{premiumPackage.packageContent}}</li>
                          </ul>
                          <div class="rate-card-cta-group">
                            <a class="btn small w-button" @click="openModal('.custom-quote-modal')">Select Package</a>
                            <div @click="toggleShow" data-delay="200" data-hover="false" data-w-id="ff86300e-8e8a-453f-de3b-9cf06aa3ea4b" class="dropdown2_component w-dropdown">
                              <div  class="dropdown2_toggle-4 w-dropdown-toggle" :class="{'w--open':show===true}">
                                <div>Contact me</div>
                                <div v-if="show===true" class="dropdown2_dropdown-icon-2 w-embed" style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(180deg) skew(0deg, 0deg) !important; transform-style: preserve-3d;"><svg width=" 100%" height=" 100%" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.55806 6.29544C2.46043 6.19781 2.46043 6.03952 2.55806 5.94189L3.44195 5.058C3.53958 4.96037 3.69787 4.96037 3.7955 5.058L8.00001 9.26251L12.2045 5.058C12.3021 4.96037 12.4604 4.96037 12.5581 5.058L13.4419 5.94189C13.5396 6.03952 13.5396 6.19781 13.4419 6.29544L8.17678 11.5606C8.07915 11.6582 7.92086 11.6582 7.82323 11.5606L2.55806 6.29544Z" fill="currentColor"></path>
                                </svg></div>
                                <div v-else class="dropdown2_dropdown-icon-2 w-embed"><svg width=" 100%" height=" 100%" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.55806 6.29544C2.46043 6.19781 2.46043 6.03952 2.55806 5.94189L3.44195 5.058C3.53958 4.96037 3.69787 4.96037 3.7955 5.058L8.00001 9.26251L12.2045 5.058C12.3021 4.96037 12.4604 4.96037 12.5581 5.058L13.4419 5.94189C13.5396 6.03952 13.5396 6.19781 13.4419 6.29544L8.17678 11.5606C8.07915 11.6582 7.92086 11.6582 7.82323 11.5606L2.55806 6.29544Z" fill="currentColor"></path>
                                </svg></div>
                              </div>
                              <nav  :class="{'w--open open-modal': show===true}" style="-webkit-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0" class="dropdown2_dropdown-list-4 w-dropdown-list">
                                <div class="rate-card-contact-item is-title">How can i help?</div>
                                <a @click="openModal('.custom-quote-modal')" data-w-id="751f4d49-b564-345d-9ad5-f9b44308106a" href="#" class="rate-card-contact-item w-inline-block">
                                  <div>Get a custom quote</div><img loading="lazy" src="../../assets/images/chevron-right_1.svg" alt="">
                                </a>
                                <a href="#" class="rate-card-contact-item w-inline-block">
                                  <div>Chat with me</div><img loading="lazy" src="../../assets/images/chevron-right_1.svg" alt="">
                                </a>
                              </nav>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div v-else class="rate-item-empty-state">
                        <div class="rate-card-wrapper">
                          <div class="empty-rate-card-description"><img loading="lazy" src="../../assets/images/empty-rate-card.svg" alt="" class="empty-rate-card-img">
                            <div class="empty-rate-card-text">
                              <h6 class="heading-31">Contact influencer</h6>
                              <p class="paragraph-6">Influencer is yet to set package. Click on contact me to speak to influencer</p>
                            </div>
                          </div>
                          <div class="rate-card-cta-group">
                            <a href="#" class="btn small is-disabled w-button">Select Package</a>
                            <div data-delay="200" data-hover="false" data-w-id="a27ec3a2-60f2-e480-36a1-8e1e1dc537de" class="dropdown2_component w-dropdown">
                              <div class="dropdown2_toggle-4 w-dropdown-toggle">
                                <div>Contact me</div>
                                <div class="dropdown2_dropdown-icon-2 w-embed"><svg width=" 100%" height=" 100%" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.55806 6.29544C2.46043 6.19781 2.46043 6.03952 2.55806 5.94189L3.44195 5.058C3.53958 4.96037 3.69787 4.96037 3.7955 5.058L8.00001 9.26251L12.2045 5.058C12.3021 4.96037 12.4604 4.96037 12.5581 5.058L13.4419 5.94189C13.5396 6.03952 13.5396 6.19781 13.4419 6.29544L8.17678 11.5606C8.07915 11.6582 7.92086 11.6582 7.82323 11.5606L2.55806 6.29544Z" fill="currentColor"></path>
                                </svg></div>
                              </div>
                              <nav style="-webkit-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 3rem, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0" class="dropdown2_dropdown-list-4 w-dropdown-list">
                                <div class="rate-card-contact-item is-title">How can i help?</div>
                                <a data-w-id="a27ec3a2-60f2-e480-36a1-8e1e1dc537e6" href="#" class="rate-card-contact-item w-inline-block">
                                  <div>Get a custom quote</div><img loading="lazy" src="../../assets/images/chevron-right_1.svg" alt="">
                                </a>
                                <a href="#" class="rate-card-contact-item w-inline-block">
                                  <div>Chat with me</div><img loading="lazy" src="../../assets/images/chevron-right_1.svg" alt="">
                                </a>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@media screen and (max-width: 991px){
  .container-large{
    margin-top:100px!important;
  }
}

.dropdown2_dropdown-icon-2.w-embed{
  color:#344054;
}
.open-modal{
  transform: translate3d(0px, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !important;
  opacity: 1 !important;
  transform-style: preserve-3d !important;

}
.btn.small.w-button{
  width: 100%;
}
.w--tab-active{
  opacity: 1;
  transition: opacity 300ms ease 0s;
}
.drop{
  transform: translate3d(0px, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !important;
  opacity: 1 !important;
  transform-style: preserve-3d !important;
  display: block !important;
}
.new-socials{
  z-index: 901;
}
.dropdown2_toggle-3{
  min-height: 2.75rem;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  align-items: center;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  font-size: .875rem;
  display: flex;
}
.new-modal{
  transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !important;
  transform-style: preserve-3d !important;
}
.contact-modal1_content-wrapper::-webkit-scrollbar{
  display: none;
}
.contact-modal1_content-wrapper{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.breadcrumb_component:hover{
  color: #667085
}
.rate-card-wrapper{
  grid-row-gap: 20px!important;
  min-height: 370px;
}
.rate-card-cta-group{
  margin-top: auto;
}
.link-block-28{
  border: 1px solid #DFE1E7 !important;
  background: white !important;
  width: 100% !important;
  padding: 16px 14px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
}
.url-container{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  padding: 0;
}

.url-container li{
  width: 48%;
  height: 176px;
  border-radius: 8px;
  position: relative;
  list-style-type: none;
}
</style>